import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AyudaIndexComponent } from './ayuda-index/ayuda-index.component';
import { ArticuloDetalleComponent } from './articulo-detalle/articulo-detalle.component';
import { ApiService } from '../servicios/servicio.api';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [
    ApiService
  ],
  declarations: [AyudaIndexComponent, ArticuloDetalleComponent]
})
export class AyudaModule { }
