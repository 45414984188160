<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>Eliminar Documento</h1>
    <div mat-dialog-content>
      <p>¿Esta seguro que desea eliminar este documento?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="{ result: 'ok', documento: this.documento }" class="btn btn-danger" cdkFocusInitial>Si</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>