import { AbstractFileHandler } from './AbstractFileHandler';

export class FileHandlerWeb extends AbstractFileHandler {

  constructor() {
    super();
  }

  Descargar(base64: string, nombreArchivo: string): void {
    var mediaType = 'data:application/octet-stream;base64,';
    const blob = super.b64toBlob(base64, mediaType);
    const blobUrl = URL.createObjectURL(blob);
    var downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = nombreArchivo;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
