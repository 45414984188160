<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title><i class="fa fa-times"></i> Anular Documento</h1>
    <div mat-dialog-content>
      <p>Para anular este documento ud debe enviar otro documento que revierta esta operación. <br> 
        Al ser esta una Factura Electrónica, deberá emitir una Nota de Crédito. <br>¿Desea crear automaticamente la Nota de Crédito?
      </p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="{ result: 'ok', documento: this.documento }" class="btn btn-success">Crear Documento</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>