import { Component, OnInit } from '@angular/core';
import { ClientesService } from '../../servicios/servicio.clientes';

@Component({
  selector: 'app-cliente-lista',
  templateUrl: './cliente-lista.component.html',
  styleUrls: ['./cliente-lista.component.css']
})
export class ClienteListaComponent implements OnInit {
  public clientes: any;
  public pagina: number;
  public largoPagina: number;
  public filtro: string;

  constructor(private servicioClientes: ClientesService) { }

  ngOnInit() {
    this.pagina = 1;
    this.largoPagina = 10;
    this.filtro = "";
    this.clientes = { datos: [] };
    this.recargar();
  }

  recargar() : void {
    this.servicioClientes.getClientes(this.filtro, this.pagina, this.largoPagina).subscribe(response => {
      this.clientes = response;
    });
  }

  siguientePagina() : void{
    var aux = this.pagina * this.largoPagina;
    this.pagina = this.clientes.datos.totalElementos > aux ? this.pagina + 1 : this.pagina;
    this.recargar();
  }

  anteriorPagina() : void{
    this.pagina = this.pagina > 1 ? this.pagina - 1 : this.pagina;
    this.recargar();
  }

  setFiltro(event: any) : void{
    this.filtro = event.target.value;
    this.pagina = 1;
    this.recargar();
  }
}
