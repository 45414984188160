import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibroDetallesComponent } from './libro-detalles/libro-detalles.component';
import { TranslateModule } from '@ngx-translate/core';
import { LibrosService } from '../servicios/servicio.libro';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LibroSinPeriodoComponent } from './libro-sinperiodo/libro-sinperiodo.component';
import { CambiarperiodoDialogoComponent } from './dialogos/cambiarperiodo-dialogo/cambiarperiodo-dialogo.component';
import { LibroGuiasComponent } from './libro-guias/libro-guias.component';
import { GuiasPorClienteComponent } from './libro-guias/guias-por-cliente/guias-por-cliente.component';
import { LibrosDialogoConfirmarGuiasComponent } from './libro-guias/dialogos/libros-dialogo-confirmarguias/libros-dialogo-confirmarguias.component';
import { LibroImprimirComponent } from './libro-imprimir/libro-imprimir.component';
import { DialogoDescargaXml } from './dialogos/dialogo-descarga-xml/dialogo-descarga-xml.component';
import { DocumentosService } from '../servicios/servicio.documentos';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    FormsModule
  ],
  entryComponents: [CambiarperiodoDialogoComponent, LibrosDialogoConfirmarGuiasComponent, DialogoDescargaXml],
  declarations: [LibroDetallesComponent, LibroSinPeriodoComponent, CambiarperiodoDialogoComponent,
    LibroGuiasComponent, GuiasPorClienteComponent, LibrosDialogoConfirmarGuiasComponent, LibroImprimirComponent, DialogoDescargaXml],
  providers: [LibrosService, DocumentosService]
})
export class LibrosModule { }
