import { Component, OnInit } from '@angular/core';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentosService } from '../../servicios/servicio.documentos';
import { EnumeracionesService } from '../../servicios/servicio.enumeraciones';
import { ClientesService } from '../../servicios/servicio.clientes';
import { InventarioService } from '../../servicios/servicio.inventario';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

import { DocumentoDialogoNuevoClienteComponent } from '../dialogos/documento-dialogo-nuevocliente/documento-dialogo-nuevocliente.component';

@Component({
  selector: 'app-documento-modificar',
  templateUrl: './documento-modificar.component.html',
  styleUrls: ['./documento-modificar.component.css']
})
export class DocumentoModificarComponent implements OnInit {
  public accion: string;
  public idDocumento: string;
  public documento: any;
  public documentoReferenciadoTemporal: any;
  public empresaSeleccionada: any;

  public esVisibleAgregarReferencia: any;

  public destinatarioBuscado: any;
  public destinatariosSugeridos: Array<any>;
  public panelSugerirDestinatarioVisible: any;

  public productosSugeridos: Array<any>;

  public tipoDocumento = TipoDocumento;
  public todosLosImpuestosEmpresa: any[];
  public todosLosImpuestos: any[];
  public comunas: any[];
  public codigosReferenciaDocumentos: any[];
  public tiposDocumentos: any[];
  public formasDePago: any[];
  public mediosDePago: any[];
  public tiposDespacho: any[];
  public indicadoresTraslado: any[];

  public autoCompletarItemActivo: number = -1;

  public enviando: boolean = false;

  private sugerirProductosDelay = 400;
  private subjectSugerirProducto = new Subject();

  constructor(
    private servicioSesiones: SesionesService,
    private route: ActivatedRoute,
    private router: Router,
    private servicioDocumentos: DocumentosService,
    private servicioEnumeraciones: EnumeracionesService,
    private servicioClientes: ClientesService,
    private servicioProductos: InventarioService,
    private servicioNotificaciones: NotificacionesService,
    public dialog: MatDialog
  ) { }

  esElectronico(tipo: TipoDocumento) : boolean {
    if (
      tipo == this.tipoDocumento.FacturaElectronica ||
      tipo == this.tipoDocumento.FacturaExentaElectronica ||
      tipo == this.tipoDocumento.BoletaElectronica ||
      tipo == this.tipoDocumento.BoletaExentaElectronica ||
      tipo == this.tipoDocumento.LiquidacionFacturaElectronica ||
      tipo == this.tipoDocumento.FacturaCompraElectronica ||
      tipo == this.tipoDocumento.GuiaDespachoElectronica ||
      tipo == this.tipoDocumento.NotaDebitoElectronica ||
      tipo == this.tipoDocumento.NotaCreditoElectronica
    )
      return true;
    else
      return false;

  }

  ngOnInit() {
    this.documentoReferenciadoTemporal = {};
    this.empresaSeleccionada = this.servicioSesiones.obtenerSesion().empresaSeleccionada;

    this.comunas = this.servicioEnumeraciones.getComunas();
    this.tiposDocumentos = this.servicioEnumeraciones.getTiposDocumentos();
    this.todosLosImpuestosEmpresa = this.empresaSeleccionada.impuestos;
    this.todosLosImpuestos = this.servicioEnumeraciones.getImpuestos();
    this.codigosReferenciaDocumentos = this.servicioEnumeraciones.getCodigosReferenciaDocumentos();
    this.formasDePago = this.servicioEnumeraciones.formasDePago();
    this.mediosDePago = this.servicioEnumeraciones.mediosDePago();
    this.tiposDespacho = this.servicioEnumeraciones.getTiposDespacho();
    this.indicadoresTraslado = this.servicioEnumeraciones.getIndicadoresTraslado();

    this.route.params.subscribe(params => {
      this.accion = params['accion']; // (+) converts string 'id' to a number
      let idParam = params['id'] * 1.0;
      if (this.accion == "crear") {
        this.panelSugerirDestinatarioVisible = true;
        var impuestosEmpresa = this.todosLosImpuestosEmpresa;
        var impuestosDocumento = [];
        for (let i=0; i < impuestosEmpresa.length; i++)
        {
            impuestosDocumento.push({ impuesto: impuestosEmpresa[i], opcionSeleccionada: 0, seleccionado: true, total: 0 });
        }

        this.documento = {
          numero: 0,
          fechaEmision: new Date(),
          fechaVencimiento: new Date(),
          tipoDocumento: idParam,
          formaDePago: 0,
          medioDePago: 0,
          emisor: { rut: 0, comuna: this.empresaSeleccionada.comuna },
          destinatario: { rut:0, comuna: this.empresaSeleccionada.comuna },
          transporte: {
            destinatario: {
              comuna: this.empresaSeleccionada.comuna
            }
          },
          items: [],
          referencias: [],
          impuestos: impuestosDocumento,
          descuentoPorcentaje: 0
        };
        this.cambioPeriodoDocumento();
      }
      if (this.accion == "emision") {
        this.panelSugerirDestinatarioVisible = true;
        var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
        var impuestosDocumento = [];
        for (let i=0; i < impuestosEmpresa.length; i++)
        {
            impuestosDocumento.push({ impuesto: impuestosEmpresa[i], opcionSeleccionada: 0, seleccionado: true, total: 0 });
        }

        this.documento = {
          numero: 0,
          fechaEmision: new Date(),
          fechaVencimiento: new Date(),
          tipoDocumento: idParam,
          formaDePago: 0,
          medioDePago: 0,
          emisor: this.empresaSeleccionada,
          destinatario: { comuna: this.empresaSeleccionada.comuna },
          items: [],
          referencias: [],
          impuestos: impuestosDocumento,
          descuentoPorcentaje: 0
        };

        if (idParam == TipoDocumento.GuiaDespachoElectronica || idParam == TipoDocumento.GuiaDespacho)
        {
          this.documento.transporte = {
            chofer: { },
            transportista: this.empresaSeleccionada,
            comuna: this.empresaSeleccionada.comuna,
            indicador: 0,
            tipo: 0
          };
        }
        this.cambioPeriodoDocumento();
      }
      if (this.accion == "modificar") {
        this.panelSugerirDestinatarioVisible = false;
        this.idDocumento = params['id'];
        this.servicioDocumentos.getDocumento(this.idDocumento).subscribe(response => {
          this.documento = response.datos;
          this.documento.items.forEach(item => {
            let impuestosCargados = item.impuestos;
            item.impuestos = [];
            var impuestosEmpresa = this.todosLosImpuestosEmpresa;
            for (let i=0; i < impuestosEmpresa.length; i++) {
              let estaSeleccionado = impuestosCargados.find(item => item.seleccionado && item.impuesto.id == impuestosEmpresa[i].id);
              item.impuestos.push({ impuesto: impuestosEmpresa[i], seleccionado: typeof estaSeleccionado !== 'undefined', total: 0 });
            }
          });
        });
      }
      if (this.accion == "duplicar") {
        this.panelSugerirDestinatarioVisible = false;
        this.servicioDocumentos.getDocumento(idParam+"").subscribe(response => {
          this.documento = response.datos;
          this.documento.fechaEmision = new Date();
          this.documento.fechaVencimiento = new Date();
          if (this.documento.emisor.rut == this.empresaSeleccionada.rut && this.esElectronico(this.documento.tipoDocumento)) {
            this.documento.numero = -1;
            this.accion = "emision";
          };
          this.cambioPeriodoDocumento();
        });
      }
      if (this.accion == "anular") {
        this.panelSugerirDestinatarioVisible = false;
        this.servicioDocumentos.getDocumento(idParam+"").subscribe(response => {
          let documentoReferencia = (JSON.parse(JSON.stringify(response.datos)));
          this.documento = response.datos;
          this.documento.fechaEmision = new Date();
          this.documento.fechaVencimiento = new Date();
          this.documento.tipoDocumento = this.tipoDocumento.NotaCreditoElectronica;
          if (this.documento.emisor.rut == this.empresaSeleccionada.rut && this.esElectronico(this.documento.tipoDocumento)) {
            this.documento.numero = -1;
            this.accion = "emision";
          }
          this.documento.referencias = [];
          this.documento.referencias.push({ documento: documentoReferencia, codigoReferencia: 1, razon: "Documento mal emitido", fecha: response.datos.fechaEmision  });
          this.cambioPeriodoDocumento();
        });
      }
      
    });

    this.subjectSugerirProducto.pipe(
      debounceTime(this.sugerirProductosDelay)).
      subscribe((productoBuscado: any) => {
        this.servicioProductos.getProductos(1, 3, productoBuscado.producto).subscribe(response => {
          this.productosSugeridos = response.datos.datos;
          if (this.productosSugeridos && this.productosSugeridos.length > 0 && this.productosSugeridos[0].codigoInterno == productoBuscado.producto){
            this.seleccionarProducto(productoBuscado.indice, this.productosSugeridos[0]);
            this.autoCompletarItemActivo = -1;
          }
        });
    });
  }

  agregarNuevoItem(): void {
    this.autoCompletarItemActivo = -1;
    var impuestosItem = [];
    if (this.accion == "emision") {
      var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
      for (let i=0; i < impuestosEmpresa.length; i++) {
          impuestosItem.push({ impuesto: impuestosEmpresa[i], seleccionado: true, total: 0 });
      }
    }
    else {
      var impuestosEmpresa = this.todosLosImpuestosEmpresa;
      console.log(impuestosEmpresa);
      for (let i=0; i < impuestosEmpresa.length; i++) {
          impuestosItem.push({ impuesto: impuestosEmpresa[i], seleccionado: impuestosEmpresa.filter(m => m.id == impuestosEmpresa[i].id && m.afectaLibroVentas).length > 0, total: 0 });
      }
    }

    this.documento.items.push({
      cantidad: 1,
      unidadMedida: {
        id: 0,
        nombre: "Unidad",
        codigo: "UN"
      },
      glosa: "",
      precioUnitario: 0,
      descuentoPorcentaje: 0,
      impuestos: impuestosItem,
      descuento: 0,
      total: 0,
      producto: {
        id: -1
      }
    });
  }

  agregarNuevoDocumentoReferenciado(): void {
    let validacion = true;
    let mensajes = [];

    if (!this.documentoReferenciadoTemporal.tipoDocumento) {
      mensajes.push("Hay problemas con el tipo del documento referenciado");
      validacion = false;
    }
    if (!this.documentoReferenciadoTemporal.numero || this.documentoReferenciadoTemporal.numero <= 0) {
      mensajes.push("Hay problemas con el numero del documento referenciado");
      validacion = false;
    }
    if (!this.documentoReferenciadoTemporal.codigoReferencia && this.documentoReferenciadoTemporal.codigoReferencia != 0) {
      mensajes.push("Hay problemas con el codigo de referencia del documento referenciado");
      validacion = false;
    }
    if (!this.documentoReferenciadoTemporal.motivo) {
      mensajes.push("Hay problemas con el motivo del documento referenciado");
      validacion = false;
    }
    if (!this.documentoReferenciadoTemporal.fecha) {
      mensajes.push("Hay problemas con la fecha del documento referenciado");
      validacion = false;
    }
    if (this.documento.referencias.length >= 40) {
      mensajes.push("No se pueden agregar mas de 40 documentos referenciados");
      validacion = false;
    }

    if (validacion) {
      this.documento.referencias.push({
        documento: {
          tipoDocumento: this.documentoReferenciadoTemporal.tipoDocumento,
          numero: (1 * this.documentoReferenciadoTemporal.numero)
        },
        razon: this.documentoReferenciadoTemporal.motivo,
        codigoReferencia: this.documentoReferenciadoTemporal.codigoReferencia,
        fecha: this.documentoReferenciadoTemporal.fecha
      });
      this.documentoReferenciadoTemporal = {};
      this.esVisibleAgregarReferencia = false;
    }
    else {
      var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + mensajes[0] + "</p>", "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
    }
  }

  eliminarItem(index: number): void {
    this.documento.items.splice(index, 1);
    this.recalcular();
  }

  cambioPeriodoDocumento(): void {
    var anio = new Date(this.documento.fechaEmision).getFullYear() * 1;
    var mes = (new Date(this.documento.fechaEmision).getMonth() + 1) * 1;
    var nuevoPeriodo = {
      empresa: this.empresaSeleccionada,
      anio: anio,
      mes: mes,
      estaAbierto: true,
      existe: true
    };
    this.documento.periodo = nuevoPeriodo;
  }

  cambioRutDestinatario() {
    this.servicioClientes.getClientes(this.documento.destinatario.rut, 1, 10).subscribe(res => {
      if (res.datos.length > 0) {
        var finded = res.datos.find(c => c.rut == this.documento.destinatario.rut);
        if (finded) {
          this.documento.destinatario = finded;
        }
        else {
          this.documento.destinatario = { rut: 0, comuna: this.empresaSeleccionada.comuna };
        }
      }
    });
    this.documento.destinatario.rut = Number(this.documento.destinatario.rut);
  }

  guardar(): any {
    this.cambioPeriodoDocumento();

    if (this.accion == "crear") {
      this.ingresar();
    }
    if (this.accion == "modificar") {
      this.modificar();
    }
    if (this.accion == "emision") {
      this.emision();
    }
    if (this.accion == "duplicar") {
      this.ingresar();
    }
  }

  emision(): void {
    this.enviando = true;
    if(this.documento.tipoDocumento == 39) {
      this.servicioDocumentos.emisionBoletaElectronica(this.documento).subscribe(res => { 
        this.manejarRespuestaEmision(res); 
        this.enviando = false; 
      });
    } 
    else {
      this.servicioDocumentos.emision(this.documento).subscribe(res => { 
        this.manejarRespuestaEmision(res); 
        this.enviando = false; 
        this.servicioClientes.getCliente(this.documento.destinatario.rut).subscribe(res2 => {}, (error) => {
          if (error.status == 404 && error.error.esCorrecto){
            const dialogRef = this.dialog.open(DocumentoDialogoNuevoClienteComponent, {
              width: '70%',
              data: this.documento
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result && result.result == 'ok') {
                this.servicioClientes.crearCliente(this.documento.destinatario).subscribe(respuestaCliente => {
                  var notificacion = new Notificacion("Cliente creado", "<p>El cliente fue guardado exitosamente</p>", "Info");
                  this.servicioNotificaciones.agregarNotificacion(notificacion);
                });
              }
            });
          }
        });
      });
    }
  }

  private manejarRespuestaEmision(res){
    if (res.esCorrecto)
    {
      this.router.navigate(['/erp/documentos/documento-detalle/', res.datos.id]);
    }
    else
    {
      var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
    }

  }

  ingresar(): void {
    this.enviando = true;
    this.servicioDocumentos.ingreso(this.documento).subscribe(res => {
      if (res.esCorrecto) 
      {
        this.router.navigate(['/erp/documentos/documento-detalle/', res.datos.id]);
        this.enviando = false; 

        this.servicioClientes.getCliente(this.documento.destinatario.rut).subscribe(res2 => {}, (error) => {
          if (error.status == 404 && error.error.esCorrecto){
            const dialogRef = this.dialog.open(DocumentoDialogoNuevoClienteComponent, {
              width: '70%',
              data: this.documento
            });
        
            dialogRef.afterClosed().subscribe(result => {
              if (result && result.result == 'ok') {
                this.servicioClientes.crearCliente(this.documento.destinatario).subscribe(respuestaCliente => {
                  var notificacion = new Notificacion("Cliente creado", "<p>El cliente fue guardado exitosamente</p>", "Info");
                  this.servicioNotificaciones.agregarNotificacion(notificacion);
                });
              }
            });
          }
        });
      }
      else
      {
        var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      this.enviando = false;
    });
  }

  modificar(): void {
    this.enviando = true;
    this.servicioDocumentos.modificar(this.documento).subscribe(res => {
      if (res.esCorrecto)
      {
        this.router.navigate(['/erp/documentos/documento-detalle/', this.documento.id]);
      }
      else
      {
        var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      this.enviando = false;
    });
  }

  eliminar(): void {
    this.enviando = true;
    this.servicioDocumentos.eliminar(this.documento).subscribe(res => {
      if (res.esCorrecto)
      {
        this.router.navigate(['erp/users/user-desktop']);
      }
      else
      {
        var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      this.enviando = false;
    });
  }

  eliminarDocumentoReferenciado(index: number): void {
    this.documento.referencias.splice(index, 1);
  }

  private obtenerOpcionesImpuesto(impuesto: any): any[] {
    var imp = this.todosLosImpuestos.find(imp => imp.id == impuesto.id);
    if(imp)
      return imp.Opciones;
    else
      return [];
  }

  private recalcular(): void {
    //calcula totales del documento
    this.documento.subtotal = 0;
    this.documento.neto = 0;
    this.documento.exento = 0;
    this.documento.total = 0;
    let totalImpuestos = 0;

    if (this.documento.tipoDocumento == this.tipoDocumento.BoletaElectronica) {
      //calcula el neto unitario por item
      this.documento.items.forEach(item => {
        if(item.precioUnitario != 0 && (item.precioBruto == undefined || item.precioBruto == 0)) {
          item.precioBruto = this.montoBrutoUnitarioItem(item);
        }
        else {
          item.precioUnitario = this.montoNetoUnitarioItem(item);
        }

        let totalSinDescuento = item.cantidad * item.precioBruto;
        item.total = Math.round(totalSinDescuento - (totalSinDescuento * item.descuentoPorcentaje / 100));
      });

      ////////////////////////////////////////
      // recorrer impuestos, calcular la suma y despues deglosar entre neto bruto e impuestos considerando descuentos
      for (let i=0; i< this.documento.impuestos.length; i++) {
        let totalBrutoImpuesto = 0;
        for (let j=0; j< this.documento.items.length; j++) {
          for (let k=0; k< this.documento.items[j].impuestos.length; k++) {
            if (this.documento.items[j].impuestos[k].impuesto.id == this.documento.impuestos[i].impuesto.id) {
              if (this.documento.items[j].impuestos[k].seleccionado && this.documento.impuestos[i].impuesto.tasa > 0) {
                let totalBrutoItem =  (this.documento.items[j].impuestos[k].total + this.documento.items[j].precioUnitario) * this.documento.items[j].cantidad;
                totalBrutoImpuesto += totalBrutoItem;
              }
            }
          }
        }

        let neto = Math.floor(totalBrutoImpuesto / ((100 + this.documento.impuestos[i].impuesto.tasa) / 100))
        let impuestoDelNeto = Math.ceil(neto * this.documento.impuestos[i].impuesto.tasa / 100);

        if(neto + impuestoDelNeto < totalBrutoImpuesto){
          impuestoDelNeto++;
        }

        this.documento.subtotal += neto;

        if (this.documento.descuentoPorcentaje > 0) {
          this.documento.impuestos[i].total = Math.round(impuestoDelNeto - (impuestoDelNeto * this.documento.descuentoPorcentaje / 100));
        }
        else {
          this.documento.impuestos[i].total = Math.round(impuestoDelNeto);
        }

        totalImpuestos += this.documento.impuestos[i].total;
      }
      ////////////////////////////////////////
    }
    else{
      //calcula totales de los items
      this.documento.items.forEach(item => {
        let totalSinDescuento = item.cantidad * item.precioUnitario;
        item.total = Math.round(totalSinDescuento - (totalSinDescuento * item.descuentoPorcentaje / 100));
      });

      //calcula el subtotal del documento
      this.documento.items.forEach(item => {
        if(item.impuestos.some(i => i.seleccionado)) {
          this.documento.subtotal += item.total;
        }
      });

      //calcula impuestos
      for (let i=0; i< this.documento.impuestos.length; i++) {
        var netoImpuesto = 0;
        for (let j=0; j< this.documento.items.length; j++) {
          for (let k=0; k< this.documento.items[j].impuestos.length; k++) {
            if (this.documento.items[j].impuestos[k].impuesto.id == this.documento.impuestos[i].impuesto.id) {
              if (this.documento.items[j].impuestos[k].seleccionado && this.documento.impuestos[i].impuesto.tasa > 0) {
                netoImpuesto += 1.0 * this.documento.items[j].total * this.documento.impuestos[i].impuesto.tasa / 100;
              }
            }
          }
        }

        if (this.documento.descuentoPorcentaje > 0) {
          this.documento.impuestos[i].total = Math.round(netoImpuesto - (netoImpuesto * this.documento.descuentoPorcentaje / 100));
        }
        else {
          this.documento.impuestos[i].total = Math.round(netoImpuesto);
        }
        totalImpuestos += this.documento.impuestos[i].total;
      }
    }

    //calcula el descuento y el neto
    this.documento.neto = Math.round(this.documento.subtotal - Math.round(this.documento.subtotal * this.documento.descuentoPorcentaje / 100));
    this.documento.descuentoMonto = this.documento.subtotal - this.documento.neto;

    //calcula el exento del documento
    this.documento.items.forEach(item => {
      if(!item.impuestos.some(i => i.seleccionado)) {
        this.documento.exento += item.total;
      }
    });

    this.documento.total = this.documento.neto + this.documento.exento + totalImpuestos;
  }

  seleccionarDestinatarioEstaEmpresa() : void {
    this.documento.destinatario = JSON.parse(JSON.stringify(this.empresaSeleccionada));
    this.panelSugerirDestinatarioVisible = false;
  }

  seleccionarEmisorEstaEmpresa() : void {
    this.documento.emisor = JSON.parse(JSON.stringify(this.empresaSeleccionada));
  }

  agregarTransporte() : void {
    if (!this.documento.transporte)
    {
      this.documento.transporte = {
        chofer: { rut: 0, digitoVerificador: "0" },
        transportista: this.empresaSeleccionada,
        comuna: this.empresaSeleccionada.comuna,
        indicador: 0,
        tipo: 0
      };
    }
    else
    {
      console.log("el transporte ya existe");
    }
  }

  sugerirDestinatario() : void {
    if (this.destinatarioBuscado == '') {
      this.destinatariosSugeridos = [];
    }
    else {
      this.servicioClientes.getClientes(this.destinatarioBuscado, 1, 3).subscribe(response => {
        this.destinatariosSugeridos = response.datos.datos;
        this.destinatariosSugeridos.forEach(dest => { dest.nombre = dest.razonSocial; });
      });
    }
  }

  seleccionarDestinatario(destinatario: any) : void {
    this.documento.destinatario = destinatario;
  }

  sugerirProducto(i: number) : void {
    this.autoCompletarItemActivo = i;
    var productoBuscado = this.documento.items[i].glosa;
    if (productoBuscado == '') {
      this.productosSugeridos = [];
    }
    else {
      if (this.documento.items[i].producto.id < 0){
        setTimeout(() => this.subjectSugerirProducto.next({ producto: productoBuscado, indice: i }), 0);
      }
    }
  }

  seleccionarProducto(index: any, producto: any) : void {
    this.documento.items[index].glosa = producto.nombre;
    this.documento.items[index].producto = producto;
    this.documento.items[index].precioUnitario = producto.precio;
    var impuestosItem = [];

    for (let i=0; i < this.documento.impuestos.length; i++) {
      impuestosItem.push({ impuesto: this.documento.impuestos[i].impuesto, seleccionado: producto.impuestos.some(m => m.id == this.documento.impuestos[i].impuesto.id), total: 0 });
    }

    this.documento.items[index].impuestos = impuestosItem;
    this.recalcular();
  }

  montoBrutoUnitarioItem(item) : number {
    let sum = item.precioUnitario;
    item.impuestos.forEach(i => {
      if(i.seleccionado) {
        i.total = i.impuesto.tipoTasa == 0 ? Math.round(item.precioUnitario * i.impuesto.tasa / 100) : i.impuesto.tasa;
        sum += i.total;
      }
    });
    return sum;
  }

  montoNetoUnitarioItem(item) : number {
    let impuestos = 0;
    item.impuestos.forEach(i => {
      if(i.seleccionado) {
        // Me huele a que esto falla si hay más de un impuesto aplicado
        item.precioBruto = isNaN(item.precioBruto) ? 0 : item.precioBruto;
        let neto = i.impuesto.tipoTasa == 0 ? Math.floor(item.precioBruto / ((100 + i.impuesto.tasa) / 100)) : item.precioBruto - i.impuesto.tasa;
        let impuestoDelNeto = i.impuesto.tipoTasa == 0 ? Math.ceil(neto * i.impuesto.tasa / 100) : neto + i.impuesto.tasa;
        if(neto + impuestoDelNeto < item.precioBruto){
          impuestoDelNeto++;
        }
        i.total = impuestoDelNeto;
        impuestos += i.total;
      }
    });
    return item.precioBruto - impuestos;
  }

  private sinDestinatario() : void {
    this.documento.destinatario = { rut: 66666666, comuna: this.empresaSeleccionada.comuna };
    this.panelSugerirDestinatarioVisible = false;
    console.log(this.documento);
  }
}
