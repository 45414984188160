<div class="panel panel-default">
    <div class="panel-heading">
        <h3>Folios</h3>
    </div>
    <div class="panel-body">
        <p>En la siguiente tabla ud podrá ver todos los folios que se han asociado a su empresa.</p>
        <ng-container *ngIf="this.cargando">
            <i class="fa fa-spinner"></i>
        </ng-container>
        <ng-container *ngIf="!this.cargando">
            <ng-container *ngIf="this.foliosEnUso && this.foliosEnUso.length > 0">
                <hr />
                <h4>En Uso</h4>
                <table class="table table-bordered table-hover table-striped tablaFiltrada">
                    <thead>
                        <tr>
                            <th>Tipo Documento</th>
                            <th class="hidden-xs hidden-sm">Desde</th>
                            <th class="hidden-xs hidden-sm">Hasta</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let folio of this.foliosEnUso">
                            <td>{{ 'DOCUMENTOS.TipoDocumento_' + folio.tipoDocumento | translate }}</td>
                            <td class="numero hidden-xs hidden-sm">{{ folio.desde }}</td>
                            <td class="numero hidden-xs hidden-sm">{{ folio.hasta }}</td>
                            <td class="right">
                                <button type="button" class="btn btn-primary" mat-button
                                    [matMenuTriggerFor]="menuAccionesActivos">
                                    Acciones <span class="caret"></span>
                                </button>
                                <mat-menu #menuAccionesActivos="matMenu">
                                    <button mat-menu-item [routerLink]="['/erp/instancia/folio-detalle', folio.id]"><i
                                            class="fa fa-eye"></i> Mas detalles</button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr />
                
                <button class="btn btn-secondary" *ngIf="!mostrarTodosLosNoActivos"
                    (click)="mostrarTodos(); mostrarTodosLosNoActivos = !mostrarTodosLosNoActivos">Ver Todos... </button>

                <table *ngIf="mostrarTodosLosNoActivos"
                    class="table table-bordered table-hover table-striped tablaFiltrada">
                    <thead>
                        <tr>
                            <th>Tipo Documento</th>
                            <th class="hidden-xs hidden-sm">Desde</th>
                            <th class="hidden-xs hidden-sm">Hasta</th>
                            <th class="hidden-xs">¿En Uso?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let folio of this.foliosArchivados">
                            <td>{{ 'DOCUMENTOS.TipoDocumento_' + folio.tipoDocumento | translate }}</td>
                            <td class="numero hidden-xs hidden-sm">{{ folio.desde }}</td>
                            <td class="numero hidden-xs hidden-sm">{{ folio.hasta }}</td>
                            <td class="numero hidden-xs">{{ folio.enUso ? "Si" : "No" }}</td>
                            <td class="right">
                                <button type="button" class="btn btn-primary" mat-button
                                    [matMenuTriggerFor]="menuAccionesOtros">
                                    Acciones <span class="caret"></span>
                                </button>
                                <mat-menu #menuAccionesOtros="matMenu">
                                    <button mat-menu-item [routerLink]="['/erp/instancia/folio-detalle', folio.id]"><i
                                            class="fa fa-eye"></i> Mas detalles</button>
                                    <button mat-menu-item (click)="utilizar(folio)"><i class="fa fa-check"></i> Utilizar</button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="mostrarTodosLosNoActivos && (!this.foliosArchivados || this.foliosArchivados.length < 1)">
                    <p>No hay folios para mostrar</p>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
    <div class="panel-footer">
        <a class="btn btn-warning pull-right" onclick="javascript:history.go(-1)">
            <i class="fa fa-arrow-left"></i> Regresar</a>
        <a (click)="dialogoSubirFolio()" class="btn btn-success">
            <i class="fa fa-plus"></i> Nuevo Folio</a>
    </div>
</div>