
<div class="panel panel-default">
        <div class="panel-heading">
            <h4>Detalles de los Folios</h4>
        </div>
        <div class="panel-body">
            <div *ngIf="this.folio" class="row">
                <div class="col-md-12">
                    Tipo de Folio: <strong>{{ 'DOCUMENTOS.TipoDocumento_' + folio.tipoDocumento | translate }}</strong><br />
                    Desde: <strong>{{ folio.desde }}</strong><br />
                    Hasta: <strong>{{ folio.hasta }}</strong><br />
                    Disponibles: <strong>{{ (folio.hasta - folio.desde + 1) - this.documentosFolio.totalElementos }}</strong><br />
                    Utilizados:<strong> {{ this.documentosFolio.totalElementos }}</strong><br />
                    Fecha Autorizacion: <strong>{{ folio.fechaAutorizacion | date : "dd/MM/yyyy" }}</strong><br />
                </div>

                <div *ngIf="this.documentosFolio && this.documentosFolio.datos && this.documentosFolio.totalElementos > 0" class="col-md-12">
                    <hr />



                    <h4>Documentos</h4>
                    <p>({{this.documentosFolio.totalElementos}} documentos)</p>
                    <!--div class="table-responsive"-->
                    <table class="table table-bordered table-hover table-striped tablaFiltrada">
                        <thead>
                            <tr>
                                <th class="numero">Folio</th>
                                <th class="hidden-xs hidden-sm">Fecha Emision</th>
                                <th class="numero">Rut</th>
                                <th class="hidden-xs hidden-sm">Destinatario</th>
                                <th class="numero">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let doc of this.documentosFolio.datos">
                                <td class="numero"><a [routerLink]="['/erp/documentos/documento-detalle', doc.id]">{{ doc.numero | number:'':'es-CL' }}</a></td>
                                <td class="hidden-xs hidden-sm">{{ doc.fechaEmision | date : "dd/MM/yyyy" }}</td>
                                <td class="numero">{{ doc.destinatario.rut | number:'':'es-CL' }} - {{ doc.destinatario.digitoVerificador }}</td>
                                <td class="hidden-xs hidden-sm">{{ doc.destinatario.razonSocial }}</td>
                                <td class="numero">{{ doc.total | number:'':'es-CL' }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina) + documentosFolio.datos.length }} de {{ documentosFolio.totalElementos }} documentos</div>
                        </div>
                        <div class="col-sm-6">
                            <ul class="pagination pull-right">
                                <li class="paginate_button previous">
                                    <a (click)="anteriorPagina()">Anterior</a>
                                </li>
                                <li class="paginate_button next" aria-controls="dataTables-example" tabindex="0">
                                    <a (click)="siguientePagina()">Siguiente</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--/div-->
                </div>
                <div *ngIf="this.documentosFolio && this.documentosFolio.length == 0" class="col-md-12">
                    <hr />
                    <h4>Documentos</h4>
                    <p>No existen documentos asociados a este folio.</p>
                </div>
            </div>

        </div>
        <div class="panel-footer">
            <a class="btn btn-warning" onclick="javascript:history.go(-1)"><i class="fa fa-arrow-left"></i> Regresar</a>
        </div>
    </div>
