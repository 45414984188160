<div class="panel panel-default">
  <div class="panel-heading">
      <h4><i class="fa fa-question-circle"></i> Ayuda</h4>
  </div>
  <div class="panel-body">
      <ul>
              <li *ngFor="let ayuda of this.articulos">
                  <a [routerLink]="['/erp/ayuda/articulo-detalle', ayuda.id]">
                      {{ayuda.titulo}}
                  </a>
              </li>
      </ul>
  </div>
  <div class="panel-footer">
      <span class="right">
          <small>Ultima actualización</small> 02-05-2017 9:00:00
      </span>
  </div>
</div>