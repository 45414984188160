<div class="row">
    <div class="col-xs-12">
        <h1 mat-dialog-title>Cambio Firma Electrónica</h1>
        <div mat-dialog-content>
            <p>Seleccione su firma digital con la cual firmará los documentos electrónicos. Esto le permitirá emitir
                documentos desde su celular y cualquier disposivo.</p>
            <p><strong>(El formato aceptado es .pfx)</strong></p>
            <input type="file" (change)="subirFirma($event)">
            <p>
                A continuación ingrese la contraseña de este certificado, esta se le solicitará solo por esta vez.
            </p>
            <div class="form-group">
                <label>
                    Contraseña
                </label>
                <input class="form-control" placeholder="Ingrese la contraseña del certificado digital" required="true" type="password" [(ngModel)]="this.nuevaFirmaPassword">
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button class="btn btn-danger" [mat-dialog-close]="{ result: 'ok', pass: this.nuevaFirmaPassword, pfx: this.nuevaFirma }" cdkFocusInitial>Cambiar</button>
            <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
        </div>
    </div>
</div>