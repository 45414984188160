import { Component, OnInit } from '@angular/core';
import { UsuariosService } from '../../servicios/servicio.usuarios';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioCambiarcontrasenaComponent } from './dialogos/usuario-cambiarcontrasena/usuario-cambiarcontrasena.component';
import { UsuarioCambiarfirmaComponent } from './dialogos/usuario-cambiarfirma/usuario-cambiarfirma.component';

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.css']
})
export class UserOptionsComponent implements OnInit {
  usuarioSeleccionado: any;

  constructor(private servicioSesiones: SesionesService,
    private servicioUsuarios: UsuariosService,
    private servicioNotificaciones: NotificacionesService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.usuarioSeleccionado = this.servicioSesiones.obtenerSesion().usuarioAutenticado;
    console.log(this.usuarioSeleccionado);
  }

  abrirDialogoCambiarContrasena(): void {
    const dialogRef = this.dialog.open(UsuarioCambiarcontrasenaComponent, {
      width: '80%',
      data: this.usuarioSeleccionado
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result == 'ok') {
        this.cambiarPass(result.pass);
      }
    });
  }

  abrirDialogoCambiarFirma(): void {
    const dialogRef = this.dialog.open(UsuarioCambiarfirmaComponent, {
      width: '80%',
      data: this.usuarioSeleccionado
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result == 'ok') {
        console.log(result);
        this.cambiarFirma(result.pfx, result.pass);
      }
    });
  }

  cambiarPass(nuevaPassword: string): void {
    this.servicioUsuarios.cambiarPassword(nuevaPassword).subscribe(res => {
      if (res.esCorrecto) {
        var titulo = "Contraseña modificada con éxito";
        var mensaje = "<p>Su contraseña fue actualizada exitosamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      else {
        var titulo = "Hubieron problemas modificando su contraseña";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }

  cambiarFirma(nuevaFirma, nuevaFirmaPassword): void {
    console.log(nuevaFirma, nuevaFirmaPassword);
    const formData = new FormData();
    formData.append('firma', nuevaFirma, nuevaFirma.name);
    this.servicioUsuarios.cambioFirma(formData).subscribe(res => {
      if (res.esCorrecto) {
        this.servicioUsuarios.cambioPasswordFirma(nuevaFirmaPassword).subscribe(res2 => {
          if (res2.esCorrecto) {
            var titulo = "Certificado digital modificado con éxito";
            var mensaje = "<p>Su certificado digital fue actualizazado exitosamente.</p>";
            var notificacion = new Notificacion(titulo, mensaje);
            this.servicioNotificaciones.agregarNotificacion(notificacion);
          }
          else {
            var titulo = "Hubieron problemas modificando la contraseña de su certificado digital";
            var mensaje = "<p><ul>";
            res2.mensajes.forEach(m => {
              mensaje = mensaje + "<li>" + m + "</li>";
            });
            mensaje = mensaje + "</ul></p>";
            var notificacion = new Notificacion(titulo, mensaje, "Error");
            this.servicioNotificaciones.agregarNotificacion(notificacion);
          }
        });
      }
      else {
        var titulo = "Hubieron problemas subiendo su certificado digital";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }
}
