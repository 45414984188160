import { Component, OnInit } from '@angular/core';
import { DocumentosService } from '../../servicios/servicio.documentos';

@Component({
  selector: 'app-administracion-certificacionboletas',
  templateUrl: './administracion-certificacionboletas.component.html',
  styleUrls: ['./administracion-certificacionboletas.component.css']
})
export class AdministracionCertificacionBoletasComponent implements OnInit {

  public boletasAEmitir: string;

  constructor(
    private servicioDocumentos: DocumentosService
  ) { }

  ngOnInit() {
  }

  emitir(){
    var boletasArray = this.boletasAEmitir.split(',');
    boletasArray = boletasArray.map(boletaId => boletaId.trim());

    let documentos = []
    boletasArray.forEach(boletaId => {
      documentos.push({
        id: Number(boletaId)
      });
    });

    this.enviarDocumentos(documentos);
    // var observables = []
    // boletasArray.map(boletaId =>
    //   observables.push(
    //     this.servicioDocumentos.getDocumento(boletaId).pipe()
    //   )
    // );

    // forkJoin(observables).subscribe(response => {
    //   var documentos = [];
    //   response.forEach(element => {
    //     documentos.push((element as any).datos);
    //   });

    //   this.enviarDocumentos(documentos);
    // })
  }

  enviarDocumentos(documentos: any[]) {
    console.log(documentos);
    this.servicioDocumentos.emitirBoletasParaCertificacion(documentos).subscribe(response => console.log(response));
  }
}
