<ng-container *ngIf="libro">
    <h2>Libro de  Guías</h2>
    <h4>Periodo {{ this.libro.periodo.mes }} / {{ this.libro.periodo.anio }} ({{ this.libro.periodo.estaAbierto ? "Abierto" : "Cerrado" }})</h4>
    <div class="input-group">
        <div class="input-group-btn">
            <a class="btn btn-success" (click)="mesAnterior()">
                <i class="fa fa-arrow-left"></i>
            </a>
            <a class="btn btn-primary" data-toggle="modal" (click)="abrirModalCambiarMes()">
                <i class="fa fa-level-up"></i> Cambiar
            </a>
            <a class="btn btn-success" (click)="mesSiguiente()">
                <i class="fa fa-arrow-right"></i>
            </a>
        </div>
    </div>
    <hr />
    <div>
        <div class="row">
            <div class="col-sm-6">
                <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina) + this.largoPagina }} de {{ this.destinatariosDocumentos.totalElementos | number:'':'es-CL' }} destinatarios</div>
            </div>
            <div class="col-sm-6">
                <div class="dataTables_filter">
                    <div class="row">
                        <div class="col-sm-6" style="text-align: right">
                            <span>Filtrar: </span>
                        </div>
                        <div class="col-sm-6" style="margin-bottom: 5px;">
                            <input class="form-control input-sm" [(ngModel)]="this.filtroDestinatario" (keyup)="filtrarDestinatarios()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="this.destinatariosDocumentos.datos && this.destinatariosDocumentos.datos.length > 0">
        <table class="table table-bordered table-hover table-striped tablaFiltrada">
            <thead>
                <tr>
                    <th class="numero">Rut</th>
                    <th class="hidden-xs hidden-sm">Destinatario</th>
                    <th class="hidden-xs hidden-sm numero">Cantidad Guías</th>
                    <th class="numero">Total Guías</th>
                    <th class=""></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let resumenDest of this.destinatariosDocumentos.datos">
                    <td class="numero">
                        {{resumenDest.rut | number:'':'es-CL'}} - {{ dv(resumenDest.rut) }}
                    </td>
                    <td class="hidden-xs hidden-sm">
                        {{resumenDest.nombre | slice:0:26}}
                    </td>
                    <td class="hidden-xs hidden-sm numero">{{ resumenDest.cantidadDocumentos | number:'':'es-CL' }}</td>
                    <td class="numero">{{ resumenDest.totalDocumentos | number:'':'es-CL' }}</td>
                    <td class="numero"><a style="cursor: pointer;" (click)="verTodos(resumenDest.rut)">Ver Guías</a></td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col-sm-6">
                <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina)
                    + this.largoPagina }} de {{ this.destinatariosDocumentos.totalElementos | number:'':'es-CL' }} destinatarios</div>
            </div>
            <div class="col-sm-6">
                <ul class="pagination pull-right">
                    <li class="paginate_button previous" [class.disabled]="!tienePaginaAnterior()">
                        <a [ngStyle]="{'cursor': tienePaginaAnterior() ? 'pointer' : 'not-allowed'}" (click)="anteriorPagina()">Anterior</a>
                    </li>
                    <li class="paginate_button next" [class.disabled]="!tienePaginaSiguiente()" aria-controls="dataTables-example" tabindex="0">
                        <a [ngStyle]="{'cursor': tienePaginaSiguiente() ? 'pointer' : 'not-allowed'}" (click)="siguientePagina()">Siguiente</a>
                    </li>
                </ul>
            </div>
        </div>
        <hr />
    </ng-container>
   
</ng-container>