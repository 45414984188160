<div class="panel panel-default">
  <div class="panel-heading">
    <h4>Periodos Contables {{ this.periodo }}</h4>
  </div>
  <div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <div class="input-group">
          <div class="input-group-btn">
            <a class="btn btn-success" (click)="anioAnterior()">
              <i class="fa fa-arrow-left"></i> {{ this.periodo - 1 }}</a>
            <a class="btn btn-success" (click)="proximoAnterior()"> {{ this.periodo + 1 }}
              <i class="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
        <br />
        <ng-container *ngIf="this.periodosEnUso && this.periodosEnUso.length > 0">
          <hr />
          <h4>En uso</h4>
          <table class="table table-bordered table-hover table-striped tablaFiltrada">
            <thead>
              <tr>
                <th>
                  Año
                </th>
                <th>
                  Mes
                </th>
                <th>
                  Estado
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let periodo of this.periodosEnUso">
                <td>
                  {{ periodo.anio }}
                </td>
                <td>
                  {{ periodo.mes }}
                </td>
                <td>
                  {{ periodo.estaAbierto ? "Abierto" : "Cerrado" }}
                </td>
                <td>
                    <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                      Acciones <span class="caret"></span>
                    </button>
                    <mat-menu #menuAcciones="matMenu">
                      <button mat-menu-item (click)="cerrar(periodo.anio, periodo.mes)" ><i class="fa fa-door"></i> Cerrar</button>
                      <button mat-menu-item [routerLink]="['/erp/libros/libro-detalle', periodo.anio, periodo.mes, 2]"><i class="fa fa-book"></i> Libro de Ventas</button>
                      <button mat-menu-item [routerLink]="['/erp/libros/libro-detalle', periodo.anio, periodo.mes, 1]"><i class="fa fa-book"></i> Libro de Compras</button>
                    </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="this.periodosArchivados && this.periodosArchivados.length > 0">
          <hr />
          <h4>Inactivos</h4>
          <table class="table table-bordered table-hover table-striped tablaFiltrada">
            <thead>
              <tr>
                <th>
                  Año
                </th>
                <th>
                  Mes
                </th>
                <th>
                  Estado
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let periodo of this.periodosArchivados">
                <td>
                  {{ periodo.anio }}
                </td>
                <td>
                  {{ periodo.mes }}
                </td>
                <td>
                  {{ periodo.estaAbierto ? "Abierto" : "Cerrado" }}
                </td>
                <td>
                  <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                    Acciones <span class="caret"></span>
                  </button>
                  <mat-menu #menuAcciones="matMenu">
                    <button mat-menu-item (click)="abrir(periodo.anio, periodo.mes)" ><i class="fa fa-door"></i> Abrir</button>
                    <button mat-menu-item [routerLink]="['/erp/libros/libro-detalle', periodo.anio, periodo.mes, 2]"><i class="fa fa-book"></i> Libro de Ventas</button>
                    <button mat-menu-item [routerLink]="['/erp/libros/libro-detalle', periodo.anio, periodo.mes, 1]"><i class="fa fa-book"></i> Libro de Compras</button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

      </div>
    </div>
  </div>
  <div class="panel-footer">
    <a class="btn btn-warning" onclick="javascript:history.go(-1)">
      <i class="fa fa-arrow-left"></i> Regresar</a>
  </div>
</div>