
<div class="row">
    <div class="col-xs-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h4><i class="fa fa-info"></i> Nuevo Envio</h4>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table class="table table-bordered table-hover table-striped tablaFiltrada">
                            <thead>
                                <tr>
                                    <th class="numero">Folio</th>
                                    <th>Emisor</th>
                                    <th class="hidden-xs hidden-sm">Destinatario</th>
                                    <th class="hidden-xs hidden-sm">Fecha Emision</th>
                                    <th class="hidden-xs hidden-sm numero">Neto</th>
                                    <th class="numero">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let doc of this.documentos">
                                    <td class="numero">
                                        <a [routerLink]="['/erp/documentos/documento-detalle', doc.ID]">
                                            <ng-container *ngIf="doc.TipoDocumento != tipoDocumento.Boleta">{{doc.Numero | number:'':'es-CL' }}</ng-container>
                                            <ng-container *ngIf="doc.TipoDocumento === tipoDocumento.Boleta">{{doc.Desde | number:'':'es-CL'}} - {{ doc.Hasta | number:'':'es-CL' }}</ng-container>
                                        </a>
                                    </td>
                                    <ng-container *ngIf="doc.TipoDocumento === tipoDocumento.Boleta">
                                        <td class="numero">-</td>
                                        <td class="hidden-xs hidden-sm">-</td>
                                    </ng-container>
                                    <ng-container *ngIf="doc.TipoDocumento != tipoDocumento.Boleta">
                                      <td class="">{{ doc.Emisor.RazonSocial | slice:0:26 }}...<br />{{ doc.Emisor.RUT | number:'':'es-CL'}} - {{ doc.Emisor.DigitoVerificador  }}</td>  
                                      <td class="hidden-xs hidden-sm">{{ doc.Destinatario.RazonSocial | slice:0:26 }}...<br />{{ doc.Destinatario.RUT | number:'':'es-CL'}} - {{doc.Destinatario.DigitoVerificador  }}</td>
                                    </ng-container>
                                    <td class="hidden-xs hidden-sm">{{doc.FechaEmision | date : "dd/MM/yyyy" }}</td>
                                    <td class="hidden-xs hidden-sm numero">{{ doc.Neto | number:'':'es-CL'  }}</td>
                                    <td class="numero">{{ doc.Total | number:'':'es-CL'  }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <fieldset>
                            <div class="form-group">
                                <label>
                                    ID Documento
                                </label>
                                <input type="text" [(ngModel)]="this.documentoSeleccionado"
                                    placeholder="Ingrese el documento que desea agregar al envio" required="true"
                                    class="form-control" />
                            </div>
                            <button class="btn btn-primary" (click)="seleccionaDocumento()">Agregar</button>
                        </fieldset>
                        <hr />


                        <button class="btn btn-success btn-lg" (click)="enviar()">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
