import { Component, OnInit } from '@angular/core';
import { Notificacion } from '../../core/models/notificacion';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../servicios/servicio.api';

@Component({
  selector: 'app-user-desktop',
  templateUrl: './user-desktop.component.html',
  styleUrls: ['./user-desktop.component.css']
})
export class UserDesktopComponent implements OnInit {
  public frase: any;
  public indicadores: any;
  public cargandoIndicadores: boolean;

  notificaciones: Notificacion[] = [];

  constructor(private apiService: ApiService)
   { }

  ngOnInit() {
    this.cargandoIndicadores = true;
    this.obtenerFraseAleatoria();
    this.apiService.getIndicadores().subscribe(res => {
      this.indicadores = res;
      this.cargandoIndicadores = false;
    });
    this.apiService.getAlertas(environment.nombreInstancia).subscribe(response => {
      console.log(response);
      for (let i = 0; i < response.datos.length; i++) {
        let notificacion: Notificacion;
        let titulo = response.datos[i].titulo;
        let mensaje = response.datos[i].mensaje;
        let fechaCreacion = response.datos[i].fechaCreacion;
        if (response.datos[i].tipo == 0) { //Importante
          notificacion = new Notificacion(titulo, mensaje, "Advertencia", fechaCreacion);
        }
        if (response.datos[i].tipo == 1) { //Recordatorio
          notificacion = new Notificacion(titulo, mensaje, "Info", fechaCreacion);
        }
        if (response.datos[i].tipo == 2) { //Ayuda
          notificacion = new Notificacion(titulo, mensaje, "Info", fechaCreacion);
        }
        this.notificaciones.push(notificacion);
      }
      console.log(this.notificaciones);
    });
  }

  obtenerFraseAleatoria() : any {
    this.apiService.getFrasesAleatoria().subscribe(res => {
      this.frase = res.datos;
    });
  }
}
