<div class="row">
    <div class="col-xs-12 col-md-6">
        <mat-card>
            <mat-card-title>
                Bienvenido a Condor ERP
            </mat-card-title>
            <mat-card-content>
                <p>
                    Le damos un bienvenida a la mejor opción en facturación electrónica del mercado. Este sistema le
                    ofrece de forma rápida,
                    estable y segura la emisión de su documentación electrónica y el correcto envío de sus obligaciones
                    para con el SII.
                </p>
                <p>
                    El software que Ud. esta viendo es multiplataforma, lo que quiere decir que puede utilizarlo desde
                    cualquier dispositivo:
                    teléfonos móviles, tablets, portátiles, computadores de escritorio, etc, todo esto sin tener que
                    configurar ni portar sus
                    datos entre dispositivos cada vez que lo necesite ya que esta todo sincronizado y celosamente
                    custodiado en nuestros servidores.
                </p>
                <p>
                    Esperamos desde ya que el sistema cumpla con sus expectativas.
                    <br />Atte.
                    <br />Equipo de Condor ERP
                </p>
            </mat-card-content>
        </mat-card>
        <br>
        <mat-card *ngIf="this.notificaciones && this.notificaciones.length > 0">
            <mat-card-title>Alertas</mat-card-title>
            <mat-card-content>

                <div class="list-group">
                    <a class="list-group-item" *ngFor="let notificacion of this.notificaciones">
                        <ng-container *ngIf="notificacion.Nivel == 'Error'">
                            <i class="fa fa-radiation fa-fw"></i>
                        </ng-container>
                        <ng-container *ngIf="notificacion.Nivel == 'Advertencia'">
                            <i class="fa fa-exclamation fa-fw"></i>
                        </ng-container>
                        <ng-container *ngIf="notificacion.Nivel == 'Info'">
                            <i class="fa fa-envelope fa-fw"></i>
                        </ng-container>
                        {{ notificacion.Titulo }}
                        <span class="pull-right text-muted small"><em>{{notificacion.Fecha  | date : "dd/MM/yyyy" }}</em>
                        </span>
                        <div [innerHTML]="notificacion.Mensaje" class="panel-body"></div>
                    </a>
                </div>

            </mat-card-content>
        </mat-card>
        <br>
    </div>
    <div class="col-xs-12 col-md-6">
        <mat-card>
            <mat-card-title>
                <i class="fa fa-bar-chart"></i> Indicadores Económicos
            </mat-card-title>
            <mat-card-content>
                <table *ngIf="!this.cargandoIndicadores" class="table table-striped">
                    <thead>
                        <tr>
                            <th>Indicador</th>
                            <th style="text-align: right">Fecha</th>
                            <th style="text-align: right">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let indicador of this.indicadores.datos">
                            <td>{{ indicador.nombre }}</td>
                            <td style="text-align: right">{{ indicador.fecha | date : "dd/MM/yyyy" }}</td>
                            <td style="text-align: right">{{ indicador.valor }}</td>
                        </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="this.cargandoIndicadores">
                    <i class="fa fa-spin fa-spinner"></i> Cargando
                </ng-container>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xs-12 col-md-6">
        <br />
        <mat-card *ngIf="this.frase">
            <mat-card-title>Frases</mat-card-title>
            <mat-card-content>
                <blockquote>
                    <p><i class="fa fa-quote-left"></i> {{ this.frase.texto }} <i class="fa fa-quote-right"></i></p>
                    <footer><cite title="Source Title">{{ this.frase.autor }}</cite></footer>
                </blockquote>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xs-12 col-md-6" *ngIf="this.notificaciones && this.notificaciones.length > 0">
        <br />
        
    </div>
</div>