import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-usuario-cambiarcontrasena',
  templateUrl: './usuario-cambiarcontrasena.component.html',
  styleUrls: ['./usuario-cambiarcontrasena.component.css']
})
export class UsuarioCambiarcontrasenaComponent implements OnInit {
  nuevaPassword: any;
  public esPass1Valida: boolean;
  public esPass2Valida: boolean;

  constructor(
    public dialogRef: MatDialogRef<UsuarioCambiarcontrasenaComponent>,
    @Inject(MAT_DIALOG_DATA) public usuarioSeleccionado: any) {}

  ngOnInit() {
    this.nuevaPassword = {}
    this.nuevaPassword.pass1 = "";
    this.nuevaPassword.pass2 = "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onPass1Validation(valor: boolean) { this.esPass1Valida = valor; }
  public onPass2Validation(valor: boolean) { this.esPass2Valida = valor; }
  public esPassValida(): boolean { return (this.esPass1Valida && this.esPass2Valida && this.esPass1Valida == this.esPass2Valida); }
}
