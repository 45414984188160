import { Component, OnInit } from '@angular/core';
import { ClientesService } from '../../servicios/servicio.clientes';
import { ActivatedRoute, Router } from '@angular/router';
import { Notificacion } from '../../core/models/notificacion';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { MatDialog } from '@angular/material/dialog';
import { EliminarDialogoComponent } from './dialogos/eliminar-dialogo/eliminar-dialogo.component';

@Component({
  selector: 'app-cliente-detalle',
  templateUrl: './cliente-detalle.component.html',
  styleUrls: ['./cliente-detalle.component.css']
})
export class ClienteDetalleComponent implements OnInit {
  public cliente: any;

  constructor(
    private servicioClientes: ClientesService, 
    private route: ActivatedRoute,
    private router: Router,
    private servicioNotificaciones: NotificacionesService,
    public dialog: MatDialog) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      var rut = params['rut'];
      this.servicioClientes.getCliente(rut).subscribe(response => {
        this.cliente = response.datos;
      });
    });

  }

  dialogoEliminar() : void {
    const dialogRef = this.dialog.open(EliminarDialogoComponent, {
      width: '400px',
      data: this.cliente
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result == 'ok') {
        console.log(result);
        this.eliminar(result.cliente);
      }
      else
      {
        console.log('The dialog was closed');
      }
    });
  }

  eliminar(cliente: any): void {
    this.servicioClientes.eliminarCliente(cliente).subscribe(res => {
      if (res.esCorrecto) {
        var titulo = "Cliente eliminado con éxito";
        var mensaje = "<p>El cliente " + cliente.razonSocial + " ha sido eliminado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['/erp/clientes/']);
      }
      else {
        var titulo = "Hubieron problemas eliminando al cliente";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }
}
