<div class="container">
    <div class="row">
        <div class="col-md-4 col-md-offset-4">
            <div class="login-panel panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Ingreso Condor ERP</h3>
                </div>
                <div class="panel-body">
                  <p>
                      Por favor ingrese su email y contraseña para autenticarse en el sistema.
                  </p>
                  <form (submit)="ingresar()" ng-controller="UserLoginComponent">
                    <fieldset>
                        <div class="form-group">
                            <label>
                                Email
                            </label>
                            <input [(ngModel)]="user" name="user" type="text" required class="form-control" [disabled]="this.estaAutentificando" />
                        </div>
                        <div class="form-group">
                            <label>
                                Contraseña
                            </label>
                            <input [(ngModel)]="password" name="password" type="password" required class="form-control" ng-keyup="$event.keyCode == 13 && ingresar()" [disabled]="this.estaAutentificando" />
                        </div>
                        <div class="form-group">
                            <p>
                                <button type="submit" value="Ingresar" class="btn btn-success" [disabled]="this.estaAutentificando" >
                                    <ng-container *ngIf="this.estaAutentificando">
                                        <i class="fa fa-spin fa-spinner"></i> Ingresando
                                    </ng-container>
                                    <ng-container *ngIf="!this.estaAutentificando">
                                        <i class="fa fa-login"></i> Ingresar
                                    </ng-container>
                                </button>
                            </p>
                        </div>
                    </fieldset>
                  </form>
                </div>
            </div>
        </div>
    </div>
</div>