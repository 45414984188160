import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SesionesService } from '../../../../servicios/servicio.sesiones';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sesionporexpirar-dialogo',
  templateUrl: './sesionporexpirar-dialogo.component.html',
  styleUrls: ['./sesionporexpirar-dialogo.component.css']
})
export class SesionPorExpirarDialogoComponent implements OnInit, OnDestroy {

  public countdown: number;
  suscripcionCountdown: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SesionPorExpirarDialogoComponent>,
    private servicioSesiones: SesionesService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  ngOnInit() {
    this.countdown = 60;
    const source = interval(1000);
    this.suscripcionCountdown = source.subscribe(val => {
      if (this.countdown-- <= 1) {
        this.suscripcionCountdown.unsubscribe();
        this.cerrarSesion();
      }
      console.log('countdown', this.countdown);
    });
  }

  ngOnDestroy() {
    if (this.suscripcionCountdown) {
      this.suscripcionCountdown.unsubscribe();
    }
  }

  cerrarSesion() {
    this.servicioSesiones.cerrarSesion().subscribe();;
    this.dialogRef.close();
    this.router.navigate(['']);
  }

  mantenerAbierta() {
    this.servicioSesiones.refrescarSesion().subscribe(res => {
      if (res && res.esCorrecto && res.datos) {
        this.dialogRef.close('ok');
      }
    });
  }
}
