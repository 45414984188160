import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instancia-ayuda',
  templateUrl: './instancia-ayuda.component.html',
  styleUrls: ['./instancia-ayuda.component.css']
})
export class InstanciaAyudaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
