import { Component, OnInit } from '@angular/core';
import { LibrosService } from '../../servicios/servicio.libro';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { MatDialog } from '@angular/material/dialog';
import { TipoLibro } from '../../core/enums/tipo-libro.enum';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { CambiarperiodoDialogoComponent } from '../dialogos/cambiarperiodo-dialogo/cambiarperiodo-dialogo.component';

@Component({
  selector: 'app-libro-guias',
  templateUrl: './libro-guias.component.html',
  styleUrls: ['./libro-guias.component.css']
})
export class LibroGuiasComponent implements OnInit {
  libro: any;
  totaleslibro: any;
  public destinatariosDocumentos: any;
  advertenciasLibro: Array<any>;
  public anio: number;
  public mes: number;

  public pagina: number;
  public largoPagina: number;
  public filtroDestinatario: string;

  public tiposLibro = TipoLibro;
  public tipoDocumento = TipoDocumento;

  constructor(
    private servicioLibros: LibrosService,
    private activatedRoute: ActivatedRoute,
    private notificacionesService: NotificacionesService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.destinatariosDocumentos = [];
    this.filtroDestinatario = '';
    this.pagina = 1;
    this.largoPagina = 10;
    this.activatedRoute.params.subscribe(params => {
        const fechaActual = new Date();
        if (params['anio']) {
          this.anio = +params['anio']; // (+) converts string  to a number
        } else {
          this.anio = fechaActual.getFullYear();
        }

        if (params['mes']) {
          this.mes = +params['mes']; // (+) converts string  to a number
        } else {
          this.mes = fechaActual.getMonth() + 1;
        }

        this.recargar();
    });
  }

  recargar(): void {
    this.destinatariosDocumentos = [];
    this.recargarLibro();
    this.recargarDestinatarios();
  }

  recargarLibro() {
    this.servicioLibros.getLibro(this.anio, this.mes, this.tiposLibro.Guias).subscribe(response => {
      this.libro = response.datos;
      this.pagina = 1;
      this.recargarDestinatarios();
    });
  }

  recargarDestinatarios(): void {
    this.servicioLibros.getDestinatariosPorTipoDocumentos(this.anio, this.mes, this.tiposLibro.Guias, this.tipoDocumento.GuiaDespachoElectronica, this.pagina, this.largoPagina, this.filtroDestinatario).subscribe(response => {
      this.destinatariosDocumentos = response.datos;
      console.log(this.destinatariosDocumentos);
    });
  }

  siguientePagina(): void {
    if (this.tienePaginaSiguiente()) {
      this.pagina++;
      this.recargarDestinatarios();
    }
  }

  tienePaginaSiguiente(): boolean {
    const siguientePagina = this.pagina + 1;
    return (((siguientePagina - 1) * this.largoPagina) < this.destinatariosDocumentos.totalElementos);
  }

  anteriorPagina(): void {
    if (this.tienePaginaAnterior()) {
      this.pagina--;
      this.recargarDestinatarios();
    }
  }

  tienePaginaAnterior(): boolean {
    const paginaAnterior = this.pagina - 1;
    return paginaAnterior > 0;
  }

  public mesSiguiente(): void
  {
    if (this.mes === 12) {
      this.mes = 1;
      this.anio++;
    } else {
      this.mes++;
    }
    this.router.navigate(['erp/libros/libro-guias', this.anio, this.mes]);
  }

  public mesAnterior(): void
  {
    if (this.mes <= 1) {
      this.mes = 12;
      this.anio--;
    } else {
      this.mes--;
    }
    this.router.navigate(['erp/libros/libro-guias', this.anio, this.mes]);
  }

  public verTodos(rut: any): void {
    this.router.navigate(['erp/libros/libro-guias', this.anio, this.mes, rut]);
  }

  public abrirModalCambiarMes(): void {
    const dialogRef = this.dialog.open(CambiarperiodoDialogoComponent, {
      width: '400px',
      data: { anio: this.anio, mes: this.mes }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.result === 'ok') {
        this.cambiarMesDesdeModal(result.mes, result.anio);
        console.log(result);
      }
    });
  }

  public cambiarMesDesdeModal(nuevoMes: number, nuevoAnio: number): void {
    this.router.navigate(['erp/libros/libro-guias', nuevoAnio, nuevoMes]);
  }

  public filtrarDestinatarios() : void {
    this.recargarDestinatarios();
  }

  dv(T): any {
    let M = 0, S = 1;
    for (; T; T = Math.floor(T / 10)) {
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
    }
    return S ? S - 1 : 'k';
  }
}
