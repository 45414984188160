<ng-container *ngIf="!this._readOnly">
    <div class="input-group">
        <input class="form-control" [matDatepicker]="picker" [(ngModel)]="value" placeholder="{{placeholder}}" (focus)="picker.open()" readonly>
        <mat-datepicker #picker></mat-datepicker>
        <div class="input-group-btn">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        </div> 
    </div>
</ng-container>
<ng-container *ngIf="this._readOnly">
    <div>
        <b style="font-size:medium">{{ this._value | date : "dd/MM/yyyy" }}</b>
    </div>
</ng-container>