<ng-container *ngIf="producto">
  <fieldset>
      <div class="panel panel-default">
          <div class="panel-heading">
              <h4>¿Esta seguro?</h4>
          </div>
          <div class="panel-body">
              <p>¿Confirma que quiere eliminar el producto {{producto.nombre}} ({{producto.codigoInterno}})?</p>
          </div>
          <div class="panel-footer">
              <div class="row">
                  <div class="col-xs-6 col-md-6">
                      <a class="btn btn-block btn-success" [routerLink]="['/erp/inventario/producto-detalle/', producto.id]"><i class="fa fa-arrow-left"></i> Regresar</a>
                  </div>
                  <div class="col-xs-6 col-md-6">
                      <button (click)="eliminar()" class="btn btn-block btn-danger"><i class="fa fa-times"></i> Eliminar</button>
                  </div>
              </div>
          </div>
      </div>
  </fieldset>
</ng-container>