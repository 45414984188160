
<div class="row">
    <div class="col-xs-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h4><i class="fa fa-info"></i> Mi Empresa</h4>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <h1 style="text-align: right">{{ this.empresa.rut }} - {{ this.empresa.digitoVerificador }}</h1>
                        <h3 style="text-align: right">{{ this.empresa.razonSocial }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <h3>Acciones</h3>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="panel panel-info">
                            <div class="panel-heading">
                                <div class="row">
                                    <div class="col-xs-3">
                                        <i class="fa fa-list-ol fa-5x"></i>
                                    </div>
                                    <div class="col-xs-9 text-right">
                                        <div>Para ingresar los folios que el SII le proporcionó</div>
                                    </div>
                                </div>
                            </div>
                            <a [routerLink]="['/erp/instancia/folio-lista']">
                                <div class="panel-footer">
                                    <span class="pull-left">Ir a Folios</span>
                                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                                    <div class="clearfix"></div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="panel panel-info">
                            <div class="panel-heading">
                                <div class="row">
                                    <div class="col-xs-3">
                                        <i class="fa fa-flag-checkered fa-5x"></i>
                                    </div>
                                    <div class="col-xs-9 text-right">
                                        <div>Gestione la apertura o cierre de los periodos contables</div>
                                    </div>
                                </div>
                            </div>
                            <a  [routerLink]="['/erp/instancia/periodo-lista']">
                                <div class="panel-footer">
                                    <span class="pull-left">Ir a Periodos Contables</span>
                                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                                    <div class="clearfix"></div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="panel panel-info">
                            <div class="panel-heading">
                                <div class="row">
                                    <div class="col-xs-3">
                                        <i class="fa fa-flag-checkered fa-5x"></i>
                                    </div>
                                    <div class="col-xs-9 text-right">
                                        <div>Pruebe si es posible obtener una semilla del SII</div>
                                    </div>
                                </div>
                            </div>
                            <a  (click)="this.obtenerSemilla()">
                                <div class="panel-footer">
                                    <span class="pull-left">Obtener Semilla</span>
                                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                                    <div class="clearfix"></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
