import { Component, OnInit } from '@angular/core';
import { EnviosService } from '../../servicios/servicio.envios';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-envio-detalles',
  templateUrl: './envio-detalles.component.html',
  styleUrls: ['./envio-detalles.component.css']
})
export class EnvioDetallesComponent implements OnInit {

  public envioDTE: any;
  private documentosEnEnvio: Array<any> = [];

  constructor(
    private servicioEnvios: EnviosService,
    private servicioNotificaciones: NotificacionesService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.recargar();
  }

  recargar() : void {
    this.route.params.subscribe(params => {
      var id =  params['id'];
      this.servicioEnvios.getEnvio(id).subscribe(response => {
        console.log(response);
        this.envioDTE = response.Datos;
      });
    });
  }
}
