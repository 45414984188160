import { Component, OnInit } from '@angular/core';
import { InstanciaService } from '../../servicios/servicio.instancia';
import { DocumentosService } from '../../servicios/servicio.documentos';

@Component({
  selector: 'app-administracion-dashboard',
  templateUrl: './administracion-dashboard.component.html',
  styleUrls: ['./administracion-dashboard.component.css']
})
export class AdministracionDashboardComponent implements OnInit {

  public tokenBoleta:string = "";

  constructor(
    private servicioDocumentos: DocumentosService,
    private servicioInstancia: InstanciaService
    ) {
    this.servicioDocumentos = servicioDocumentos;
   }

  ngOnInit() {
  }

  public generarTokenBoleta() {
    this.servicioDocumentos.obtenerTokenBoleta().subscribe(res => {
      console.log("token:", res);
      this.tokenBoleta = res.datos;
    });
  }

  public generarException() : void {
    this.servicioInstancia.generarExcepcion().subscribe(res => {
      console.log(res);
    });
  }
}
