import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClienteListaComponent } from './cliente-lista/cliente-lista.component';
import { ClienteDetalleComponent } from './cliente-detalle/cliente-detalle.component';
import { ClientesService } from '../servicios/servicio.clientes';
import { ClienteModificarComponent } from './cliente-modificar/cliente-modificar.component';
import { FormsModule } from '@angular/forms';
import { EnumeracionesService } from '../servicios/servicio.enumeraciones';
import { NotificacionesService } from '../servicios/servicio.notificaciones';
import { SharedModule } from '../shared/shared.module';
import { EliminarDialogoComponent } from './cliente-detalle/dialogos/eliminar-dialogo/eliminar-dialogo.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  entryComponents: [EliminarDialogoComponent],
  declarations: [ClienteListaComponent, ClienteDetalleComponent, ClienteModificarComponent, EliminarDialogoComponent],
  providers: [ClientesService, EnumeracionesService, NotificacionesService]
})
export class ClientesModule { }
