export var Enumeraciones = {
    TiposDocumento: [
        {
            "id": -1,
            "nombre": "SET"
        },
        {
            "id": 30,
            "nombre": "Factura"
        },
        {
            "id": 32,
            "nombre": "Factura Exenta"
        },
        {
            "id": 33,
            "nombre": "Factura Electrónica"
        },
        {
            "id": 34,
            "nombre": "Factura Exenta Electrónica"
        },
        {
            "id": 35,
            "nombre": "Boletas"
        },
        {
            "id": 38,
            "nombre": "Boleta Exenta"
        },
        {
            "id": 39,
            "nombre": "Boleta Electrónica"
        },
        {
            "id": 40,
            "nombre": "Liquidación Factura"
        },
        {
            "id": 41,
            "nombre": "Boleta Exenta Electrónica"
        },
        {
            "id": 43,
            "nombre": "Liquidación Factura Electrónica"
        },
        {
            "id": 45,
            "nombre": "Factura Compra"
        },
        {
            "id": 46,
            "nombre": "Factura Compra Electrónica"
        },
        {
            "id": 50,
            "nombre": "Guía de Despacho"
        },
        {
            "id": 52,
            "nombre": "Guía de Despacho Electrónica"
        },
        {
            "id": 55,
            "nombre": "Nota de Débito"
        },
        {
            "id": 56,
            "nombre": "Nota de Débito Electrónica"
        },
        {
            "id": 60,
            "nombre": "Nota de Crédito"
        },
        {
            "id": 61,
            "nombre": "Nota de Crédito Electrónica"
        },
        {
            "id": 103,
            "nombre": "Liquidación"
        },
        {
            "id": 801,
            "nombre": "Orden de compra"
        },
        {
            "id": 802,
            "nombre": "Nota de pedido"
        }
    ],
    Impuestos: [
        {
            "ID": 1,
            "CodigoSII": "IVA",
            "Nombre": "IVA",
            "Descripcion": "Impuesto al Valor Agregado",
            "Tasa": 19,
            "TipoTasa": 0,
            "AfectaLibroCompras": 1,
            "AfectaLibroVentas": 1,
            "CodigoInterno": "IVA",
            "Opciones": []
        },
        {
            "ID": 2,
            "CodigoSII": "IVANOREC",
            "Nombre": "IVA No Recup.",
            "Descripcion": "IVA No Recuperable",
            "Tasa": -1,
            "TipoTasa": 0,
            "AfectaLibroCompras": 1,
            "AfectaLibroVentas": 0,
            "CodigoInterno": "IVANOREC",
            "Opciones": [
                {
                    "Valor": 1,
                    "Descripcion": "Compras destinadas a IVA a generar operaciones no gravadas o exentas."
                },
                {
                    "Valor": 2,
                    "Descripcion": "Facturas de proveedores registradas fuera de plazo."
                },
                {
                    "Valor": 3,
                    "Descripcion": "Gastos rechazados."
                },
                {
                    "Valor": 4,
                    "Descripcion": "Entregas gratuitas (premios, bonificaciones, etc.) recibidas."
                },
                {
                    "Valor": 9,
                    "Descripcion": "Otros."
                }
            ]
        },
        {
            "ID": 3,
            "CodigoSII": "IVACOMUN",
            "Nombre": "IVA Uso Común",
            "Descripcion": "IVA de uso común",
            "Tasa": -1,
            "TipoTasa": 0,
            "AfectaLibroCompras": 1,
            "AfectaLibroVentas": 0,
            "CodigoInterno": "IVACOMUN",
            "Opciones": []
        },
        {
            "ID": 4,
            "CodigoSII": "15",
            "Nombre": "IVA Retenido",
            "Descripcion": "IVA Retenido",
            "Tasa": -1,
            "TipoTasa": 0,
            "AfectaLibroCompras": 1,
            "AfectaLibroVentas": 0,
            "CodigoInterno": "IVARET",
            "Opciones": []
        }
    ],
    UnidadesMedida: [
        {
            "id": 0,
            "nombre": "Unidad",
            "codigo": "UN"
        },
        {
            "id": 1,
            "nombre": "Metro",
            "codigo": "MTS"
        },
        {
            "id": 2,
            "nombre": "Kilo",
            "codigo": "KG"
        },
        {
            "id": 3,
            "nombre": "Gramo",
            "codigo": "GR"
        },
        {
            "id": 4,
            "nombre": "Hora",
            "codigo": "HR"
        },
        {
            "id": 5,
            "nombre": "Litro",
            "codigo": "LT"
        },
        {
            "id": 6,
            "nombre": "Caja",
            "codigo": "CJ"
        },
        {
            "id": 7,
            "nombre": "Bin",
            "codigo": "BIN"
        },
        {
            "id": 8,
            "nombre": "Malla",
            "codigo": "MLL"
        }
    ],
    Regiones: [
        {
            "id": "15",
            "nombre": "Arica y Parinacota",
            "provincias": [
                {
                    "id": "15-1",
                    "nombre": "Arica",
                    "comunas": [
                        {
                            "id": "15-1-01",
                            "nombre": "Arica"
                        },
                        {
                            "id": "15-1-02",
                            "nombre": "Camarones"
                        }
                    ]
                },
                {
                    "id": "15-2",
                    "nombre": "Parinacota",
                    "comunas": [
                        {
                            "id": "15-2-01",
                            "nombre": "Putre"
                        },
                        {
                            "id": "15-2-02",
                            "nombre": "General Lagos"
                        }
                    ]
                }
            ]
        },
        {
            "id": "01",
            "nombre": "Tarapacá",
            "provincias": [
                {
                    "id": "01-1",
                    "nombre": "Iquique",
                    "comunas": [
                        {
                            "id": "01-1-01",
                            "nombre": "Iquique"
                        },
                        {
                            "id": "01-1-07",
                            "nombre": "Alto Hospicio"
                        }
                    ]
                },
                {
                    "id": "01-4",
                    "nombre": "Tamarugal",
                    "comunas": [
                        {
                            "id": "01-4-01",
                            "nombre": "Pozo Almonte"
                        },
                        {
                            "id": "01-4-02",
                            "nombre": "Camiña"
                        },
                        {
                            "id": "01-4-03",
                            "nombre": "Colchane"
                        },
                        {
                            "id": "01-4-04",
                            "nombre": "Huara"
                        },
                        {
                            "id": "01-4-05",
                            "nombre": "Pica"
                        }
                    ]
                }
            ]
        },
        {
            "id": "02",
            "nombre": "Antofagasta",
            "provincias": [
                {
                    "id": "02-1",
                    "nombre": "Antofagasta",
                    "comunas": [
                        {
                            "id": "02-1-01",
                            "nombre": "Antofagasta"
                        },
                        {
                            "id": "02-1-02",
                            "nombre": "Mejillones"
                        },
                        {
                            "id": "02-1-03",
                            "nombre": "Sierra Gorda"
                        },
                        {
                            "id": "02-1-04",
                            "nombre": "Taltal"
                        }
                    ]
                },
                {
                    "id": "02-2",
                    "nombre": "El Loa",
                    "comunas": [
                        {
                            "id": "02-2-01",
                            "nombre": "Calama"
                        },
                        {
                            "id": "02-2-02",
                            "nombre": "Ollagüe"
                        },
                        {
                            "id": "02-2-03",
                            "nombre": "San Pedro de Atacama"
                        }
                    ]
                },
                {
                    "id": "02-3",
                    "nombre": "Tocopilla",
                    "comunas": [
                        {
                            "id": "02-3-01",
                            "nombre": "Tocopilla"
                        },
                        {
                            "id": "02-3-02",
                            "nombre": "María Elena"
                        }
                    ]
                }
            ]
        },
        {
            "id": "03",
            "nombre": "Atacama",
            "provincias": [
                {
                    "id": "03-1",
                    "nombre": "Copiapó",
                    "comunas": [
                        {
                            "id": "03-1-01",
                            "nombre": "Copiapó"
                        },
                        {
                            "id": "03-1-02",
                            "nombre": "Caldera"
                        },
                        {
                            "id": "03-1-03",
                            "nombre": "Tierra Amarilla"
                        }
                    ]
                },
                {
                    "id": "03-2",
                    "nombre": "Chañaral",
                    "comunas": [
                        {
                            "id": "03-2-01",
                            "nombre": "Chañaral"
                        },
                        {
                            "id": "03-2-02",
                            "nombre": "Diego de Almagro"
                        }
                    ]
                },
                {
                    "id": "03-3",
                    "nombre": "Huasco",
                    "comunas": [
                        {
                            "id": "03-3-01",
                            "nombre": "Vallenar"
                        },
                        {
                            "id": "03-3-02",
                            "nombre": "Alto del Carmen"
                        },
                        {
                            "id": "03-3-03",
                            "nombre": "Freirina"
                        },
                        {
                            "id": "03-3-04",
                            "nombre": "Huasco"
                        }
                    ]
                }
            ]
        },
        {
            "id": "04",
            "nombre": "Coquimbo",
            "provincias": [
                {
                    "id": "04-1",
                    "nombre": "Elqui",
                    "comunas": [
                        {
                            "id": "04-1-01",
                            "nombre": "La Serena"
                        },
                        {
                            "id": "04-1-02",
                            "nombre": "Coquimbo"
                        },
                        {
                            "id": "04-1-03",
                            "nombre": "Andacollo"
                        },
                        {
                            "id": "04-1-04",
                            "nombre": "La Higuera"
                        },
                        {
                            "id": "04-1-05",
                            "nombre": "Paiguano"
                        },
                        {
                            "id": "04-1-06",
                            "nombre": "Vicuña"
                        }
                    ]
                },
                {
                    "id": "04-2",
                    "nombre": "Choapa",
                    "comunas": [
                        {
                            "id": "04-2-01",
                            "nombre": "Illapel"
                        },
                        {
                            "id": "04-2-02",
                            "nombre": "Canela"
                        },
                        {
                            "id": "04-2-03",
                            "nombre": "Los Vilos"
                        },
                        {
                            "id": "04-2-04",
                            "nombre": "Salamanca"
                        }
                    ]
                },
                {
                    "id": "04-3",
                    "nombre": "Limarí",
                    "comunas": [
                        {
                            "id": "04-3-01",
                            "nombre": "Ovalle"
                        },
                        {
                            "id": "04-3-02",
                            "nombre": "Combarbalá"
                        },
                        {
                            "id": "04-3-03",
                            "nombre": "Monte Patria"
                        },
                        {
                            "id": "04-3-04",
                            "nombre": "Punitaqui"
                        },
                        {
                            "id": "04-3-05",
                            "nombre": "Río Hurtado"
                        }
                    ]
                }
            ]
        },
        {
            "id": "05",
            "nombre": "Valparaíso",
            "provincias": [
                {
                    "id": "05-1",
                    "nombre": "Valparaíso",
                    "comunas": [
                        {
                            "id": "05-1-01",
                            "nombre": "Valparaíso"
                        },
                        {
                            "id": "05-1-02",
                            "nombre": "Casablanca"
                        },
                        {
                            "id": "05-1-03",
                            "nombre": "Concón"
                        },
                        {
                            "id": "05-1-04",
                            "nombre": "Juan Fernández"
                        },
                        {
                            "id": "05-1-05",
                            "nombre": "Puchuncaví"
                        },
                        {
                            "id": "05-1-07",
                            "nombre": "Quintero"
                        },
                        {
                            "id": "05-1-09",
                            "nombre": "Viña del Mar"
                        }
                    ]
                },
                {
                    "id": "05-2",
                    "nombre": "Isla de Pascua",
                    "comunas": [
                        {
                            "id": "05-2-01",
                            "nombre": "Isla de Pascua"
                        }
                    ]
                },
                {
                    "id": "05-3",
                    "nombre": "Los Andes",
                    "comunas": [
                        {
                            "id": "05-3-01",
                            "nombre": "Los Andes"
                        },
                        {
                            "id": "05-3-02",
                            "nombre": "Calle Larga"
                        },
                        {
                            "id": "05-3-03",
                            "nombre": "Rinconada"
                        },
                        {
                            "id": "05-3-04",
                            "nombre": "San Esteban"
                        }
                    ]
                },
                {
                    "id": "05-4",
                    "nombre": "Petorca",
                    "comunas": [
                        {
                            "id": "05-4-01",
                            "nombre": "La Ligua"
                        },
                        {
                            "id": "05-4-02",
                            "nombre": "Cabildo"
                        },
                        {
                            "id": "05-4-03",
                            "nombre": "Papudo"
                        },
                        {
                            "id": "05-4-04",
                            "nombre": "Petorca"
                        },
                        {
                            "id": "05-4-05",
                            "nombre": "Zapallar"
                        }
                    ]
                },
                {
                    "id": "05-5",
                    "nombre": "Quillota",
                    "comunas": [
                        {
                            "id": "05-5-01",
                            "nombre": "Quillota"
                        },
                        {
                            "id": "05-5-02",
                            "nombre": "Calera"
                        },
                        {
                            "id": "05-5-03",
                            "nombre": "Hijuelas"
                        },
                        {
                            "id": "05-5-04",
                            "nombre": "La Cruz"
                        },
                        {
                            "id": "05-5-06",
                            "nombre": "Nogales"
                        }
                    ]
                },
                {
                    "id": "05-6",
                    "nombre": "San Antonio",
                    "comunas": [
                        {
                            "id": "05-6-01",
                            "nombre": "San Antonio"
                        },
                        {
                            "id": "05-6-02",
                            "nombre": "Algarrobo"
                        },
                        {
                            "id": "05-6-03",
                            "nombre": "Cartagena"
                        },
                        {
                            "id": "05-6-04",
                            "nombre": "El Quisco"
                        },
                        {
                            "id": "05-6-05",
                            "nombre": "El Tabo"
                        },
                        {
                            "id": "05-6-06",
                            "nombre": "Santo Domingo"
                        }
                    ]
                },
                {
                    "id": "05-7",
                    "nombre": "San Felipe de Aconcagua",
                    "comunas": [
                        {
                            "id": "05-7-01",
                            "nombre": "San Felipe"
                        },
                        {
                            "id": "05-7-02",
                            "nombre": "Catemu"
                        },
                        {
                            "id": "05-7-03",
                            "nombre": "Llaillay"
                        },
                        {
                            "id": "05-7-04",
                            "nombre": "Panquehue"
                        },
                        {
                            "id": "05-7-05",
                            "nombre": "Putaendo"
                        },
                        {
                            "id": "05-7-06",
                            "nombre": "Santa María"
                        }
                    ]
                },
                {
                    "id": "05-8",
                    "nombre": "Marga Marga",
                    "comunas": [
                        {
                            "id": "05-8-01",
                            "nombre": "Quilpué"
                        },
                        {
                            "id": "05-8-02",
                            "nombre": "Limache"
                        },
                        {
                            "id": "05-8-03",
                            "nombre": "Olmué"
                        },
                        {
                            "id": "05-8-04",
                            "nombre": "Villa Alemana"
                        }
                    ]
                }
            ]
        },
        {
            "id": "06",
            "nombre": "Lib. Gral. Bernardo O'Higgins",
            "provincias": [
                {
                    "id": "06-1",
                    "nombre": "Cachapoal",
                    "comunas": [
                        {
                            "id": "06-1-01",
                            "nombre": "Rancagua"
                        },
                        {
                            "id": "06-1-02",
                            "nombre": "Codegua"
                        },
                        {
                            "id": "06-1-03",
                            "nombre": "Coinco"
                        },
                        {
                            "id": "06-1-04",
                            "nombre": "Coltauco"
                        },
                        {
                            "id": "06-1-05",
                            "nombre": "Doñihue"
                        },
                        {
                            "id": "06-1-06",
                            "nombre": "Graneros"
                        },
                        {
                            "id": "06-1-07",
                            "nombre": "Las Cabras"
                        },
                        {
                            "id": "06-1-08",
                            "nombre": "Machalí"
                        },
                        {
                            "id": "06-1-09",
                            "nombre": "Malloa"
                        },
                        {
                            "id": "06-1-10",
                            "nombre": "Mostazal"
                        },
                        {
                            "id": "06-1-11",
                            "nombre": "Olivar"
                        },
                        {
                            "id": "06-1-12",
                            "nombre": "Peumo"
                        },
                        {
                            "id": "06-1-13",
                            "nombre": "Pichidegua"
                        },
                        {
                            "id": "06-1-14",
                            "nombre": "Quinta de Tilcoco"
                        },
                        {
                            "id": "06-1-15",
                            "nombre": "Rengo"
                        },
                        {
                            "id": "06-1-16",
                            "nombre": "Requínoa"
                        },
                        {
                            "id": "06-1-17",
                            "nombre": "San Vicente de Tagua Tagua"
                        }
                    ]
                },
                {
                    "id": "06-2",
                    "nombre": "Cardenal Caro",
                    "comunas": [
                        {
                            "id": "06-2-01",
                            "nombre": "Pichilemu"
                        },
                        {
                            "id": "06-2-02",
                            "nombre": "La Estrella"
                        },
                        {
                            "id": "06-2-03",
                            "nombre": "Litueche"
                        },
                        {
                            "id": "06-2-04",
                            "nombre": "Marchihue"
                        },
                        {
                            "id": "06-2-05",
                            "nombre": "Navidad"
                        },
                        {
                            "id": "06-2-06",
                            "nombre": "Paredones"
                        }
                    ]
                },
                {
                    "id": "06-3",
                    "nombre": "Colchagua",
                    "comunas": [
                        {
                            "id": "06-3-01",
                            "nombre": "San Fernando"
                        },
                        {
                            "id": "06-3-02",
                            "nombre": "Chépica"
                        },
                        {
                            "id": "06-3-03",
                            "nombre": "Chimbarongo"
                        },
                        {
                            "id": "06-3-04",
                            "nombre": "Lolol"
                        },
                        {
                            "id": "06-3-05",
                            "nombre": "Nancagua"
                        },
                        {
                            "id": "06-3-06",
                            "nombre": "Palmilla"
                        },
                        {
                            "id": "06-3-07",
                            "nombre": "Peralillo"
                        },
                        {
                            "id": "06-3-08",
                            "nombre": "Placilla"
                        },
                        {
                            "id": "06-3-09",
                            "nombre": "Pumanque"
                        },
                        {
                            "id": "06-3-10",
                            "nombre": "Santa Cruz"
                        }
                    ]
                }
            ]
        },
        {
            "id": "07",
            "nombre": "Maule",
            "provincias": [
                {
                    "id": "07-1",
                    "nombre": "Talca",
                    "comunas": [
                        {
                            "id": "07-1-01",
                            "nombre": "Talca"
                        },
                        {
                            "id": "07-1-02",
                            "nombre": "Constitución"
                        },
                        {
                            "id": "07-1-03",
                            "nombre": "Curepto"
                        },
                        {
                            "id": "07-1-04",
                            "nombre": "Empedrado"
                        },
                        {
                            "id": "07-1-05",
                            "nombre": "Maule"
                        },
                        {
                            "id": "07-1-06",
                            "nombre": "Pelarco"
                        },
                        {
                            "id": "07-1-07",
                            "nombre": "Pencahue"
                        },
                        {
                            "id": "07-1-08",
                            "nombre": "Río Claro"
                        },
                        {
                            "id": "07-1-09",
                            "nombre": "San Clemente"
                        },
                        {
                            "id": "07-1-10",
                            "nombre": "San Rafael"
                        }
                    ]
                },
                {
                    "id": "07-2",
                    "nombre": "Cauquenes",
                    "comunas": [
                        {
                            "id": "07-2-01",
                            "nombre": "Cauquenes"
                        },
                        {
                            "id": "07-2-02",
                            "nombre": "Chanco"
                        },
                        {
                            "id": "07-2-03",
                            "nombre": "Pelluhue"
                        }
                    ]
                },
                {
                    "id": "07-3",
                    "nombre": "Curicó",
                    "comunas": [
                        {
                            "id": "07-3-01",
                            "nombre": "Curicó"
                        },
                        {
                            "id": "07-3-02",
                            "nombre": "Hualañé"
                        },
                        {
                            "id": "07-3-03",
                            "nombre": "Licantén"
                        },
                        {
                            "id": "07-3-04",
                            "nombre": "Molina"
                        },
                        {
                            "id": "07-3-05",
                            "nombre": "Rauco"
                        },
                        {
                            "id": "07-3-06",
                            "nombre": "Romeral"
                        },
                        {
                            "id": "07-3-07",
                            "nombre": "Sagrada Familia"
                        },
                        {
                            "id": "07-3-08",
                            "nombre": "Teno"
                        },
                        {
                            "id": "07-3-09",
                            "nombre": "Vichuquén"
                        }
                    ]
                },
                {
                    "id": "07-4",
                    "nombre": "Linares",
                    "comunas": [
                        {
                            "id": "07-4-01",
                            "nombre": "Linares"
                        },
                        {
                            "id": "07-4-02",
                            "nombre": "Colbún"
                        },
                        {
                            "id": "07-4-03",
                            "nombre": "Longaví"
                        },
                        {
                            "id": "07-4-04",
                            "nombre": "Parral"
                        },
                        {
                            "id": "07-4-05",
                            "nombre": "Retiro"
                        },
                        {
                            "id": "07-4-06",
                            "nombre": "San Javier"
                        },
                        {
                            "id": "07-4-07",
                            "nombre": "Villa Alegre"
                        },
                        {
                            "id": "07-4-08",
                            "nombre": "Yerbas Buenas"
                        }
                    ]
                }
            ]
        },
        {
            "id": "08",
            "nombre": "Biobío",
            "provincias": [
                {
                    "id": "08-1",
                    "nombre": "Concepción",
                    "comunas": [
                        {
                            "id": "08-1-01",
                            "nombre": "Concepción"
                        },
                        {
                            "id": "08-1-02",
                            "nombre": "Coronel"
                        },
                        {
                            "id": "08-1-03",
                            "nombre": "Chiguayante"
                        },
                        {
                            "id": "08-1-04",
                            "nombre": "Florida"
                        },
                        {
                            "id": "08-1-05",
                            "nombre": "Hualqui"
                        },
                        {
                            "id": "08-1-06",
                            "nombre": "Lota"
                        },
                        {
                            "id": "08-1-07",
                            "nombre": "Penco"
                        },
                        {
                            "id": "08-1-08",
                            "nombre": "San Pedro de La Paz"
                        },
                        {
                            "id": "08-1-09",
                            "nombre": "Santa Juana"
                        },
                        {
                            "id": "08-1-10",
                            "nombre": "Talcahuano"
                        },
                        {
                            "id": "08-1-11",
                            "nombre": "Tomé"
                        },
                        {
                            "id": "08-1-12",
                            "nombre": "Hualpén"
                        }
                    ]
                },
                {
                    "id": "08-2",
                    "nombre": "Arauco",
                    "comunas": [
                        {
                            "id": "08-2-01",
                            "nombre": "Lebu"
                        },
                        {
                            "id": "08-2-02",
                            "nombre": "Arauco"
                        },
                        {
                            "id": "08-2-03",
                            "nombre": "Cañete"
                        },
                        {
                            "id": "08-2-04",
                            "nombre": "Contulmo"
                        },
                        {
                            "id": "08-2-05",
                            "nombre": "Curanilahue"
                        },
                        {
                            "id": "08-2-06",
                            "nombre": "Los Álamos"
                        },
                        {
                            "id": "08-2-07",
                            "nombre": "Tirúa"
                        }
                    ]
                },
                {
                    "id": "08-3",
                    "nombre": "Biobío",
                    "comunas": [
                        {
                            "id": "08-3-01",
                            "nombre": "Los Ángeles"
                        },
                        {
                            "id": "08-3-02",
                            "nombre": "Antuco"
                        },
                        {
                            "id": "08-3-03",
                            "nombre": "Cabrero"
                        },
                        {
                            "id": "08-3-04",
                            "nombre": "Laja"
                        },
                        {
                            "id": "08-3-05",
                            "nombre": "Mulchén"
                        },
                        {
                            "id": "08-3-06",
                            "nombre": "Nacimiento"
                        },
                        {
                            "id": "08-3-07",
                            "nombre": "Negrete"
                        },
                        {
                            "id": "08-3-08",
                            "nombre": "Quilaco"
                        },
                        {
                            "id": "08-3-09",
                            "nombre": "Quilleco"
                        },
                        {
                            "id": "08-3-10",
                            "nombre": "San Rosendo"
                        },
                        {
                            "id": "08-3-11",
                            "nombre": "Santa Bárbara"
                        },
                        {
                            "id": "08-3-12",
                            "nombre": "Tucapel"
                        },
                        {
                            "id": "08-3-13",
                            "nombre": "Yumbel"
                        },
                        {
                            "id": "08-3-14",
                            "nombre": "Alto Biobío"
                        }
                    ]
                },
                {
                    "id": "08-4",
                    "nombre": "Ñuble",
                    "comunas": [
                        {
                            "id": "08-4-01",
                            "nombre": "Chillán"
                        },
                        {
                            "id": "08-4-02",
                            "nombre": "Bulnes"
                        },
                        {
                            "id": "08-4-03",
                            "nombre": "Cobquecura"
                        },
                        {
                            "id": "08-4-04",
                            "nombre": "Coelemu"
                        },
                        {
                            "id": "08-4-05",
                            "nombre": "Coihueco"
                        },
                        {
                            "id": "08-4-06",
                            "nombre": "Chillán Viejo"
                        },
                        {
                            "id": "08-4-07",
                            "nombre": "El Carmen"
                        },
                        {
                            "id": "08-4-08",
                            "nombre": "Ninhue"
                        },
                        {
                            "id": "08-4-09",
                            "nombre": "Ñiquén"
                        },
                        {
                            "id": "08-4-10",
                            "nombre": "Pemuco"
                        },
                        {
                            "id": "08-4-11",
                            "nombre": "Pinto"
                        },
                        {
                            "id": "08-4-12",
                            "nombre": "Portezuelo"
                        },
                        {
                            "id": "08-4-13",
                            "nombre": "Quillón"
                        },
                        {
                            "id": "08-4-14",
                            "nombre": "Quirihue"
                        },
                        {
                            "id": "08-4-15",
                            "nombre": "Ránquil"
                        },
                        {
                            "id": "08-4-16",
                            "nombre": "San Carlos"
                        },
                        {
                            "id": "08-4-17",
                            "nombre": "San Fabián"
                        },
                        {
                            "id": "08-4-18",
                            "nombre": "San Ignacio"
                        },
                        {
                            "id": "08-4-19",
                            "nombre": "San Nicolás"
                        },
                        {
                            "id": "08-4-20",
                            "nombre": "Treguaco"
                        },
                        {
                            "id": "08-4-21",
                            "nombre": "Yungay"
                        }
                    ]
                }
            ]
        },
        {
            "id": "09",
            "nombre": "La Araucanía",
            "provincias": [
                {
                    "id": "09-1",
                    "nombre": "Cautín",
                    "comunas": [
                        {
                            "id": "09-1-01",
                            "nombre": "Temuco"
                        },
                        {
                            "id": "09-1-02",
                            "nombre": "Carahue"
                        },
                        {
                            "id": "09-1-03",
                            "nombre": "Cunco"
                        },
                        {
                            "id": "09-1-04",
                            "nombre": "Curarrehue"
                        },
                        {
                            "id": "09-1-05",
                            "nombre": "Freire"
                        },
                        {
                            "id": "09-1-06",
                            "nombre": "Galvarino"
                        },
                        {
                            "id": "09-1-07",
                            "nombre": "Gorbea"
                        },
                        {
                            "id": "09-1-08",
                            "nombre": "Lautaro"
                        },
                        {
                            "id": "09-1-09",
                            "nombre": "Loncoche"
                        },
                        {
                            "id": "09-1-10",
                            "nombre": "Melipeuco"
                        },
                        {
                            "id": "09-1-11",
                            "nombre": "Nueva Imperial"
                        },
                        {
                            "id": "09-1-12",
                            "nombre": "Padre Las Casas"
                        },
                        {
                            "id": "09-1-13",
                            "nombre": "Perquenco"
                        },
                        {
                            "id": "09-1-14",
                            "nombre": "Pitrufquén"
                        },
                        {
                            "id": "09-1-15",
                            "nombre": "Pucón"
                        },
                        {
                            "id": "09-1-16",
                            "nombre": "Saavedra"
                        },
                        {
                            "id": "09-1-17",
                            "nombre": "Teodoro Schmidt"
                        },
                        {
                            "id": "09-1-18",
                            "nombre": "Toltén"
                        },
                        {
                            "id": "09-1-19",
                            "nombre": "Vilcún"
                        },
                        {
                            "id": "09-1-20",
                            "nombre": "Villarrica"
                        },
                        {
                            "id": "09-1-21",
                            "nombre": "Cholchol"
                        }
                    ]
                },
                {
                    "id": "09-2",
                    "nombre": "Malleco",
                    "comunas": [
                        {
                            "id": "09-2-01",
                            "nombre": "Angol"
                        },
                        {
                            "id": "09-2-02",
                            "nombre": "Collipulli"
                        },
                        {
                            "id": "09-2-03",
                            "nombre": "Curacautín"
                        },
                        {
                            "id": "09-2-04",
                            "nombre": "Ercilla"
                        },
                        {
                            "id": "09-2-05",
                            "nombre": "Lonquimay"
                        },
                        {
                            "id": "09-2-06",
                            "nombre": "Los Sauces"
                        },
                        {
                            "id": "09-2-07",
                            "nombre": "Lumaco"
                        },
                        {
                            "id": "09-2-08",
                            "nombre": "Purén"
                        },
                        {
                            "id": "09-2-09",
                            "nombre": "Renaico"
                        },
                        {
                            "id": "09-2-10",
                            "nombre": "Traiguén"
                        },
                        {
                            "id": "09-2-11",
                            "nombre": "Victoria"
                        }
                    ]
                }
            ]
        },
        {
            "id": "14",
            "nombre": "Los Ríos",
            "provincias": [
                {
                    "id": "14-1",
                    "nombre": "Valdivia",
                    "comunas": [
                        {
                            "id": "14-1-01",
                            "nombre": "Valdivia"
                        },
                        {
                            "id": "14-1-02",
                            "nombre": "Corral"
                        },
                        {
                            "id": "14-1-03",
                            "nombre": "Lanco"
                        },
                        {
                            "id": "14-1-04",
                            "nombre": "Los Lagos"
                        },
                        {
                            "id": "14-1-05",
                            "nombre": "Máfil"
                        },
                        {
                            "id": "14-1-06",
                            "nombre": "Mariquina"
                        },
                        {
                            "id": "14-1-07",
                            "nombre": "Paillaco"
                        },
                        {
                            "id": "14-1-08",
                            "nombre": "Panguipulli"
                        }
                    ]
                },
                {
                    "id": "14-2",
                    "nombre": "Ranco",
                    "comunas": [
                        {
                            "id": "14-2-01",
                            "nombre": "La Unión"
                        },
                        {
                            "id": "14-2-02",
                            "nombre": "Futrono"
                        },
                        {
                            "id": "14-2-03",
                            "nombre": "Lago Ranco"
                        },
                        {
                            "id": "14-2-04",
                            "nombre": "Río Bueno"
                        }
                    ]
                }
            ]
        },
        {
            "id": "10",
            "nombre": "Los Lagos",
            "provincias": [
                {
                    "id": "10-1",
                    "nombre": "Llanquihue",
                    "comunas": [
                        {
                            "id": "10-1-01",
                            "nombre": "Puerto Montt"
                        },
                        {
                            "id": "10-1-02",
                            "nombre": "Calbuco"
                        },
                        {
                            "id": "10-1-03",
                            "nombre": "Cochamó"
                        },
                        {
                            "id": "10-1-04",
                            "nombre": "Fresia"
                        },
                        {
                            "id": "10-1-05",
                            "nombre": "Frutillar"
                        },
                        {
                            "id": "10-1-06",
                            "nombre": "Los Muermos"
                        },
                        {
                            "id": "10-1-07",
                            "nombre": "Llanquihue"
                        },
                        {
                            "id": "10-1-08",
                            "nombre": "Maullín"
                        },
                        {
                            "id": "10-1-09",
                            "nombre": "Puerto Varas"
                        }
                    ]
                },
                {
                    "id": "10-2",
                    "nombre": "Chiloé",
                    "comunas": [
                        {
                            "id": "10-2-01",
                            "nombre": "Castro"
                        },
                        {
                            "id": "10-2-02",
                            "nombre": "Ancud"
                        },
                        {
                            "id": "10-2-03",
                            "nombre": "Chonchi"
                        },
                        {
                            "id": "10-2-04",
                            "nombre": "Curaco de Vélez"
                        },
                        {
                            "id": "10-2-05",
                            "nombre": "Dalcahue"
                        },
                        {
                            "id": "10-2-06",
                            "nombre": "Puqueldón"
                        },
                        {
                            "id": "10-2-07",
                            "nombre": "Queilén"
                        },
                        {
                            "id": "10-2-08",
                            "nombre": "Quellón"
                        },
                        {
                            "id": "10-2-09",
                            "nombre": "Quemchi"
                        },
                        {
                            "id": "10-2-10",
                            "nombre": "Quinchao"
                        }
                    ]
                },
                {
                    "id": "10-3",
                    "nombre": "Osorno",
                    "comunas": [
                        {
                            "id": "10-3-01",
                            "nombre": "Osorno"
                        },
                        {
                            "id": "10-3-02",
                            "nombre": "Puerto Octay"
                        },
                        {
                            "id": "10-3-03",
                            "nombre": "Purranque"
                        },
                        {
                            "id": "10-3-04",
                            "nombre": "Puyehue"
                        },
                        {
                            "id": "10-3-05",
                            "nombre": "Río Negro"
                        },
                        {
                            "id": "10-3-06",
                            "nombre": "San Juan de la Costa"
                        },
                        {
                            "id": "10-3-07",
                            "nombre": "San Pablo"
                        }
                    ]
                },
                {
                    "id": "10-4",
                    "nombre": "Palena",
                    "comunas": [
                        {
                            "id": "10-4-01",
                            "nombre": "Chaitén"
                        },
                        {
                            "id": "10-4-02",
                            "nombre": "Futaleufú"
                        },
                        {
                            "id": "10-4-03",
                            "nombre": "Hualaihué"
                        },
                        {
                            "id": "10-4-04",
                            "nombre": "Palena"
                        }
                    ]
                }
            ]
        },
        {
            "id": "11",
            "nombre": "Aysén del Gral. C. Ibáñez del Campo",
            "provincias": [
                {
                    "id": "11-1",
                    "nombre": "Coyhaique",
                    "comunas": [
                        {
                            "id": "11-1-01",
                            "nombre": "Coyhaique"
                        },
                        {
                            "id": "11-1-02",
                            "nombre": "Lago Verde"
                        }
                    ]
                },
                {
                    "id": "11-2",
                    "nombre": "Aysén",
                    "comunas": [
                        {
                            "id": "11-2-01",
                            "nombre": "Aysén"
                        },
                        {
                            "id": "11-2-02",
                            "nombre": "Cisnes"
                        },
                        {
                            "id": "11-2-03",
                            "nombre": "Guaitecas"
                        }
                    ]
                },
                {
                    "id": "11-3",
                    "nombre": "Capitán Prat",
                    "comunas": [
                        {
                            "id": "11-3-01",
                            "nombre": "Cochrane"
                        },
                        {
                            "id": "11-3-02",
                            "nombre": "O'Higgins"
                        },
                        {
                            "id": "11-3-03",
                            "nombre": "Tortel"
                        }
                    ]
                },
                {
                    "id": "11-4",
                    "nombre": "General Carrera",
                    "comunas": [
                        {
                            "id": "11-4-01",
                            "nombre": "Chile Chico"
                        },
                        {
                            "id": "11-4-02",
                            "nombre": "Río Ibáñez"
                        }
                    ]
                }
            ]
        },
        {
            "id": "12",
            "nombre": "Magallanes y Antártica Chilena",
            "provincias": [
                {
                    "id": "12-1",
                    "nombre": "Magallanes",
                    "comunas": [
                        {
                            "id": "12-1-01",
                            "nombre": "Punta Arenas"
                        },
                        {
                            "id": "12-1-02",
                            "nombre": "Laguna Blanca"
                        },
                        {
                            "id": "12-1-03",
                            "nombre": "Río Verde"
                        },
                        {
                            "id": "12-1-04",
                            "nombre": "San Gregorio"
                        }
                    ]
                },
                {
                    "id": "12-2",
                    "nombre": "Antártica Chilena",
                    "comunas": [
                        {
                            "id": "12-2-01",
                            "nombre": "Cabo de Hornos"
                        },
                        {
                            "id": "12-2-02",
                            "nombre": "Antártica"
                        }
                    ]
                },
                {
                    "id": "12-3",
                    "nombre": "Tierra del Fuego",
                    "comunas": [
                        {
                            "id": "12-3-01",
                            "nombre": "Porvenir"
                        },
                        {
                            "id": "12-3-02",
                            "nombre": "Primavera"
                        },
                        {
                            "id": "12-3-03",
                            "nombre": "Timaukel"
                        }
                    ]
                },
                {
                    "id": "12-4",
                    "nombre": "Última Esperanza",
                    "comunas": [
                        {
                            "id": "12-4-01",
                            "nombre": "Natales"
                        },
                        {
                            "id": "12-4-02",
                            "nombre": "Torres del Paine"
                        }
                    ]
                }
            ]
        },
        {
            "id": "13",
            "nombre": "Metropolitana de Santiago",
            "provincias": [
                {
                    "id": "13-1",
                    "nombre": "Santiago",
                    "comunas": [
                        {
                            "id": "13-1-01",
                            "nombre": "Santiago"
                        },
                        {
                            "id": "13-1-02",
                            "nombre": "Cerrillos"
                        },
                        {
                            "id": "13-1-03",
                            "nombre": "Cerro Navia"
                        },
                        {
                            "id": "13-1-04",
                            "nombre": "Conchalí"
                        },
                        {
                            "id": "13-1-05",
                            "nombre": "El Bosque"
                        },
                        {
                            "id": "13-1-06",
                            "nombre": "Estación Central"
                        },
                        {
                            "id": "13-1-07",
                            "nombre": "Huechuraba"
                        },
                        {
                            "id": "13-1-08",
                            "nombre": "Independencia"
                        },
                        {
                            "id": "13-1-09",
                            "nombre": "La Cisterna"
                        },
                        {
                            "id": "13-1-10",
                            "nombre": "La Florida"
                        },
                        {
                            "id": "13-1-11",
                            "nombre": "La Granja"
                        },
                        {
                            "id": "13-1-12",
                            "nombre": "La Pintana"
                        },
                        {
                            "id": "13-1-13",
                            "nombre": "La Reina"
                        },
                        {
                            "id": "13-1-14",
                            "nombre": "Las Condes"
                        },
                        {
                            "id": "13-1-15",
                            "nombre": "Lo Barnechea"
                        },
                        {
                            "id": "13-1-16",
                            "nombre": "Lo Espejo"
                        },
                        {
                            "id": "13-1-17",
                            "nombre": "Lo Prado"
                        },
                        {
                            "id": "13-1-18",
                            "nombre": "Macul"
                        },
                        {
                            "id": "13-1-19",
                            "nombre": "Maipú"
                        },
                        {
                            "id": "13-1-20",
                            "nombre": "Ñuñoa"
                        },
                        {
                            "id": "13-1-21",
                            "nombre": "Pedro Aguirre Cerda"
                        },
                        {
                            "id": "13-1-22",
                            "nombre": "Peñalolén"
                        },
                        {
                            "id": "13-1-23",
                            "nombre": "Providencia"
                        },
                        {
                            "id": "13-1-24",
                            "nombre": "Pudahuel"
                        },
                        {
                            "id": "13-1-25",
                            "nombre": "Quilicura"
                        },
                        {
                            "id": "13-1-26",
                            "nombre": "Quinta Normal"
                        },
                        {
                            "id": "13-1-27",
                            "nombre": "Recoleta"
                        },
                        {
                            "id": "13-1-28",
                            "nombre": "Renca"
                        },
                        {
                            "id": "13-1-29",
                            "nombre": "San Joaquín"
                        },
                        {
                            "id": "13-1-30",
                            "nombre": "San Miguel"
                        },
                        {
                            "id": "13-1-31",
                            "nombre": "San Ramón"
                        },
                        {
                            "id": "13-1-32",
                            "nombre": "Vitacura"
                        }
                    ]
                },
                {
                    "id": "13-2",
                    "nombre": "Cordillera",
                    "comunas": [
                        {
                            "id": "13-2-01",
                            "nombre": "Puente Alto"
                        },
                        {
                            "id": "13-2-02",
                            "nombre": "Pirque"
                        },
                        {
                            "id": "13-2-03",
                            "nombre": "San José de Maipo"
                        }
                    ]
                },
                {
                    "id": "13-3",
                    "nombre": "Chacabuco",
                    "comunas": [
                        {
                            "id": "13-3-01",
                            "nombre": "Colina"
                        },
                        {
                            "id": "13-3-02",
                            "nombre": "Lampa"
                        },
                        {
                            "id": "13-3-03",
                            "nombre": "Tiltil"
                        }
                    ]
                },
                {
                    "id": "13-4",
                    "nombre": "Maipo",
                    "comunas": [
                        {
                            "id": "13-4-01",
                            "nombre": "San Bernardo"
                        },
                        {
                            "id": "13-4-02",
                            "nombre": "Buin"
                        },
                        {
                            "id": "13-4-03",
                            "nombre": "Calera de Tango"
                        },
                        {
                            "id": "13-4-04",
                            "nombre": "Paine"
                        }
                    ]
                },
                {
                    "id": "13-5",
                    "nombre": "Melipilla",
                    "comunas": [
                        {
                            "id": "13-5-01",
                            "nombre": "Melipilla"
                        },
                        {
                            "id": "13-5-02",
                            "nombre": "Alhué"
                        },
                        {
                            "id": "13-5-03",
                            "nombre": "Curacaví"
                        },
                        {
                            "id": "13-5-04",
                            "nombre": "María Pinto"
                        },
                        {
                            "id": "13-5-05",
                            "nombre": "San Pedro"
                        }
                    ]
                },
                {
                    "id": "13-6",
                    "nombre": "Talagante",
                    "comunas": [
                        {
                            "id": "13-6-01",
                            "nombre": "Talagante"
                        },
                        {
                            "id": "13-6-02",
                            "nombre": "El Monte"
                        },
                        {
                            "id": "13-6-03",
                            "nombre": "Isla de Maipo"
                        },
                        {
                            "id": "13-6-04",
                            "nombre": "Padre Hurtado"
                        },
                        {
                            "id": "13-6-05",
                            "nombre": "Peñaflor"
                        }
                    ]
                }
            ]
        },
        {
            "id": "99",
            "nombre": "No Encontrado",
            "provincias": [
                {
                    "id": "99-1",
                    "nombre": "No Encontrado",
                    "comunas": [
                        {
                            "id": "99-1-01",
                            "nombre": "No Encontrado"
                        }
                    ]
                }
            ]
        }
    ],
    FormasDePago: [
        {
            "id": 0,
            "texto": "-- Sin especificar --"
        },
        {
            "id": 1,
            "texto": "Contado"
        },
        {
            "id": 2,
            "texto": "Crédito"
        },
        {
            "id": 3,
            "texto": "Sin costo"
        }
    ],
    MediosDePago: [
        {
            "id": 0,
            "texto": "-- Sin especificar --"
        },
        {
            "id": 1,
            "texto": "Cheque"
        },
        {
            "id": 2,
            "texto": "Cheque a fecha"
        },
        {
            "id": 3,
            "texto": "Letra"
        },
        {
            "id": 4,
            "texto": "Efectivo"
        },
        {
            "id": 5,
            "texto": "Pago a cuenta corriente"
        },
        {
            "id": 6,
            "texto": "Tarjeta de Crédito"
        },
        {
            "id": 7,
            "texto": "Otro"
        }
    ],
    CodigosReferenciaDocumentos: [
        {
            "id": 0,
            "texto": "-- Sin especificar --"
        },
        {
            "id": 1,
            "texto": "Anula Documento de Referencia"
        },
        { 
            "id": 2,
            "texto": "Corrige Texto Documento de Referencia"
        },
        {
            "id": 3,
            "texto": "Corrige montos"
        },
        {
            "id": -1,
            "texto": "SET"
        },
    ],
    OficinasSII: [
        {
            "id": "00",
            "nombre": "REGIONAL DESCONOCIDA",
            "unidades": [
                {
                    "id": "00-0",
                    "nombre": "Oficina Desconocida",
                    "direccion": "",
                    "formatoimpresion": "",
                    "comunas": ""
                }
            ]
        },
        {
            "id": "01",
            "nombre": "D. REGIONAL IQUIQUE",
            "unidades": [
                {
                    "id": "01-0",
                    "nombre": "Direccion Regional de Iquique",
                    "direccion": "Tarapacá 470",
                    "formatoimpresion": "S.I.I - IQUIQUE",
                    "comunas": "Iquique, Pica, Pozo Almonte, Huara, Camiña, Colchane, Alto Hospicio."
                }
            ]
        },
        {
            "id": "06",
            "nombre": "D. REGIONAL RANCAGUA",
            "unidades": [
                {
                    "id": "06-00",
                    "nombre": "Direccion Regional de Rancagua",
                    "direccion": "Calle Estado N° 154 de Rancagua",
                    "formatoimpresion": "S.I.I - RANCAGUA",
                    "comunas": "Rancagua, Machalí, Graneros, San Fco. de Mostazal, Doñihue, Codegua, Rengo, Coltauco, Requinoa, Olivar, Malloa, Coinco, Quinta de Tilcoco."
                },
                {
                    "id": "06-01",
                    "nombre": "Unidad San Fernando",
                    "direccion": "Chacabuco N° 428",
                    "formatoimpresion": "S.I.I - SAN FERNANDO",
                    "comunas": "San Fernando, Chimbarongo, Nancagua, Placilla."
                },
                {
                    "id": "06-02",
                    "nombre": "Unidad Santa Cruz",
                    "direccion": "21 de mayo 172",
                    "formatoimpresion": "S.I.I - SANTA CRUZ",
                    "comunas": "Santa Cruz, Lolol, Palmilla, Peralillo, Chépica, Pumanque"
                },
                {
                    "id": "06-03",
                    "nombre": "Unidad San Vicente de Tagua Tagua",
                    "direccion": "Calle Estado N° 154 de Rancagua",
                    "formatoimpresion": "S.I.I - SAN VICENTE",
                    "comunas": "San Vicente, Las Cabras, Peumo, Pichidegua."
                },
                {
                    "id": "06-04",
                    "nombre": "Unidad Pichilemu",
                    "direccion": "Calle Estado N° 154 de Rancagua",
                    "formatoimpresion": "S.I.I - PICHILEMU",
                    "comunas": "Pichilemu, Paredones,  Marchigue, Litueche, La Estrella."
                }
            ]
        },
        {
            "id": "13",
            "nombre": "D. REGIONAL SANTIAGO",
            "unidades": [
                {
                    "id": "13-01",
                    "nombre": "Unidad Santiago Centro",
                    "direccion": "Alonso Ovalle 680, Santiago",
                    "formatoimpresion": "S.I.I - SANTIAGO CENTRO",
                    "comunas": ""
                },
                {
                    "id": "13-02",
                    "nombre": "Unidad Santiago Poniente",
                    "direccion": "Romero 3226, Santiago",
                    "formatoimpresion": "S.I.I - SANTIAGO PONIENTE",
                    "comunas": ""
                },
                {
                    "id": "13-03",
                    "nombre": "Unidad Ñuñoa",
                    "direccion": "Avda. Irarrazaval 5515, Ñuñoa, Santiago",
                    "formatoimpresion": "S.I.I - ÑUÑOA",
                    "comunas": "Ñuñoa, La Reina, Macul, Peñalolen"
                }
            ]
        }
    ],
    TiposDespacho: [
        {
            "id": 0,
            "nombre": "Sin respuesta"
        },
        {
            "id": 1,
            "nombre": "Despacho por cuenta del receptor del documento"
        },
        {
            "id": 2,
            "nombre": "Despacho por cuenta del emisor a instalaciones del cliente"
        },
        {
            "id": 3,
            "nombre": "Despacho por cuenta del emisor a otras instalaciones"
        }
    ],
    IndicadoresTraslado: [
        { "id": 0, "nombre": "Sin respuesta" },
        { "id": 1, "nombre": "Operación constituye venta" },
        { "id": 2, "nombre": "Ventas por efectuar" },
        { "id": 3, "nombre": "Consignaciones" },
        { "id": 4, "nombre": "Entrega gratuita" },
        { "id": 5, "nombre": "Traslados internos" },
        { "id": 6, "nombre": "Otros traslados no venta" },
        { "id": 7, "nombre": "Guía de devolución" },
        { "id": 8, "nombre": "Traslado para exportación. (no venta)" },
        { "id": 9, "nombre": "Venta para exportación" }
    ]
}