import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-documento-dialogo-enviar',
  templateUrl: './documento-dialogo-enviar.component.html',
  styleUrls: ['./documento-dialogo-enviar.component.css']
})
export class DocumentoDialogoEnviarComponent implements OnInit {

  public documento: any;

  constructor(
    public dialogRef: MatDialogRef<DocumentoDialogoEnviarComponent>,
    @Inject(MAT_DIALOG_DATA) public documentoAEnviar: any) {
      console.log(documentoAEnviar);
      this.documento = documentoAEnviar;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  ngOnInit() {
  }

}
