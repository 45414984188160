import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { RouterModule } from '@angular/router';
import { ApiService } from '../servicios/servicio.api';
import { NotificacionesService } from '../servicios/servicio.notificaciones';
import { InputValidadoComponent } from './input-validado/input-validado.component';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from './truncate-pipe/truncate-pipe';
import { DateFormatPipe } from './date-format-pipe/date-format-pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { InformacionDialogoComponent } from './notificaciones/dialogos/informacion-dialogo/informacion-dialogo.component';
import { WarningDialogoComponent } from './notificaciones/dialogos/warning-dialogo/warning-dialogo.component';
import { ErrorDialogoComponent } from './notificaciones/dialogos/error-dialogo/error-dialogo.component';
import { LoggingService } from '../servicios/servicio.logging';
import { InputFechaComponent } from './input-fecha/input-fecha';
import { VerificarSesionComponent } from './verificar-sesion/verificar-sesion.component';
import { SesionPorExpirarDialogoComponent } from './verificar-sesion/dialogos/sesionporexpirar-dialogo/sesionporexpirar-dialogo.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatTreeModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule
  ],
  entryComponents: [
    ErrorDialogoComponent,
    SesionPorExpirarDialogoComponent],
  declarations: [
    InputFechaComponent,
    MenuComponent,
    FooterComponent,
    NotificacionesComponent,
    InputValidadoComponent,
    DateFormatPipe,
    TruncatePipe,
    InformacionDialogoComponent,
    WarningDialogoComponent,
    ErrorDialogoComponent,
    VerificarSesionComponent,
    SesionPorExpirarDialogoComponent],
  exports: [
    MenuComponent,
    FooterComponent,
    NotificacionesComponent,
    InputFechaComponent,
    InputValidadoComponent,
    DateFormatPipe,
    TruncatePipe,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatTreeModule,
    MatCardModule,
    MatMenuModule,
    MatDatepickerModule,
    MatDialogModule],
  providers: [
    ApiService,
    NotificacionesService,
    LoggingService]
})
export class SharedModule { }
