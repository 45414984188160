import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-dialogo',
  templateUrl: './warning-dialogo.component.html',
  styleUrls: ['./warning-dialogo.component.css']
})
export class WarningDialogoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WarningDialogoComponent>,
      @Inject(MAT_DIALOG_DATA) public notificacion: any) {
      }

  ngOnInit() {
  }

}
