import { Component, OnInit } from '@angular/core';
import { PeriodosService } from '../../servicios/servicio.periodos';
import { Notificacion } from '../../core/models/notificacion';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';

@Component({
  selector: 'app-periodo-lista',
  templateUrl: './periodo-lista.component.html',
  styleUrls: ['./periodo-lista.component.css']
})
export class PeriodoListaComponent implements OnInit {
  public periodo: number;
  public periodos: Array<any>;
  public periodosEnUso: Array<any>;
  public periodosArchivados: Array<any>;

  constructor(
    private periodosService: PeriodosService,
    private servicioNotificaciones: NotificacionesService) { }

  ngOnInit() {
    this.periodo = new Date().getFullYear();
    this.periodos = [];
    this.refrescar();
  }

  refrescar(): void {
    this.periodosService.getPorAnio(this.periodo).subscribe(response => {
      this.periodos = response.datos;
      this.periodosEnUso = this.periodos.filter(m => m.estaAbierto);
      this.periodosArchivados = this.periodos.filter(m => !m.estaAbierto);
    });
  }

  anioAnterior() : void {
    this.periodo--;
    this.refrescar();
  }

  proximoAnterior() : void {
    this.periodo++;
    this.refrescar();
  }

  abrir(anio: number, mes: number) : void {
    console.log("abrir " + anio + "-" + mes);
    this.periodosService.abrir(anio, mes).subscribe(response => {
      if(response.esCorrecto) {
        var titulo = "Periodo abierto con éxito";
        var mensaje = "<p>El periodo " + anio + "-" + mes + " ha sido abierto satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      else {
        var titulo = "Hubieron problemas cerrando el producto";
        var mensaje = "<p><ul>";
        response.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      this.refrescar();
    });
    
  }

  cerrar(anio: number, mes: number) : void {
    console.log("cerrar " + anio + "-" + mes);
    this.periodosService.cerrar(anio, mes).subscribe(response => {
      if(response.esCorrecto) {
        var titulo = "Periodo cerrado con éxito";
        var mensaje = "<p>El periodo " + anio + "-" + mes + " ha sido cerrado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      else {
        var titulo = "Hubieron problemas cerrando el producto";
        var mensaje = "<p><ul>";
        response.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      this.refrescar();
    });
  }
}
