<div *ngIf="this.documento" class="panel panel-default">
    <div class="panel-heading">
        <h4>Ingreso manual de Boletas</h4>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <fieldset>
                    <div class="form-group">
                        <label>
                            Rango de boletas
                        </label>
                        <div class="input-group">
                            <input class="numerico form-control" placeholder="Ingrese el inicio" [(ngModel)]="this.documento.desde" required="true" type="number">
                            <span class="input-group-addon">-</span>
                            <input class="numerico form-control" placeholder="Ingrese el fin" [(ngModel)]="this.documento.hasta" required="true" type="number">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            Fecha
                        </label>
                        <app-input-fecha [readOnly]="this.accion == 'modificar'" ngDefaultControl (valueChange)="this.cambioPeriodoDocumento(this.documento);" [(value)]="this.documento.fechaEmision" [placeholder]="'Seleccione la fecha de emisión'">
                        </app-input-fecha>
                    </div>
                    <div class="form-group">
                        <label>
                            Total
                        </label>
                        <input type="number" [(ngModel)]="this.documento.total" placeholder="Total" required="true" class="numero numerico form-control" />
                    </div>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="panel-footer">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <input class="btn btn-block btn-success" value="Guardar" (click)="guardar()">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <a class="btn btn-block btn-warning" onclick="javascript:history.go(-1)">Cancelar</a>
            </div>
        </div>
    </div>
</div>