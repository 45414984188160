<div class="panel panel-default">
    <div class="panel-heading">
        <h4>Configuración de la cuenta: {{this.usuarioSeleccionado.nombre}}</h4>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="panel panel-info">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xs-3">
                                <i class="fa fa-key fa-5x"></i>
                            </div>
                            <div class="col-xs-9 text-right">
                                <div>Para cambiar su contraseña de ingreso</div>
                            </div>
                        </div>
                    </div>
                    <a style="cursor: pointer;" (click)="abrirDialogoCambiarContrasena()">
                        <div class="panel-footer">
                            <span class="pull-left">Cambiar contraseña</span>
                            <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                            <div class="clearfix"></div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="panel panel-info">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xs-3">
                                <i class="fa fa-certificate fa-5x"></i>
                            </div>
                            <div class="col-xs-9 text-right">
                                <div>Para cargar su firma electrónica con la que firmará los documentos</div>
                            </div>
                        </div>
                    </div>
                    <a style="cursor: pointer;"  (click)="abrirDialogoCambiarFirma()">
                        <div class="panel-footer">
                            <span class="pull-left">Firma Electrónica</span>
                            <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                            <div class="clearfix"></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>