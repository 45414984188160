<div *ngIf="documento" class="panel panel-default">
  <div class="panel-body">
<!-- Destinatario opcional -->
    <!-- Destinatario -->
    <div class="row">
      <div class="col-md-12">
        <div style="text-align: right;" >
          <button class="btn btn-primary" *ngIf="!this.ingresarDestinatarioActivado" (click)="this.ingresarDestinatarioActivado = !this.ingresarDestinatarioActivado; panelSugerirDestinatarioVisible = true"><i class="fa fa-user"></i> Ingresar Destinatario</button>
        </div>
        <ng-container *ngIf="this.ingresarDestinatarioActivado">
          <fieldset>
            <legend>Destinatario
              <ng-container>
                <sup><small><a style="cursor: pointer;" (click)="this.sinDestinatario()">Sin destinatario </a></small></sup>
              </ng-container>

              <ng-container *ngIf="this.accion == 'crear' && this.documento.destinatario.rut < 1">
                <sup><small><a style="cursor: pointer;" (click)="this.seleccionarDestinatarioEstaEmpresa()">esta
                      empresa</a></small></sup>
              </ng-container>
              <ng-container *ngIf="!panelSugerirDestinatarioVisible">
                <sup><small><a style="cursor: pointer"
                      (click)="panelSugerirDestinatarioVisible = !panelSugerirDestinatarioVisible">Ir al
                      buscador</a></small></sup>
              </ng-container>
            </legend>
            <small *ngIf="panelSugerirDestinatarioVisible && this.documento.destinatario.razonSocial">{{ this.documento.destinatario.razonSocial }}</small>
            <ng-container *ngIf="panelSugerirDestinatarioVisible">
              <div class="form-group" style="margin-bottom: 0px">
                <label>
                  Busca aquí:
                </label>
                <input type="text" [(ngModel)]="this.destinatarioBuscado" (keyup)="sugerirDestinatario($event)"
                  placeholder="Busca a tu cliente aquí..." class="form-control">
              </div>
              <ng-container *ngIf="this.destinatariosSugeridos && this.destinatariosSugeridos.length > 0">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                    <tr *ngFor="let alternativa of this.destinatariosSugeridos">
                      <td>{{ alternativa.rut }}-{{ alternativa.digitoVerificador}}</td>
                      <td><a style="cursor: pointer"
                          (click)="seleccionarDestinatario(alternativa);">{{
                          alternativa.nombre | truncate:[20, '...'] }}</a></td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <a style="cursor: pointer"
                (click)="panelSugerirDestinatarioVisible = !panelSugerirDestinatarioVisible">Saltar, quiero ingresarlo
                manualmente</a>
              <br /><br />
            </ng-container>
            <ng-container *ngIf="!panelSugerirDestinatarioVisible">
              <div class="form-group">
                <label>
                  RUT
                </label>
                <div class="input-group">
                  <input type="text" [(ngModel)]="documento.destinatario.rut" (change)="cambioRutDestinatario()"
                    placeholder="Ingrese el rut del destinatario" required="true" class="numerico form-control">
                  <span class="input-group-addon">-</span>
                  <input type="text" [(ngModel)]="documento.destinatario.digitoVerificador"
                    placeholder="Ingrese el digito verificador del rut del destinatario" required="true"
                    class="numerico form-control">
                </div>
              </div>
              <div class="form-group">
                <label>
                  Razon Social
                </label>
                <input type="text" [(ngModel)]="documento.destinatario.razonSocial"
                  placeholder="Ingrese la razón social del destinatario" required="true" class="form-control">
              </div>
              <div class="form-group">
                <label>
                  Giro
                </label>
                <input type="text" [(ngModel)]="documento.destinatario.giro"
                  placeholder="Ingrese el giro del destinatario" required="true" class="form-control">
              </div>
              <div class="form-group">
                <label>
                  Dirección
                </label>
                <div class="input-group">
                  <input type="text" [(ngModel)]="documento.destinatario.direccion"
                    placeholder="Ingrese la dirección del destinatario" required="true" class="form-control">
                  <span class="input-group-addon">,</span>
                  <select *ngIf="comunas" class="form-control" [(ngModel)]="documento.destinatario.comuna.id">
                    <option *ngFor="let comuna of comunas" value="{{comuna.id}}">{{comuna.nombre}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Teléfono
                </label>
                <input type="text" [(ngModel)]="documento.destinatario.telefono"
                  placeholder="Ingrese el teléfono del destinatario" required="true" class="form-control">
              </div>
            </ng-container>
          </fieldset>
        </ng-container>
      </div>
    </div>


    <!-- Detalle -->
    <!-- Para boletas incluye el bruto -->
    <div class="row">
      <div class="col-md-12">
        <fieldset>
          <legend>Detalle de productos/servicios</legend>
        </fieldset>
        <div *ngIf="this.documento.items.length > 0" class="row">
          <div class="col-md-12">
            <div style="background-color: rgb(200,200,200); font-weight: bolder; padding: 10px 10px 10px 10px; color: black" class="col-xs-3 col-md-2 col-lg-2">Cantidad</div>
            <div style="background-color: rgb(200,200,200); font-weight: bolder; padding: 10px 10px 10px 10px; color: black" class="col-xs-5 col-md-4 col-lg-6">Detalle</div>
            <div style="background-color: rgb(200,200,200); font-weight: bolder; padding: 10px 10px 10px 10px; color: black" class="col-xs-2 col-md-4 col-lg-2">Precio</div>
            <div style="background-color: rgb(200,200,200); font-weight: bolder; padding: 10px 10px 10px 10px; color: black" class="col-xs-2 col-md-2 col-lg-2">&nbsp; </div>
          </div>
        </div>
        <div *ngFor="let item of documento.items; let i = index;" class="row"
          style="border-bottom: 1px dashed #dddddd; padding-bottom: 10px; padding-top: 10px;">
          <div class="col-md-12">
            <div style="background-color:white; color: black" class="col-xs-3 col-md-2 col-lg-2">{{ item.cantidad }}
            </div>
            <div style="background-color:white; color: black" class="col-xs-5 col-md-4 col-lg-6">{{ item.glosa }}</div>
            <div style="background-color:white; color: black" class="col-xs-2 col-md-4 col-lg-2">{{ item.precioBruto }}
            </div>
            <div style="background-color:white; color: black" class="col-xs-2 col-md-2 col-lg-2">
              <div class="col-xs-12 col-sm-1 col-md-1 col-lg-2">
                <a (click)="eliminarItem(i)" class="btn btn-danger">
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        <ng-container>
          <div class="col-xs-3 col-sm-2 col-md-2 col-lg-2">
            <label>Cantidad</label>
            <input type="number" [(ngModel)]="this.itemDocumentoTemporal.cantidad" (change)="recalcular()"
              placeholder="Cantidad" required="true" class="form-control">
          </div>
          <div class="col-xs-4 col-sm-5 col-md-4 col-lg-6">
            <label>Descripción</label>
            <input type="text" #itemDocumentoTemporalglosa autofocus  [(ngModel)]="this.itemDocumentoTemporal.glosa"
              [readonly]="this.itemDocumentoTemporal.producto.id > 0"
              (keypress)="sugerirProducto(this.itemDocumentoTemporal)" placeholder="Descripción" required="true"
              class="form-control">
            <!-- <div class="input-group">

                <div class="input-group-btn">
                  <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuImpuesto">
                    Imp. <span class="caret"></span>
                  </button>
                  <mat-menu #menuImpuesto="matMenu">
                    <div style="padding: 10px 10px 10px">
                      <span>Impuestos del producto</span>
                      <div role="separator" class="dropdown-divider"></div>
                      <span *ngFor="let item of documento.items[i].impuestos; let a = index;" class="dropdown-item">
                        <input [(ngModel)]="documento.items[i].impuestos[a].impuesto.id" type="hidden">
                        <input [(ngModel)]="documento.items[i].impuestos[a].seleccionado" (change)="recalcular()" type="checkbox"> {{documento.items[i].impuestos[a].impuesto.nombre}}
                        <br />
                      </span>
                    </div>
                  </mat-menu>
                </div>
              </div> -->
            <ng-container *ngIf="autoCompletarItemActivo">
              <ng-container *ngIf="this.productosSugeridos && this.productosSugeridos.length > 0">
                <table class="table table-striped table-bordered table-hover">
                  <tbody>
                    <tr *ngFor="let prod of this.productosSugeridos">
                      <td>{{ prod.codigoInterno }}</td>
                      <td><a style="cursor: pointer"
                          (click)="seleccionarProducto(this.itemDocumentoTemporal, prod)">{{
                          prod.nombre |
                          truncate:[20, '...'] }}</a></td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
          </div>
          <div class="col-xs-3 col-sm-2 col-md-4 col-lg-2">
            <label>P. Unit</label>
            <input type="number" [(ngModel)]="this.itemDocumentoTemporal.precioBruto" (change)="recalcular()"
              placeholder="P.Unit" required="true" class="form-control">
          </div>
          <div class="col-xs-2 col-sm-3 col-md-2 col-lg-2">
            <br />
            <a (click)="agregarNuevoItem()" name="agregarItem" class="btn btn-success">
              <i class="fa fa-plus"></i> <span class="hidden-xs">Agregar Item</span></a>
          </div>
        </ng-container>
      </div>
    </div>
    <br />


    <!-- Mas Info y totales -->
    <div class="row">
      <div class="col-md-6">

        <br />
      </div>

      <div class="col-md-6">
        <fieldset>
          <legend>Totales</legend>
          <ng-container *ngFor="let impuestoDocumento of documento.impuestos; let i = index;">
            <div class="form-group">
              <label>
                {{ impuestoDocumento.impuesto.nombre }}
              </label>
              <div class="form-group">
                <input type="number" readonly [(ngModel)]="impuestoDocumento.total"
                  placeholder="{{ impuestoDocumento.impuesto.nombre }}" required="true"
                  class="numero numerico form-control" />
              </div>
            </div>
          </ng-container>

          <div class="form-group">
            <label>
              Total
            </label>
            <input type="text" readonly [(ngModel)]="documento.total" placeholder="Total" required="true"
              class="numero numerico form-control" />
          </div>

        </fieldset>
      </div>
    </div>
  </div>
  <div class="panel-footer">
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <a class="btn btn-warning  btn-lg" onclick="javascript:history.go(-1)">
          <i class="fa fa-arrow-left"></i> Regresar</a>
      </div>
      <div class="col-xs-6 col-md-6">
        
          <ng-container *ngIf="enviando">
            <i class="fa fa-circle-notch fa-spin"></i> Cargando
          </ng-container>
          <ng-container *ngIf="!enviando">
              <a *ngIf="!this.mostrandoConfirmacion"  class="btn btn-block btn-success  btn-lg" (click)="mostrarConfirmacion()"><i class="fa fa-save"></i> Emitir</a>
              <div *ngIf="this.mostrandoConfirmacion">
                  <div class="col-xs-4">
                    ¿Seguro?
                  </div>
                  <div class="col-xs-4">  
                    <button class="btn btn-warning btn-lg" style="width: 100%;" (click)="this.mostrandoConfirmacion = false">&nbsp;&nbsp;No&nbsp;&nbsp;</button>
                  </div>
                  <div class="col-xs-4">
                    <button class="btn btn-success btn-lg" #buttonConfirmacionEmision style="width: 100%;" (click)="emitir()">&nbsp;&nbsp;Si&nbsp;&nbsp;</button>
                  </div>
              </div>
              <div *ngIf="this.estaEnviando" class="boletaExpress-cargando">
                <p><i class="fa fa-circle-notch fa-spin"></i>&nbsp;&nbsp; Emitiendo Boleta...</p>
              </div>
          </ng-container>
      </div>
    </div>
  </div>
</div>
