<div class="row">
    <div class="col-lg-6 col-md-6">
        <div class="panel panel-info">
            <div class="panel-heading">
                <div class="row">
                    <div class="col-xs-3">
                        <i class="fa fa-receipt fa-5x"></i>
                    </div>
                    <div class="col-xs-9 text-right">
                        <div>Para certificar esta instancia para emitir boletas electrónicas</div>
                    </div>
                </div>
            </div>
            <a style="cursor: pointer;" [routerLink]="['administracion/certificacion-boletas']">
                <div class="panel-footer">
                    <span class="pull-left">Certificación Boletas</span>
                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                    <div class="clearfix"></div>
                </div>
            </a>
        </div>
    </div>

    <div class="col-lg-6 col-md-6">
        <div class="panel panel-info">
            <div class="panel-heading">
                <div class="row">
                    <div class="col-xs-3">
                        <i class="fa fa-key fa-5x"></i>
                    </div>
                    <div class="col-xs-9 text-right">
                        <div>Generar token boletas</div>
                        <strong>
                            <p>{{tokenBoleta}}</p>
                        </strong>
                    </div>
                </div>
            </div>
            <a style="cursor: pointer;" (click)="generarTokenBoleta()">
                <div class="panel-footer">
                    <span class="pull-left">Generar</span>
                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                    <div class="clearfix"></div>
                </div>
            </a>
        </div>
    </div>

    <div class="col-lg-6 col-md-6">
        <div class="panel panel-info">
            <div class="panel-heading">
                <div class="row">
                    <div class="col-xs-3">
                        <i class="fa fa-lightbulb fa-5x"></i>
                    </div>
                    <div class="col-xs-9 text-right">
                        <div>Aqui puede ver los appsettings del backend</div>
                    </div>
                </div>
            </div>
            <a style="cursor: pointer;" [routerLink]="['instancia/propiedades-globales']">
                <div class="panel-footer">
                    <span class="pull-left">AppSettings de la instancia</span>
                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                    <div class="clearfix"></div>
                </div>
            </a>
        </div>
    </div>


    <div class="col-lg-6 col-md-6">
        <div class="panel panel-info">
            <div class="panel-heading">
                <div class="row">
                    <div class="col-xs-3">
                        <i class="fa fa-exclamation-triangle fa-5x"></i>
                    </div>
                    <div class="col-xs-9 text-right">
                        <div>Generar exception en el backend</div>
                    </div>
                </div>
            </div>
            <a style="cursor: pointer;" (click)="generarException()">
                <div class="panel-footer">
                    <span class="pull-left">Genera una exception en el backend, para probar logging</span>
                    <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>
                    <div class="clearfix"></div>
                </div>
            </a>
        </div>
    </div>
</div>