import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class UsuariosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private sesionesService: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersAutentificados() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.sesionesService.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }
    
    private headersPostFile(): any {
        const headerDict = {
            "enctype": "multipart/form-data",
            'token': this.sesionesService.obtenerSesion().token
        }

        return new HttpHeaders(headerDict);
    }

    public cambiarPassword(password: string) : any {
        var datos = { Contrasena: password, ConfirmaContrasena: password };
        return this.http.post(`${this.urlApi}UsuarioApi/CambioContrasena`, datos, { headers: this.headersAutentificados() }).pipe(map((res: Response) => res));
    }

    public cambioFirma(fichero: any) : any {
        return this.http.post(`${this.urlApi}UsuarioApi/SubirFirma`, fichero, { headers: this.headersPostFile() }).pipe(map((res: Response) => res));
    }

    public cambioPasswordFirma(password: string) : any {
        var datos = { Contrasena: password };
        return this.http.post(`${this.urlApi}UsuarioApi/CambiarContrasenaFirma`, datos, { headers: this.headersAutentificados() }).pipe(map((res: Response) => res));
    }
}