<div class="boletaExpress" *ngIf="this.documento">
  <button type="button" class="btn btn-outline-secondary" *ngIf="!this.estaEmitiendoBoleta"
    (click)="iniciarBoletaRapida()">Boleta Rápida</button>
  <div *ngIf="this.estaEmitiendoBoleta" class="emitiendoBoletaExpress">
    <div *ngIf="!this.estaEnviando && !this.mostrandoConfirmacion" class="input-group">
      <table>
        <tr *ngFor="let item of this.documento.items">
          <td>
            $ {{ item.precioBruto.toLocaleString('es-CL') }}
          </td>
          <td>
            <button mat-mini-fab color="primary" aria-label="remove" (click)="this.eliminarItem(item)">
              <i class="fa fa-minus"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p style="text-align: right;">
            Total: <b>$ {{ this.documento.total.toLocaleString('es-CL') }}</b>
          </p>
          </td>
        </tr>
        <tr>
          <td>
            <input type="number" [ngModel]="this.itemTemporal.precioBruto" (ngModelChange)="quitarCerosALaIzquierda($event)" placeholder="Total" required="true" 
                   class="form-control boletaExpress-monto" (keydown)="eventoTeclado($event)" #boletaExpressMonto>
          </td>
          <td>
            <button mat-mini-fab color="primary" aria-label="Example icon button with a plus one icon" (click)="this.agregarItem()">
              <i class="fa fa-plus"></i>
            </button>
          </td>
        </tr>
      </table>

      <div class="flex-container">
        <button class="btn btn-success" *ngIf="!this.mostrandoConfirmacion" (click)="mostrarConfirmacion()">Emitir</button>
        <button class="btn btn-warning" (click)="cancelar()">Cancelar</button>
      </div>
    </div>
    <div *ngIf="!this.estaEnviando && this.mostrandoConfirmacion" class="input-group">
      <p>Emitir boleta por $ {{ this.documento.total.toLocaleString('es-CL') }}</p>
      <p>Seguro?</p>
      <div class="flex-container">
        <button class="btn btn-success" (click)="emitir()" #botonEmitir>&nbsp;&nbsp;Si&nbsp;&nbsp;</button>
        <button class="btn btn-warning" (click)="regresar()">&nbsp;&nbsp;No&nbsp;&nbsp;</button>
      </div>
    </div>
    <div *ngIf="this.estaEnviando" class="boletaExpress-cargando">
      <p><i class="fa fa-circle-notch fa-spin"></i>&nbsp;&nbsp; Emitiendo Boleta...</p>
    </div>
  </div>
</div>