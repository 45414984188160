import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventarioService } from '../../servicios/servicio.inventario';

@Component({
  selector: 'app-producto-detalles',
  templateUrl: './producto-detalles.component.html',
  styleUrls: ['./producto-detalles.component.css']
})
export class ProductoDetallesComponent implements OnInit {
  id: number;
  producto: any;
  documentosRecibidos: any[];
  documentosEmitidos: any[];
  servicioInventario: InventarioService;
  panelNoEncontradoVisible: boolean;

  constructor(servicioInventario: InventarioService, private route: ActivatedRoute) {
    this.servicioInventario = servicioInventario;
  }

  ngOnInit() {
    this.panelNoEncontradoVisible = false;
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.obtenerProducto(this.id);
   });
  }

  obtenerProducto(idProducto: number) {
    this.servicioInventario.getProducto(idProducto).subscribe(
      response => {
        console.log(response);  
          this.producto = response.datos;
          this.obtenerDocumentos(idProducto, 0, 1, 10);
          this.obtenerDocumentos(idProducto, 1, 1, 10);
          this.panelNoEncontradoVisible = false;
      }, (error) => {
        this.panelNoEncontradoVisible = true;
      }
    );
  }

  obtenerDocumentos(idProducto: number, operacion: number, pagina: number, cantidadResultados: number) {
    this.servicioInventario.getDocumentos(idProducto, operacion, pagina, cantidadResultados).subscribe(response => {
      if (operacion == 0) {
        this.documentosRecibidos = response.datos.datos;
      }
      else {
        this.documentosEmitidos = response.datos.datos;
      }
    });
  }

  contarCantidadProductosEnDocumento(documento: any) : number {
    var itemsMatching = documento.items.filter(i => i.producto.id === this.producto.id);
    var sum = 0;
    itemsMatching.forEach(item => {
      sum += item.cantidad;
    });
    return sum;
  }

  sumarCostoProductoEnDocumento(documento: any) : number {
    var itemsMatching = documento.items.filter(i => i.producto.id === this.producto.id);
    var sum = 0;
    itemsMatching.forEach(item => {
      sum += item.total;
    });
    return sum;
  }

}
