import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-documento-dialogo-nuevocliente',
  templateUrl: './documento-dialogo-nuevocliente.component.html',
  styleUrls: ['./documento-dialogo-nuevocliente.component.css']
})
export class DocumentoDialogoNuevoClienteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DocumentoDialogoNuevoClienteComponent>,
    @Inject(MAT_DIALOG_DATA) public documento: any) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
}
