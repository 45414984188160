import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogo-descarga-xml',
  templateUrl: './dialogo-descarga-xml.component.html',
  styleUrls: ['./dialogo-descarga-xml.component.css']
})
export class DialogoDescargaXml implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoDescargaXml>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  descargarContenido(): void {
    const blob = new Blob([this.data.datos], { type: 'application/xml' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

}
