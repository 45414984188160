import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public/public.component';
import { NotificacionesService } from '../servicios/servicio.notificaciones';
import { SesionesService } from '../servicios/servicio.sesiones';
import { WrapperlessComponent } from './wrapperless/wrapperless.component';
import { DocumentosModule } from '../documentos/documentos.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DocumentosModule,
    RouterModule.forChild([
      { path: 'erp', component: HomeComponent }
    ]),
  ],
  providers: [
    NotificacionesService,
    SesionesService
  ],
  declarations: [HomeComponent, PublicComponent, WrapperlessComponent]
})
export class ErpModule { }
