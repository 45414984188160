<ng-container *ngIf="this.estaCargando">
  <i class="fa fa-spin fa-spinner"></i> Cargando
  <br />
</ng-container>
<ng-container *ngIf="this.documento && !this.estaCargando">


  <ng-container *ngIf="this.documento.tipoDocumento == tipoDocumento.Boleta">

    <div class="row">
      <div class="col-xs-12 col-md-12">
        <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
          Acciones <span class="caret"></span>
        </button>
        <mat-menu #menuAcciones="matMenu">
          <button mat-menu-item [routerLink]="['/erp/documentos/boleta-modificar', 'modificar', this.documento.id]"><i class="fa fa-edit"></i> Modificar</button>
          <button *ngIf="this.servicioSesiones.sesionContieneRol('Administrador')" mat-menu-item data-target="#SeguroMoverPeriodo" (click)="abrirDialogoCambiarPerido()"><i class="fa fa-exchange"></i> Cambiar Periodo</button>
          <button *ngIf="this.servicioSesiones.sesionContieneRol('Administrador')" mat-menu-item data-target="#SeguroRemoverPeriodo" (click)="abrirDialogoRemoverPerido()"><i class="fa fa-external-link"></i> Quitar del Periodo</button>
        </mat-menu>
        <hr/>
      </div>
    </div>


    <div class="row">
      <div class="col-xs-12 col-md-12">
        <fieldset>
          <legend>Boletas</legend>
          <div class="col-xs-6 col-md-6">
            <label class="numero">Fecha Emisión</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h5 class="numero">{{ documento.fechaEmision | date : "dd/MM/yyyy" }}</h5>
          </div>
          <div class="col-xs-6 col-md-6">
            <label class="numero">Rango</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h5 class="numero">{{ documento.desde | number:'':'es-CL'  }} - {{ documento.hasta | number:'':'es-CL'  }}</h5>
          </div>
          <div class="col-xs-6 col-md-6">
            <label class="numero">Neto</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h5 class="numero">{{ documento.neto | number:'':'es-CL'  }}</h5>
          </div>
          <ng-container *ngFor="let impuesto of documento.impuestos">
            <div class="col-xs-6 col-md-6">
              <label class="numero">{{ impuesto.impuesto.nombre }}</label>
            </div>
            <div class="col-xs-6 col-md-6">
              <h5 class="numero">{{ impuesto.total | number:'':'es-CL'  }}</h5>
            </div>
          </ng-container>
          <div class="col-xs-12 col-md-12">
            <hr />
          </div>
          <div class="col-xs-6 col-md-6">
            <label class="numero">Total</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h3 class="numero">{{ documento.Total | number:'':'es-CL'  }}</h3>
          </div>
        </fieldset>
      </div>
      <div class="col-xs-12 col-md-12">
        <a [routerLink]="['/erp/documentos/boleta-modificar', 'crear', -1]" class="btn btn-success">Ingresar más Boletas</a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="this.documento.tipoDocumento != tipoDocumento.Boleta">

    <div class="row">
      <div class="col-xs-12 col-md-12">
        <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
          Acciones <span class="caret"></span>
        </button>
        <mat-menu #menuAcciones="matMenu">
          <button *ngIf="!this.respuestaEnvioDte" mat-menu-item [routerLink]="['/erp/documentos/documento-modificar', 'modificar', this.documento.id]"><i class="fa fa-edit"></i> Modificar</button>
          <button mat-menu-item *ngIf="this.documento.tipoDocumento != tipoDocumento.BoletaElectronica" (click)="abrirDialogoDuplicar()"><i class="fa fa-copy"></i> Duplicar</button>
          <button mat-menu-item (click)="imprimirPdf()" *ngIf="this.respuestaEnvioDte" ><i class="fa fa-print"></i> Imprimir</button>
          <button mat-menu-item (click)="imprimirPdfBoleta()" *ngIf="this.respuestaEnvioDte" ><i class="fa fa-print"></i> Imprimir Voucher</button>
          <button mat-menu-item (click)="imprimirPdfBorrador()" *ngIf="!this.respuestaEnvioDte" ><i class="fa fa-print"></i> Imprimir Borrador</button>
          <button *ngIf="this.documento.tipoDocumento == 33" mat-menu-item data-target="#SeguroAnular" (click)="abrirDialogoAnular()"><i class="fa fa-times"></i> Anular</button>
          <button mat-menu-item data-target="#SeguroMoverPeriodo" (click)="abrirDialogoCambiarPerido()"><i class="fa fa-exchange-alt"></i> Cambiar Periodo</button>
          <button *ngIf="this.servicioSesiones.sesionContieneRol('Administrador')" mat-menu-item data-target="#SeguroRemoverPeriodo" (click)="abrirDialogoRemoverPerido()"><i class="fa fa-folder-minus"></i> Quitar del Periodo</button>
          <button *ngIf="!this.respuestaEnvioDte && esElectronico(this.documento.tipoDocumento)" (click)="abrirDialogoEnviar()" mat-menu-item><i class="fa fa-envelope"></i> Enviar al SII</button>
          <button *ngIf="this.respuestaEnvioDte && this.respuestaEnvioDte.status == 11 && esElectronico(this.documento.tipoDocumento)" (click)="reenviar()" mat-menu-item><i class="fa fa-envelope"></i> Reenviar al SII</button>
          <button *ngIf="this.respuestaEnvioDte" (click)="abrirDialogoEnviarPorCorreo()" mat-menu-item><i class="fa fa-envelope"></i> Enviar por correo</button>
          <button *ngIf="!this.respuestaEnvioDte" mat-menu-item (click)="abrirDialogoEliminar()"><i class="fa fa-trash"></i> Eliminar</button>
          <button *ngIf="this.respuestaEnvioDte" mat-menu-item (click)="abrirDialogoVerInformacionEnvio()"><i class="fa fa-eye"></i> Respuesta SII</button>
          <button *ngIf="this.respuestaEnvioDte" mat-menu-item (click)="descargarPdf(false)"><i class="fa fa-print"></i> PDF</button>
          <button *ngIf="this.respuestaEnvioDte" mat-menu-item (click)="descargarPdf(true)"><i class="fa fa-print"></i> PDF Cedible</button>
          <button *ngIf="this.respuestaEnvioDte" mat-menu-item (click)="descargarXml()"><i class="fa fa-print"></i> XML</button>
        </mat-menu>
        <hr/>
      </div>
    </div>


    <div class="row" *ngIf="this.documento.emisor">
      <div class="col-md-4 col-sm-6 col-xs-12">
        <fieldset>
          <legend>Emisor</legend>
          <div>
            <h4 style="text-align: center"> {{ documento.emisor.razonSocial }} </h4>
          </div>
          <div>
            <h5 style="text-align: center; overflow: hidden">{{ documento.emisor.giro }}</h5>
          </div>
          <div>
            <h5 style="text-align: center">{{ documento.emisor.direccion}}, {{ documento.emisor.comuna?.nombre}}</h5>
          </div>
        </fieldset>
      </div>
      <div class="col-md-4 col-sm-0 col-xs-0"></div>
      <div class="col-md-4 col-sm-6 col-xs-12">
        <fieldset>
          <legend>Documento</legend>
          <h3 style="text-align: center">RUT: {{ documento.emisor.rut | number:'':'es-CL'  }} - {{ documento.emisor.digitoVerificador }}</h3>
          <h4 style="text-align: center"> {{ 'DOCUMENTOS.TipoDocumento_' + documento.tipoDocumento | translate }} </h4>
          <h3 style="text-align: center">
            <span *ngIf="documento.numero < 1">(Folio Pendiente)
            </span>
            <span *ngIf="documento.numero > 0">{{ documento.numero | number:'':'es-CL'  }}</span>
          </h3>
          <h4 style="text-align: center">{{ documento.fechaEmision | date : "dd/MM/yyyy" }}</h4>
        </fieldset>
      </div>
    </div>
    <br />
    <br />
    <div class="row" *ngIf="this.documento.destinatario && this.documento.destinatario.rut != 66666666 && this.documento.destinatario.rut != 0">
      <div class="col-md-12 col-xs-12">
        <fieldset>
          <legend>Destinatario</legend>
          <ng-container *ngIf="this.documento.destinatario && this.documento.destinatario.rut != 0">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <label> RUT </label>
              </div>
              <div class="col-md-8 col-sm-6 col-xs-12"> {{ documento.destinatario.rut | number:'':'es-CL' }} - {{ documento.destinatario.digitoVerificador }} </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <label>
                  <b>Nombre</b>
                </label>
              </div>
              <div class="col-md-8 col-sm-6 col-xs-12"> {{ documento.destinatario.razonSocial }} </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <label>
                  <b>Giro</b>
                </label>
              </div>
              <div class="col-md-8 col-sm-6 col-xs-12"> {{ documento.destinatario.giro }} </div>
            </div>
            <div class="row" *ngIf="documento.destinatario.Telefono != ''">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <label>
                  <b>Teléfono</b>
                </label>
              </div>
              <div class="col-md-8 col-sm-6 col-xs-12"> {{ documento.destinatario.telefono }} </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <label>
                  <b>Dirección</b>
                </label>
              </div>
              <div class="col-md-8 col-sm-6 col-xs-12"> {{ documento.destinatario.direccion }}, {{ documento.destinatario.comuna.nombre }} </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!(this.documento.destinatario && this.documento.destinatario.rut != 0)">
            <div class="row">
              <div class="col-xs-12">
                Sin destinatario
              </div>
            </div>
          </ng-container>
        </fieldset>
      </div>
    </div>
    <div class="row" *ngIf="!this.documento.destinatario || this.documento.destinatario?.rut == 66666666 || this.documento.destinatario?.rut == 0">
      <div class="col-md-12 col-xs-12">
        <fieldset>
          <legend>Destinatario</legend>
          <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
              <p> - Sin Destinatario - </p>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <br />
    <br />
  <ng-container *ngIf="this.documento.tipoDocumento == 39">
    <!-- Si es boleta electronica muestra montos brutos -->
    <div class="row" *ngIf="this.documento.items">
      <div class="col-md-12">
        <fieldset>
          <legend>Detalle de productos/servicios</legend>
          <table style="width: 100%" class="table table-bordered table-hover table-striped dataTable">
            <thead>
              <tr>
                <td>Código Interno</td>
                <td>Cantidad</td>
                <td class="hidden-xs hidden-sm">Detalle</td>
                <td>P Unit.</td>
                <td class="hidden-xs hidden-sm">Descuento</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody id="ListaProductos">
              <tr *ngFor="let item of documento.items">
                <td>
                  <!--if (!soloLectura && string.IsNullOrEmpty(item.Producto.CodigoInterno) && documento.Emisor.rut != empresa.rut)-->
                  <!--a data-toggle="modal" data-target="#AsociarProductoModal" lineaId="@item.Indice" class="asociarCodigoInterno">Asociar</a-->
                  <!--else --><a [routerLink]="['/erp/inventario/producto-detalle', item.producto.id]">{{ item.producto.codigoInterno }}</a>
                </td>
                <td> {{ item.cantidad | number:'':'es-CL'  }} </td>
                <td class="hidden-xs hidden-sm"> {{ item.glosa }} </td>
                <td style="text-align: right"> {{ item.precioBruto | number:'':'es-CL'  }} </td>
                <td class="hidden-xs hidden-sm" style="text-align: right"> {{ item.descuentoPorcentaje }} % </td>
                <td style="text-align: right"> {{ item.total | number:'':'es-CL'  }} </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="this.documento.tipoDocumento != 39">
    <!-- para todo lo otro muestra los montos netos como siempre -->
    <div class="row" *ngIf="this.documento.items">
      <div class="col-md-12">
        <fieldset>
          <legend>Detalle de productos/servicios</legend>
          <table style="width: 100%" class="table table-bordered table-hover table-striped dataTable">
            <thead>
              <tr>
                <td>Código Interno</td>
                <td>Cantidad</td>
                <td class="hidden-xs hidden-sm">Detalle</td>
                <td>P Unit.</td>
                <td class="hidden-xs hidden-sm">Descuento</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody id="ListaProductos">
              <tr *ngFor="let item of documento.items">
                <td>
                  <!--if (!soloLectura && string.IsNullOrEmpty(item.Producto.CodigoInterno) && documento.Emisor.rut != empresa.rut)-->
                  <!--a data-toggle="modal" data-target="#AsociarProductoModal" lineaId="@item.Indice" class="asociarCodigoInterno">Asociar</a-->
                  <!--else --><a [routerLink]="['/erp/inventario/producto-detalle', item.producto.id]">{{ item.producto.codigoInterno }}</a>
                </td>
                <td> {{ item.cantidad | number:'':'es-CL'  }} </td>
                <td class="hidden-xs hidden-sm"> {{ item.glosa }} </td>
                <td style="text-align: right"> {{ item.precioUnitario | number:'':'es-CL'  }} </td>
                <td class="hidden-xs hidden-sm" style="text-align: right"> {{ item.descuentoPorcentaje }} % </td>
                <td style="text-align: right"> {{ item.total | number:'':'es-CL'  }} </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </div>
  </ng-container>
    <br />
    <br />
    <div class="row" *ngIf="this.documento">
      <div class="col-xs-12 col-md-6 col-lg-6">
        <div *ngIf="documento.tipoDocumento != 50 && documento.tipoDocumento != 52">
          <ng-container *ngIf="documento.formaDePago != 0 || documento.medioDePago != 0 || documento.fechaVencimiento">
            <fieldset>
              <legend> Detalles del pago </legend>
              <div class="row" *ngIf="documento.formaDePago != 0">
                <div class="col-md-6 col-xs-6">
                  <label> Forma de Pago: </label>
                </div>
                <div class="col-md-6 col-xs-6"> {{ 'DOCUMENTOS.FormaDePago_' + documento.formaDePago | translate }} </div>
              </div>
              <div class="row" *ngIf="documento.medioDePago != 0">
                <div class="col-md-6 col-xs-6">
                  <label> Medio de Pago: </label>
                </div>
                <div class="col-md-6 col-xs-6"> {{ 'DOCUMENTOS.MedioDePago_' + documento.medioDePago | translate }} </div>
              </div>
              <div class="row" *ngIf="documento.fechaVencimiento">
                <div class="col-md-6 col-xs-6">
                  <label> Fecha Vencimiento: </label>
                </div>
                <div class="col-md-6 col-xs-6"> {{documento.fechaVencimiento | date : "dd/MM/yyyy" }} </div>
              </div>
              <br />
            </fieldset>
          </ng-container>
        </div>
        <div *ngIf="documento.tipoDocumento === 50 || documento.tipoDocumento === 52">
          <fieldset *ngIf="documento.transporte">
            <legend> Transporte </legend>
            <div class="row" *ngIf="documento.transporte.transportista && documento.transporte.transportista.rut > 0">
              <div class="col-md-4 col-xs-6">
                <label> Transportista: </label>
              </div>
              <div class="col-md-8 col-xs-6"> {{documento.transporte.transportista.razonSocial}} (RUT {{documento.transporte.transportista.rut}} - {{documento.transporte.transportista.digitoVerificador}})
                </div>
            </div>
            <div class="row" *ngIf="documento.transporte.chofer && documento.transporte.chofer.rut > 0">
              <div class="col-md-4 col-xs-6">
                <label> Chofer: </label>
              </div>
              <div class="col-md-8 col-xs-6"> {{documento.transporte.chofer.nombre}} (RUT {{documento.transporte.chofer.rut}} - {{documento.transporte.chofer.digitoVerificador}})
                </div>
            </div>
            <div class="row" *ngIf="documento.transporte.tipo !== 0">
              <div class="col-md-4 col-xs-6">
                <label> Tipo Despacho </label>
              </div>
              <div class="col-md-8 col-xs-6"> {{ 'DOCUMENTOS.TipoDespacho_' + documento.transporte.tipo | translate }} </div>
            </div>
            <div class="row" *ngIf="documento.transporte.indicador !== 0">
              <div class="col-md-4 col-xs-6">
                <label> Indicador Traslado </label>
              </div>
              <div class="col-md-8 col-xs-6"> {{ 'DOCUMENTOS.IndicadorTraslado_' + documento.transporte.indicador | translate }} </div>
            </div>
            <div class="row" *ngIf="documento.transporte.direccion && documento.transporte.direccion !== ''">
              <div class="col-md-4 col-xs-6">
                <label> Dirección </label>
              </div>
              <div class="col-md-8 col-xs-6"> {{documento.transporte.direccion}}, {{documento.transporte.comuna.nombre}} </div>
            </div>
          </fieldset>
          <br />
        </div>
        <fieldset *ngIf="documento.referencias && documento.referencias.length > 0">
          <legend>Documentos Referenciados ({{ documento.referencias.length }})</legend>
          <ul class="list-group" id="ListaReferencias">
            <li class="list-group-item" *ngFor="let docref of documento.referencias"> {{ 'DOCUMENTOS.TipoDocumento_' + docref.documento.tipoDocumento | translate }}, Número:
              <strong>{{docref.documento.numero | number:'':'es-CL' }}</strong>
              <br />
              <ng-container *ngIf="docref.razon && docref.razon != ''">
                <span>Motivo:</span>{{docref.razon}}
                <br />
              </ng-container>
              <span>Código Referencia:</span> {{ 'DOCUMENTOS.CodigoReferencia_' + docref.codigoReferencia | translate }}
              <br />
              <span>Fecha:</span>{{docref.fecha | date : "dd/MM/yyyy"}}
              <br />
            </li>
          </ul>
        </fieldset>
        <fieldset *ngIf="documento.observaciones != ''">
          <legend> Observaciones </legend>
          <p>{{documento.observaciones}}</p>
        </fieldset>
      </div>
      <div class="col-xs-0 col-md-0 col-lg-2"></div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <fieldset>
          <legend>Totales</legend>
          <div class="col-xs-6 col-md-6" *ngIf="documento.descuentoPorcentaje > 0">
            <label class="numero">Subtotal </label>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.descuentoPorcentaje > 0">
            <h3 class="numero">{{documento.subtotal | number:'':'es-CL' }}</h3>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.descuentoPorcentaje > 0">
            <label class="numero">Descuento {{documento.descuentoPorcentaje}} %</label>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.descuentoPorcentaje > 0">
            <h3 class="numero">{{documento.descuentoMonto | number:'':'es-CL' }}</h3>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.neto > 0">
            <label class="numero">Neto</label>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.neto > 0">
            <h3 class="numero">{{documento.neto | number:'':'es-CL' }}</h3>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.exento > 0">
            <label class="numero">Exento</label>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.exento > 0">
            <h3 class="numero">{{documento.exento | number:'':'es-CL' }}</h3>
          </div>
          <div *ngFor="let impuesto of documento.impuestos">
            <div class="col-xs-6 col-md-6" *ngIf="impuesto.total > 0">
              <label class="numero">{{impuesto.impuesto.nombre}}</label>
            </div>
            <div class="col-xs-6 col-md-6" *ngIf="impuesto.total > 0">
              <h3 class="numero">{{impuesto.total | number:'':'es-CL' }}</h3>
            </div>
            <div class="col-xs-12 col-md-12" *ngIf="impuesto.total > 0 && impuesto.opcionSeleccionada != 0"> {{ 'DOCUMENTOS.CodigoImpuesto_' + impuesto.opcionSeleccionada | translate }} </div>
          </div>
          <div class="col-xs-12 col-md-12">
            <hr />
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.total > 0">
            <label class="numero">Total</label>
          </div>
          <div class="col-xs-6 col-md-6" *ngIf="documento.total > 0">
            <h3 class="numero">{{documento.total | number:'':'es-CL' }}</h3>
          </div>
        </fieldset>
      </div>
    </div>
    <hr />
    <p *ngIf="this.documento.periodo && this.documento.periodo.mes > 0">Este documento pertenece al periodo: <strong>{{ this.documento.periodo.mes }}/{{ this.documento.periodo.anio }}</strong></p>
    <p *ngIf="this.documento.periodo && this.documento.periodo.mes < 0">Este documento no pertenece a ningún periodo</p>
  </ng-container>
  <br />
  <br />
  <br />
</ng-container>
