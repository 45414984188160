<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>{{this.data.titulo}}</h1>
    <div mat-dialog-content>
      <pre style="word-break: break-word; white-space: pre-wrap;">{{this.data.datos}}</pre>
    </div>
    <div mat-dialog-actions>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cerrar</button>
      <button mat-button class="btn btn-default" (click)="descargarContenido()">Descargar</button>
    </div>
  </div>
</div>
