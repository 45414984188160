import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventarioService } from '../../servicios/servicio.inventario';

@Component({
  selector: 'app-producto-documentos',
  templateUrl: './producto-documentos.component.html',
  styleUrls: ['./producto-documentos.component.css']
})
export class ProductoDocumentosComponent implements OnInit {
  servicioInventario: InventarioService;
  producto: any;
  documentosRecibidos: any[];
  documentosEmitidos: any[];
  paginaRecibidos: number;
  largoPaginaRecibidos: number;
  totalDocumentosRecibidos: number;
  paginaEmitidos: number;
  largoPaginaEmitidos: number;
  totalDocumentosEmitidos: number;

  cargandoDocumentosEmitidos: boolean;
  cargandoDocumentosRecibidos: boolean;

  constructor(servicioInventario: InventarioService, private route: ActivatedRoute) {
    this.servicioInventario = servicioInventario;
  }

  ngOnInit() {
    this.paginaRecibidos = 1;
    this.paginaEmitidos = 1;
    this.largoPaginaRecibidos = 10;
    this.largoPaginaEmitidos = 10;
    this.route.params.subscribe(params => {
      var idProducto = params['id'];
      this.obtenerProducto(idProducto);
      this.obtenerDocumentos(idProducto, 0, this.paginaEmitidos, this.largoPaginaEmitidos);
      this.obtenerDocumentos(idProducto, 1, this.paginaRecibidos, this.largoPaginaRecibidos);
   });
  }
  
  obtenerProducto(idProducto: number) {
    this.servicioInventario.getProducto(idProducto).subscribe(response => {
      this.producto = response.datos;
    });
  }

  obtenerDocumentos(idProducto: number, operacion: number, pagina: number, cantidadResultados: number) {
    if (operacion === 0) {
      this.cargandoDocumentosRecibidos = true;
    }
    else if (operacion === 1) {
      this.cargandoDocumentosEmitidos = true;
    }

    this.servicioInventario.getDocumentos(idProducto, operacion, pagina, cantidadResultados).subscribe(response => {
      if (operacion === 0) {
        this.documentosRecibidos = response.datos.datos;
        this.totalDocumentosRecibidos = response.datos.totalElementos;
        this.cargandoDocumentosRecibidos = false;
      }
      else if (operacion === 1) {
        this.documentosEmitidos = response.datos.datos;
        this.totalDocumentosEmitidos = response.datos.totalElementos;
        this.cargandoDocumentosEmitidos = false;
      }
    });
  }

  contarCantidadProductosEnDocumento(documento: any) : number {
    var itemsMatching = documento.items.filter(i => i.producto.id === this.producto.id);
    var sum = 0;
    itemsMatching.forEach(item => {
      sum += item.cantidad;
    });
    return sum;
  }

  sumarCostoProductoEnDocumento(documento: any) : number {
    var itemsMatching = documento.items.filter(i => i.producto.id === this.producto.id);
    var sum = 0;
    itemsMatching.forEach(item => {
      sum += item.total;
    });
    return sum;
  }

  paginaSiguiente(operacion: number) : void{
    if(operacion === 0){
      if (this.existePaginaSiguiente(operacion)) {
        this.paginaRecibidos++;
        this.obtenerDocumentos(this.producto.id, operacion, this.paginaRecibidos, this.largoPaginaRecibidos);
      }
    }
    else if (operacion === 1){
      if (this.existePaginaSiguiente(operacion)) {
        this.paginaEmitidos++;
        this.obtenerDocumentos(this.producto.id, operacion, this.paginaEmitidos, this.largoPaginaEmitidos);
      }
    }
  }

  paginaAnterior(operacion: number) : void{
    if(operacion === 0){
      if (this.existePaginaAnterior(operacion)) {
        this.paginaRecibidos--;
        this.obtenerDocumentos(this.producto.id, operacion, this.paginaRecibidos, this.largoPaginaRecibidos);
      }   
    }
    else if (operacion === 1){
      if (this.existePaginaAnterior(operacion)) {
        this.paginaEmitidos--;
        this.obtenerDocumentos(this.producto.id, operacion, this.paginaEmitidos, this.largoPaginaEmitidos);
      }
    }
  }

  existePaginaSiguiente(operacion: number) : boolean{
    if(operacion === 0){
      var aux = this.paginaRecibidos * this.largoPaginaRecibidos;
      return this.totalDocumentosRecibidos > aux;
    }
    else if (operacion === 1){
      var aux = this.paginaEmitidos * this.largoPaginaEmitidos;
      return this.totalDocumentosEmitidos > aux;
    }
  }

  existePaginaAnterior(operacion: number) : boolean{
    if(operacion === 0){
      return this.paginaRecibidos > 1
    }
    else if (operacion === 1){
      return this.paginaEmitidos > 1
    }
    
  }
}
