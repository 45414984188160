<div class="panel panel-default">
    <div class="panel-heading">
        <h4>
            <div *ngIf="this.accion == 'Crear'">Creando Cliente</div>
            <div *ngIf="this.accion != 'Crear'">Modificando Cliente</div>
        </h4>
    </div>
    <div class="panel-body">
        <div *ngIf="this.cliente && this.comunas" class="row">
            <div class="col-md-12">
                <fieldset>
                    <div *ngIf="this.accion == 'Crear'">
                        <div class="form-group">
                            <label>
                                RUT
                            </label>
                            <div class="input-group">
                                <input [(ngModel)]="this.cliente.rut" placeholder="Ingrese el rut" required="true"
                                    class="numerico form-control" type="number">
                                <span class="input-group-addon">-</span>
                                <input [(ngModel)]="this.cliente.digitoVerificador"
                                    placeholder="Ingrese el digito verificador del rut" required="true"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            Nombre
                        </label>
                        <input [(ngModel)]="this.cliente.razonSocial"
                            placeholder="Ingrese la razón social de su cliente" required="true" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>
                            Giro
                        </label>
                        <input [(ngModel)]="this.cliente.giro" placeholder="Ingrese  el giro de su cliente"
                            required="true" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>
                            Dirección
                        </label>
                        <div class="input-group">
                            <input [(ngModel)]="this.cliente.direccion" placeholder="Ingrese la direccion de su cliente"
                                required="true" class="form-control">
                            <span class="input-group-addon">,</span>
                            <select class="form-control" [(ngModel)]="this.cliente.comuna.id">
                                <option *ngFor="let comuna of comunas" value="{{comuna.id}}">{{comuna.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            Telefono
                        </label>
                        <input [(ngModel)]="this.cliente.telefono" placeholder="Ingrese el teléfono de su cliente"
                            required="true" class="form-control">
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <div *ngIf="this.cliente" class="panel-footer">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <a [routerLink]="['/erp/clientes/cliente-lista', 1, 10]" class="btn btn-warning"><i class="fa fa-arrow-left"></i> Volver a clientes</a>
            </div>
            <div *ngIf="this.accion == 'Crear'" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button class="btn btn-block btn-success" (click)="crear()"><i class="fa fa-save"></i> Crear</button>
            </div>
            <div *ngIf="this.accion != 'Crear'" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button class="btn btn-block btn-success" (click)="guardar()"><i class="fa fa-save"></i> Guardar</button>
            </div>
        </div>
    </div>
</div>