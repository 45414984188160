import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-documento-dialogo-enviarcopia',
  templateUrl: './documento-dialogo-enviarcopia.component.html',
  styleUrls: ['./documento-dialogo-enviarcopia.component.css']
})
export class DocumentoDialogoEnviarcopiaComponent implements OnInit {

  public correoElectronico: string;

  constructor(
    public dialogRef: MatDialogRef<DocumentoDialogoEnviarcopiaComponent>,
    @Inject(MAT_DIALOG_DATA) public email: any) {
      this.correoElectronico = email;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  ngOnInit() {
  }

}
