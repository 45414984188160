import { Component, OnInit } from '@angular/core';
import { FoliosService } from '../../servicios/servicio.folios';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-folio-detalle',
  templateUrl: './folio-detalle.component.html',
  styleUrls: ['./folio-detalle.component.css']
})
export class FolioDetalleComponent implements OnInit {
  public folio: any;
  public folioId: number;
  public documentosFolio: any;
  public pagina:number = 1;
  public largoPagina: number = 10;

  constructor(private servicioFolios: FoliosService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.documentosFolio = [];
    this.route.params.subscribe(params => {
      this.folioId = params['id'];
      this.servicioFolios.getDetalle(this.folioId).subscribe(respuestaDetalle => {
        this.folio = respuestaDetalle.datos;
      });
      this.obtenerDocumentos();
    });
  }

  obtenerDocumentos() : void {
    console.log(this.pagina, this.largoPagina);
    this.servicioFolios.getDocumentos(this.folioId, this.pagina, this.largoPagina).subscribe(respuestaDocumentos => {
      this.documentosFolio = respuestaDocumentos.datos;
      console.log(this.documentosFolio);
    });
  }

  siguientePagina() : void{
    var aux = this.pagina * this.largoPagina;
    this.pagina = this.documentosFolio.totalElementos > aux ? this.pagina + 1 : this.pagina;
    this.obtenerDocumentos();
  }

  anteriorPagina() : void{
    this.pagina = this.pagina > 1 ? this.pagina - 1 : this.pagina;
    this.obtenerDocumentos();
  }
}
