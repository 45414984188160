import { Component, OnInit } from '@angular/core';
import { LibrosService } from '../../../servicios/servicio.libro';
import { DocumentosService } from '../../../servicios/servicio.documentos';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacionesService } from '../../../servicios/servicio.notificaciones';
import { MatDialog } from '@angular/material/dialog';
import { TipoLibro } from '../../../core/enums/tipo-libro.enum';
import { TipoDocumento } from '../../../core/enums/tipo-documento.enum';
import { LibrosDialogoConfirmarGuiasComponent } from '../dialogos/libros-dialogo-confirmarguias/libros-dialogo-confirmarguias.component';

@Component({
  selector: 'app-guias-por-cliente',
  templateUrl: './guias-por-cliente.component.html',
  styleUrls: ['./guias-por-cliente.component.css']
})
export class GuiasPorClienteComponent implements OnInit {
  libro: any;
  public rut: number;
  public documentos: any;
  public guiasSeleccionadas: Array<any>;

  public anio: number;
  public mes: number;

  public pagina: number;
  public largoPagina: number;

  public tiposLibro = TipoLibro;
  public tipoDocumento = TipoDocumento;

  constructor(
    private servicioLibros: LibrosService, 
    private servicioDocumentos: DocumentosService, 
    private activatedRoute: ActivatedRoute,
    private notificacionesService: NotificacionesService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.rut = 0;
    this.documentos = [];
    this.guiasSeleccionadas = [];
    this.pagina = 1;
    this.largoPagina = 10;
    var fechaActual = new Date();
    this.anio = fechaActual.getFullYear();
    this.mes = fechaActual.getMonth() + 1;
    this.activatedRoute.params.subscribe(params => {
        if (params['anio'])
        {
          this.anio = +params['anio'];// (+) converts string  to a number
        }

        if (params['mes'])
        {
          this.mes = +params['mes'];// (+) converts string  to a number
        }

        if (params['rut'])
        {
          this.rut = +params['rut'];// (+) converts string  to a number
        }

        this.libro = {
          periodo: {
            mes: this.mes,
            anio: this.anio
          }
        };

        this.recargarDocumentos();
    });
  } 

  volverLibro() : void {
    this.router.navigate(['erp/libros/libro-guias', this.anio, this.mes]);
  }

  recargarDocumentos() : void {
    this.servicioLibros.getDocumentosPorDestinatarios(this.anio, this.mes, this.tiposLibro.Guias, this.tipoDocumento.GuiaDespachoElectronica, this.rut, this.pagina, this.largoPagina, "").subscribe(response => {
      this.documentos = response.datos;
      console.log(this.documentos);
    });   
  }
  
  siguientePagina() : void{
    var siguientePagina = this.pagina + 1;
    if(((siguientePagina - 1) * this.largoPagina) < this.documentos.totalElementos)
    {
      this.pagina++;
      this.recargarDocumentos();
    }
  }

  anteriorPagina() : void{
    var paginaAnterior = this.pagina - 1;
    if (paginaAnterior > 0)
    {
      this.pagina--;
      this.recargarDocumentos();
    }

  }

  dv(T) : any {
    var M = 0, S = 1;
    for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }

  estaSeleccionado(doc: any) : boolean {
    if (this.guiasSeleccionadas.some(a => a.id == doc.id)) {
      return true;
    }
    return false;
  }

  cambiarSeleccion(doc: any) : void {
    if (this.guiasSeleccionadas.some(a => a.id == doc.id)) {
      this.guiasSeleccionadas = this.guiasSeleccionadas.filter(e => e.id != doc.id);
    }
    else {
      this.guiasSeleccionadas.push(doc);
    }
  }

  public abrirModalFacturar() : void{
    const dialogRef = this.dialog.open(LibrosDialogoConfirmarGuiasComponent, {
      width: '400px',
      data: this.guiasSeleccionadas
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result == 'ok') {
        this.facturar();
      }
    });
  }

  facturar() : void {
    if (this.guiasSeleccionadas.length > 0){
      console.log(this.guiasSeleccionadas);
      this.servicioDocumentos.documentoDesdeGuias(this.guiasSeleccionadas).subscribe(res => {
        if (res.esCorrecto) {
          var docId = res.datos.id;
          this.router.navigate(['erp/documentos/documento-detalle', docId]);
        }  
      });
    }
  }
}
