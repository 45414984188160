<ng-container *ngIf="this.clientes">
    <h2><i class="fa fa-users"></i>  Listado de Clientes</h2>
    <div class="row">
        <div class="col-sm-6">
                ({{this.clientes.datos.totalElementos}} clientes)
            &nbsp;
        </div>
        <div class="col-sm-6">
            <div class="dataTables_filter">
                <div class="row">
                    <div class="col-sm-6" style="text-align: right">
                            <span>Filtrar: </span>
                    </div>
                    <div class="col-sm-6" style="margin-bottom: 5px;">
                        <input class="form-control input-sm" (keyup)="setFiltro($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <table class="table table-bordered table-hover table-striped tablaFiltrada">
        <thead>
            <tr>
                <th class="hidden-xs hidden-sm">Rut</th>
                <th>Razón Social</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let cliente of this.clientes.datos.datos">
                <tr>
                    <td class="hidden-xs hidden-sm">{{ cliente.rut | number:'':'es-CL' }}-{{cliente.digitoVerificador}}</td>
                    <td>{{cliente.razonSocial}}</td>
                    <td>
                        <a [routerLink]="['/erp/clientes/cliente-detalle', cliente.rut]" class="btn btn-primary btn-sm" style="cursor: pointer;">Más detalles</a>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="row">
        <div class="col-sm-6" *ngIf="this.clientes.datos.datos">
            <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina) + this.clientes.datos.datos.length }} de {{ this.clientes.datos.totalElementos }} clientes</div>
        </div>
        <div class="col-sm-6">
            <ul class="pagination pull-right">
                <li class="paginate_button previous">
                    <a (click)="anteriorPagina()" style="cursor: pointer;">Anterior</a>
                </li>
                <li class="paginate_button next" aria-controls="dataTables-example" tabindex="0">
                    <a (click)="siguientePagina()" style="cursor: pointer;">Siguiente</a>
                </li>
            </ul>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-xs-12">
            <a [routerLink]="['/erp/clientes/cliente-modificar', -1, 'Crear']" class="btn btn-success"><i class="fa fa-plus"></i> Crear Cliente</a>
        </div>
    </div>
</ng-container>
