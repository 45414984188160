import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { TipoLibro } from '../core/enums/tipo-libro.enum';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SesionesService } from './servicio.sesiones';
import { map } from 'rxjs/operators';
import { IEnvioLibro } from '../core/models/libro/envio-libro.interface';

@Injectable()
export class LibrosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersGet(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        };

        return new HttpHeaders(headerDict);
    }

    private headersPost(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        };

        return new HttpHeaders(headerDict);
    }

    public getLibro(anio: number, mes: number, tipo: TipoLibro): Observable<any> {
        const options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}LibroApi/Get?anio=${anio}&mes=${mes}&tipoLibro=${tipo}`, options);
    }

    public getLibroValidar(anio: number, mes: number, tipo: TipoLibro): Observable<any> {
        const options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}LibroApi/Validar?anio=${anio}&mes=${mes}&tipoLibro=${tipo}`, options);
    }

    public getTotales(anio: number, mes: number, tipo: TipoLibro): Observable<any> {
        const options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}LibroApi/Totales?anio=${anio}&mes=${mes}&tipoLibro=${tipo}`, options);
    }

    public getDocumentos(
        anio: number,
        mes: number,
        tipo: TipoLibro,
        tipoDocumento: number,
        pagina: number,
        largoPagina: number,
        filtro: string,
    ): Observable<any> {

        const options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}LibroApi/LibroPorTipoDocumento?anio=${anio}&mes=${mes}&tipoLibro=${tipo}&tipoDocumento=${tipoDocumento}&pagina=${pagina}&largoPagina=${largoPagina}&filtro=${filtro}`, options);
    }

    public getDestinatariosPorTipoDocumentos(
        anio: number,
        mes: number,
        tipo: TipoLibro,
        tipoDocumento: number,
        pagina: number,
        largoPagina: number,
        filtro: string,
    ): Observable<any> {

        const options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}LibroApi/DestinatariosPorTipoDocumento?anio=${anio}&mes=${mes}&tipoLibro=${tipo}&tipoDocumento=${tipoDocumento}&pagina=${pagina}&largoPagina=${largoPagina}&filtro=${filtro}`, options);
    }

    public getDocumentosPorDestinatarios(
        anio: number,
        mes: number,
        tipo: TipoLibro,
        tipoDocumento: number,
        rutDestinatario: number,
        pagina: number,
        largoPagina: number,
        filtro: string,
    ): Observable<any> {

        const options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}LibroApi/DocumentosPorDestinatarios?anio=${anio}&mes=${mes}&tipoLibro=${tipo}&tipoDocumento=${tipoDocumento}&rutDestinatario=${rutDestinatario}&pagina=${pagina}&largoPagina=${largoPagina}&filtro=${filtro}`, options);
    }



    public getLibroSinPeriodo(
        filtro: string,
        pagina: number,
        largoPagina: number
    ): Observable<any> {
        const options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}LibroApi/SinPeriodo?filtro=${filtro}&pagina=${pagina}&largoPagina=${largoPagina}`, options);
    }

    public enviar(libro: IEnvioLibro){
        var headers = this.headersPost();
        return this.http.post(`${this.urlApi}LibroApi/Enviar/`, libro, { headers: headers }).pipe(map((res: Response) => res));
    }

    public descargarXmlLibroBoletas(libro: IEnvioLibro){
        var headers = this.headersPost();
        return this.http.post(`${this.urlApi}LibroApi/DescargarXmlLibroBoletas/`, libro, { headers: headers }).pipe(map(res => res));
    }
}
