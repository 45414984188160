import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-usuario-cambiarfirma',
  templateUrl: './usuario-cambiarfirma.component.html',
  styleUrls: ['./usuario-cambiarfirma.component.css']
})
export class UsuarioCambiarfirmaComponent implements OnInit {
  nuevaFirma: any;
  nuevaFirmaPassword: any;

  constructor(
    public dialogRef: MatDialogRef<UsuarioCambiarfirmaComponent>,
    @Inject(MAT_DIALOG_DATA) public documento: any) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  subirFirma(ficheros): void {
    this.nuevaFirma = ficheros.target.files[0];
  }
}
