<div class="panel panel-default" *ngIf="this.articulo">
    <div class="panel-heading">
        <h4>{{articulo.titulo}}</h4>
    </div>
    <div class="panel-body">
        <div [innerHTML]="articulo.texto"></div>
    </div>
    <div class="panel-footer">
        <span class="right">
            <small>Ultima actualización</small> {{articulo.ultimaActualizacion}}
        </span>
    </div>
  </div>