<ng-container *ngIf="envioDTE">
    <div class="row">
        <div class="col-xs-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4>
                        <i class="fa fa-info"></i> Información del Envio</h4>
                </div>
                <div class="panel-body">
                    <fieldset>
                        <legend>Detalles del envio</legend>

                        <label>RUT que Envia</label>
                        <p>{{envioDTE.RespuestaEnvioSII.RUTEnvia }}</p>

                        <label>RUT Empresa</label>
                        <p>{{envioDTE.RespuestaEnvioSII.RUTEmpresa }}</p>

                        <label>Fecha</label>
                        <p>{{envioDTE.RespuestaEnvioSII.Timestamp }}</p>

                        <label>Track ID</label>
                        <p>{{envioDTE.RespuestaEnvioSII.TrackId }}</p>

                        <label>Envio DTE ID</label>
                        <p>{{envioDTE.EnvioDTEID}}</p>
                    </fieldset>
                </div>
            </div>
        </div>

        <div class="col-xs-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4>
                        <i class="fa fa-files-o"></i> Documentos</h4>
                </div>
                <div class="panel-body">
                    <ng-container *ngIf="documentosEnEnvio && documentosEnEnvio.length > 0">
                        <p>Documentos en este envio.</p>
                        <table id="tablaRecibidos" class="table table-bordered table-hover table-striped tablaFiltrada">
                            <thead>
                                <tr>
                                    <th class="numero">
                                        Folio
                                    </th>
                                    <th class="hidden-xs hidden-sm">
                                        Destinatario
                                    </th>
                                    <th class="numero">
                                        Cantidad
                                    </th>
                                    <th class="numero">
                                        Total Producto
                                    </th>
                                    <th class="hidden-xs hidden-sm numero">
                                        Total Documento
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="documentosEnEnvio">
                                    <tr *ngFor="let documento of documentosEnEnvio">
                                        <td class="numero">{{documento.Numero | number:'':'es-CL'}}</td>
                                        <td class="hidden-xs hidden-sm">{{documento.Destinatario.RazonSocial}}</td>
                                        <td class="numero">{{contarCantidadProductosEnDocumento(documento) | number:'':'es-CL'}}</td>
                                        <td class="numero">{{sumarCostoProductoEnDocumento(documento) | number:'':'es-CL'}}</td>
                                        <td class="hidden-xs hidden-sm numero">{{documento.Total | number:'':'es-CL'}}</td>
                                        <td>
                                            <a class="btn btn-sm btn-default" [routerLink]="['/erp/documentos/documento-detalle/', documento.ID]">
                                                <i class="fa fa-arrow-right"></i> Ir</a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="documentosEnEnvio && documentosEnEnvio.length <= 0">
                        No hay documentos para este envio
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>