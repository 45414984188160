import { Component, OnInit } from '@angular/core';
import { EnumeracionesService } from '../../servicios/servicio.enumeraciones';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { SesionesService } from '../../servicios/servicio.sesiones';

@Component({
  selector: 'app-instancia-modificar',
  templateUrl: './instancia-modificar.component.html',
  styleUrls: ['./instancia-modificar.component.css']
})
export class InstanciaModificarComponent implements OnInit {
  public accion: any;
  public empresa: any;
  public comunas: any[];
  public unidades: any[];

  constructor(
    private servicioSesiones: SesionesService,
    private servicioEnumeraciones: EnumeracionesService,
    private servicioNotificaciones: NotificacionesService
  ) { }

  ngOnInit() {
    this.comunas = this.servicioEnumeraciones.getComunas();
    this.unidades = this.servicioEnumeraciones.getUnidadesSII();
    this.empresa = this.servicioSesiones.obtenerSesion().empresaSeleccionada;
  }

}
