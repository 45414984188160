<div class="panel panel-default">
  <div class="panel-heading">
      <h4>@ViewBag.Titulo</h4>
  </div>
  <div class="panel-body">
      <p>A continuación ud puede ver todas las empresas para las cuales <strong>@Model.Nombre (RUT: @Model.RUT.ToString("N0")-@Model.DigitoVerificador)</strong> esta autorizado.</p>
      <table class="table table-bordered table-hover table-striped tablaFiltrada">
          <thead>
              <tr>
                  <th>
                      RUT
                  </th>
                  <th class="hidden-xs hidden-sm">
                      Alias
                  </th>
                  <th>
                      Autorizado
                  </th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
              <!--foreach (var empresa in empresasTodas)-->
                  <tr>
                      <td>
                          @empresa.RUT.ToString("N0")-@Model.DigitoVerificador
                      </td>
                      <td class="hidden-xs hidden-sm">
                          @empresa.Alias
                      </td>
                      <td>
                          @(empresasAutorizadas.Any(m => m.RUT == empresa.RUT) ? "Si" : "No")
                      </td>
                      <td>
                          <div class="btn-group" role="group">
                              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                  Acciones <span class="caret"></span>
                              </button>
                              <ul class="dropdown-menu" role="menu">
                                  <!--if (empresasAutorizadas.Any(m => m.RUT == empresa.RUT))-->
                                  
                                      <li><!--Html.ActionLink("Desautorizar", "EliminarEmpresaAutorizada", new { idusuario = Model.RUT, idempresa = empresa.RUT })--></li>
                                  
                                  <!--else -->
                                  
                                      <li><!--Html.ActionLink("Autorizar", "AgregarEmpresaAutorizada", new { idusuario = Model.RUT, idempresa = empresa.RUT })--></li>
                                  
                              </ul>
                          </div>
                      </td>
                  </tr>
          </tbody>
      </table>
  </div>
  <div class="panel-footer">
      <div class="row">
          <div class="col-xs-6 col-md-6">
              <a class="btn btn-block btn-warning" onclick="javascript:history.go(-1)"><i class="fa fa-arrow-left"></i> Regresar</a>
          </div>
      </div>
  </div>
</div>