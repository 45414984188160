<div class="row">
    <div class="col-xs-12">
        <h1 mat-dialog-title>Cambio de contraseña</h1>
        <div mat-dialog-content>
            <p>{{this.usuarioSeleccionado.nombre}}, Por favor ingrese la información requerida para cambiar su
                contraseña. Las contraseñas
                deben tener al menos 6 caracteres de largo.</p>
            <app-input-validado type="password" label="Nueva Contraseña" [(value)]="nuevaPassword.pass1" required="true"
                min="6" (onValidation)="onPass1Validation($event)"></app-input-validado>
            <app-input-validado type="password" label="Confirme Contraseña" [(value)]="nuevaPassword.pass2"
                required="true" min="6" (onValidation)="onPass2Validation($event)"></app-input-validado>
        </div>
        <div mat-dialog-actions>
            <button mat-button class="btn btn-danger" [disabled]="!esPassValida()"
                [mat-dialog-close]="{ result: 'ok', pass: this.nuevaPassword.pass1 }" cdkFocusInitial>Cambiar</button>
            <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
        </div>
    </div>
</div>