import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class PeriodosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getDetalle(anio: number, mes: number) : Observable<any> {
        var headers = this.headersPost();
        return this.http.get(this.urlApi + `PeriodosApi/Detalle/?anio=${anio}&mes=${mes}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getAbiertos() : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(`${this.urlApi}PeriodosApi/Abiertos`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getPorAnio(anio: number) : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(`${this.urlApi}PeriodosApi/PorAnio/?anio=${anio}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public abrir(anio: number, mes: number) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `PeriodosApi/Abrir/?anio=${anio}&mes=${mes}`, { }, { headers: headers }).pipe(map((res: Response) => res));
    }

    public cerrar(anio: number, mes: number) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `PeriodosApi/Cerrar/?anio=${anio}&mes=${mes}`, { }, { headers: headers }).pipe(map((res: Response) => res));
    }
}