<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>Cambiar de Periodo</h1>
    <div mat-dialog-content>
        <p>Seleccione el mes al que quiere mover este documento:</p>
        <select [(ngModel)]="this.nuevoPeriodo" class="form-control">
          <option *ngFor="let periodo of this.periodosAbiertos" [ngValue]="periodo">{{periodo.mes}}-{{periodo.anio}}</option>
        </select>
    </div>
    <div mat-dialog-actions>
      <button mat-button class="btn btn-danger" [mat-dialog-close]="this.nuevoPeriodo" cdkFocusInitial>Mover</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>