<div class="row">
    <div class="col-xs-12">
        <h1 mat-dialog-title><i class="fa fa-upload"></i> Subir Folios</h1>
        <div mat-dialog-content>
            <div class="col-md-12 form-group">
                <label>
                    XML
                </label>
                <input type="file" (change)="subirFolio($event)" />
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button [mat-dialog-close]="{ result: 'ok', folio: this.nuevoFolio }" class="btn btn-success">Cargar</button>
            <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
        </div>
    </div>
</div>