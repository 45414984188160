<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>Enviar al SII</h1>
    <div mat-dialog-content>
      <p>Recuerde que para poder emitir el documento ud necesita estar autorizado como emisor en el sitio del SII y
        ademas haber cargado su firma digital en el panel de control de usuario.</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="{ result: 'ok', documento: this.documento }" class="btn btn-danger" cdkFocusInitial>Enviar</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>