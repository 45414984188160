import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentoDetallesComponent } from './documento-detalles/documento-detalles.component';
import { DocumentoModificarComponent } from './documento-modificar/documento-modificar.component';
import { SharedModule } from '../shared/shared.module';
import { DocumentosService } from '../servicios/servicio.documentos';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EnumeracionesService } from '../servicios/servicio.enumeraciones';
import { PeriodosService } from '../servicios/servicio.periodos';
import { ClientesService } from '../servicios/servicio.clientes';
import { DateFormatPipe } from '../shared/date-format-pipe/date-format-pipe';
import { DocumentoDialogoAnularComponent } from './dialogos/documento-dialogo-anular/documento-dialogo-anular.component';
import { DocumentoDialogoEnviarComponent } from './dialogos/documento-dialogo-enviar/documento-dialogo-enviar.component';
import { DocumentoDialogoDuplicarComponent } from './dialogos/documento-dialogo-duplicar/documento-dialogo-duplicar.component';
import { DocumentoDialogoInfoenvioComponent } from './dialogos/documento-dialogo-infoenvio/documento-dialogo-infoenvio.component';
import { DocumentoDialogoEliminarComponent } from './dialogos/documento-dialogo-eliminar/documento-dialogo-eliminar.component';
import { DocumentoDialogoPeriodoremoverComponent } from './dialogos/documento-dialogo-periodoremover/documento-dialogo-periodoremover.component';
import { DocumentoDialogoPeriodocambiarComponent } from './dialogos/documento-dialogo-periodocambiar/documento-dialogo-periodocambiar.component';
import { DocumentoDialogoEnviarcopiaComponent } from './dialogos/documento-dialogo-enviarcopia/documento-dialogo-enviarcopia.component';
import { BoletasModificarComponent } from './boletas-modificar/boletas-modificar.component';
import { BoletaPosComponent } from './boleta-pos/boleta-pos.component';
import { BoletaTotalComponent } from './boleta-total/boleta-total.component';
import { DocumentoDialogoNuevoClienteComponent } from './dialogos/documento-dialogo-nuevocliente/documento-dialogo-nuevocliente.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    MatButtonModule
  ],
  declarations: [DocumentoDetallesComponent, DocumentoModificarComponent, DocumentoDialogoAnularComponent, DocumentoDialogoEnviarComponent, DocumentoDialogoDuplicarComponent, DocumentoDialogoInfoenvioComponent, DocumentoDialogoEliminarComponent, DocumentoDialogoPeriodoremoverComponent, DocumentoDialogoPeriodocambiarComponent, DocumentoDialogoEnviarcopiaComponent, DocumentoDialogoNuevoClienteComponent, BoletasModificarComponent, BoletaPosComponent, BoletaTotalComponent],
  entryComponents: [DocumentoDialogoAnularComponent, DocumentoDialogoEnviarComponent, DocumentoDialogoDuplicarComponent, DocumentoDialogoInfoenvioComponent, DocumentoDialogoEliminarComponent, DocumentoDialogoPeriodoremoverComponent, DocumentoDialogoPeriodocambiarComponent, DocumentoDialogoEnviarcopiaComponent, DocumentoDialogoNuevoClienteComponent],
  exports: [DocumentoDetallesComponent, BoletaTotalComponent],
  providers: [DocumentosService, EnumeracionesService, PeriodosService, ClientesService, DateFormatPipe]
})
export class DocumentosModule { }
