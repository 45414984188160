<div class="row">
    <div class="col-xs-12">
      <h1 mat-dialog-title><i class="fa fa-times"></i> Eliminar Cliente</h1>
      <div mat-dialog-content>
        <p>¿Confirma que quiere eliminar el cliente {{ this.cliente.RazonSocial }} con RUT {{ this.cliente.RUT | number:'':'es-CL' }}-{{ this.cliente.DigitoVerificador }}?</p>
      </div>
      <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="{ result: 'ok', cliente: this.cliente }" class="btn btn-danger">Eliminar</button>
        <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
      </div>
    </div>
  </div>