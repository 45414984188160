import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from '../../servicios/servicio.inventario';
import { Notificacion } from '../../core/models/notificacion';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';

@Component({
  selector: 'app-producto-eliminar',
  templateUrl: './producto-eliminar.component.html',
  styleUrls: ['./producto-eliminar.component.css']
})
export class ProductoEliminarComponent implements OnInit {
  public producto: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private servicioInventario: InventarioService,
    private servicioNotificaciones: NotificacionesService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      var idProducto = params['id'];
      this.servicioInventario.getProducto(idProducto).subscribe(response => {
        this.producto = response.datos;
      });
    });
  }

  eliminar(): void {
    this.servicioInventario.eliminar(this.producto).subscribe(res => {

      if(res.esCorrecto) {
        var titulo = "Producto eliminado con éxito";
        var mensaje = "<p>El producto " + this.producto.nombre + " ha sido eliminado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['/erp/inventario/inventario-detalle/']);
      }
      else {
        var titulo = "Hubieron problemas eliminando el producto";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }

    });
  }

}
