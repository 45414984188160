import { Injectable, Injector } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Sesion } from './../../modelos/sesion';
import { Empresa } from './../../modelos/empresa';
import { Usuario } from './../../modelos/usuario';
import { Comuna } from './../../modelos/comuna';
import { Provincia } from './../../modelos/provincia';
import { Region } from './../../modelos/region';
import { Impuesto } from './../../modelos/impuesto';
import { Unidad } from './../../modelos/unidad';

@Injectable()
export class SesionesService {
    urlApi: string;
    private get router() { return this.injector.get(Router); }

    constructor(
        private http: HttpClient,
        private injector: Injector
    ) {
        this.urlApi = environment.apiURL;
    }

    private headers(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        };

        return new HttpHeaders(headerDict);
    }

    private headersAutentificados(): any {
        const sessionCondor = this.obtenerSesion();

        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': sessionCondor != null ? sessionCondor.token : '00000000-0000-0000-0000-000000000000'
        };

        return new HttpHeaders(headerDict);
    }

    public login(email: string, password: string): Observable<any> {
        const options = {
            headers: this.headers()
        };
        localStorage.clear();
        const usuario = { Email: email, Contrasena: password };
        return this.http.post(`${this.urlApi}SesionApi/Ingresar`, usuario, options);
    }

    public establecerSesion(sesion): void {
        localStorage.setItem('sessionCondor', JSON.stringify(sesion));
    }

    public cerrarSesion(): Observable<any> {
        const options = {
            headers: this.headersAutentificados()
        };
        const datos = {};
        localStorage.clear();
        return this.http.post(`${this.urlApi}SesionApi/CerrarSesion`, datos, options);
    }

    public estaActiva(): Observable<any> {
        const options = {
            headers: this.headersAutentificados()
        };
        return this.http.get(`${this.urlApi}SesionApi/EstaActiva`, options);
    }

    public refrescarSesion(): Observable<any> {
        const options = {
            headers: this.headersAutentificados()
        };
        return this.http.get(`${this.urlApi}SesionApi/RefrescarSesion`, options);
    }

    public sesionContieneRol(rol: string) {
        let session = this.obtenerSesion();
        if (session.roles.split(',').find(r => r == rol)) {
            return true;
        }
        return false;
    }

    public obtenerSesion(): Sesion {
        const sessionCondor = localStorage.getItem('sessionCondor');
        if (sessionCondor != null) {
            return JSON.parse(sessionCondor);
        } else {
            this.router.navigate(['']);
            return this.SesionVacia();
        }
        return null;
    }

    public obtenerSesionDesdeBackend(): any {
        const options = {
            headers: this.headersAutentificados()
        };
        const datos = {};
        return this.http.post(`${this.urlApi}SesionApi/ObtenerSesion`, datos, options);
    }

    private SesionVacia(): Sesion {
        const region: Region = {
            id: '',
            nombre: ''
        };

        const provincia: Provincia = {
            id: '',
            nombre: '',
            region: region
        };

        const comuna: Comuna = {
            id: '',
            nombre: '',
            provincia: provincia
        };

        const impuestos =  new Array<Impuesto>();

        const unidad: Unidad = {
            id: '',
            comunas: '',
            direccion: '',
            formatoImpresion: '',
            nombre: ''
        }

        const empresa: Empresa = {
            alias: '',
            codActividadEconomica: '',
            digitoVerificador: '',
            direccion: '',
            factorIvaUsoComun: -1,
            fechaResolucion: new Date(),
            giro: '',
            razonSocial: '',
            representante: '',
            resolucion: '',
            rut: -1,
            telefono: '',
            comuna: comuna,
            impuestos: impuestos,
            unidadSII: unidad
        };

        const empresas = new Array<Empresa>();

        const usuario: Usuario = {
            antiguaContrasena: '',
            apellido: '',
            confirmaContrasena: '',
            contrasena: '',
            digitoVerificador: '',
            email: '',
            nombre: '',
            recuerdame: false,
            rut: -1,
            empresasAutorizadas: empresas
        };

        const valor: Sesion = {
            token: '00000000-0000-0000-0000-000000000000',
            roles: '',
            expiracion: new Date(),
            empresaSeleccionada: empresa,
            usuarioAutenticado: usuario
        };

        return valor;
    }
}
