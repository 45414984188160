import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LibrosService } from '../../servicios/servicio.libro';
import { TipoLibro } from '../../core/enums/tipo-libro.enum';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { MatDialog } from '@angular/material/dialog';
import { CambiarperiodoDialogoComponent } from '../dialogos/cambiarperiodo-dialogo/cambiarperiodo-dialogo.component';
import { Notificacion } from '../../../app/core/models/notificacion';
import { IEnvioLibro } from '../../../app/core/models/libro/envio-libro.interface';
import { DialogoDescargaXml } from '../dialogos/dialogo-descarga-xml/dialogo-descarga-xml.component';
import { SesionesService } from '../../../app/servicios/servicio.sesiones';

@Component({
  selector: 'app-libro-detalles',
  templateUrl: './libro-detalles.component.html',
  styleUrls: ['./libro-detalles.component.css']
})
export class LibroDetallesComponent implements OnInit {
  libro: any;
  totaleslibro: any;
  documentosPorTipos: Array<any>;
  advertenciasLibro: Array<any>;
  public anio: number;
  public mes: number;

  public pagina: number;
  public largoPagina: number;

  public tipoLibro: TipoLibro;
  public tiposLibro = TipoLibro;
  public tipoDocumento = TipoDocumento;

  public estaCargando: boolean = false;

  constructor(
    public servicioSesiones: SesionesService,
    private servicioLibros: LibrosService,
    private activatedRoute: ActivatedRoute,
    private notificacionesService: NotificacionesService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.documentosPorTipos = [];
    this.advertenciasLibro = [];
  }

  ngOnInit() {
    this.pagina = 1;
    this.largoPagina = 10;
    this.activatedRoute.params.subscribe(params => {
      var fechaActual = new Date();
      if (params['anio']) {
        this.anio = +params['anio'];// (+) converts string  to a number
      }
      else {
        this.anio = fechaActual.getFullYear();
      }

      if (params['mes']) {
        this.mes = +params['mes'];// (+) converts string  to a number
      }
      else {
        this.mes = fechaActual.getMonth() + 1;
      }

      this.tipoLibro = +params['tipo'];
      this.recargar();
    });
  }

  recargar(): void {
    this.documentosPorTipos = [];
    this.recargarLibro();
    this.recargarTotales();
    this.recargarAdvertencias();
    if (this.tipoLibro != TipoLibro.Guias) {
      this.recargarTipoDocumento(TipoDocumento.FacturaElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.Boleta, 1, "");
      this.recargarTipoDocumento(TipoDocumento.FacturaVenta, 1, "");
      this.recargarTipoDocumento(TipoDocumento.FacturaVentaExenta, 1, "");
      this.recargarTipoDocumento(TipoDocumento.FacturaElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.FacturaExentaElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.Boleta, 1, "");
      this.recargarTipoDocumento(TipoDocumento.BoletaExenta, 1, "");
      this.recargarTipoDocumento(TipoDocumento.BoletaElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.LiquidacionFactura, 1, "");
      this.recargarTipoDocumento(TipoDocumento.BoletaExentaElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.LiquidacionFacturaElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.FacturaCompra, 1, "");
      this.recargarTipoDocumento(TipoDocumento.FacturaCompraElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.NotaDebito, 1, "");
      this.recargarTipoDocumento(TipoDocumento.NotaDebitoElectronica, 1, "");
      this.recargarTipoDocumento(TipoDocumento.NotaCredito, 1, "");
      this.recargarTipoDocumento(TipoDocumento.NotaCreditoElectronica, 1, "");
    }
    else {
      this.recargarTipoDocumento(TipoDocumento.GuiaDespacho, 1, "");
      this.recargarTipoDocumento(TipoDocumento.GuiaDespachoElectronica, 1, "");
    }
  }

  recargarAdvertencias() {
    this.servicioLibros.getLibroValidar(this.anio, this.mes, this.tipoLibro).subscribe(response => {
      if (response.datos.length > 0) {
        var titulo = "Hay algunas observaciones con el Libro de " + (this.tipoLibro === this.tiposLibro.Compras ? "Compras" : this.tipoLibro === this.tiposLibro.Ventas ? "Ventas" : this.tipoLibro === this.tiposLibro.Boletas ? "Boletas" : "Guias") + " del " + this.libro.periodo.mes + "/" + this.libro.periodo.anio;
        var mensaje = "<p><ul>";
        response.datos.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Advertencia");
        this.notificacionesService.agregarNotificacion(notificacion);
      }
    });
  }

  recargarLibro() {
    this.servicioLibros.getLibro(this.anio, this.mes, this.tipoLibro).subscribe(response => {
      this.libro = response.datos;
    });
  }

  recargarTotales() {
    this.totaleslibro = null;
    this.servicioLibros.getTotales(this.anio, this.mes, this.tipoLibro).subscribe(response => {
      this.totaleslibro = response.datos;
      console.log(this.totaleslibro);
    });
  }

  recargarTipoDocumento(tipoDocumento: TipoDocumento, pagina: number, filtro: string) {

    var documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo == tipoDocumento);
    if (documentoPorTipo.length > 0) {
      console.log("fire");
      documentoPorTipo[0].pagina = pagina,
        documentoPorTipo[0].filtro = filtro,
        documentoPorTipo[0].respuestaPendiente = true
    }
    else {
      this.documentosPorTipos.push(
        {
          tipo: tipoDocumento,
          pagina: pagina,
          filtro: filtro,
          respuestaPendiente: true
        }
      );
    }
    var documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo == tipoDocumento);

    this.servicioLibros.getDocumentos(this.anio, this.mes, this.tipoLibro, tipoDocumento, pagina, this.largoPagina, filtro)
      .subscribe(response => {
        console.log(response);
        documentoPorTipo[0].documentos = response.datos.datos;
        documentoPorTipo[0].totalDocumentos = response.datos.totalElementos;
        documentoPorTipo[0].respuestaPendiente = false;
        console.log(documentoPorTipo);
      }
      );
  }

  siguientePagina(tipoDocumento: TipoDocumento): void {

    var documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo == tipoDocumento);
    if (documentoPorTipo.length > 0) {
      var siguientePagina = documentoPorTipo[0].pagina + 1;
      if (((siguientePagina - 1) * this.largoPagina) < documentoPorTipo[0].totalDocumentos) {
        this.recargarTipoDocumento(tipoDocumento, siguientePagina, documentoPorTipo[0].filtro);
      }
    }
    else {
      this.recargarTipoDocumento(tipoDocumento, 1, "");
    }
  }

  anteriorPagina(tipoDocumento: TipoDocumento): void {

    var documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo == tipoDocumento);
    if (documentoPorTipo.length > 0) {
      var paginaAnterior = documentoPorTipo[0].pagina - 1;
      if (paginaAnterior > 0) {
        this.recargarTipoDocumento(tipoDocumento, paginaAnterior, documentoPorTipo[0].filtro);
      }
    }
    else {
      this.recargarTipoDocumento(tipoDocumento, 1, "");
    }
  }

  public mesSiguiente(): void {
    if (this.mes === 12) {
      this.mes = 1;
      this.anio++;
    }
    else {
      this.mes++;
    }
    this.router.navigate(['erp/libros/libro-detalle', this.anio, this.mes, this.tipoLibro]);
  }

  public mesAnterior(): void {
    if (this.mes <= 1) {
      this.mes = 12;
      this.anio--;
    }
    else {
      this.mes--;
    }
    this.router.navigate(['erp/libros/libro-detalle', this.anio, this.mes, this.tipoLibro]);
  }

  public abrirModalCambiarMes(): void {
    const dialogRef = this.dialog.open(CambiarperiodoDialogoComponent, {
      width: '400px',
      data: { anio: this.anio, mes: this.mes }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result == 'ok') {
        this.cambiarMesDesdeModal(result.mes, result.anio);
        console.log(result);
      }
    });
  }

  public cambiarMesDesdeModal(nuevoMes: number, nuevoAnio: number): void {
    this.router.navigate(['erp/libros/libro-detalle', nuevoAnio, nuevoMes, this.tipoLibro]);
  }

  filtroPorDocumento(tipoDocumento: TipoDocumento, event: any): void {

    var documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo == tipoDocumento);
    if (documentoPorTipo.length > 0) {
      documentoPorTipo[0].pagina = 1;
      documentoPorTipo[0].filtro = event.target.value;
      this.recargarTipoDocumento(tipoDocumento, documentoPorTipo[0].pagina, documentoPorTipo[0].filtro);
    }
    else {
      this.recargarTipoDocumento(tipoDocumento, 1, event.target.value);
    }
  }

  public stringify(param) {
    return JSON.stringify(param);
  }

  enviar() {
    let envioLibro: IEnvioLibro = {
      ID: -1,
      Periodo: {
        Anio: this.anio,
        Mes: this.mes
      },
      TipoLibro: this.tipoLibro,
      TrackID: 'Track ID de Pruebitas',
      CodigoAutorizacionReemplazo: null,
      ContrasenaCertificado: null
    }

    this.servicioLibros.enviar(envioLibro).subscribe(response => {
      console.log(response);
    });
  }

  descargarXmlLibroBoletas() {
    let envioLibro: IEnvioLibro = {
      ID: -1,
      Periodo: {
        Anio: this.anio,
        Mes: this.mes
      },
      TipoLibro: this.tipoLibro,
      TrackID: 'Track ID de Pruebitas',
      CodigoAutorizacionReemplazo: null,
      ContrasenaCertificado: null
    }
    this.estaCargando = true;
    this.servicioLibros.descargarXmlLibroBoletas(envioLibro).subscribe((response: any) => {
      this.estaCargando = false;
      let data: any;
      if (response.esCorrecto) {
        data = {
          titulo: 'Esta es la representacion xml del EnvioLibro',
          datos: response.datos
        }
      }
      else{
        data = {
          titulo: 'Hubieron errores generando el EnvioLibro',
          datos: response.mensajes.join('. ')
        }
      }
      const dialogRef = this.dialog.open(DialogoDescargaXml, {
        width: '70%',
        data: data
      });

    });
  }
}
