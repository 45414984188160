import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { Router } from '@angular/router';
import { DocumentosService } from '../../servicios/servicio.documentos';
import { EnumeracionesService } from '../../servicios/servicio.enumeraciones';
import { PeriodosService } from '../../servicios/servicio.periodos';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';

@Component({
  selector: 'app-boleta-total',
  templateUrl: './boleta-total.component.html',
  styleUrls: ['./boleta-total.component.css']
})
export class BoletaTotalComponent implements OnInit {

  public accion: string;
  public idDocumento: string;
  public documento: any;
  public empresaSeleccionada: any;
  public tipoDocumento = TipoDocumento;
  public todosLosImpuestosEmpresa: any[];
  public todosLosImpuestos: any[];
  public indiceItemModificando: number = 0;
  public impuestosPorItem: any[];
  public comunas: any[];
  public tiposDocumentos: any[];
  public formasDePago: any[];
  public mediosDePago: any[];

  public estaEmitiendoBoleta: boolean;
  public estaEnviando: boolean
  public itemTemporal: any;
  public mostrandoConfirmacion: boolean;

  @ViewChild("boletaExpressMonto", {static: false}) inputMonto:ElementRef;
  @ViewChild('botonEmitir', {static: false}) botonEmitir: ElementRef;

  constructor(private servicioSesiones: SesionesService,
    private router: Router,
    private servicioDocumentos: DocumentosService,
    private servicioEnumeraciones: EnumeracionesService,
    private servicioPeriodos: PeriodosService,
    private servicioNotificaciones: NotificacionesService,) { }


  ngOnInit() {
    this.empresaSeleccionada = this.servicioSesiones.obtenerSesion().empresaSeleccionada;
    this.comunas = this.servicioEnumeraciones.getComunas();
    this.todosLosImpuestosEmpresa = this.empresaSeleccionada.impuestos;
    this.todosLosImpuestos = this.servicioEnumeraciones.getImpuestos();
    this.formasDePago = this.servicioEnumeraciones.formasDePago();
    this.mediosDePago = this.servicioEnumeraciones.mediosDePago();
    this.reiniciar();
  }

  reiniciar() : void {
    let tipoDocumento = this.tipoDocumento.BoletaElectronica;
    var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
    var impuestosDocumento = [];
    for (let i = 0; i < impuestosEmpresa.length; i++) {
      impuestosDocumento.push({ impuesto: impuestosEmpresa[i], opcionSeleccionada: 0, seleccionado: true, total: 0 });
    }

    this.impuestosPorItem = [];
    if (this.accion == "emision") {
      var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
      for (let i = 0; i < impuestosEmpresa.length; i++) {
        this.impuestosPorItem.push({ impuesto: impuestosEmpresa[i], seleccionado: true, total: 0 });
      }
    }
    else {
      var impuestosEmpresa = this.todosLosImpuestosEmpresa;
      for (let i = 0; i < impuestosEmpresa.length; i++) {
        this.impuestosPorItem.push(
          {
            impuesto: impuestosEmpresa[i],
            seleccionado: impuestosEmpresa.filter(m => m.id == impuestosEmpresa[i].id && m.afectaLibroVentas).length > 0,
            total: 0
          });
      }
    }

    this.itemTemporal = {
      cantidad: 1,
      unidadMedida: {
        id: 0,
        nombre: "Unidad",
        codigo: "UN"
      },
      glosa: "Varios",
      precioUnitario: 0,
      descuentoPorcentaje: 0,
      impuestos: JSON.parse(JSON.stringify(this.impuestosPorItem)), // Truco para crear una copia en lugar de pasar por referencia
      descuento: 0,
      precioBruto: 0,
      total: 0,
      producto: {
        id: -1
      }
    };

    this.documento = {
      numero: 0,
      fechaEmision: new Date(),
      fechaVencimiento: new Date(),
      tipoDocumento: tipoDocumento,
      formaDePago: 0,
      medioDePago: 0,
      emisor: this.empresaSeleccionada,
      destinatario: { rut: 66666666, digitoVerificador: '6', comuna: this.empresaSeleccionada.comuna },
      items: [],
      referencias: [],
      impuestos: impuestosDocumento,
      descuentoPorcentaje: 0,
      total: 0
    };

    this.estaEmitiendoBoleta = false;
    this.cambioPeriodoDocumento(this.documento);
  }

  iniciarBoletaRapida() : void {
    this.estaEmitiendoBoleta = true;
    setTimeout(() => this.inputMonto.nativeElement.focus(), 0);
  }

  emitir(): void {
    this.estaEnviando = true;
    if (this.documento.tipoDocumento == 39) {
      this.recalcular();
      this.servicioDocumentos.emisionBoletaElectronica(this.documento).subscribe(res => {
        if (res.esCorrecto) {
          this.documento = res.datos;
          this.servicioDocumentos.emitirBoleta(this.documento).subscribe(res2 => {
            if (res2.esCorrecto) {
              this.router.navigate(['/erp/documentos/documento-detalle/', res.datos.id]);
              this.cancelar();
            }
            else {
              var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res2.mensajes[0] + "</p>", "Error");
              this.servicioNotificaciones.agregarNotificacion(notificacion);
              this.cancelar();
            }
          });
        }
        else {
          var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
          this.servicioNotificaciones.agregarNotificacion(notificacion);
          this.cancelar();
        }
      });
    } else {
      console.log("no soportado en este componente");
      this.cancelar();
    }
  }

  cambioPeriodoDocumento(documento: any): void {
    var anio = new Date(documento.fechaEmision).getFullYear() * 1;
    var mes = (new Date(documento.fechaEmision).getMonth() + 1) * 1;
    this.servicioPeriodos.getDetalle(anio, mes).subscribe(res => {
      documento.periodo = res.datos;
      console.log("periodo", this.documento.periodo);
    });
  }

  private mostrarConfirmacion() : void {
    if(this.documento.items.length > 0) {
      this.mostrandoConfirmacion = true;
      setTimeout(() => this.botonEmitir.nativeElement.focus(), 0);
    }
    else {
      var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>Agregue algun item primero</p>", "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
    }
  }

  private eventoTeclado(evento: any) : void {
    this.itemTemporal.total = parseInt(this.itemTemporal.total, 10);
    if (evento.key == '+' && this.itemTemporal.precioBruto != 0)
    {
      evento.preventDefault();
      this.agregarItem();
    }
    else if (evento.key == 'Enter') {
      this.mostrarConfirmacion();
    }
    else if (evento.key == 'Escape') {
      this.itemTemporal.total = 0;
      this.itemTemporal.precioBruto = 0;
    }
  }

  private agregarItem() : void {
    this.documento.items.push(this.itemTemporal);
    this.itemTemporal = {
      cantidad: 1,
      unidadMedida: {
        id: 0,
        nombre: "Unidad",
        codigo: "UN"
      },
      glosa: "Varios",
      precioUnitario: 0,
      descuentoPorcentaje: 0,
      impuestos: this.impuestosPorItem,
      descuento: 0,
      precioBruto: 0,
      total: 0,
      producto: {
        id: -1
      }
    };
    this.indiceItemModificando++;
    this.recalcular();
    this.inputMonto.nativeElement.focus();
  }

  private eliminarItem(item: any) : void {
    const index = this.documento.items.indexOf(item, 0);
    if (index > -1) {
      this.documento.items.splice(index, 1);
    }
  }

  private cancelar() : void {
    this.mostrandoConfirmacion = false;
    this.estaEmitiendoBoleta = false;
    this.estaEnviando = false;

    //reiniciar valores documento
    this.reiniciar();
    this.documento.total = 0;
  }

  private regresar() : void {
    this.mostrandoConfirmacion = false;
    this.estaEmitiendoBoleta = true;
    this.estaEnviando = false;
  }

  montoBrutoUnitarioItem(item): number {
    let sum = item.precioUnitario;
    item.impuestos.forEach(i => {
      if (i.seleccionado) {
        i.total = i.impuesto.tipoTasa == 0 ? Math.round(item.precioUnitario * i.impuesto.tasa / 100) : i.impuesto.tasa;
        sum += i.total;
      }
    });
    return sum;
  }

  montoNetoUnitarioItem(item): number {
    let impuestos = 0;
    item.impuestos.forEach(i => {
      if (i.seleccionado) {
        // Me huele a que esto falla si hay más de un impuesto aplicado
        item.precioBruto = isNaN(item.precioBruto) ? 0 : item.precioBruto;
        let neto = i.impuesto.tipoTasa == 0 ? Math.floor(item.precioBruto / ((100 + i.impuesto.tasa) / 100)) : item.precioBruto - i.impuesto.tasa;
        let impuestoDelNeto = i.impuesto.tipoTasa == 0 ? Math.ceil(neto * i.impuesto.tasa / 100) : neto + i.impuesto.tasa;
        if (neto + impuestoDelNeto < item.precioBruto) {
          impuestoDelNeto++;
        }
        i.total = impuestoDelNeto;
        impuestos += i.total;
      }
    });
    return item.precioBruto - impuestos;
  }

  public recalcular(): void {
    //calcula totales del documento
    this.documento.subtotal = 0;
    this.documento.neto = 0;
    this.documento.exento = 0;
    this.documento.total = 0;
    let totalImpuestos = 0;

    if (this.documento.tipoDocumento == this.tipoDocumento.BoletaElectronica) {
      //calcula el neto unitario por item
      this.documento.items.forEach(item => {
        if (item.precioUnitario != 0 && (item.precioBruto == undefined || item.precioBruto == 0)) {
          item.precioBruto = this.montoBrutoUnitarioItem(item);
        }
        else {
          item.precioUnitario = this.montoNetoUnitarioItem(item);
        }

        let totalSinDescuento = item.cantidad * item.precioBruto;
        item.total = Math.round(totalSinDescuento - (totalSinDescuento * item.descuentoPorcentaje / 100));
      });

      ////////////////////////////////////////
      // recorrer impuestos, calcular la suma y despues deglosar entre neto bruto e impuestos considerando descuentos
      for (let i = 0; i < this.documento.impuestos.length; i++) {
        let totalBrutoImpuesto = 0;
        for (let j = 0; j < this.documento.items.length; j++) {
          for (let k = 0; k < this.documento.items[j].impuestos.length; k++) {
            if (this.documento.items[j].impuestos[k].impuesto.id == this.documento.impuestos[i].impuesto.id) {
              if (this.documento.items[j].impuestos[k].seleccionado && this.documento.impuestos[i].impuesto.tasa > 0) {
                let totalBrutoItem = (this.documento.items[j].impuestos[k].total + this.documento.items[j].precioUnitario) * this.documento.items[j].cantidad;
                totalBrutoImpuesto += totalBrutoItem;
              }
            }
          }
        }

        let neto = Math.floor(totalBrutoImpuesto / ((100 + this.documento.impuestos[i].impuesto.tasa) / 100))
        let impuestoDelNeto = Math.ceil(neto * this.documento.impuestos[i].impuesto.tasa / 100);

        if (neto + impuestoDelNeto < totalBrutoImpuesto) {
          impuestoDelNeto++;
        }

        this.documento.subtotal += neto;

        if (this.documento.descuentoPorcentaje > 0) {
          this.documento.impuestos[i].total = Math.round(impuestoDelNeto - (impuestoDelNeto * this.documento.descuentoPorcentaje / 100));
        }
        else {
          this.documento.impuestos[i].total = Math.round(impuestoDelNeto);
        }

        totalImpuestos += this.documento.impuestos[i].total;
      }
      ////////////////////////////////////////
    }
    else {
      //calcula totales de los items
      this.documento.items.forEach(item => {
        let totalSinDescuento = item.cantidad * item.precioUnitario;
        item.total = Math.round(totalSinDescuento - (totalSinDescuento * item.descuentoPorcentaje / 100));
      });

      //calcula el subtotal del documento
      this.documento.items.forEach(item => {
        if (item.impuestos.some(i => i.seleccionado)) {
          this.documento.subtotal += item.total;
        }
      });

      //calcula impuestos
      for (let i = 0; i < this.documento.impuestos.length; i++) {
        var netoImpuesto = 0;
        for (let j = 0; j < this.documento.items.length; j++) {
          for (let k = 0; k < this.documento.items[j].impuestos.length; k++) {
            if (this.documento.items[j].impuestos[k].impuesto.id == this.documento.impuestos[i].impuesto.id) {
              if (this.documento.items[j].impuestos[k].seleccionado && this.documento.impuestos[i].impuesto.tasa > 0) {
                netoImpuesto += 1.0 * this.documento.items[j].total * this.documento.impuestos[i].impuesto.tasa / 100;
              }
            }
          }
        }

        if (this.documento.descuentoPorcentaje > 0) {
          this.documento.impuestos[i].total = Math.round(netoImpuesto - (netoImpuesto * this.documento.descuentoPorcentaje / 100));
        }
        else {
          this.documento.impuestos[i].total = Math.round(netoImpuesto);
        }
        totalImpuestos += this.documento.impuestos[i].total;
      }
    }

    //calcula el descuento y el neto
    this.documento.neto = Math.round(this.documento.subtotal - Math.round(this.documento.subtotal * this.documento.descuentoPorcentaje / 100));
    this.documento.descuentoMonto = this.documento.subtotal - this.documento.neto;

    //calcula el exento del documento
    this.documento.items.forEach(item => {
      if (!item.impuestos.some(i => i.seleccionado)) {
        this.documento.exento += item.total;
      }
    });

    this.documento.total = this.documento.neto + this.documento.exento + totalImpuestos;
  }

  public quitarCerosALaIzquierda(valor: string): void{
    this.inputMonto.nativeElement.value = Number(valor).toString();
    this.itemTemporal.precioBruto = Number(valor);
  }
}
