import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanciaDetalleComponent } from './instancia-detalle/instancia-detalle.component';
import { InstanciaModificarComponent } from './instancia-modificar/instancia-modificar.component';
import { FolioDetalleComponent } from './folio-detalle/folio-detalle.component';
import { FolioListaComponent } from './folio-lista/folio-lista.component';
import { PeriodoDetalleComponent } from './periodo-detalle/periodo-detalle.component';
import { PeriodoListaComponent } from './periodo-lista/periodo-lista.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FoliosService } from '../servicios/servicio.folios';
import { PeriodosService } from '../servicios/servicio.periodos';
import { InstanciaPropiedadesglobalesComponent } from './instancia-propiedadesglobales/instancia-propiedadesglobales.component';
import { InstanciaAyudaComponent } from './instancia-ayuda/instancia-ayuda.component';
import { InstanciaService } from '../servicios/servicio.instancia';
import { FolioCargarComponent } from './folio-lista/dialogos/folio-cargar/folio-cargar.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    FormsModule
  ],
  entryComponents: [FolioCargarComponent],
  providers: [
    FoliosService,
    PeriodosService,
    InstanciaService
  ],
  declarations: [
    InstanciaDetalleComponent, 
    InstanciaModificarComponent,
    FolioDetalleComponent, 
    FolioListaComponent,
    PeriodoDetalleComponent, 
    PeriodoListaComponent, 
    InstanciaPropiedadesglobalesComponent,
    InstanciaAyudaComponent, 
    FolioCargarComponent]
})
export class InstanciaModule { }
