import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { SesionPorExpirarDialogoComponent } from './dialogos/sesionporexpirar-dialogo/sesionporexpirar-dialogo.component';

@Component({
  selector: 'app-verificar-sesion',
  templateUrl: './verificar-sesion.component.html',
  styleUrls: ['./verificar-sesion.component.css']
})
export class VerificarSesionComponent implements OnInit, OnDestroy {

  suscripcionVerificacionSesion: Subscription;

  constructor(
    private servicioSesiones: SesionesService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.inicializarVerificacionSesion();
  }

  ngOnDestroy() {
    if (this.suscripcionVerificacionSesion) {
      this.suscripcionVerificacionSesion.unsubscribe();
    }
  }

  inicializarVerificacionSesion(){
    const sesion = localStorage.getItem('sessionCondor');
    if (sesion) {
      this.verificarSesion();
      const source = interval(60000);
      this.suscripcionVerificacionSesion = source.subscribe(val => this.verificarSesion());
    }
  }

  verificarSesion() {
    this.servicioSesiones.obtenerSesionDesdeBackend().subscribe(res => {
      const expiracion = new Date(res.datos.expiracion);
      const fechaConsulta = new Date(res.datos.fechaConsulta);
      const minutosRestantes = (expiracion.getTime() - fechaConsulta.getTime()) / 60000;
      if (minutosRestantes > 0 && minutosRestantes <= 2) {
        this.suscripcionVerificacionSesion.unsubscribe();
        this.mostrarModalCountdown();
      } else if (minutosRestantes < 0) {
        console.log('Sesion Expirada');
        localStorage.clear();
        this.router.navigate(['']);
      }
      console.log('Sesion verificada, minutos restantes', minutosRestantes);
    });
  }

  mostrarModalCountdown(): void {
    const dialogRef = this.dialog.open(SesionPorExpirarDialogoComponent, {
      width: '400px',
      data: {  },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        console.log('Reiniciando verificación de sesión');
        this.inicializarVerificacionSesion();
      }
    });
  }
}
