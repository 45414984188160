import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentosService } from '../../servicios/servicio.documentos';
import { EnumeracionesService } from '../../servicios/servicio.enumeraciones';
import { PeriodosService } from '../../servicios/servicio.periodos';
import { ClientesService } from '../../servicios/servicio.clientes';
import { DateFormatPipe } from '../../shared/date-format-pipe/date-format-pipe';
import { InventarioService } from '../../servicios/servicio.inventario';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Component({
  selector: 'app-boletapos-modificar',
  templateUrl: './boleta-pos.component.html',
  styleUrls: ['./boleta-pos.component.css']
})
export class BoletaPosComponent implements OnInit, AfterViewInit {
  @ViewChild('itemDocumentoTemporalglosa', { static: false }) itemDocumentoTemporalglosa: ElementRef;

  public accion: string;
  public idDocumento: string;
  public documento: any;
  public empresaSeleccionada: any;

  public ingresarDestinatarioActivado: boolean;
  public destinatarioBuscado: any;
  public destinatariosSugeridos: Array<any>;
  public panelSugerirDestinatarioVisible: any;

  public productosSugeridos: Array<any>;

  public tipoDocumento = TipoDocumento;
  public todosLosImpuestosEmpresa: any[];
  public todosLosImpuestos: any[];
  public comunas: any[];
  public periodosAbiertos: any[];
  public tiposDocumentos: any[];
  public formasDePago: any[];
  public mediosDePago: any[];
  public itemDocumentoTemporal: any;

  public autoCompletarItemActivo: boolean = false;

  public enviando: boolean = false;

  private sugerirProductosDelay = 50;
  private subjectSugerirProducto = new Subject();

  public mostrandoConfirmacion: boolean;

  constructor(
    private servicioSesiones: SesionesService,
    private route: ActivatedRoute,
    private router: Router,
    private servicioDocumentos: DocumentosService,
    private servicioEnumeraciones: EnumeracionesService,
    private servicioPeriodos: PeriodosService,
    private servicioClientes: ClientesService,
    private servicioProductos: InventarioService,
    private servicioNotificaciones: NotificacionesService,
    public dialog: MatDialog
  ) { }

  esElectronico(tipo: TipoDocumento): boolean {
    if (
      tipo == this.tipoDocumento.FacturaElectronica ||
      tipo == this.tipoDocumento.FacturaExentaElectronica ||
      tipo == this.tipoDocumento.BoletaElectronica ||
      tipo == this.tipoDocumento.BoletaExentaElectronica ||
      tipo == this.tipoDocumento.LiquidacionFacturaElectronica ||
      tipo == this.tipoDocumento.FacturaCompraElectronica ||
      tipo == this.tipoDocumento.GuiaDespachoElectronica ||
      tipo == this.tipoDocumento.NotaDebitoElectronica ||
      tipo == this.tipoDocumento.NotaCreditoElectronica
    )
      return true;
    else
      return false;

  }

  ngOnInit() {
    this.empresaSeleccionada = this.servicioSesiones.obtenerSesion().empresaSeleccionada;

    this.comunas = this.servicioEnumeraciones.getComunas();
    this.tiposDocumentos = this.servicioEnumeraciones.getTiposDocumentos();
    this.todosLosImpuestosEmpresa = this.empresaSeleccionada.impuestos;
    this.todosLosImpuestos = this.servicioEnumeraciones.getImpuestos();
    this.formasDePago = this.servicioEnumeraciones.formasDePago();
    this.mediosDePago = this.servicioEnumeraciones.mediosDePago();
    this.ingresarDestinatarioActivado = false;
    this.route.params.subscribe(params => {
      this.accion = params['accion']; // (+) converts string 'id' to a number
      let tipoDocumento = params['id'] * 1.0;
      if (this.accion == "emision") {
        
        this.panelSugerirDestinatarioVisible = true;
        var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
        var impuestosDocumento = [];
        for (let i = 0; i < impuestosEmpresa.length; i++) {
          impuestosDocumento.push({ impuesto: impuestosEmpresa[i], opcionSeleccionada: 0, seleccionado: true, total: 0 });
        }

        this.documento = {
          numero: 0,
          fechaEmision: new Date(),
          fechaVencimiento: new Date(),
          tipoDocumento: tipoDocumento,
          formaDePago: 0,
          medioDePago: 0,
          emisor: this.empresaSeleccionada,
          destinatario: { comuna: this.empresaSeleccionada.comuna },
          items: [],
          referencias: [],
          impuestos: impuestosDocumento,
          descuentoPorcentaje: 0
        };

        var impuestosItem = [];
        var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
        for (let i = 0; i < impuestosEmpresa.length; i++) {
          impuestosItem.push({ impuesto: impuestosEmpresa[i], seleccionado: true, total: 0 });
        }

        this.itemDocumentoTemporal = {
          cantidad: 1,
          unidadMedida: {
            id: 0,
            nombre: "Unidad",
            codigo: "UN"
          },
          glosa: "",
          precioUnitario: 0,
          descuentoPorcentaje: 0,
          impuestos: impuestosItem,
          descuento: 0,
          total: 0,
          producto: {
            id: -1
          }
        };

        this.cambioPeriodoDocumento(this.documento);
      }
    });

    this.subjectSugerirProducto.pipe(
      debounceTime(this.sugerirProductosDelay)).
      subscribe((productoBuscado: string) => {
        this.servicioProductos.getProductos(1, 3, productoBuscado).subscribe(response => {
          this.productosSugeridos = response.datos.datos;
          if (this.productosSugeridos && this.productosSugeridos.length > 0 && this.productosSugeridos[0].codigoInterno == productoBuscado){
            this.seleccionarProducto(this.itemDocumentoTemporal, this.productosSugeridos[0]);
            this.agregarNuevoItem();
          }
        });
      });
  }

  ngAfterViewInit() {
    this.itemDocumentoTemporalglosa.nativeElement.focus();
    this.itemDocumentoTemporalglosa.nativeElement.select();
  }

  cambioPeriodoDocumento(documento: any): void {
    var anio = new Date(documento.fechaEmision).getFullYear() * 1;
    var mes = (new Date(documento.fechaEmision).getMonth() + 1) * 1;
    this.servicioPeriodos.getDetalle(anio, mes).subscribe(res => { 
      documento.periodo = res.datos;
      console.log("periodo", this.documento.periodo);
    });
  }

  agregarNuevoItem(): void {
    if (this.itemDocumentoTemporal.cantidad > 0 && this.itemDocumentoTemporal.precioBruto > 0 &&
      this.itemDocumentoTemporal.glosa && this.itemDocumentoTemporal.glosa != '') {
      this.documento.items.push(this.itemDocumentoTemporal);
      var impuestosItem = [];
      if (this.accion == "emision") {
        var impuestosEmpresa = this.todosLosImpuestosEmpresa.filter(m => m.afectaLibroVentas);
        for (let i = 0; i < impuestosEmpresa.length; i++) {
          impuestosItem.push({ impuesto: impuestosEmpresa[i], seleccionado: true, total: 0 });
        }
      }
      else {
        var impuestosEmpresa = this.todosLosImpuestosEmpresa;
        console.log(impuestosEmpresa);
        for (let i = 0; i < impuestosEmpresa.length; i++) {
          impuestosItem.push({ impuesto: impuestosEmpresa[i], seleccionado: impuestosEmpresa.filter(m => m.id == impuestosEmpresa[i].id && m.afectaLibroVentas).length > 0, total: 0 });
        }
      }
      this.itemDocumentoTemporal = {
        cantidad: 1,
        unidadMedida: {
          id: 0,
          nombre: "Unidad",
          codigo: "UN"
        },
        glosa: "",
        precioUnitario: 0,
        descuentoPorcentaje: 0,
        impuestos: impuestosItem,
        descuento: 0,
        total: 0,
        producto: {
          id: -1
        }
      };
      this.recalcular();
      this.itemDocumentoTemporalglosa.nativeElement.focus();
      this.itemDocumentoTemporalglosa.nativeElement.select();
    }
    else {
      var mensaje = "";
      if (this.itemDocumentoTemporal.cantidad <= 0)
        mensaje = "El item debe al menos contener una unidad.";

      if (!this.itemDocumentoTemporal.precioBruto || this.itemDocumentoTemporal.precioBruto <= 0)
        mensaje = "El item debe tener un precio positivo.";

      if (!this.itemDocumentoTemporal.glosa && this.itemDocumentoTemporal.glosa == '')
        mensaje = "El item debe tener una descripción";


      var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + mensaje + "</p>", "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
    }

  }

  eliminarItem(index: number): void {
    this.documento.items.splice(index, 1);
    this.recalcular();
  }

  cambioRutDestinatario() {
    this.servicioClientes.getClientes(this.documento.destinatario.rut, 1, 10).subscribe(res => {
      if (res.datos.length > 0) {
        var finded = res.datos.find(c => c.rut == this.documento.destinatario.rut);
        if (finded) {
          this.documento.destinatario = finded;
        }
        else {
          this.documento.destinatario = { rut: 0, comuna: this.empresaSeleccionada.comuna };
        }
      }
    });
    this.documento.destinatario.rut = Number(this.documento.destinatario.rut);
  }

  guardar(): any {
    this.revisarYCorregirRutGenerico();
    if (this.accion == "emision") {
      this.emision();
    }
  }

  emision(): void {
    this.enviando = true;
    if (this.documento.tipoDocumento == 39) {
      this.servicioDocumentos.emisionBoletaElectronica(this.documento).subscribe(res => { this.manejarRespuestaEmision(res); this.enviando = false; });
    } else {
      this.servicioDocumentos.emision(this.documento).subscribe(res => { this.manejarRespuestaEmision(res); this.enviando = false; });
    }
  }

  private manejarRespuestaEmision(res) {
    if (res.esCorrecto) {
      this.router.navigate(['/erp/documentos/documento-detalle/', res.datos.id]);
    }
    else {
      var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
    }

  }

  public recalcular(): void {
    //calcula totales del documento
    this.documento.subtotal = 0;
    this.documento.neto = 0;
    this.documento.exento = 0;
    this.documento.total = 0;
    let totalImpuestos = 0;

    if (this.documento.tipoDocumento == this.tipoDocumento.BoletaElectronica) {
      //calcula el neto unitario por item
      this.documento.items.forEach(item => {
        if (item.precioUnitario != 0 && (item.precioBruto == undefined || item.precioBruto == 0)) {
          item.precioBruto = this.montoBrutoUnitarioItem(item);
        }
        else {
          item.precioUnitario = this.montoNetoUnitarioItem(item);
        }

        let totalSinDescuento = item.cantidad * item.precioBruto;
        item.total = Math.round(totalSinDescuento - (totalSinDescuento * item.descuentoPorcentaje / 100));
      });

      ////////////////////////////////////////
      // recorrer impuestos, calcular la suma y despues deglosar entre neto bruto e impuestos considerando descuentos
      for (let i = 0; i < this.documento.impuestos.length; i++) {
        let totalBrutoImpuesto = 0;
        for (let j = 0; j < this.documento.items.length; j++) {
          for (let k = 0; k < this.documento.items[j].impuestos.length; k++) {
            if (this.documento.items[j].impuestos[k].impuesto.id == this.documento.impuestos[i].impuesto.id) {
              if (this.documento.items[j].impuestos[k].seleccionado && this.documento.impuestos[i].impuesto.tasa > 0) {
                let totalBrutoItem = (this.documento.items[j].impuestos[k].total + this.documento.items[j].precioUnitario) * this.documento.items[j].cantidad;
                totalBrutoImpuesto += totalBrutoItem;
              }
            }
          }
        }

        let neto = Math.floor(totalBrutoImpuesto / ((100 + this.documento.impuestos[i].impuesto.tasa) / 100))
        let impuestoDelNeto = Math.ceil(neto * this.documento.impuestos[i].impuesto.tasa / 100);

        if (neto + impuestoDelNeto < totalBrutoImpuesto) {
          impuestoDelNeto++;
        }

        this.documento.subtotal += neto;

        if (this.documento.descuentoPorcentaje > 0) {
          this.documento.impuestos[i].total = Math.round(impuestoDelNeto - (impuestoDelNeto * this.documento.descuentoPorcentaje / 100));
        }
        else {
          this.documento.impuestos[i].total = Math.round(impuestoDelNeto);
        }

        totalImpuestos += this.documento.impuestos[i].total;
      }
      ////////////////////////////////////////
    }
    else {
      //calcula totales de los items
      this.documento.items.forEach(item => {
        let totalSinDescuento = item.cantidad * item.precioUnitario;
        item.total = Math.round(totalSinDescuento - (totalSinDescuento * item.descuentoPorcentaje / 100));
      });

      //calcula el subtotal del documento
      this.documento.items.forEach(item => {
        if (item.impuestos.some(i => i.seleccionado)) {
          this.documento.subtotal += item.total;
        }
      });

      //calcula impuestos
      for (let i = 0; i < this.documento.impuestos.length; i++) {
        var netoImpuesto = 0;
        for (let j = 0; j < this.documento.items.length; j++) {
          for (let k = 0; k < this.documento.items[j].impuestos.length; k++) {
            if (this.documento.items[j].impuestos[k].impuesto.id == this.documento.impuestos[i].impuesto.id) {
              if (this.documento.items[j].impuestos[k].seleccionado && this.documento.impuestos[i].impuesto.tasa > 0) {
                netoImpuesto += 1.0 * this.documento.items[j].total * this.documento.impuestos[i].impuesto.tasa / 100;
              }
            }
          }
        }

        if (this.documento.descuentoPorcentaje > 0) {
          this.documento.impuestos[i].total = Math.round(netoImpuesto - (netoImpuesto * this.documento.descuentoPorcentaje / 100));
        }
        else {
          this.documento.impuestos[i].total = Math.round(netoImpuesto);
        }
        totalImpuestos += this.documento.impuestos[i].total;
      }
    }

    //calcula el descuento y el neto
    this.documento.neto = Math.round(this.documento.subtotal - Math.round(this.documento.subtotal * this.documento.descuentoPorcentaje / 100));
    this.documento.descuentoMonto = this.documento.subtotal - this.documento.neto;

    //calcula el exento del documento
    this.documento.items.forEach(item => {
      if (!item.impuestos.some(i => i.seleccionado)) {
        this.documento.exento += item.total;
      }
    });

    this.documento.total = this.documento.neto + this.documento.exento + totalImpuestos;
  }

  sugerirDestinatario(): void {
    if (this.destinatarioBuscado == '') {
      this.destinatariosSugeridos = [];
    }
    else {
      this.servicioClientes.getClientes(this.destinatarioBuscado, 1, 3).subscribe(response => {
        this.destinatariosSugeridos = response.datos.datos;
        this.destinatariosSugeridos.forEach(dest => { dest.nombre = dest.razonSocial; });
      });
    }
  }

  seleccionarDestinatario(destinatario: any): void {
    this.documento.destinatario = destinatario;
    this.destinatarioBuscado = "";
    this.panelSugerirDestinatarioVisible = false;
    this.destinatariosSugeridos = [];
  }

  sugerirProducto(itemDocumentoTemporal: any): void {
    this.autoCompletarItemActivo = true;

    var productoBuscado = itemDocumentoTemporal.glosa;
    if (productoBuscado == '') {
      this.productosSugeridos = [];
    }
    else {
      setTimeout(() => this.subjectSugerirProducto.next(productoBuscado), 0);
    }
  }

  sinDestinatario(): void {
    this.ingresarDestinatarioActivado = false;
    this.documento.destinatario = { rut: 0, comuna: this.empresaSeleccionada.comuna };
  }

  seleccionarProducto(itemDocumentoTemporal: any, producto: any): void {
    itemDocumentoTemporal.glosa = producto.nombre;
    itemDocumentoTemporal.producto = producto;
    itemDocumentoTemporal.precioUnitario = producto.precio;
    var impuestosItem = [];

    for (let i = 0; i < this.documento.impuestos.length; i++) {
      impuestosItem.push({ impuesto: this.documento.impuestos[i].impuesto, seleccionado: producto.impuestos.some(m => m.id == this.documento.impuestos[i].impuesto.id), total: 0 });
    }

    itemDocumentoTemporal.impuestos = impuestosItem;
    itemDocumentoTemporal.precioBruto = this.montoBrutoUnitarioItem(itemDocumentoTemporal);
    this.autoCompletarItemActivo = false;
    this.recalcular();
  }

  montoBrutoUnitarioItem(item): number {
    let sum = item.precioUnitario;
    item.impuestos.forEach(i => {
      if (i.seleccionado) {
        i.total = i.impuesto.tipoTasa == 0 ? Math.round(item.precioUnitario * i.impuesto.tasa / 100) : i.impuesto.tasa;
        sum += i.total;
      }
    });
    return sum;
  }

  montoNetoUnitarioItem(item): number {
    let impuestos = 0;
    item.impuestos.forEach(i => {
      if (i.seleccionado) {
        // Me huele a que esto falla si hay más de un impuesto aplicado
        item.precioBruto = isNaN(item.precioBruto) ? 0 : item.precioBruto;
        let neto = i.impuesto.tipoTasa == 0 ? Math.floor(item.precioBruto / ((100 + i.impuesto.tasa) / 100)) : item.precioBruto - i.impuesto.tasa;
        let impuestoDelNeto = i.impuesto.tipoTasa == 0 ? Math.ceil(neto * i.impuesto.tasa / 100) : neto + i.impuesto.tasa;
        if (neto + impuestoDelNeto < item.precioBruto) {
          impuestoDelNeto++;
        }
        i.total = impuestoDelNeto;
        impuestos += i.total;
      }
    });
    return item.precioBruto - impuestos;
  }

  revisarYCorregirRutGenerico() {
    if (!this.documento.destinatario.rut || this.documento.destinatario.rut == 0) {
      this.documento.destinatario.rut = 66666666;
      this.documento.destinatario.digitoVerificador = '6';
    }
  }

  mostrarConfirmacion(): void {
    this.mostrandoConfirmacion = true;
  }

  emitir(): void {
    this.enviando = true;
    if (this.documento.tipoDocumento == 39) {
      this.recalcular();
      this.servicioDocumentos.emisionBoletaElectronica(this.documento).subscribe(res => {
        if (res.esCorrecto) {
          this.documento = res.datos;
          this.servicioDocumentos.emitirBoleta(this.documento).subscribe(res2 => {
            if (res2.esCorrecto) {
              this.router.navigate(['/erp/documentos/documento-detalle/', res.datos.id]);
            }
            else {
              var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res2.mensajes[0] + "</p>", "Error");
              this.servicioNotificaciones.agregarNotificacion(notificacion);
            }
          });
        }
        else {
          var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
          this.servicioNotificaciones.agregarNotificacion(notificacion);
        }
      });
    } else {
      console.log("no soportado en este componente");
    }
  }
}
