import { Component, OnInit } from '@angular/core';
import { SesionesService } from '../../servicios/servicio.sesiones';

@Component({
  selector: 'app-instancia-detalle',
  templateUrl: './instancia-detalle.component.html',
  styleUrls: ['./instancia-detalle.component.css']
})
export class InstanciaDetalleComponent implements OnInit {
  public empresa: any;
  constructor(
    private servicioSesiones: SesionesService
  ) { }

  ngOnInit() {
    this.empresa = this.servicioSesiones.obtenerSesion().empresaSeleccionada;
  }

  obtenerSemilla() : void {
    
  }

}
