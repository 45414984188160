import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserDesktopComponent } from './user-desktop/user-desktop.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UsuariosService } from '../servicios/servicio.usuarios';
import { FormsModule } from '@angular/forms';
import { UserOptionsComponent } from './user-options/user-options.component';
import { SharedModule } from '../shared/shared.module';
import { UserEmpresasComponent } from './user-empresas/user-empresas.component';
import { SesionesService } from '../servicios/servicio.sesiones';
import { UsuarioCambiarfirmaComponent } from './user-options/dialogos/usuario-cambiarfirma/usuario-cambiarfirma.component';
import { UsuarioCambiarcontrasenaComponent } from './user-options/dialogos/usuario-cambiarcontrasena/usuario-cambiarcontrasena.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    UserDetailsComponent, UserDesktopComponent, UserLoginComponent, UserOptionsComponent, 
    UserEmpresasComponent, UsuarioCambiarfirmaComponent, UsuarioCambiarcontrasenaComponent],
  entryComponents: [UsuarioCambiarfirmaComponent, UsuarioCambiarcontrasenaComponent],
  providers: [UsuariosService, SesionesService]
})
export class UsersModule { }
