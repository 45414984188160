import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { TipoLibro } from '../../core/enums/tipo-libro.enum';
import { Notificacion } from '../../core/models/notificacion';
import { LibrosService } from '../../servicios/servicio.libro';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';

@Component({
  selector: 'app-libro-imprimir',
  templateUrl: './libro-imprimir.component.html',
  styleUrls: ['./libro-imprimir.component.css']
})

export class LibroImprimirComponent implements OnInit {
  private readonly MAX_INT = 2147483647;

  public anio: number;
  public mes: number;
  public tipoLibro: TipoLibro;
  public libro: any;
  public totalesLibro: any;
  public documentosPorTipos: Array<any>;
  public largoPagina = this.MAX_INT;

  constructor(
    private servicioLibros: LibrosService,
    private activatedRoute: ActivatedRoute,
    private notificacionesService: NotificacionesService
    ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const fechaActual = new Date();
      if (params['anio']) {
        this.anio = +params['anio']; // (+) converts string  to a number
      } else {
        this.anio = fechaActual.getFullYear();
      }

      if (params['mes']) {
        this.mes = +params['mes']; // (+) converts string  to a number
      } else {
        this.mes = fechaActual.getMonth() + 1;
      }

      this.tipoLibro = +params['tipo'];
      this.recargar();
  });
  }

  recargar(): void {
    this.documentosPorTipos = [];
    this.recargarLibro();
    this.recargarTotales();
    this.recargarAdvertencias();
    if (this.tipoLibro !== TipoLibro.Guias)
    {
      this.recargarTipoDocumento(TipoDocumento.FacturaElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.Boleta, 1,"");
      this.recargarTipoDocumento(TipoDocumento.FacturaVenta, 1,"");
      this.recargarTipoDocumento(TipoDocumento.FacturaVentaExenta, 1,"");
      this.recargarTipoDocumento(TipoDocumento.FacturaElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.FacturaExentaElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.Boleta, 1,"");
      this.recargarTipoDocumento(TipoDocumento.BoletaExenta, 1,"");
      this.recargarTipoDocumento(TipoDocumento.BoletaElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.LiquidacionFactura, 1,"");
      this.recargarTipoDocumento(TipoDocumento.BoletaExentaElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.LiquidacionFacturaElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.FacturaCompra, 1,"");
      this.recargarTipoDocumento(TipoDocumento.FacturaCompraElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.NotaDebito, 1,"");
      this.recargarTipoDocumento(TipoDocumento.NotaDebitoElectronica, 1,"");
      this.recargarTipoDocumento(TipoDocumento.NotaCredito, 1,"");
      this.recargarTipoDocumento(TipoDocumento.NotaCreditoElectronica, 1,"");
    }
    else
    {
      this.recargarTipoDocumento(TipoDocumento.GuiaDespacho, 1,"");
      this.recargarTipoDocumento(TipoDocumento.GuiaDespachoElectronica, 1,"");
    }
  }

  recargarLibro() {
    this.servicioLibros.getLibro(this.anio, this.mes, this.tipoLibro).subscribe(response => {
        this.libro = response.datos;
    });
  }

  recargarTotales() {
    this.totalesLibro = null;
    this.servicioLibros.getTotales(this.anio, this.mes, this.tipoLibro).subscribe(response => {
        this.totalesLibro = response.datos;
    });
  }

  recargarAdvertencias() {
    this.servicioLibros.getLibroValidar(this.anio, this.mes, this.tipoLibro).subscribe(response => {
      if (response.datos.length > 0) {
        const titulo = 'Hay algunas observaciones con el Libro de ' + this.tipoLibro + ' del ' + this.libro.periodo.mes + '/' + this.libro.periodo.anio;
        let mensaje = '<p><ul>';
        response.datos.forEach(m => {
          mensaje = mensaje + '<li>' + m + '</li>';
        });
        mensaje = mensaje + '</ul></p>';
        const notificacion = new Notificacion(titulo, mensaje, 'Advertencia');
        this.notificacionesService.agregarNotificacion(notificacion);
      }
    });
  }

  recargarTipoDocumento(tipoDocumento: TipoDocumento, pagina: number, filtro: string) {
    let documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo === tipoDocumento);
    if (documentoPorTipo.length > 0) {
      documentoPorTipo[0].pagina = pagina,
      documentoPorTipo[0].filtro = filtro,
      documentoPorTipo[0].respuestaPendiente = true;
    } else {
      this.documentosPorTipos.push(
        {
          tipo: tipoDocumento,
          pagina: pagina,
          filtro: filtro,
          respuestaPendiente: true
        }
      );
    }
    documentoPorTipo = this.documentosPorTipos.filter(m => m.tipo === tipoDocumento);

    this.servicioLibros.getDocumentos(this.anio, this.mes, this.tipoLibro, tipoDocumento, pagina, this.largoPagina, filtro)
      .subscribe(response => {
        documentoPorTipo[0].documentos = response.datos.datos;
        documentoPorTipo[0].totalDocumentos = response.datos.totalElementos;
        documentoPorTipo[0].respuestaPendiente = false;
      }
    );
  }

  public titulo() {
    return 'Libro de ' + TipoLibro[this.tipoLibro];
  }

  public ObtenerImpuesto(doc, impuesto) {
    return doc.impuestos.find(i => i.impuesto.id === impuesto.id);
  }

  public SumatoriaNetos(documentos) {
    let sum = 0;
    documentos.forEach(documento => {
      sum += documento.neto;
    });
    return sum;
  }

  public SumatoriaExentos(documentos) {
    let sum = 0;
    documentos.forEach(documento => {
      sum += documento.exento;
    });
    return sum;
  }

  public SumatoriaImpuestos(documentos, impuesto) {
    let sum = 0;
    documentos.forEach(documento => {
      documento.impuestos.forEach(i => {
        if (i.impuesto.id === impuesto.id) {
          sum += i.total;
        }
      });
    });
    return sum;
  }

  public SumatoriaTotales(documentos) {
    let sum = 0;
    documentos.forEach(documento => {
      sum += documento.total;
    });
    return sum;
  }
}
