import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class EnviosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private sesionesService: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.sesionesService.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.sesionesService.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getEnvio(idEnvioDTE: string) : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(this.urlApi + `EnvioApi/Get?id=${idEnvioDTE}`, { headers: headers }).pipe(map((res: Response) => res));
    }

}