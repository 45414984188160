import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecepcionListaComponent } from './recepcion-lista/recepcion-lista.component';
import { RecepcionDetalleComponent } from './recepcion-detalle/recepcion-detalle.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RecepcionesService } from '../servicios/servicio.recepciones';
import { DocumentosModule } from '../documentos/documentos.module'

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    DocumentosModule
  ],
  providers: [RecepcionesService],
  declarations: [RecepcionListaComponent, RecepcionDetalleComponent]
})
export class RecepcionesModule { }
