import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { InventarioDetallesComponent } from './inventario-detalles/inventario-detalles.component';
import { InventarioService } from '../servicios/servicio.inventario';
import { ProductoDetallesComponent } from './producto-detalles/producto-detalles.component';
import { ProductoModificarComponent } from './producto-modificar/producto-modificar.component';
import { ProductoEliminarComponent } from './producto-eliminar/producto-eliminar.component';
import { ProductoDocumentosComponent } from './producto-documentos/producto-documentos.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    FormsModule
  ],
  declarations: [InventarioDetallesComponent, ProductoDetallesComponent, ProductoModificarComponent, ProductoEliminarComponent, ProductoDocumentosComponent],
  providers: [InventarioService]
})
export class InventarioModule { }
