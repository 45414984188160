import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-folio-cargar',
  templateUrl: './folio-cargar.component.html',
  styleUrls: ['./folio-cargar.component.css']
})
export class FolioCargarComponent implements OnInit {
  nuevoFolio: any;
  constructor(
    public dialogRef: MatDialogRef<FolioCargarComponent>,
    @Inject(MAT_DIALOG_DATA) public cliente: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
  

  subirFolio(ficheros): void {
    this.nuevoFolio = ficheros.target.files[0];
  }
}
