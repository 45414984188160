import { Injectable, Output, EventEmitter } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpHeaders } from '@angular/common/http';
import { Notificacion } from '../core/models/notificacion';
import { Subject }    from 'rxjs';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class NotificacionesService {
    private notificacionAgregadaSource = new Subject<Notificacion>();
    notificacionAgregada$ = this.notificacionAgregadaSource.asObservable();

    constructor (private servicioSesiones: SesionesService
    ) {
    }

    public agregarNotificacion(notificacion: any){
        this.notificacionAgregadaSource.next(notificacion);
    }
}