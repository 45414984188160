<div class="row">
    <div class="col-xs-12">
      <h1 mat-dialog-title><i class="fa fa-times"></i> Otros Periodos</h1>
      <div mat-dialog-content>
        <p>Seleccione el mes del libro que quiere visitar:</p>
        <div class="input-group">
            <select name="Mes" class="form-control" [(ngModel)]="this.modalInformaciones.mesSeleccionado">
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
            </select>
            <span class="input-group-addon">/</span>
            <select *ngIf="this.modalInformaciones.aniosSeleccionables" [(ngModel)]="this.modalInformaciones.anioSeleccionado" name="Anio"
                class="form-control">
                <option value="{{ anioSeleccionable }}" *ngFor="let anioSeleccionable of this.modalInformaciones.aniosSeleccionables">{{ anioSeleccionable }}</option>
            </select>
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="{ result: 'ok', anio: this.modalInformaciones.anioSeleccionado, mes: this.modalInformaciones.mesSeleccionado }" class="btn btn-success">Cambiar periodo</button>
        <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
      </div>
    </div>
  </div>