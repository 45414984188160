import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from '../../servicios/servicio.inventario';
import { EnumeracionesService } from '../../servicios/servicio.enumeraciones';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';
import { SesionesService } from '../../servicios/servicio.sesiones';

@Component({
  selector: 'app-producto-modificar',
  templateUrl: './producto-modificar.component.html',
  styleUrls: ['./producto-modificar.component.css']
})
export class ProductoModificarComponent implements OnInit {
  public accion: string;
  public idProducto: number;
  public producto: any;
  public unidades: any[];
  public impuestos: any[];
  public empresaSeleccionada: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicioSesiones: SesionesService,
    private servicioInventario: InventarioService,
    private servicioEnumeraciones: EnumeracionesService,
    private servicioNotificaciones: NotificacionesService
  ) { }

  public esValido(): boolean { return true; }

  ngOnInit() {
    this.empresaSeleccionada = this.servicioSesiones.obtenerSesion().empresaSeleccionada;
    this.unidades = this.servicioEnumeraciones.getUnidadesMedida();
    this.impuestos = this.empresaSeleccionada.impuestos.filter(m => m.afectaLibroVentas);;

    this.route.params.subscribe(params => {
      console.log(params);
      if(params['id']) {
        this.idProducto = params['id'];
        this.accion = "modificar";
        this.servicioInventario.getProducto(this.idProducto).subscribe(response => {
          this.producto = response.datos;
          console.log(this.producto);
        });
      }
      else {
        this.accion = "crear";
        this.producto = {};
        this.producto.unidadMedida = this.producto.unidadMedida || { id: 0};
        this.producto.impuestos = this.producto.impuestos || [];
      }
    });
  }

  guardar (): void {
    if (this.accion == "crear") {
      this.crear();
    }
    if (this.accion == "modificar") {
      this.modificar();
    }
  }

  modificar(): void {
    this.servicioInventario.modificar(this.producto).subscribe(res => {
      if(res.esCorrecto) {
        var titulo = "Producto actualizado con éxito";
        var mensaje = "<p>El producto " + this.producto.nombre + " ha sido modificado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['/erp/inventario/producto-detalle/', this.producto.id]);
      }
      else {
        var titulo = "Hubieron problemas modificando el producto";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }

  crear(): void {
    console.log(this.producto);
    this.producto.unidadMedida.id = Number(this.producto.unidadMedida.id);
    this.servicioInventario.crear(this.producto).subscribe(res => {
      if(res.esCorrecto) {
        console.log
        var titulo = "Producto creado con éxito";
        var mensaje = "<p>El producto " + this.producto.nombre + " ha sido creado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['erp/inventario/producto-detalle/', res.datos.id]);
      }
      else {
        var titulo = "Hubieron problemas creando el producto";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }

  checkboxImpuestoCliqueado(idImpuesto: number, evento: any): void {
    if(evento.target.checked) {
      var impuesto = this.impuestos.find(i => i.id === idImpuesto);
      this.producto.impuestos.push(impuesto);
    }
    else {
      this.producto.impuestos = this.producto.impuestos.filter(i => i.id != idImpuesto);
    }
    console.log(this.producto.impuestos);
  }

  impuestoEstaSeleccionado(impuesto: any): boolean{
    return this.producto.impuestos.some(i => i.id === impuesto.id);
  }
}
