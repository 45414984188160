import { environment } from './../../../environments/environment';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TipoLibro } from '../../core/enums/tipo-libro.enum';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NotificacionesService } from '../../../app/servicios/servicio.notificaciones';
import { Notificacion } from '../../../app/core/models/notificacion';
import { FoliosService } from '../../../app/servicios/servicio.folios';

/**
 * Food data with nested structure.
 * Each node has a name and an optiona list of children.
 */
interface ItemMenu {
  name: string;
  parameters: any[];
  children?: ItemMenu[];
  icono?: string;
}

const TREE_DATA: ItemMenu[] = [
  {
    name: 'Inicio',
    parameters: ['/erp/users/user-desktop'],
    icono: 'fa-home'
  },
  {
    name: 'Emisión',
    parameters: [],
    children: [
    ]
  },
  {
    name: 'Ingreso Manual',
    parameters: [],
    children: [
      { name: 'Documentos', parameters: ['/erp/documentos/documento-modificar', 'crear', TipoDocumento.FacturaVenta], icono: 'fa-file-alt' },
      { name: 'Boletas', parameters: ['/erp/documentos/boleta-modificar', 'crear', TipoDocumento.Boleta], icono: 'fa-receipt' },
    ]
  },
  {
    name: 'Libros',
    parameters: [],
    children: [
      { name: 'Libro de Ventas', parameters: ['/erp/libros/libro-detalle', TipoLibro.Ventas], icono: 'fa-book-open' },
      { name: 'Libro de Compras', parameters: ['/erp/libros/libro-detalle', TipoLibro.Compras], icono: 'fa-book-open' },
      { name: 'Libro de Guías', parameters: ['/erp/libros/libro-guias'], icono: 'fa-book-open' },
      { name: 'Libro de Boletas', parameters: ['/erp/libros/libro-detalle', TipoLibro.Boletas], icono: 'fa-book-open' },
      { name: 'Sin Periodo', parameters: ['/erp/libros/libro-sinperiodo'], icono: 'fa-question-circle' },
    ]
  },
  {
    name: 'Inventario',
    parameters: ['/erp/inventario/inventario-detalle'],
    children: [],
    icono: 'fa-boxes'
  },
  {
    name: 'Mi Empresa',
    parameters: ['/erp/instancia/instancia-detalle', 1],
    children: [],
    icono: 'fa-store-alt'
  },
  {
    name: 'Mis clientes',
    parameters: ['/erp/clientes/cliente-lista', 1, 10],
    children: [],
    icono: 'fa-users'
  }
];


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public tipoLibro = TipoLibro;
  public tipoDocumento = TipoDocumento;
  empresaSeleccionada: any;
  usuarioSeleccionado: any;

  treeControl = new NestedTreeControl<ItemMenu>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ItemMenu>();

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    private servicioSesiones: SesionesService,
    private servicioNotificaciones: NotificacionesService,
    private servicioFolios: FoliosService,
    private router: Router
  ) {
  }

  hasChild = (_: number, node: ItemMenu) => !!node.children && node.children.length > 0;

  ngOnInit() {
    const sesion = this.servicioSesiones.obtenerSesion();
    if (sesion) {
      if (TREE_DATA[1].children.length === 0) {
        this.empresaSeleccionada = sesion.empresaSeleccionada;
        this.servicioFolios.getEnUso().subscribe(res => {
          var foliosActivos = res.datos;
          if (foliosActivos.some(a => a.tipoDocumento === 33)) {
            TREE_DATA[1].children.push({ name: 'Factura', parameters: ['/erp/documentos/documento-modificar', 'emision', TipoDocumento.FacturaElectronica], icono: 'fa-file-invoice-dollar' });
          }
          if (foliosActivos.some(a => a.tipoDocumento === 61)) {
            TREE_DATA[1].children.push({ name: 'Nota de Crédito', parameters: ['/erp/documentos/documento-modificar', 'emision', TipoDocumento.NotaCreditoElectronica], icono: 'fa-money-check-alt' });
          }
          if (foliosActivos.some(a => a.tipoDocumento === 56)) {
            TREE_DATA[1].children.push({ name: 'Nota de Débito', parameters: ['/erp/documentos/documento-modificar', 'emision', TipoDocumento.NotaDebitoElectronica], icono: 'fa-money-check-alt' });
          }
          if (foliosActivos.some(a => a.tipoDocumento === 52)) {
            TREE_DATA[1].children.push({ name: 'Guía de Despacho', parameters: ['/erp/documentos/documento-modificar', 'emision', TipoDocumento.GuiaDespachoElectronica], icono: 'fa-truck' });
          }
          if (foliosActivos.some(a => a.tipoDocumento === 46)) {
            TREE_DATA[1].children.push({ name: 'Factura de Compra', parameters: ['/erp/documentos/documento-modificar', 'emision', TipoDocumento.FacturaCompraElectronica], icono: 'fa-file-invoice' });
          }
          if (foliosActivos.some(a => a.tipoDocumento === 39)) {
            TREE_DATA[1].children.push({ name: 'Boleta Electronica', parameters: ['/erp/documentos/documento-modificar', 'emision', TipoDocumento.BoletaElectronica], icono: 'fa-receipt' });
            TREE_DATA[1].children.push({ name: 'Boleta POS', parameters: ['/erp/documentos/boleta-pos', 'emision', TipoDocumento.BoletaElectronica], icono: 'fa-file-invoice' });
          }

          this.usuarioSeleccionado = sesion.usuarioAutenticado;
          if (this.usuarioSeleccionado.email === 'edgardo@lintu.cl' || this.usuarioSeleccionado.email === 'milo@condorerp.cl') {
            if (TREE_DATA[TREE_DATA.length - 1].name !== 'Administracion') {
              TREE_DATA.push({ name: 'Administracion', parameters: ['/erp/administracion'], icono: 'fa-cogs' });
            }
          }

          this.dataSource.data = TREE_DATA;
        });
      }
    } else {
      const titulo = 'Sesión expirada';
      const mensaje = '<p>Acceda nuevamente por favor.</p>';
      const notificacion = new Notificacion(titulo, mensaje, 'Error');
      this.servicioNotificaciones.agregarNotificacion(notificacion);
      this.router.navigate(['public/users/user-login']);
      console.log('Expirado');
    }
  }

  menuClickeado(nodo: ItemMenu): void {
    if (!nodo.children || nodo.children.length === 0) {
      this.router.navigate(nodo.parameters);
      this.onClose.emit();
    }
  }

  checkearSesion(): void {
    this.servicioSesiones.estaActiva().subscribe(res => {
      console.log(res);
      if (!res.datos) {
        const titulo = 'Sesión expirada';
        const mensaje = '<p>Acceda nuevamente por favor.</p>';
        const notificacion = new Notificacion(titulo, mensaje, 'Error');
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['public/users/user-login']);
        console.log('Expirado');
      }
    });
  }

  cerrarSesion(): void {
    this.servicioSesiones.cerrarSesion().subscribe(res => {
      this.router.navigate(['public/users/user-login']);
      console.log('adios');
    });
  }
}
