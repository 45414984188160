import { Component, OnInit } from '@angular/core';
import { FoliosService } from '../../servicios/servicio.folios';
import { MatDialog } from '@angular/material/dialog';
import { FolioCargarComponent } from './dialogos/folio-cargar/folio-cargar.component';
import { Notificacion } from '../../core/models/notificacion';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';

@Component({
  selector: 'app-folio-lista',
  templateUrl: './folio-lista.component.html',
  styleUrls: ['./folio-lista.component.css']
})
export class FolioListaComponent implements OnInit {
  public folios: Array<any>;
  public foliosEnUso: Array<any>;
  public foliosArchivados: Array<any>;
  public cargando: boolean = false;
  public mostrarTodosLosNoActivos: boolean = false;

  constructor(
    private foliosService: FoliosService,
    private notificacionesService: NotificacionesService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.recargar();
  }

  recargar() : void {
    this.cargando = true;
    this.folios = [];
    this.foliosService.getEnUso().subscribe(response => {
      this.foliosEnUso = response.datos;
      console.log(this.foliosEnUso);
      this.cargando = false;
    });
  }

  mostrarTodos() : void {
    this.foliosService.getTodos().subscribe(response => {
      this.folios = response.datos;
      this.foliosArchivados = this.folios.filter(m => !m.enUso);
    });
  }

  dialogoSubirFolio() : void {
    const dialogRef = this.dialog.open(FolioCargarComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result == 'ok') {

        const formData = new FormData();
        formData.append('file', result.folio, result.folio.name);
        this.foliosService.agregar(formData).subscribe(res => {
          if(res.esCorrecto) {
            var titulo = "Folio cargado exitosamente";
            var mensaje = "<p>El folio fue cargado exitosamente.</p>";
            var notificacion = new Notificacion(titulo, mensaje);
            this.notificacionesService.agregarNotificacion(notificacion);
            this.recargar();
          }
          else {
            var titulo = "Hubieron problemas subiendo el folio";
            var mensaje = "<p>" + res.Datos + "</p>";
            var notificacion = new Notificacion(titulo, mensaje, "Error");
            this.notificacionesService.agregarNotificacion(notificacion);
          }
        });
      }
    });
  }

  utilizar(folio: any) : void {
    this.foliosService.utilizar(folio.id).subscribe(res => {
      if(res.esCorrecto) {
        var titulo = "Utilizando folios exitosamente";
        var mensaje = "<p>El folio fue marcado para utilizar exitosamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.notificacionesService.agregarNotificacion(notificacion);
        this.recargar();
      }
      else {
        var titulo = "Hubieron problemas marcando el folio para su utilización";
        var mensaje = "<p>" + res.Datos + "</p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.notificacionesService.agregarNotificacion(notificacion);
      }
    });
  }

  
}