<ng-container *ngIf="productosPaginados">
  <h2>Productos</h2>
  <h4></h4>
  <div>
    <div class="row">
        <div class="col-sm-6">
                ({{productosPaginados.datos.length}} productos)
            &nbsp;
        </div>
        <div class="col-sm-6">
            <div class="dataTables_filter">
                <div class="row">
                    <div class="col-sm-6" style="text-align: right">
                            <span>Filtrar: </span>
                    </div>
                    <div class="col-sm-6" style="margin-bottom: 5px;">
                        <input class="form-control input-sm" (keyup)="setFiltro($event)">
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
  </div>
  <table class="table table-bordered table-hover table-striped tablaFiltrada">
      <thead>
          <tr>
              <th>Código</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Precio Unitario</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let producto of productosPaginados.datos">
            <td>
                <a [routerLink]="['/erp/inventario/producto-detalle/', producto.id]">
                    {{producto.codigoInterno}}
                </a>
            </td>
            <td>
                <a [routerLink]="['/erp/inventario/producto-detalle/', producto.id]">
                    {{producto.nombre}}
                </a>
            </td>
            <td>
                {{producto.descripcion}}
            </td>
            <td>
                {{producto.precio | number:'':'es-CL' }}
            </td>
          </tr>
      </tbody>
  </table>
  <div class="row">
      <div class="col-sm-6">
          <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina) + productosPaginados.datos.length }} de {{ productosPaginados.totalElementos }} productos</div>
      </div>
      <div class="col-sm-6">
          <ul class="pagination pull-right">
              <li class="paginate_button previous">
                  <a (click)="anteriorPagina()">Anterior</a>
              </li>
              <li class="paginate_button next" aria-controls="dataTables-example" tabindex="0">
                  <a (click)="siguientePagina()">Siguiente</a>
              </li>
          </ul>
      </div>
  </div>
  <hr />
  <div class="row">
      <div class="col-xs-12">
        <a [routerLink]="['/erp/inventario/producto-modificar', 'Crear', '']" class="btn btn-success"><i class="fa fa-plus"></i> Crear Producto</a>
        </div>
    </div>
</ng-container>