import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiService {
    urlApi: string;

    constructor (
        private http: HttpClient
    ) {
        this.urlApi = environment.apiCondorURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
          
        return new HttpHeaders(headerDict);
    }

    public getClienteApi(alias: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/Seleccionar?alias=${alias}`, options);
    }

    public getAlertas(alias: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/Alertas?alias=${alias}`, options);
    }

    public getIndicadores() : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}/Info/Indicadores`, options);
    }

    public getFrasesAleatoria() : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}/Info/FraseAleatoria`, options);
    }

    public getArticulosAyuda() : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}/Ayuda/Todas`, options);
    }

    public getArticuloAyuda(id: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}/Ayuda/Articulo/?id=` + id, options);
    }
}