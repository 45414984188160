import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment'
import { Injectable } from '@angular/core';
import { SesionesService } from './servicio.sesiones';
import { catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable()
export class LoggingService {
    urlApi: string;

    constructor(
        private http: HttpClient,
        private servicioSesiones: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersPost(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        };

        return new HttpHeaders(headerDict);
    }

    public async registrarError(error: string) {
        const headers = this.headersPost();
        const body = { error: error };

        this.http.post(`${this.urlApi}LoggingApi/Error`, body, { headers: headers }).pipe(
            // Agrego este catch, para que si este request falla, no se reporte ese error.
            catchError((e) => {
                console.log('Error registrando error en el backend. Error: ' + e);
                return EMPTY;
            })
          );
    }
}
