import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-documento-dialogo-duplicar',
  templateUrl: './documento-dialogo-duplicar.component.html',
  styleUrls: ['./documento-dialogo-duplicar.component.css']
})
export class DocumentoDialogoDuplicarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DocumentoDialogoDuplicarComponent>,
    @Inject(MAT_DIALOG_DATA) public documento: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
