<div class="row">
  <div class="col-xs-12">
      <div class="panel panel-default">
          <div class="panel-heading">
              <h4><i class="fa fa-info"></i> Detalles del cliente</h4>
          </div>
          <div *ngIf="this.cliente" class="panel-body">
              <fieldset>
                  <label>RUT</label>
                  <p>{{ this.cliente.rut | number:'':'es-CL' }} - {{ this.cliente.digitoVerificador }}</p>

                  <label>Razón social</label>
                  <p>{{ this.cliente.razonSocial }}</p>

                  <label>Giro</label>
                  <p>{{ this.cliente.giro }}</p>

                  <label>Dirección</label>
                  <p>{{ this.cliente.direccion }}, {{ this.cliente.comuna.nombre }}</p>
                  
                  <div *ngIf="this.cliente.telefono">
                    <label>Teléfono</label>
                    <p>{{ this.cliente.telefono }}</p>
                    </div>
              </fieldset>
          </div>
          <div *ngIf="this.cliente" class="panel-footer">
              
              <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                Acciones <span class="caret"></span>
              </button>
              <mat-menu #menuAcciones="matMenu">
                <button mat-menu-item [routerLink]="['/erp/clientes/cliente-modificar', this.cliente.rut, 'Modificar']"><i class="fa fa-edit"></i> Modificar</button>
                <button mat-menu-item (click)="dialogoEliminar()"><i class="fa fa-times"></i> Eliminar</button>
              </mat-menu>


              <a  [routerLink]="['/erp/clientes/cliente-lista', 1, 10]" class="btn btn-warning pull-right"><i class="fa fa-arrow-left"></i> Clientes</a>
          </div>
      </div>
  </div>
</div>