<div *ngIf="documento" class="panel panel-default">
  <div class="panel-heading">
    <h4>{{ this.accion == 'emision' ? "Emisión de " : this.accion == 'crear' ? "Ingreso de " : this.accion == 'duplicar' ? "Duplicando " : "Modificando" }} {{ 'DOCUMENTOS.TipoDocumento_'
      + documento.tipoDocumento | translate }}</h4>
  </div>
  <div class="panel-body">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4">
        <ng-container *ngIf="this.accion == 'emision' || (this.accion == 'modificar' && documento.emisor.rut == this.empresaSeleccionada.rut)">
          <fieldset>
            <legend>Emisor</legend>
            <div>
              <h3 style="text-align: center">
                {{ documento.emisor.razonSocial }}
              </h3>
            </div>
            <div>
              <h5 style="text-align: center">{{ documento.emisor.giro }}</h5>
            </div>
            <div>
              <h5 style="text-align: center; margin-bottom: 0px">{{ documento.emisor.direccion }}, {{documento.emisor.comuna.nombre }}</h5>
            </div>
          </fieldset>
        </ng-container>
        <ng-container *ngIf="!(this.accion == 'emision' || (this.accion == 'modificar' && documento.emisor.rut == this.empresaSeleccionada.rut))">
          <fieldset>
            <legend>Emisor
              <ng-container *ngIf="this.accion == 'crear' && this.documento.destinatario.rut != this.empresaSeleccionada.rut && this.documento.emisor.rut != this.empresaSeleccionada.rut">
                <sup>
                  <small>
                    <a style="cursor: pointer;" (click)="this.seleccionarEmisorEstaEmpresa()">esta empresa</a>
                  </small>
                </sup>
              </ng-container>
            </legend>
            <div class="form-group">
              <label>
                Razón Social
              </label>
              <input type="text" [(ngModel)]="documento.emisor.razonSocial" placeholder="Ingrese el nombre del emisor" required="true"
                class="form-control">
            </div>
            <div class="form-group">
              <label>
                Giro
              </label>
              <input type="text" [(ngModel)]="documento.emisor.giro" placeholder="Ingrese el giro del emisor" required="true" class="form-control">
            </div>
            <div class="form-group">
              <label>
                Dirección
              </label>
              <div class="input-group">
                <input type="text" [(ngModel)]="documento.emisor.direccion" placeholder="Ingrese la dirección del emisor" required="true"
                  class="form-control">
                <span class="input-group-addon">,</span>
                <select class="form-control" [(ngModel)]="documento.emisor.comuna.id">
                  <option *ngFor="let comuna of comunas" [ngValue]="comuna.id">{{comuna.nombre}}</option>
                </select>
              </div>
            </div>
          </fieldset>
        </ng-container>
      </div>
      <div class="hidden-xs hidden-sm col-md-4">
        &nbsp;
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4">
        <fieldset>
          <legend>Documento</legend>
          <ng-container *ngIf="this.accion == 'emision' || (this.accion == 'modificar' && documento.emisor.rut == this.empresaSeleccionada.rut)">
            <div>
              <h3 style="text-align: center">RUT: {{ documento.emisor.rut | number:'':'es-CL' }} - {{ documento.emisor.digitoVerificador}}</h3>
            </div>
          </ng-container>
          <ng-container *ngIf="!(this.accion == 'emision' || (this.accion == 'modificar' && documento.emisor.rut == this.empresaSeleccionada.rut))">
            <div class="form-group">
              <label>
                RUT
              </label>
              <div class="input-group">
                <input placeholder="Ingrese el rut del emisor" required="true" class="numerico form-control" type="text" [(ngModel)]="documento.emisor.rut">
                <span class="input-group-addon">-</span>
                <input placeholder="Ingrese el digito verificador del rut" required="true" class="form-control" type="text" [(ngModel)]="documento.emisor.digitoVerificador">
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="this.accion == 'emision' || this.accion == 'duplicar' || (this.accion == 'modificar' && documento.Folio)">
            <h3 style="text-align: center">
              {{ 'DOCUMENTOS.TipoDocumento_' + documento.tipoDocumento | translate }}
            </h3>

            <div class="form-group">
              <label>
                Número
              </label>
              <div>
                <ng-container *ngIf="documento.numero > 0">
                  <span>{{ documento.numero }}</span>
                </ng-container>
                <ng-container *ngIf="documento.numero < 1">
                  <span>(Pendiente)</span>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!(this.accion == 'emision' || this.accion == 'duplicar' || (this.accion == 'modificar' && documento.Folio))">
            <div class="form-group">
              <label>
                Tipo
              </label>
              <div>
                <select [(ngModel)]="documento.tipoDocumento" class="form-control">
                  <option *ngFor="let tipoDoc of this.tiposDocumentos" [ngValue]="tipoDoc.id">{{tipoDoc.nombre}}</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label>
                Número
              </label>
              <div>
                <input type="number" [(ngModel)]="documento.numero" placeholder="Ingrese número del documento" class="form-control" />
              </div>
            </div>
          </ng-container>

          <div class="form-group">
            <label>
              Fecha Emisión
            </label>
            <app-input-fecha [readOnly]="(this.accion == 'modificar')" ngDefaultControl (valueChange)="this.cambioPeriodoDocumento(this.documento);" [(value)]="this.documento.fechaEmision" [placeholder]="'Seleccione la fecha de emisión'">
            </app-input-fecha>
          </div>
        </fieldset>

      </div>
    </div>
    <!-- Destinatario -->
    <div class="row">
      <div class="col-md-12">
        <fieldset>
          <legend>Destinatario
            <sup>
              <small *ngIf="this.documento.emisor.rut != this.empresaSeleccionada.rut"><a style="cursor: pointer;" (click)="this.seleccionarDestinatarioEstaEmpresa()">Esta empresa</a></small>
              <small *ngIf="!panelSugerirDestinatarioVisible">&nbsp;|&nbsp;<a style="cursor: pointer" (click)="panelSugerirDestinatarioVisible = !panelSugerirDestinatarioVisible">Ir al buscador</a></small>
              <small *ngIf="this.documento.tipoDocumento == tipoDocumento.BoletaElectronica && this.documento.destinatario.rut != 66666666">&nbsp;|&nbsp;<a style="cursor: pointer;" (click)="this.sinDestinatario()">Sin destinatario</a></small>
            </sup>
          </legend>
          <ng-container *ngIf="panelSugerirDestinatarioVisible">
            <div class="form-group" style="margin-bottom: 0px">
              <label>
                  Busca aquí:
              </label>
              <input type="text" [(ngModel)]="this.destinatarioBuscado" (keyup)="sugerirDestinatario($event)" placeholder="Busca a tu cliente aquí..." class="form-control">
            </div>
            <ng-container *ngIf="this.destinatariosSugeridos && this.destinatariosSugeridos.length > 0">
              <table class="table table-striped table-bordered table-hover">
                <tbody>
                  <tr *ngFor="let alternativa of this.destinatariosSugeridos">
                    <td>{{ alternativa.rut }}-{{ alternativa.digitoVerificador}}</td>
                    <td><a style="cursor: pointer" (click)="seleccionarDestinatario(alternativa); panelSugerirDestinatarioVisible = !panelSugerirDestinatarioVisible">{{ alternativa.nombre | truncate:[20, '...'] }}</a></td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <a style="cursor: pointer" (click)="panelSugerirDestinatarioVisible = !panelSugerirDestinatarioVisible">Saltar, quiero ingresarlo manualmente</a>
            <br /><br />
          </ng-container>
          <ng-container *ngIf="!panelSugerirDestinatarioVisible">
            <ng-container *ngIf="!(this.documento.tipoDocumento == tipoDocumento.BoletaElectronica && this.documento.destinatario.rut == 66666666)">
              <div class="form-group">
                <label>
                  RUT
                </label>
                <div class="input-group">
                  <input type="text" [(ngModel)]="documento.destinatario.rut" (change)="cambioRutDestinatario()" placeholder="Ingrese el rut del destinatario"
                    required="true" class="numerico form-control">
                  <span class="input-group-addon">-</span>
                  <input type="text" [(ngModel)]="documento.destinatario.digitoVerificador" placeholder="Ingrese el digito verificador del rut del destinatario"
                    required="true" class="numerico form-control">
                </div>
              </div>
              <div class="form-group">
                <label>
                  Razon Social
                </label>
                <input type="text" [(ngModel)]="documento.destinatario.razonSocial" placeholder="Ingrese la razón social del destinatario"
                  required="true" class="form-control">
              </div>
              <div class="form-group">
                <label>
                  Giro
                </label>
                <input type="text" [(ngModel)]="documento.destinatario.giro" placeholder="Ingrese el giro del destinatario" required="true"
                  class="form-control">
              </div>
              <div class="form-group">
                <label>
                  Dirección
                </label>
                <div class="input-group">
                  <input type="text" [(ngModel)]="documento.destinatario.direccion" placeholder="Ingrese la dirección del destinatario" required="true"
                    class="form-control">
                  <span class="input-group-addon">,</span>
                  <select *ngIf="comunas" class="form-control" [(ngModel)]="documento.destinatario.comuna.id">
                    <option *ngFor="let comuna of comunas" value="{{comuna.id}}">{{comuna.nombre}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Teléfono
                </label>
                <input type="text" [(ngModel)]="documento.destinatario.telefono" placeholder="Ingrese el teléfono del destinatario" required="true"
                  class="form-control">
              </div>
            </ng-container>
            <ng-container *ngIf="this.documento.tipoDocumento == tipoDocumento.BoletaElectronica && this.documento.destinatario.rut == 66666666">
              <p>-- Sin Destinatario --</p>
            </ng-container>
          </ng-container>
        </fieldset>
      </div>
    </div>


    <!-- Detalle -->
    <ng-container *ngIf="this.documento.tipoDocumento == tipoDocumento.BoletaElectronica">
      <!-- Para boletas incluye el bruto -->
      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <legend>Detalle de productos/servicios</legend>
          </fieldset>
          <div class="row">
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-1">Cantidad</div>
            <div class="hidden-xs col-sm-5 col-md-4 col-lg-5">Detalle</div>
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">P Unit.</div>
            <div class="hidden-xs hidden-sm col-md-1 col-lg-1">Descuento %</div>
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">Total</div>
          </div>
          <div *ngFor="let item of documento.items; let i = index;" class="row" style="border-bottom: 1px dashed #dddddd; padding-bottom: 10px; padding-top: 10px;">
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-1">
              <label class="hidden-sm hidden-md hidden-lg">Cantidad</label>
              <input type="number" [(ngModel)]="documento.items[i].cantidad" (change)="recalcular()" placeholder="Cantidad" required="true"
                class="form-control">
            </div>
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-5">
              <label class="hidden-sm hidden-md hidden-lg">Descripción</label>
              <div class="input-group">
                <input type="text" [(ngModel)]="documento.items[i].glosa" [readonly]="documento.items[i].producto.id > 0"  (keyup)="sugerirProducto(i)" placeholder="Descripción" required="true" class="form-control">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuImpuesto">
                    Imp. <span class="caret"></span>
                  </button>
                  <mat-menu #menuImpuesto="matMenu">
                    <div style="padding: 10px 10px 10px">
                      <span>Impuestos del producto</span>
                      <div role="separator" class="dropdown-divider"></div>
                      <span *ngFor="let item of documento.items[i].impuestos; let a = index;" class="dropdown-item">
                        <input [(ngModel)]="documento.items[i].impuestos[a].impuesto.id" type="hidden">
                        <input [(ngModel)]="documento.items[i].impuestos[a].seleccionado" (change)="recalcular()" type="checkbox"> {{documento.items[i].impuestos[a].impuesto.nombre}}
                        <br />
                      </span>
                    </div>
                  </mat-menu>
                </div>
              </div>
              <ng-container *ngIf="autoCompletarItemActivo == i">
                <ng-container *ngIf="this.productosSugeridos && this.productosSugeridos.length > 0">
                  <table class="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr *ngFor="let prod of this.productosSugeridos">
                        <td>{{ prod.codigoInterno }}</td>
                        <td><a style="cursor: pointer" (click)="seleccionarProducto(i, prod); autoCompletarItemActivo = -1">{{ prod.nombre | truncate:[20, '...'] }}</a></td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </ng-container>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
              <label class="hidden-sm hidden-md hidden-lg">Precio Unitario</label>
              <input type="number" [(ngModel)]="documento.items[i].precioBruto" (change)="recalcular()" placeholder="P.Unit" required="true"
                class="form-control">
            </div>
            <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
              <input type="number" [(ngModel)]="documento.items[i].descuentoPorcentaje" (change)="recalcular()" placeholder="Dcto" required="true"
                class="form-control">
            </div>
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">
              <input type="number" [(ngModel)]="documento.items[i].total" placeholder="Total" required="true" class="form-control" readonly>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
              <a (click)="eliminarItem(i)" class="btn btn-danger">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
          <br />
          <a (click)="agregarNuevoItem()" name="agregarItem" class="btn btn-success">
            <i class="fa fa-plus"></i> Agregar Item</a>
          <br />
          <br />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="this.documento.tipoDocumento != tipoDocumento.BoletaElectronica">
      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <legend>Detalle de productos/servicios</legend>
          </fieldset>
          <div class="row">
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-1">Cantidad</div>
            <div class="hidden-xs col-sm-5 col-md-3 col-lg-4">Detalle</div>
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">P Unit.</div>
            <div class="hidden-xs hidden-sm col-md-2 col-lg-2">Descuento %</div>
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">Total</div>
          </div>
          <div *ngFor="let item of documento.items; let i = index;" class="row" style="border-bottom: 1px dashed #dddddd; padding-bottom: 10px; padding-top: 10px;">
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-1">
              <label class="hidden-sm hidden-md hidden-lg">Cantidad</label>
              <input type="number" [(ngModel)]="documento.items[i].cantidad" (change)="recalcular()" placeholder="Cantidad" required="true"
                class="form-control">
            </div>
            <div class="col-xs-12 col-sm-5 col-md-3 col-lg-4">
              <label class="hidden-sm hidden-md hidden-lg">Descripción</label>
              <div class="input-group">
                <input type="text" [(ngModel)]="documento.items[i].glosa" [readonly]="documento.items[i].producto.id > 0"  (keyup)="sugerirProducto(i)" placeholder="Descripción" required="true" class="form-control">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuImpuesto">
                    Impuestos <span class="caret"></span>
                  </button>
                  <mat-menu #menuImpuesto="matMenu">
                    <div style="padding: 10px 10px 10px">
                      <span>Impuestos del producto</span>
                      <div role="separator" class="dropdown-divider"></div>
                      <span *ngFor="let item of documento.items[i].impuestos; let a = index;" class="dropdown-item">
                        <input [(ngModel)]="documento.items[i].impuestos[a].impuesto.id" type="hidden">
                        <input [(ngModel)]="documento.items[i].impuestos[a].seleccionado" (change)="recalcular()" type="checkbox"> {{documento.items[i].impuestos[a].impuesto.nombre}}
                        <br />
                      </span>
                    </div>
                  </mat-menu>
                </div>
              </div>
              <ng-container *ngIf="autoCompletarItemActivo == i">
                <ng-container *ngIf="this.productosSugeridos && this.productosSugeridos.length > 0">
                  <table class="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr *ngFor="let prod of this.productosSugeridos">
                        <td>{{ prod.codigoInterno }}</td>
                        <td><a style="cursor: pointer" (click)="seleccionarProducto(i, prod); autoCompletarItemActivo = -1">{{ prod.nombre | truncate:[20, '...'] }}</a></td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </ng-container>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
              <label class="hidden-sm hidden-md hidden-lg">Precio Unitario</label>
              <input type="number" [(ngModel)]="documento.items[i].precioUnitario" (change)="recalcular()" placeholder="P.Unit" required="true"
                class="form-control">
            </div>
            <div class="hidden-xs hidden-sm col-md-2 col-lg-2">
              <input type="number" [(ngModel)]="documento.items[i].descuentoPorcentaje" (change)="recalcular()" placeholder="Dcto" required="true"
                class="form-control">
            </div>
            <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">
              <input type="number" [(ngModel)]="documento.items[i].total" placeholder="Total" required="true" class="form-control" readonly>
            </div>
            <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
              <a (click)="eliminarItem(i)" class="btn btn-danger">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
          <br />
          <a (click)="agregarNuevoItem()" name="agregarItem" class="btn btn-success">
            <i class="fa fa-plus"></i> Agregar Item</a>
          <br />
          <br />
        </div>
      </div>
    </ng-container>


    <!-- Mas Info y totales -->
    <div class="row">
      <div class="col-md-6">
        <ng-container *ngIf="this.documento.tipoDocumento != tipoDocumento.GuiaDespachoElectronica">
          <div class="panel panel-info">
            <div class="panel-heading">
              <a class="btn btn-sm btn-warning collapseBody" (click)="esVisibleDetallesDelPago = !esVisibleDetallesDelPago">
                <i class='fa fa-minus' *ngIf="esVisibleDetallesDelPago"></i>
                <i class='fa fa-plus' *ngIf="!esVisibleDetallesDelPago"></i>
              </a>
              Detalles del Pago
            </div>
            <div *ngIf="esVisibleDetallesDelPago" class="panel-body">
              <div class="form-group">
                <label>
                  Forma de Pago
                </label>
                <select [(ngModel)]="documento.formaDePago" class="form-control">
                  <option *ngFor="let formaPago of this.formasDePago" [ngValue]="formaPago.id">{{formaPago.texto}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>
                  Medio de Pago
                </label>
                <select [(ngModel)]="documento.medioDePago" class="form-control">
                  <option *ngFor="let medioPago of this.mediosDePago" [ngValue]="medioPago.id">{{medioPago.texto}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>
                  Fecha Vencimiento
                </label>
                <div class="input-group">
                    <app-input-fecha [readOnly]="false" ngDefaultControl [(value)]="documento.fechaVencimiento" [placeholder]="'Seleccione la fecha de Vencimiento'">
                    </app-input-fecha>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.documento.tipoDocumento == tipoDocumento.GuiaDespachoElectronica">
          <div class="panel panel-info">
            <div class="panel-heading">
              <a class="btn btn-sm btn-warning collapseBody" (click)="esVisibleTransporte = !esVisibleTransporte; agregarTransporte();">
                <i class="fa fa-plus"></i>
              </a> Transporte
            </div>
            <div class="panel-body" *ngIf="esVisibleTransporte">
              <ng-container>
                <div class="form-group">
                  <label>
                    Razon social Transportista
                  </label>
                  <input type="text" [(ngModel)]="this.documento.transporte.transportista.razonSocial" placeholder="ej: Transportista S.A"
                    required="true" class="form-control">
                </div>
                <div class="form-group">
                  <label>
                    RUT Transportista
                  </label>
                  <div class="input-group">
                    <input type="number" [(ngModel)]="this.documento.transporte.transportista.rut" placeholder="RUT" required="true" class="numerico form-control">
                    <span class="input-group-addon">-</span>
                    <input type="text" [(ngModel)]="this.documento.transporte.transportista.digitoVerificador" placeholder="Digito verificador"
                      required="true" class="form-control">
                  </div>
                </div>
                <div class="form-group">
                  <label>
                    Nombre chofer
                  </label>
                  <input type="text" [(ngModel)]="this.documento.transporte.chofer.nombre" placeholder="ej: Pablo Ortiz" required="true" class="form-control">
                </div>
                <div class="form-group">
                  <label>
                    RUT Chofer
                  </label>
                  <div class="input-group">
                    <input type="number" [(ngModel)]="this.documento.transporte.chofer.rut" placeholder="RUT en formato XXXXXXXX-X" required="true"
                      class="numerico form-control">
                    <span class="input-group-addon">-</span>
                    <input type="text" [(ngModel)]="this.documento.transporte.chofer.digitoVerificador" placeholder="Digito verificador" required="true"
                      class="form-control">
                  </div>
                </div>

                <div class="form-group">
                  <label>
                    Tipo Despacho
                  </label>
                  <select class="form-control" [(ngModel)]="this.documento.transporte.tipo" convert-to-number>
                    <option *ngFor="let tipoDes of this.tiposDespacho" [ngValue]="tipoDes.id">{{tipoDes.nombre}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>
                    Indicador Traslado
                  </label>
                  <select class="form-control" [(ngModel)]="this.documento.transporte.indicador" convert-to-number>
                    <option *ngFor="let indicador of this.indicadoresTraslado" [ngValue]="indicador.id">{{indicador.nombre}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>
                    Dirección
                  </label>
                  <div class="input-group">

                    <input type="text" [(ngModel)]="this.documento.transporte.direccion" placeholder="Ingrese la direccion del destinatario"
                      required="true" class="form-control">
                    <span class="input-group-addon">,</span>
                    <select class="form-control" [(ngModel)]="documento.transporte.comuna.id">
                      <option *ngFor="let comuna of comunas" value="{{comuna.id}}">{{comuna.nombre}}</option>
                    </select>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <div class="panel panel-info">
          <div class="panel-heading">
            <a class="btn btn-sm btn-warning collapseBody" (click)="esVisibleDocumentosReferenciados = !esVisibleDocumentosReferenciados">
              <i class='fa fa-minus' *ngIf="esVisibleDocumentosReferenciados"></i>
              <i class='fa fa-plus' *ngIf="!esVisibleDocumentosReferenciados"></i>
            </a>
            Documentos Referenciados ({{documento.referencias.length }})
          </div>
          <div *ngIf="esVisibleDocumentosReferenciados" class="panel-body">
            <p *ngIf="documento.referencias.length < 1">No hay documentos en las referencias</p>
            <ul class="list-group" id="ListaReferencias">
              <li class="list-group-item" *ngFor="let docref of documento.referencias; let i = index">
                {{ 'DOCUMENTOS.TipoDocumento_' + docref.documento.tipoDocumento | translate }}, Número:
                <strong>{{docref.documento.numero }}</strong>
                <br />
                <ng-container *ngIf="docref.razon && docref.razon != ''">
                  <span>Motivo:</span>{{docref.razon}}
                  <br />
                </ng-container>
                <span>Código Referencia:</span> {{ 'DOCUMENTOS.CodigoReferencia_' + docref.codigoReferencia | translate }}
                <br />
                <span>Fecha:</span>{{docref.fecha | date : "dd/MM/yyyy"}}
                <br />
                <a (click)="eliminarDocumentoReferenciado(i)">Eliminar</a>
              </li>
            </ul>
            <a class="btn btn-sm btn-warning" *ngIf="!esVisibleAgregarReferencia" (click)="esVisibleAgregarReferencia = true">
              <i class="fa fa-plus"></i> Agregar Documento</a>
            <ng-container *ngIf="esVisibleAgregarReferencia">
              <fieldset>
                <legend>Agregar Referencia</legend>
                <div class="form-group">
                  <label>
                    Tipo
                  </label>
                  <select class="form-control" [(ngModel)]="this.documentoReferenciadoTemporal.tipoDocumento">
                    <option *ngFor="let tipoDoc of this.tiposDocumentos" [ngValue]="tipoDoc.id">{{tipoDoc.nombre}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>
                    Número
                  </label>
                  <input type="number" [(ngModel)]="this.documentoReferenciadoTemporal.numero" class="form-control" />
                </div>

                <div class="form-group">
                  <label>
                    Código Referencia
                  </label>
                  <select class="form-control" [(ngModel)]="this.documentoReferenciadoTemporal.codigoReferencia">
                    <option *ngFor="let codigo of this.codigosReferenciaDocumentos" [ngValue]="codigo.id">{{codigo.texto}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>
                    Motivo
                  </label>
                  <input type="text" value="" class="form-control" [(ngModel)]="this.documentoReferenciadoTemporal.motivo" />
                </div>

                <div class="form-group">
                  <label>
                    Fecha
                  </label>
                  <app-input-fecha [readOnly]="false" ngDefaultControl [(value)]="this.documentoReferenciadoTemporal.fecha" [placeholder]="'Seleccione la fecha de Emisión'">
                  </app-input-fecha>
                </div>

                <a (click)="agregarNuevoDocumentoReferenciado()" name="agregarItem" class="btn btn-success">
                  <i class="fa fa-plus"></i> Agregar Referencia</a>
                <a class="btn btn-warning" (click)="esVisibleAgregarReferencia = false">
                  <i class="fa fa-times"></i> Cancelar</a>
              </fieldset>

            </ng-container>
          </div>
        </div>

        <div class="panel panel-info">
          <div class="panel-heading">
            <a class="btn btn-sm btn-warning collapseBody" (click)="esVisibleObservaciones = !esVisibleObservaciones">
              <i class='fa fa-minus' *ngIf="esVisibleObservaciones"></i>
              <i class='fa fa-plus' *ngIf="!esVisibleObservaciones"></i>
            </a>
            Observaciones
          </div>
          <div *ngIf="esVisibleObservaciones" class="panel-body">
            <div class="form-group">
              <textarea placeholder="Observaciones" [(ngModel)]="this.documento.observaciones" class="form-control"></textarea>
            </div>
          </div>
        </div>

        <br />
        <br />
      </div>

      <ng-container *ngIf="this.documento.tipoDocumento == tipoDocumento.BoletaElectronica">
        <div class="col-md-6">
          <fieldset>
            <legend>Totales</legend>

            <div class="form-group">
              <label>
                Subtotal
              </label>
              <input type="number" readonly [(ngModel)]="documento.subtotal" placeholder="Subtotal" required="true" class="numero numerico form-control"
              />
            </div>

            <div class="form-group">
              <label>
                Descuento %
              </label>
              <div class="input-group">
                <input type="number" [(ngModel)]="documento.descuentoPorcentaje" (change)="recalcular()" placeholder="Descuento en %" required="true" class="numero numerico form-control"
                />
                <span class="input-group-addon">(</span>
                <input type="number" [(ngModel)]="documento.descuentoMonto" placeholder="Descuento en $" required="true" class="numero numerico form-control"
                  readonly="true" />
                <span class="input-group-addon">)</span>
              </div>
            </div>

            <div class="form-group">
              <label>
                Neto
              </label>
              <input type="number" readonly [(ngModel)]="documento.neto" placeholder="Neto" required="true" class="numero numerico form-control" />
            </div>

            <div class="form-group">
              <label>
                Exento
              </label>
              <input type="number" readonly [(ngModel)]="documento.exento" placeholder="Exento" required="true" class="numero numerico form-control"
              />
            </div>

            <ng-container *ngFor="let impuestoDocumento of documento.impuestos; let i = index;">
              <div class="form-group">
                <label>
                  {{ impuestoDocumento.impuesto.nombre }}
                </label>
                <div class="form-group">
                  <input type="number"  readonly [(ngModel)]="impuestoDocumento.total" placeholder="{{ impuestoDocumento.impuesto.nombre }}" required="true"
                    class="numero numerico form-control" />
                  <ng-container *ngIf="this.obtenerOpcionesImpuesto(impuestoDocumento.impuesto).length > 0">
                    <select class="form-control">
                      <option *ngFor="let opcion of this.obtenerOpcionesImpuesto(impuestoDocumento.impuesto)">{{ opcion.nombre }}</option>
                    </select>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div class="form-group">
              <label>
                Total
              </label>
              <input type="text" readonly [(ngModel)]="documento.total" placeholder="Total" required="true" class="numero numerico form-control"
              />
            </div>

          </fieldset>
        </div>
      </ng-container>

      <ng-container *ngIf="this.documento.tipoDocumento != tipoDocumento.BoletaElectronica">
        <div class="col-md-6">
          <fieldset>
            <legend>Totales</legend>

            <div class="form-group">
              <label>
                Subtotal
              </label>
              <input type="number" readonly [(ngModel)]="documento.subtotal" placeholder="Subtotal" required="true" class="numero numerico form-control"
              />
            </div>

            <div class="form-group">
              <label>
                Descuento %
              </label>
              <div class="input-group">
                <input type="number" [(ngModel)]="documento.descuentoPorcentaje" (change)="recalcular()" placeholder="Descuento en %" required="true" class="numero numerico form-control"
                />
                <span class="input-group-addon">(</span>
                <input type="number" [(ngModel)]="documento.descuentoMonto" placeholder="Descuento en $" required="true" class="numero numerico form-control"
                  readonly="true" />
                <span class="input-group-addon">)</span>
              </div>
            </div>

            <div class="form-group">
              <label>
                Neto
              </label>
              <input type="number" readonly [(ngModel)]="documento.neto" placeholder="Neto" required="true" class="numero numerico form-control" />
            </div>

            <div class="form-group">
              <label>
                Exento
              </label>
              <input type="number" readonly [(ngModel)]="documento.exento" placeholder="Exento" required="true" class="numero numerico form-control"
              />
            </div>

            <ng-container *ngFor="let impuestoDocumento of documento.impuestos; let i = index;">
              <div class="form-group">
                <label>
                  {{ impuestoDocumento.impuesto.nombre }}
                </label>
                <div class="form-group">
                  <input type="number"  readonly [(ngModel)]="impuestoDocumento.total" placeholder="{{ impuestoDocumento.impuesto.nombre }}" required="true"
                    class="numero numerico form-control" />
                  <ng-container *ngIf="this.obtenerOpcionesImpuesto(impuestoDocumento.impuesto).length > 0">
                    <select class="form-control">
                      <option *ngFor="let opcion of this.obtenerOpcionesImpuesto(impuestoDocumento.impuesto)">{{ opcion.nombre }}</option>
                    </select>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div class="form-group">
              <label>
                Total
              </label>
              <input type="text" readonly [(ngModel)]="documento.total" placeholder="Total" required="true" class="numero numerico form-control"
              />
            </div>

          </fieldset>
        </div>
      </ng-container>

    </div>
  </div>
  <div class="panel-footer">
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <button class="btn btn-block btn-success" value="Crear" (click)="guardar()" [disabled]="enviando">
          <ng-container *ngIf="enviando">
            <i class="fa fa-circle-notch fa-spin"></i> Cargando
          </ng-container>
          <ng-container *ngIf="!enviando">
            <i class="fa fa-save"></i> Guardar
          </ng-container>
        </button>
      </div>
      <div class="col-xs-6 col-md-6" style="text-align: right">
        <a class="btn btn-warning" onclick="javascript:history.go(-1)">
          <i class="fa fa-arrow-left"></i> Regresar</a>
      </div>
    </div>
  </div>
</div>
