import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class RecepcionesService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getRecepciones(pagina: number, largoPagina: number) : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(this.urlApi + `RecepcionesApi/Todas/?pagina=${pagina}&cantResultados=${largoPagina}`, { headers: headers }).pipe(map((res: Response) => res));
    }

}