<ng-container *ngIf="this.estaCargando">
  <i class="fa fa-spin fa-spinner"></i> Cargando
  <br />
</ng-container>

<ng-container *ngIf="libro && !this.estaCargando">
  <h2>Libro de {{ libro.tipoLibro === tiposLibro.Compras ? "Compras" : libro.tipoLibro === tiposLibro.Ventas ? "Ventas"
    : libro.tipoLibro === tiposLibro.Boletas ? "Boletas" : "Guias" }}</h2>
  <h4>Periodo {{ this.libro.periodo.mes }} / {{ this.libro.periodo.anio }} ({{ this.libro.periodo.estaAbierto ?
    "Abierto" :
    "Cerrado" }})</h4>
  <div class="row">
    <div class="col-xs-12 col-md-2">
      <div class="input-group">
        <div class="input-group-btn">
          <a class="btn btn-success" (click)="mesAnterior()">
            <i class="fa fa-arrow-left"></i>
          </a>
          <a class="btn btn-primary" data-toggle="modal" (click)="abrirModalCambiarMes()">
            <i class="fa fa-level-up"></i> Cambiar
          </a>
          <a class="btn btn-success" (click)="mesSiguiente()">
            <i class="fa fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-2 pull-right">
      <ng-container *ngIf="this.servicioSesiones.sesionContieneRol('Administrador')">
        <button type="button" class="btn btn-primary pull-right" mat-button [matMenuTriggerFor]="menuAcciones">
          Acciones <span class="caret"></span>
        </button>
        <mat-menu #menuAcciones="matMenu">
          <ng-container *ngIf="libro.tipoLibro === tiposLibro.Boletas">
            <button mat-menu-item (click)="descargarXmlLibroBoletas()"><i class="fa fa-external-link-alt"></i> Descargar
              Xml Libro</button>
          </ng-container>
          <ng-container *ngIf="libro.tipoLibro !== tiposLibro.Boletas">
            <button mat-menu-item (click)="enviar()"><i class="fa fa-external-link-alt"></i> Enviar Libro</button>
          </ng-container>
          <a mat-menu-item target="_blank" [routerLink]="['/wrapperless/libros/libro-imprimir/', anio, mes, tipoLibro]">
            <i class="fa fa-print"></i> Versión Imprimible
          </a>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="!this.servicioSesiones.sesionContieneRol('Administrador')">
        <div class="input-group">
          <div class="input-group-btn">
            <a class="btn btn-success pull-right" target="_blank"
              [routerLink]="['/wrapperless/libros/libro-imprimir/', anio, mes, tipoLibro]">
              <i class="fa fa-print"></i> Versión Imprimible
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <hr />
  <ng-container *ngFor="let docPorTipo of this.documentosPorTipos">
    <ng-container *ngIf="docPorTipo.respuestaPendiente">
      <i class="fa fa-spin fa-spinner"></i> Cargando
      <br />
    </ng-container>
    <ng-container
      *ngIf="docPorTipo.documentos && ((docPorTipo.documentos.length == 0 && docPorTipo.filtro != '') || docPorTipo.documentos.length > 0)">
      <h4>{{ 'DOCUMENTOS.TipoDocumento_' + docPorTipo.tipo | translate }}</h4>
      <div>
        <div class="row">
          <div class="col-sm-6">
            ({{ docPorTipo.totalDocumentos }} documentos) &nbsp;
          </div>
          <div class="col-sm-6">
            <div class="dataTables_filter">
              <div class="row">
                <div class="col-sm-6" style="text-align: right">
                  <span>Filtrar: </span>
                </div>
                <div class="col-sm-6" style="margin-bottom: 5px;">
                  <input class="form-control input-sm" (keyup)="filtroPorDocumento(docPorTipo.tipo, $event)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-bordered table-hover table-striped tablaFiltrada">
        <thead>
          <tr>
            <th class="numero">Folio</th>
            <th class="numero">Rut</th>
            <th class="hidden-xs hidden-sm">
              {{ libro.tipoLibro === tiposLibro.Compras ? "Emisor" : "Destinatario" }}</th>
            <th class="hidden-xs hidden-sm">Fecha Emision</th>
            <th class="hidden-xs hidden-sm numero">Neto</th>
            <th class="numero">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doc of docPorTipo.documentos">
            <td class="numero">
              <a [routerLink]="['/erp/documentos/documento-detalle', doc.id]">
                <ng-container *ngIf="doc.tipoDocumento != tipoDocumento.Boleta">
                  {{doc.numero | number:'':'es-CL'}}</ng-container>
                <ng-container *ngIf="doc.tipoDocumento === tipoDocumento.Boleta">
                  {{doc.desde | number:'':'es-CL'}} - {{ doc.hasta | number:'':'es-CL'}}
                </ng-container>
              </a>
            </td>
            <ng-container *ngIf="doc.tipoDocumento === tipoDocumento.Boleta">
              <td class="numero">-</td>
              <td class="hidden-xs hidden-sm">-</td>
            </ng-container>
            <ng-container *ngIf="doc.tipoDocumento != tipoDocumento.Boleta">
              <td class="numero">
                <ng-container *ngIf="libro.tipoLibro == tiposLibro.Compras">
                  {{doc.emisor.rut | number:'':'es-CL'}} - {{doc.emisor.digitoVerificador}}
                </ng-container>
                <ng-container *ngIf="libro.tipoLibro != tiposLibro.Compras">
                  <ng-container *ngIf="doc.tipoDocumento != tipoDocumento.BoletaElectronica">
                    {{doc.destinatario.rut | number:'':'es-CL'}} -
                    {{doc.destinatario.digitoVerificador}}
                  </ng-container>
                  <ng-container *ngIf="doc.tipoDocumento == tipoDocumento.BoletaElectronica">
                    <ng-container *ngIf="doc.destinatario.rut == 66666666 || doc.destinatario.rut == 0">
                      Sin Destinatario
                    </ng-container>
                    <ng-container *ngIf="!(doc.destinatario.rut == 66666666 || doc.destinatario.rut == 0)">
                      {{doc.destinatario.rut | number:'':'es-CL'}} -
                      {{doc.destinatario.digitoVerificador}}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </td>
              <td class="hidden-xs hidden-sm">
                <ng-container *ngIf="libro.tipoLibro == tiposLibro.Compras">
                  {{doc.emisor.razonSocial | slice:0:26}}
                </ng-container>
                <ng-container *ngIf="libro.tipoLibro != tiposLibro.Compras">
                  {{doc.destinatario.razonSocial | slice:0:26}}
                </ng-container>
              </td>
            </ng-container>
            <td class="hidden-xs hidden-sm">{{doc.fechaEmision | date : "dd/MM/yyyy" }}</td>
            <td class="hidden-xs hidden-sm numero">{{ doc.neto | number:'':'es-CL' }}</td>
            <td class="numero">{{ doc.total | number:'':'es-CL' }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-sm-6">
          <div class="dataTables_info">Mostrando {{ ((docPorTipo.pagina - 1) * this.largoPagina) + 1 }} al
            {{((docPorTipo.pagina - 1) * this.largoPagina)
            + this.largoPagina }} de {{ docPorTipo.totalDocumentos | number:'':'es-CL' }} documentos</div>
        </div>
        <div class="col-sm-6">
          <ul class="pagination pull-right">
            <li class="paginate_button previous">
              <a (click)="anteriorPagina(docPorTipo.tipo)">Anterior</a>
            </li>
            <li class="paginate_button next" aria-controls="dataTables-example" tabindex="0">
              <a (click)="siguientePagina(docPorTipo.tipo)">Siguiente</a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </ng-container>
  </ng-container>



  <ng-container>
    <div class="col-xs-0 col-md-6">
      &nbsp;
    </div>
    <div class="col-xs-12 col-md-6">
      <fieldset>
        <legend>Totales Libro</legend>
        <ng-container *ngIf="!this.totaleslibro">
          <i class="fa fa-spin fa-spinner"></i> Cargando
          <br />
        </ng-container>

        <ng-container *ngIf="this.totaleslibro">
          <div class="col-xs-6 col-md-6">
            <label class="numero">Neto</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h3 class="numero">
              {{ this.totaleslibro.neto | number:'':'es-CL' }}
            </h3>
          </div>

          <div class="col-xs-6 col-md-6">
            <label class="numero">Exento</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h3 class="numero">{{ this.totaleslibro.exento | number:'':'es-CL' }}</h3>
          </div>

          <ng-container *ngFor="let impuesto of this.totaleslibro.impuestos">
            <ng-container *ngIf="impuesto.total != 0">
              <div class="col-xs-6 col-md-6">
                <label class="numero">{{ impuesto.nombre }}</label>
              </div>
              <div class="col-xs-6 col-md-6">
                <h3 class="numero">{{ impuesto.total | number:'':'es-CL' }}</h3>
              </div>
            </ng-container>
          </ng-container>

          <div class="col-xs-6 col-md-6">
            <label class="numero">Total</label>
          </div>
          <div class="col-xs-6 col-md-6">
            <h3 class="numero">{{ this.totaleslibro.total | number:'':'es-CL' }}</h3>
          </div>
        </ng-container>
      </fieldset>
    </div>
  </ng-container>
</ng-container>
