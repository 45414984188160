<!DOCTYPE html>
<html>

<head>
    <title>Test title</title>
    <style type="text/css">
        body {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 10pt;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {}

        table {
            border-collapse: collapse;
            padding: 10px 10px 10px 10px;
        }

        table thead {}

        table thead tr {
            border-bottom: 2px solid #808080;
            font-weight: bold;
        }

        table thead tr th {
            padding: 2px 2px 2px 2px;
            text-align: left;
            font-weight: inherit;
        }

        table thead tr th.numero {
            text-align: right;
            font-weight: inherit;
        }

        table tbody {}

        table tbody tr {
            border-bottom: 1px solid #a1a1a1;
        }

        table tbody tr.totales {
            margin-top: 10px;
            border-top: 2px solid #808080;
            border-bottom: 0px;
            font-weight: bold;
        }

        table tbody tr td {
            padding: 2px 2px 2px 2px;
            font-weight: inherit;
        }

        table tbody tr td.numero {
            text-align: right;
            font-weight: inherit;
        }
    </style>
</head>

<body>
    <ng-container *ngIf="libro">
        <h1>{{titulo()}}: {{libro.periodo.empresa.razonSocial}}</h1>
        <p>Periodo {{libro.periodo.mes}} / {{libro.periodo.anio}}</p>
        <ng-container *ngIf="documentosPorTipos">
            <ng-container *ngFor="let docPorTipo of this.documentosPorTipos">
                <ng-container *ngIf="docPorTipo.respuestaPendiente">
                    <i class="fa fa-spin fa-spinner"></i> Cargando
                    <br />
                </ng-container>
                <ng-container *ngIf="docPorTipo.documentos && ((docPorTipo.documentos.length == 0 && docPorTipo.filtro != '') || docPorTipo.documentos.length > 0)" >
                    <h3>{{ 'DOCUMENTOS.TipoDocumento_' + docPorTipo.tipo | translate }}</h3>
                    <h5>{{ docPorTipo.documentos.length }} documentos</h5>
                    <table style="width: 100%">
                        <thead>
                            <tr>
                                <th># Doc</th>
                                <th>Tipo</th>
                                <th>Fecha Emision</th>
                                <th>Rut</th>
                                <th>{{libro.tipoLibro === 1 ? 'Emisor' : 'Destinatario'}}</th>
                                <th class="numero">Neto</th>
                                <th class="numero">Exento</th>
                                <ng-container *ngFor="let impuesto of libro.periodo.empresa.impuestos">
                                    <th *ngIf="impuesto.habilitado" class="numero">{{impuesto.nombre}}</th>
                                </ng-container>
                                <th class="numero">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let doc of docPorTipo.documentos">
                                <tr>
                                    <td>
                                        <ng-container *ngIf="doc.tipoDocumento !== 35">
                                            {{doc.numero | number:'':'es-CL'}}
                                        </ng-container>
                                        <ng-container *ngIf="doc.tipoDocumento === 35">
                                            {{doc.desde | number:'':'es-CL'}} - {{ doc.hasta | number:'':'es-CL'}}
                                        </ng-container>
                                    </td>
                                    <td>{{ 'DOCUMENTOS.TipoDocumento_' + doc.tipoDocumento | translate }}</td>
                                    <td>{{doc.fechaEmision | date : "dd/MM/yyyy" }}</td>
                                    <ng-container *ngIf="doc.tipoDocumento !== 35">
                                        <td>
                                            <ng-container *ngIf="libro.tipoLibro === 1">
                                                {{doc.emisor.rut | number:'':'es-CL'}} - {{doc.emisor.digitoVerificador}}
                                            </ng-container>
                                            <ng-container *ngIf="libro.tipoLibro !== 1">
                                                {{doc.destinatario.rut | number:'':'es-CL'}} -
                                                {{doc.destinatario.digitoVerificador}}
                                            </ng-container>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="libro.tipoLibro === 1">
                                                {{doc.emisor.razonSocial}}
                                            </ng-container>
                                            <ng-container *ngIf="libro.tipoLibro !== 1">
                                                {{doc.destinatario.razonSocial}}
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="doc.tipoDocumento === 35">
                                        <td>-</td>
                                        <td>-</td>
                                    </ng-container>
                                    <td class="numero">{{ doc.neto | number:'':'es-CL' }}</td>
                                    <td class="numero">{{ doc.exento | number:'':'es-CL' }}</td>
                                    <ng-container *ngFor="let impuesto of libro.periodo.empresa.impuestos">
                                        <ng-container *ngIf="impuesto.habilitado">
                                            <td *ngIf="ObtenerImpuesto(doc, impuesto)" class="numero">{{ObtenerImpuesto(doc, impuesto).total | number:'':'es-CL'}}</td>
                                            <td *ngIf="!ObtenerImpuesto(doc, impuesto)" class="numero">0</td>
                                        </ng-container>
                                    </ng-container>
                                    <td class="numero">{{ doc.total | number:'':'es-CL' }}</td>
                                </tr>
                            </ng-container>
                            <tr class="totales">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Totales</td>
                                <td class="numero">{{SumatoriaNetos(docPorTipo.documentos) | number:'':'es-CL'}}</td>
                                <td class="numero">{{SumatoriaExentos(docPorTipo.documentos) | number:'':'es-CL'}}</td>
                                <ng-container *ngFor="let impuesto of libro.periodo.empresa.impuestos">
                                    <ng-container *ngIf="impuesto.habilitado">
                                        <td class="numero">{{SumatoriaImpuestos(docPorTipo.documentos, impuesto) | number:'':'es-CL'}}</td>
                                    </ng-container>
                                </ng-container>
                                <td class="numero">{{SumatoriaTotales(docPorTipo.documentos) | number:'':'es-CL'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>

            <h2>Totales Libro</h2>
            <ng-container *ngIf="!totalesLibro">
                <i class="fa fa-spin fa-spinner"></i> Cargando
                <br />
            </ng-container>
            <ng-container *ngIf="totalesLibro">
                <table style="width: 400px">
                    <tr>
                        <td>Neto</td>
                        <td><strong>
                            {{totalesLibro.neto | number:'':'es-CL'}}
                        </strong></td>
                    </tr>
                    <tr>
                        <td>Exento</td>
                        <td><strong>
                            {{totalesLibro.exento | number:'':'es-CL'}}
                        </strong></td>
                    </tr>
                    
                    <tr *ngFor="let impuesto of totalesLibro.impuestos">
                        <td>{{impuesto.nombre}}</td>
                        <td><strong>{{impuesto.total | number:'':'es-CL'}}</strong></td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td><strong>{{totalesLibro.total | number:'':'es-CL'}}</strong></td>
                    </tr>
                </table>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!documentosPorTipos">
            <p>No hay documentos en este libro</p>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!libro">
        <h3>Periodo no encontrado</h3>
        <p>No existe un periodo contable asociado al libro que intentas ver.</p>
    </ng-container>
</body>

</html>