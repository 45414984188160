import { Component, OnInit } from '@angular/core';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { LibrosService } from '../../servicios/servicio.libro';

@Component({
  selector: 'app-libro-sinperiodo',
  templateUrl: './libro-sinperiodo.component.html',
  styleUrls: ['./libro-sinperiodo.component.css']
})
export class LibroSinPeriodoComponent implements OnInit {
  public documentos: any;
  public totalDocumentos: number;
  public pagina: number;
  public largoPagina: number;
  public filtro: string;
  public tipoDocumento = TipoDocumento;

  constructor(private servicioLibros: LibrosService) { }

  ngOnInit() {
    this.filtro = '';
    this.pagina = 1;
    this.largoPagina = 10;
    this.recargar();
  }

  recargar() : void {
    this.servicioLibros.getLibroSinPeriodo(this.filtro, this.pagina, this.largoPagina).subscribe(respuesta => {
      console.log(respuesta);
      this.totalDocumentos = respuesta.datos.totalElementos;
      this.documentos = respuesta.datos.datos;
    });
  }

  siguientePagina() : void {
    this.pagina++;
    this.recargar();
  }

  anteriorPagina() : void {
    this.pagina--;
    this.recargar();
  }

  filtrar(event: any): void {
    this.filtro = event.target.value;
    this.recargar();
  }
}
