<div class="row">
    <div class="col-xs-12">
        <h1 mat-dialog-title>Respuesta del SII</h1>
        <div mat-dialog-content>
            <p>
                <ng-container *ngIf="this.respuestaEnvioDte">
                    <dl>
                        <dt>Respuesta</dt>
                        <dd>{{ 'DOCUMENTOS.EstadoEnvioSII_' + this.respuestaEnvioDte.status | translate }}</dd>
                    </dl>
                    <dl>
                        <dt>Persona que envió</dt>
                        <dd>{{ this.respuestaEnvioDte.rutEnvia }}</dd>
                    </dl>
                    <dl>
                        <dt>Empresa que envió</dt>
                        <dd>{{ this.respuestaEnvioDte.rutEmpresa }}</dd>
                    </dl>
                    <dl>
                        <dt>Fecha</dt>
                        <dd>{{ this.respuestaEnvioDte.timestamp }}</dd>
                    </dl>
                    <dl>
                        <dt>TrackId</dt>
                        <dd>{{ this.respuestaEnvioDte.trackId }}</dd>
                    </dl>
                </ng-container>
                <ng-container *ngIf="!this.respuestaEnvioDte">
                    El documento aun no se envia al SII
                </ng-container>
            </p>
        </div>
        <div mat-dialog-actions>
            <button mat-button class="btn btn-primary" [mat-dialog-close]="respuestaEnvioDte" cdkFocusInitial>Listo</button>
        </div>
    </div>
</div>