import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { DocumentosService } from '../../servicios/servicio.documentos';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';

@Component({
  selector: 'app-envio-crear',
  templateUrl: './envio-crear.component.html',
  styleUrls: ['./envio-crear.component.css']
})
export class EnvioCrearComponent implements OnInit {
  documentoSeleccionado: any;
  documentos: Array<any> = [];
  public tipoDocumento = TipoDocumento;

  constructor(
    private servicioDocumentos: DocumentosService, 
    private servicioNotificaciones: NotificacionesService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  seleccionaDocumento() : void{
    if (this.documentoSeleccionado && this.documentoSeleccionado != "")
    {
      this.servicioDocumentos.getDocumento(this.documentoSeleccionado).subscribe(response => {
        if (response.esCorrecto)
        {
          var documento = response.Datos;
          console.log(documento);
          this.documentos.push(documento);
        }
        else
        {
          var titulo = "No se pudo encontrar el documento";
          var mensaje = "<p>No hay un documento con ID: " + this.documentoSeleccionado + ".</p>";
          var notificacion = new Notificacion(titulo, mensaje, "Error");
          this.servicioNotificaciones.agregarNotificacion(notificacion);
        }
        this.documentoSeleccionado = "";
      });
    }
    else
    {
      var titulo = "Faltan datos";
      var mensaje = "<p>Escriba primero el ID de un documento.</p>";
      var notificacion = new Notificacion(titulo, mensaje, "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
    }
  }

  enviar() : void 
  {
    this.servicioDocumentos.emitirVarios(this.documentos).subscribe(resultado => {
      console.log(resultado);
    });
  }

}
