<ng-container *ngIf="libro">
    <h2>Guías de despacho: 
        <ng-container *ngIf="this.documentos && this.documentos.datos && this.documentos.datos.length > 0">
            {{ this.documentos.datos[0].destinatario.razonSocial }}
        </ng-container>
        ({{ rut }} - {{ dv(rut) }})
    </h2>
    <div class="input-group">
        <div class="input-group-btn">
            <a class="btn btn-success" (click)="volverLibro()">
                <i class="fa fa-arrow-left"></i> Volver a guías ({{ this.libro.periodo.mes }}/{{ this.libro.periodo.anio }})
            </a>
        </div>
    </div>

    <hr />
    <ng-container *ngIf="this.documentos && this.documentos.datos && this.documentos.datos.length > 0">
        <div>
            <div class="row">
                <div class="col-sm-6">
                    ({{ this.documentos.totalElementos }} documentos) &nbsp;
                </div>
                <div class="col-sm-6">
                    
                </div>
            </div>
        </div>

        <table class="table table-bordered table-hover table-striped tablaFiltrada">
            <thead>
                <tr>
                    <th></th>
                    <th class="numero">Folio</th>
                    <th class="numero">Rut</th>
                    <th class="hidden-xs hidden-sm">Destinatario</th>
                    <th class="hidden-xs hidden-sm">Fecha Emision</th>
                    <th class="hidden-xs hidden-sm numero">Neto</th>
                    <th class="numero">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let doc of this.documentos.datos">
                    <td><input type="checkbox" [checked]="estaSeleccionado(doc)" (change)="cambiarSeleccion(doc)"/></td>
                    <td class="numero">
                        <a [routerLink]="['/erp/documentos/documento-detalle', doc.id]">
                            {{doc.numero | number:'':'es-CL'}}
                        </a>
                    </td>
                    <td class="numero">
                        <ng-container *ngIf="libro.tipoLibro == tiposLibro.Compras">
                            {{doc.emisor.rut | number:'':'es-CL'}} - {{doc.emisor.digitoVerificador}}
                        </ng-container>
                        <ng-container *ngIf="libro.tipoLibro != tiposLibro.Compras">
                            {{doc.destinatario.rut | number:'':'es-CL'}} -
                            {{doc.destinatario.digitoVerificador}}
                        </ng-container>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <ng-container *ngIf="libro.tipoLibro == tiposLibro.Compras">
                            {{doc.emisor.razonSocial | slice:0:26}}
                        </ng-container>
                        <ng-container *ngIf="libro.tipoLibro != tiposLibro.Compras">
                            {{doc.destinatario.razonSocial | slice:0:26}}
                        </ng-container>
                    </td>
                    <td class="hidden-xs hidden-sm">{{doc.fechaEmision | date : "dd/MM/yyyy" }}</td>
                    <td class="hidden-xs hidden-sm numero">{{ doc.neto | number:'':'es-CL' }}</td>
                    <td class="numero">{{ doc.total | number:'':'es-CL' }}</td>
                </tr>
            </tbody>
        </table>

        <div class="row">
            <div *ngIf="this.guiasSeleccionadas.length > 0" class="col-sm-12">
                <a class="btn btn-primary" (click)=" abrirModalFacturar()">Facturar ({{ this.guiasSeleccionadas.length }} guías)</a> &nbsp;
                <br />&nbsp;
            </div>
            <div class="col-sm-6">
                <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina)
                    + this.largoPagina }} de {{ this.totalElementos | number:'':'es-CL' }} documentos</div>
            </div>
            <div class="col-sm-6">
                <ul class="pagination pull-right">
                    <li class="paginate_button previous">
                        <a style="cursor: pointer;" (click)="anteriorPagina()">Anterior</a>
                    </li>
                    <li class="paginate_button next" aria-controls="dataTables-example" tabindex="0">
                        <a style="cursor: pointer;" (click)="siguientePagina()">Siguiente</a>
                    </li>
                </ul>
            </div>
        </div>
        <hr />
    </ng-container>
   
</ng-container>