import { Component, OnInit } from '@angular/core';
import { ClientesService } from '../../servicios/servicio.clientes';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumeracionesService } from '../../servicios/servicio.enumeraciones';
import { Notificacion } from '../../core/models/notificacion';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';

@Component({
  selector: 'app-cliente-modificar',
  templateUrl: './cliente-modificar.component.html',
  styleUrls: ['./cliente-modificar.component.css']
})
export class ClienteModificarComponent implements OnInit {
  public cliente: any;
  public comunas: any[];
  public rut: any;
  public accion: any;

  constructor(
    private servicioClientes: ClientesService,
    private route: ActivatedRoute,
    private router: Router,
    private servicioEnumeraciones: EnumeracionesService,
    private servicioNotificaciones: NotificacionesService) { }

  ngOnInit() {
    this.comunas = this.servicioEnumeraciones.getComunas();
    this.route.params.subscribe(params => {
      this.rut = params['rut'];
      this.accion = params['accion'];
      console.log(this.rut, this.accion);
      if (this.accion == "Modificar") {
        this.servicioClientes.getCliente(this.rut).subscribe(response => {
          this.cliente = response.datos;
          console.log(this.cliente);
        });
      }
      else {
        this.cliente = { comuna: { id: "13-1-01" }};
      }
    });
  }

  crear(): void {
    this.servicioClientes.crearCliente(this.cliente).subscribe(res => {
      if (res.esCorrecto) {
        var titulo = "Cliente creado con éxito";
        var mensaje = "<p>El cliente " + this.cliente.razonSocial + " ha sido creado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['/erp/clientes/cliente-detalle/', this.cliente.rut]);
      }
      else {
        var titulo = "Hubieron problemas creando el cliente";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }

  guardar(): void {
    this.servicioClientes.modificarCliente(this.cliente).subscribe(res => {
      if (res.esCorrecto) {
        var titulo = "Cliente modificado con éxito";
        var mensaje = "<p>El cliente " + this.cliente.razonSocial + " ha sido modificado satisfactoriamente.</p>";
        var notificacion = new Notificacion(titulo, mensaje);
        this.servicioNotificaciones.agregarNotificacion(notificacion);
        this.router.navigate(['/erp/clientes/cliente-detalle/', this.cliente.rut]);
      }
      else {
        var titulo = "Hubieron problemas modificando el cliente";
        var mensaje = "<p><ul>";
        res.mensajes.forEach(m => {
          mensaje = mensaje + "<li>" + m + "</li>";
        });
        mensaje = mensaje + "</ul></p>";
        var notificacion = new Notificacion(titulo, mensaje, "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }
}
