<div class="panel panel-default">
    <div class="panel-heading">
        <h4>ViewBag.Titulo</h4>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-12">
                <fieldset>
                    <legend>Información de la Empresa</legend>
                    <ng-container *ngIf="this.accion == 'Crear'">
                        <div class="form-group">
                            <label>
                                RUT
                            </label>
                            <div class="input-group">
                                <input type="text" [(ngModel)]="this.empresa.RUT" placeholder="Ingrese el rut" required="true" class="numerico form-control" />
                                <span class="input-group-addon">-</span>
                                <input type="text" [(ngModel)]="this.empresa.DigitoVerificador" placeholder="Ingrese el digito verificador del rut" required="true" class="form-control" />
                            </div>
                        </div>
                    </ng-container>
                    <div class="form-group">
                        <label>
                            Nombre
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.RazonSocial" placeholder="Ingrese la razón social del contribuyente" required="true" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>
                            Alias
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.Alias" placeholder="Ingrese el nombre de fantasía del contribuyente (max 20 caracteres)" required="true" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>
                            Giro
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.Alias" placeholder="Ingrese el giro del contribuyente" required="true" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>
                            Representante
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.Representante" placeholder="Ingrese el nombre del representante del contribuyente" required="true" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>
                            Dirección
                        </label>
                        <div class="input-group">
                            <input [(ngModel)]="this.empresa.Direccion" placeholder="Ingrese la direccion de la empresa" required="true" class="form-control">
                            <span class="input-group-addon">,</span>
                            <select class="form-control" [(ngModel)]="this.empresa.Comuna.ID">
                                <option *ngFor="let comuna of comunas" value="{{comuna.id}}">{{comuna.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Información para el membrete de los documentos</legend>
                    
                    <div class="form-group">
                        <label>
                            Resolución
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.Resolucion" placeholder="Ingrese la resolucion del contribuyente" required="true" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>
                            Factor IVA Uso Comun
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.FactorIvaUsoComun" placeholder="Ingrese el factor para el IVA de uso comun" required="true" class="form-control" />
                    </div>
                    
                    <div class="form-group">
                        <label>
                            Fecha Resolución
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.FactorIvaUsoComun" placeholder="(ej 23-12-2014) Ingrese las fecha de resolución del contribuyente" required="true" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>
                            Código de Actividad Económica
                        </label>
                        <input type="text" [(ngModel)]="this.empresa.CodActividadEconomica" placeholder="(ej 23-12-2014) Ingrese las fecha de resolución del contribuyente" required="true" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>
                            Dirección/Unidad del SII
                        </label>
                        <select class="form-control" [(ngModel)]="this.empresa.UnidadSII.ID">
                            <option *ngFor="let unidad of unidades" value="{{comuna.id}}">{{comuna.nombre}}</option>
                        </select>
                    </div>
                </fieldset>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <p>A continuación ud puede ver todos los impuestos disponibles, habilite o deshabilite los que su empresa necesita.</p>
                <table class="table table-bordered table-hover table-striped tablaFiltrada">
                    <thead>
                        <tr>
                            <th>
                                ¿Habilitado?
                            </th>
                            <th>
                                Nombre
                            </th>
                            <th>
                                Tasa
                            </th>
                            <th>
                                Descripción
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let impuesto of this.empresa.Impuestos">
                            <td>
                                Html.Hidden("Impuestos[" + i + "].ID", Model.ElementAt(i).ID)
                                Html.CheckBox("Impuestos[" + i + "].Habilitado", Model.ElementAt(i).Habilitado)
                            </td>
                            <td>
                                Html.Hidden("Impuestos[" + i + "].Nombre", Model.ElementAt(i).Nombre)
                                {{ impuesto.Nombre }}
                            </td>
                            <td>
                                {{ impuesto.Nombre }}
                                Model.ElementAt(i).Tasa.ToString("N0") (Model.ElementAt(i).TipoTasa == Lintu.ERP.Models.FacturacionElectronica.TipoTasa.Porcentual ? " %" : " Por unidad")
                            </td>
                            <td>
                                Model.ElementAt(i).Descripcion
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="panel-footer">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button class="btn btn-block btn-success" type="submit"><i class="fa fa-edit"></i> Guardar</button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <a class="btn btn-block btn-warning" onclick="javascript:history.go(-1)"><i class="fa fa-arrow-left"></i>  Regresar</a>
            </div>
        </div>
    </div>
</div>