<ng-container *ngIf="this.documentos">
  <h2>Documentos Sin Periodo</h2>
  <div class="row">
      <div class="col-sm-6">
              ({{ this.totalDocumentos }} documentos)
          &nbsp;
      </div>
      <div class="col-sm-6">
          <div class="dataTables_filter">
              <div class="row">
                  <div class="col-sm-6" style="text-align: right">
                          <span>Filtrar: </span>
                  </div>
                  <div class="col-sm-6" style="margin-bottom: 5px;">
                      <input class="form-control input-sm"  (keyup)="filtrar($event)">
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <table class="table table-bordered table-hover table-striped tablaFiltrada">
          <thead>
              <tr>
                  <th class="numero">Folio</th>
                  <th>Emisor</th>
                  <th class="hidden-xs hidden-sm">Destinatario</th>
                  <th class="hidden-xs hidden-sm">Fecha Emision</th>
                  <th class="hidden-xs hidden-sm numero">Neto</th>
                  <th class="numero">Total</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let doc of this.documentos">
                  <td class="numero">
                      <a [routerLink]="['/erp/documentos/documento-detalle', doc.id]">
                          <ng-container *ngIf="doc.tipoDocumento != tipoDocumento.Boleta">{{doc.numero | number:'':'es-CL' }}</ng-container>
                          <ng-container *ngIf="doc.tipoDocumento === tipoDocumento.Boleta">{{doc.desde | number:'':'es-CL'}} - {{ doc.hasta | number:'':'es-CL' }}</ng-container>
                      </a>
                  </td>
                  <ng-container *ngIf="doc.tipoDocumento === tipoDocumento.Boleta">
                      <td class="numero">-</td>
                      <td class="hidden-xs hidden-sm">-</td>
                  </ng-container>
                  <ng-container *ngIf="doc.tipoDocumento != tipoDocumento.Boleta">
                    <td class="">{{ doc.emisor.razonSocial | slice:0:26 }}...<br />{{ doc.emisor.rut | number:'':'es-CL'}} - {{ doc.emisor.digitoVerificador  }}</td>  
                    <td class="hidden-xs hidden-sm">{{ doc.destinatario.razonSocial | slice:0:26 }}...<br />{{ doc.destinatario.rut | number:'':'es-CL'}} - {{doc.destinatario.digitoVerificador  }}</td>
                  </ng-container>
                  <td class="hidden-xs hidden-sm">{{doc.fechaEmision | date : "dd/MM/yyyy" }}</td>
                  <td class="hidden-xs hidden-sm numero">{{ doc.neto | number:'':'es-CL'  }}</td>
                  <td class="numero">{{ doc.total | number:'':'es-CL'  }}</td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
  <div class="row">
      <div class="col-sm-6">
          <div class="dataTables_info">Mostrando {{ ((this.pagina - 1) * this.largoPagina) + 1 }} al {{((this.pagina - 1) * this.largoPagina) + this.largoPagina }} de {{ this.totalDocumentos }} documentos</div>
      </div>
      <div class="col-sm-6">
          <ul class="pagination pull-right">
              <li class="paginate_button previous">
                  <a (click)="anteriorPagina()">Anterior</a>
              </li>
              <!-- <li class="paginate_button active" aria-controls="dataTables-example" tabindex="0"><a href="#">1</a></li><li class="paginate_button " aria-controls="dataTables-example" tabindex="0"><a href="#">2</a></li><li class="paginate_button " aria-controls="dataTables-example" tabindex="0"><a href="#">3</a></li><li class="paginate_button " aria-controls="dataTables-example" tabindex="0"><a href="#">4</a></li><li class="paginate_button " aria-controls="dataTables-example" tabindex="0"><a href="#">5</a></li><li class="paginate_button " aria-controls="dataTables-example" tabindex="0"><a href="#">6</a></li> -->
              <li class="paginate_button next" aria-controls="dataTables-example" tabindex="0">
                  <a (click)="siguientePagina()">Siguiente</a>
              </li>
          </ul>
      </div>
  </div>
</ng-container>