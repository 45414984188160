import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-validado',
  templateUrl: './input-validado.component.html',
  styleUrls: ['./input-validado.component.css']
})
export class InputValidadoComponent implements OnInit {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() type: string;
  @Input() required: true;
  @Input() min: number;
  @Input() max: number;
  @Input() cols: number;
  @Input() rows: number;
  
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onValidation = new EventEmitter<boolean>();

  errores: string[];

  constructor() { 
    this.errores = [];
  }

  ngOnInit() { }

  inputChanged(){
    this.validate();
    this.valueChange.emit(this.value);
    this.onValidation.emit(this.errores.length === 0);
  }

  validate(){
    this.errores = []
    if(this.required)
      this.validateRequired();
    if(this.min)
      this.validateMin();
    if(this.max)
      this.validateMax();
  }

  validateRequired(){
    if(!this.value)
      this.errores.push("El campo " + this.label + " es requerido");
  }

  validateMin(){
    if(this.type == "text" || this.type == "textarea" || this.type == "password"){
      if(this.value.length < this.min)
        this.errores.push("Debe ingresar al menos " + this.min + " caracteres");
    }
    if(this.type == "number"){
      if(+this.value < this.min)
        this.errores.push("El menor número valido es " + this.min);
    }
  }

  validateMax(){
    if(this.type == "text" || this.type == "textarea"){
      if(this.value.length > this.max)
        this.errores.push("Como máximo puede ingresar " + this.max + " caracteres");
    }
    if(this.type == "number"){
      if(+this.value > this.max)
        this.errores.push("El mayor número valido es " + this.max);
    }
  }
}
