import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recepcion-detalle',
  templateUrl: './recepcion-detalle.component.html',
  styleUrls: ['./recepcion-detalle.component.css']
})
export class RecepcionDetalleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
