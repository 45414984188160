<ng-container *ngIf="producto">
  <div class="row">
    <div class="col-xs-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>
            <i class="fa fa-file"></i> Documentos para
            <strong>{{this.producto.nombre}}</strong>
          </h4>
        </div>
        <div class="panel-body">
          <p>A continuación puede ver todos los documentos donde aparece el producto
            <strong>{{this.producto.nombre}}</strong>:</p>
          <div class="panel-group" id="accordion">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                    <i class="fa fa-arrow-right"></i> Emitidos</a>
                </h4>
              </div>
              <div id="collapseOne" class="panel-collapse collapse in">
                <div class="panel-body">
                  <ng-container *ngIf="this.cargandoDocumentosEmitidos">
                    <i class="fa fa-spin fa-spinner"></i> Cargando
                    <br />
                  </ng-container>
                  <div *ngIf="this.documentosEmitidos && this.documentosEmitidos.length == 0">
                    No hay documentos emitidos asociados a este producto
                  </div>
                  <div *ngIf="this.documentosEmitidos && this.documentosEmitidos.length > 0 && !this.cargandoDocumentosEmitidos">
                    <nav>
                      <ul class="pager" style="text-align: right">
                        <span class="small">Página
                          <span class="documentosEmitidosPaginaActual">{{this.paginaEmitidos}}</span> &nbsp;&nbsp;</span>
                        <li [ngClass]="!this.existePaginaAnterior(1) ? 'disabled' : ''">
                          <a (click)="paginaAnterior(1)">
                            <i class="fa fa-arrow-left"></i> Anterior</a>
                        </li>
                        <li [ngClass]="!this.existePaginaSiguiente(1) ? 'disabled' : ''">
                          <a (click)="paginaSiguiente(1)">Siguiente
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a class="refrescaTablaEmitidos">
                            <i class="fa fa-refresh"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <table id="tablaEmitidos" class="table table-bordered table-hover table-striped tablaFiltrada">
                      <thead>
                        <tr>
                          <th class="numero">
                            Folio
                          </th>
                          <th class="hidden-xs hidden-sm">
                            Destinatario
                          </th>
                          <th class="numero">
                            Cantidad
                          </th>
                          <th class="numero">
                            Total Producto
                          </th>
                          <th class="hidden-xs hidden-sm numero">
                            Total Documento
                          </th>
                          <th>
  
                          </th>
                        </tr>
                      </thead>
                      <tbody id="documentosEmitidosItems">
                        <ng-container *ngIf="documentosEmitidos">
                          <tr *ngFor="let documento of this.documentosEmitidos">
                            <td class="numero">{{documento.numero | number:'':'es-CL'}}</td>
                            <td class="hidden-xs hidden-sm">{{documento.destinatario.razonSocial}}</td>
                            <td class="numero">{{contarCantidadProductosEnDocumento(documento) | number:'':'es-CL'}}</td>
                            <td class="numero">{{sumarCostoProductoEnDocumento(documento) | number:'':'es-CL'}}</td>
                            <td class="hidden-xs hidden-sm numero">{{documento.total | number:'':'es-CL'}}</td>
                            <td>
                                <a class="btn btn-sm btn-default" [routerLink]="['/erp/documentos/documento-detalle/', documento.id]">
                                    <i class="fa fa-arrow-right"></i> Ir</a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <nav>
                      <ul class="pager" style="text-align: right">
                        <span class="small">Página
                          <span class="documentosEmitidosPaginaActual">{{this.paginaEmitidos}}</span> &nbsp;&nbsp;</span>
                        <li [ngClass]="!this.existePaginaAnterior(1) ? 'disabled' : ''">
                          <a (click)="paginaAnterior(1)">
                            <i class="fa fa-arrow-left"></i> Anterior</a>
                        </li>
                        <li [ngClass]="!this.existePaginaSiguiente(1) ? 'disabled' : ''">
                          <a (click)="paginaSiguiente(1)">Siguiente
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </li>
                        <li>
                          <a class="refrescaTablaEmitidos">
                            <i class="fa fa-refresh"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  


                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" class="collapsed" aria-expanded="false">
                    <i class="fa fa-arrow-left"></i> Recibidos</a>
                </h4>
              </div>
              <div id="collapseTwo" class="panel-collapse collapse in" aria-expanded="false">
                <div class="panel-body">
                  <ng-container *ngIf="this.cargandoDocumentosRecibidos">
                    <i class="fa fa-spin fa-spinner"></i> Cargando
                    <br />
                  </ng-container>
                  <div *ngIf="this.documentosRecibidos && this.documentosRecibidos.length == 0">
                    No hay documentos recibidos asociados a este producto
                  </div>
                  <div *ngIf="this.documentosRecibidos && this.documentosRecibidos.length > 0 && !this.cargandoDocumentosRecibidos">
                    <nav>
                        <ul class="pager" style="text-align: right">
                            <span class="small">Página
                              <span>{{this.paginaRecibidos}}</span></span>
                            <li [ngClass]="!this.existePaginaAnterior(0) ? 'disabled' : ''">
                              <a (click)="paginaAnterior(0)">
                                <i class="fa fa-arrow-left"></i> Anterior</a>
                            </li>
                            <li [ngClass]="!this.existePaginaSiguiente(0) ? 'disabled' : ''">
                              <a (click)="paginaSiguiente(0)">Siguiente
                                <i class="fa fa-arrow-right"></i>
                              </a>
                            </li>
                            <li>
                              <a class="refrescaTablaEmitidos">
                                <i class="fa fa-refresh"></i>
                              </a>
                            </li>
                          </ul>
                    </nav>
                    <table class="table table-bordered table-hover table-striped tablaFiltrada">
                      <thead>
                        <tr>
                          <th class="numero">
                            Folio
                          </th>
                          <th class="hidden-xs hidden-sm">
                            Emisor
                          </th>
                          <th class="numero">
                            Cantidad
                          </th>
                          <th class="numero">
                            Total Producto
                          </th>
                          <th class="hidden-xs hidden-sm numero">
                            Total Documento
                          </th>
                          <th>

                          </th>
                        </tr>
                      </thead>
                      <tbody id="documentosRecibidosItems">
                        <ng-container *ngIf="documentosRecibidos">
                          <tr *ngFor="let documento of this.documentosRecibidos">
                            <td class="numero">{{documento.numero | number:'':'es-CL'}}</td>
                            <td class="hidden-xs hidden-sm">{{documento.destinatario.razonSocial}}</td>
                            <td class="numero">{{contarCantidadProductosEnDocumento(documento) | number:'':'es-CL'}}</td>
                            <td class="numero">{{sumarCostoProductoEnDocumento(documento) | number:'':'es-CL'}}</td>
                            <td class="hidden-xs hidden-sm numero">{{documento.total | number:'':'es-CL'}}</td>
                            <td>
                                <a class="btn btn-sm btn-default" [routerLink]="['/erp/documentos/documento-detalle/', documento.id]">
                                    <i class="fa fa-arrow-right"></i> Ir</a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <nav>
                        <ul class="pager" style="text-align: right">
                            <span class="small">Página
                              <span>{{this.paginaRecibidos}}</span></span>
                            <li [ngClass]="!this.existePaginaAnterior(0) ? 'disabled' : ''">
                              <a (click)="paginaAnterior(0)">
                                <i class="fa fa-arrow-left"></i> Anterior</a>
                            </li>
                            <li [ngClass]="!this.existePaginaSiguiente(0) ? 'disabled' : ''">
                              <a (click)="paginaSiguiente(0)">Siguiente
                                <i class="fa fa-arrow-right"></i>
                              </a>
                            </li>
                            <li>
                              <a class="refrescaTablaEmitidos">
                                <i class="fa fa-refresh"></i>
                              </a>
                            </li>
                          </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <div class="row">
            <div class="col-xs-12">
              <a class="btn btn-sm btn-primary" [routerLink]="['/erp/inventario/producto-detalle', this.producto.id]">
                <i class="fa fa-arrow-right"></i> Ir al producto</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>