import { Component, OnInit } from '@angular/core';
import { PeriodosService } from '../../servicios/servicio.periodos';
import { TipoDocumento } from '../../core/enums/tipo-documento.enum';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentosService } from '../../servicios/servicio.documentos';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';

@Component({
  selector: 'app-boletas-modificar',
  templateUrl: './boletas-modificar.component.html',
  styleUrls: ['./boletas-modificar.component.css']
})
export class BoletasModificarComponent implements OnInit {
  public accion: string;
  public idDocumento: string;
  public documento: any;
  public empresaSeleccionada: any;

  constructor(
    private servicioSesiones: SesionesService,
    private servicioPeriodos: PeriodosService,
    private route: ActivatedRoute,
    private servicioDocumentos: DocumentosService,
    private servicioNotificaciones: NotificacionesService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.empresaSeleccionada = this.servicioSesiones.obtenerSesion().empresaSeleccionada;
    this.route.params.subscribe(params => {
      this.accion = params['accion'];
      if (this.accion == "crear") {
        this.documento = {
          numero: 0,
          fechaEmision: new Date(),
          fechaVencimiento: new Date(),
          tipoDocumento: TipoDocumento.Boleta,
          emisor: { rut: 0, comuna: this.empresaSeleccionada.comuna },
          items: [],
          referencias: [],
        };
        this.cambioPeriodoDocumento(this.documento);
      }
      if (this.accion == "modificar") {
        var idDocumento = params['id'];
        this.servicioDocumentos.getDocumento(idDocumento).subscribe(response => {
          this.documento = response.datos;
        });
      }
    });
  }

  cambioPeriodoDocumento(documento: any): void {
    console.log("docto:", documento);
    var anio = new Date(documento.fechaEmision).getFullYear() * 1;
    var mes = (new Date(documento.fechaEmision).getMonth() + 1) * 1;
    this.servicioPeriodos.getDetalle(anio, mes).subscribe(res => { 
      documento.periodo = res.datos;
      console.log("periodo", this.documento.periodo);
    });
  }


  guardar(): void {
    if (this.accion == "crear") {
      this.servicioDocumentos.ingresoBoletas(this.documento).subscribe(res => {
        if (res.esCorrecto) {
          this.documento = res.datos;
          this.router.navigate(['/erp/documentos/documento-detalle/', this.documento.id]);
        }
        else {
          var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
          this.servicioNotificaciones.agregarNotificacion(notificacion);
        }
      });
    }
    else {
      this.servicioDocumentos.modificarBoletas(this.documento).subscribe(res => {
        if (res.esCorrecto) {
          this.router.navigate(['/erp/documentos/documento-detalle/', this.documento.id]);
        }
        else {
          var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
          this.servicioNotificaciones.agregarNotificacion(notificacion);
        }
      });
    }
  }
}
