import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-documento-dialogo-periodocambiar',
  templateUrl: './documento-dialogo-periodocambiar.component.html',
  styleUrls: ['./documento-dialogo-periodocambiar.component.css']
})
export class DocumentoDialogoPeriodocambiarComponent implements OnInit {

  public nuevoPeriodo: any;

  constructor(
  public dialogRef: MatDialogRef<DocumentoDialogoPeriodocambiarComponent>,
    @Inject(MAT_DIALOG_DATA) public periodosAbiertos: any) {
      this.nuevoPeriodo = periodosAbiertos.length > 0 ? periodosAbiertos[0] : {};
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
