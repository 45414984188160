import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cambiarperiodo-dialogo',
  templateUrl: './cambiarperiodo-dialogo.component.html',
  styleUrls: ['./cambiarperiodo-dialogo.component.css']
})
export class CambiarperiodoDialogoComponent implements OnInit {
  modalInformaciones: any;
  constructor(
    public dialogRef: MatDialogRef<CambiarperiodoDialogoComponent>,
    @Inject(MAT_DIALOG_DATA) public periodo: any) {
      this.modalInformaciones = {  mesSeleccionado: periodo.mes, anioSeleccionado: periodo.anio, aniosSeleccionables: [] };
      let anioActual = new Date().getFullYear();
      for (var i=anioActual - 10; i <= anioActual; i++)
      {
        this.modalInformaciones.aniosSeleccionables.push(i);
      }
      console.log(this.modalInformaciones);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
