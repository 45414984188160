import { Component, OnInit } from '@angular/core';
import { InventarioService } from '../../servicios/servicio.inventario';

@Component({
  selector: 'app-inventario-detalles',
  templateUrl: './inventario-detalles.component.html',
  styleUrls: ['./inventario-detalles.component.css']
})
export class InventarioDetallesComponent implements OnInit {
  productosPaginados: any;
  servicioInventario: InventarioService;
  public pagina: number;
  public largoPagina: number;
  public filtro: string;
  constructor(servicioInventario: InventarioService) {
    this.servicioInventario = servicioInventario;
    this.productosPaginados = {};
   }

  ngOnInit() {
    this.pagina = 1;
    this.largoPagina = 10;
    this.filtro = "";
    this.productosPaginados.datos = [];
    this.obtenerProductos();
  }

  obtenerProductos() {
    this.servicioInventario.getProductos(this.pagina, this.largoPagina, this.filtro).subscribe(response => {
        this.productosPaginados = response.datos;
        console.log(this.productosPaginados);
    });
  }

  siguientePagina() : void{
    var aux = this.pagina * this.largoPagina;
    this.pagina = this.productosPaginados.totalElementos > aux ? this.pagina + 1 : this.pagina;
    this.obtenerProductos();
  }

  anteriorPagina() : void{
    this.pagina = this.pagina > 1 ? this.pagina - 1 : this.pagina;
    this.obtenerProductos();
  }

  setFiltro(event: any) : void{
    this.filtro = event.target.value;
    this.pagina = 1;
    this.obtenerProductos();
  }
}
