import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-fecha',
  templateUrl: 'input-fecha.html',
  styleUrls: ['input-fecha.css'],
})

export class InputFechaComponent {
  private _value: Date;
  public _readOnly: Boolean;

  @Input() placeholder: string;

  @Input() set value(val: Date) {
    this._value = val;
    this.valueChange.emit(this._value);
  }

  @Input() set readOnly(val: Boolean) {
    this._readOnly = val;
    this.readOnlyChange.emit(this._readOnly);
  }

  get value(): Date {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<Date>();
  
  @Output() readOnlyChange = new EventEmitter<Boolean>();

  onFocus(event) {
    console.log('onFocus', event);
  }
}
