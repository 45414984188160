import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { Notificacion } from '../../core/models/notificacion';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public entorno = environment.entorno;
  public boletaExpressHabilitada = environment.boletaExpressHabilitada;

  constructor(
    private sesionesService: SesionesService,
    private servicioNotificaciones: NotificacionesService,
    private router: Router) { }

  ngOnInit() {

  }

  salir() : void {
    this.sesionesService.cerrarSesion().subscribe(res => {
      console.log(res);
      if (res.esCorrecto)
      {
        this.router.navigate(['']);
      }
      else
      {
        console.log(res);
        var notificacion = new Notificacion("Lo sentimos, hay errores que debe atender primero", "<p>" + res.mensajes[0] + "</p>", "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }
}
