import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionesService } from '../../servicios/servicio.sesiones';
import { environment } from '../../../environments/environment';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { Notificacion } from '../../core/models/notificacion';
import { ApiService } from '../../servicios/servicio.api';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  user: string;
  password: string;
  estaAutentificando: boolean;

  constructor(
    private router: Router,
    private servicioSesiones: SesionesService,
    private servicioNotificaciones: NotificacionesService,
    private apiService: ApiService) { }

  ngOnInit() {
    this.estaAutentificando = false;
  }

  ingresar() : void {
    this.estaAutentificando = true;
    if (!this.user || !this.password) {
      let mensaje = '';
      mensaje += 'Encontramos algunos errores:';
      mensaje += '<ul>';
      mensaje += !this.user ? '<li>El campo <strong>Email</strong> no puede estar vacío.</li>' : '';
      mensaje += !this.password ? '<li>El campo <strong>Contraseña</strong> no puede estar vacío.</li>' : '';
      mensaje += '</ul>';
      mensaje += 'Por favor reintente luego de completar todos los datos.';
      const notificacion = new Notificacion("No fue posible iniciar sesión", mensaje, "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
      this.estaAutentificando = false;
    }
    else {
      this.apiService.getClienteApi(environment.nombreInstancia).subscribe(res => {
        if (!res.datos.estaBloqueado){
          this.servicioSesiones.login(this.user, this.password).subscribe(response => {
            if(response.esCorrecto)
            {
              this.servicioSesiones.establecerSesion(response.datos);
              this.router.navigate(['erp/users/user-desktop']);
            }
            else {
              console.log(response);
              var notificacion = new Notificacion("Encontramos algunos errores", response.mensajes[0], "Error");
              this.servicioNotificaciones.agregarNotificacion(notificacion);
            }
            this.estaAutentificando = false;
          },
          error => {
            console.log(error);
            var notificacion = new Notificacion("Ups, algo anda mal...", error.message, "Error");
            this.servicioNotificaciones.agregarNotificacion(notificacion);
            this.estaAutentificando = false;
          });
        }
        else{
          var notificacion = new Notificacion("Ud no puede ingresar.", "Su usuario se encuentra bloqueado por algún motivo, si no sabe la razón puede ponerse en contacto con nosotros", "Error");
          this.servicioNotificaciones.agregarNotificacion(notificacion);
          this.estaAutentificando = false;
        }
      });
    }

  }

}
