import { Injectable } from '@angular/core';
import { Enumeraciones } from './../../assets/data/enumeraciones';

@Injectable()
export class EnumeracionesService {

    constructor (
    ) {
    }
    public getComunas() : Array<any> {
        var comunas = [];

        var regiones = Enumeraciones.Regiones;
        regiones.forEach(region => {
         region.provincias.forEach(provincia => {
           provincia.comunas.forEach(comuna => {
             comunas.push(comuna);
           });
         });
        });

        comunas = comunas.sort(
           function(a, b){
             if(a.nombre < b.nombre) return -1;
             if(a.nombre > b.nombre) return 1;
             return 0;
         });

        return comunas;
    }

    public getImpuestos() : Array<any> {
        return Enumeraciones.Impuestos;
    }

    public getCodigosReferenciaDocumentos() : Array<any> {
        return Enumeraciones.CodigosReferenciaDocumentos;
    }

    public getTiposDocumentos() : Array<any> {
        return Enumeraciones.TiposDocumento;
    }

    public mediosDePago() : Array<any> {
        return Enumeraciones.MediosDePago;
    }
    
    public formasDePago() : Array<any> {
        return Enumeraciones.FormasDePago;
    }

    public getUnidadesMedida() : Array<any> {
        return Enumeraciones.UnidadesMedida;
    }

    public getUnidadesSII() : Array<any> {
        return Enumeraciones.OficinasSII;
    }

    public getTiposDespacho() : Array<any> {
        return Enumeraciones.TiposDespacho;
    }

    public getIndicadoresTraslado() : Array<any> {
        return Enumeraciones.IndicadoresTraslado;
    }

}