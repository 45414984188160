<img height="35" style="height: 35px; margin: 15px 5px 5px 5px;" src="assets/styles/images/logoThumb.png" />
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu-tree">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
     <a class="leaf" (click)="menuClickeado(node)"><i class="fa {{node.icono ? node.icono : 'fa-play'}}"></i>&nbsp;&nbsp; {{node.name}}</a>
    </li>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <a class="node" (click)="menuClickeado(node)" matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
          <i class="fa {{treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right'}}"></i>
          &nbsp;&nbsp; {{node.name}}
        </a>
      </div>
      <ul style="margin-left: -20px;" [class.menu-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>