import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class FoliosService {
    urlApi: string;

    constructor(
        private http: HttpClient,
        private servicioSesiones: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headers(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }

        return new HttpHeaders(headerDict);
    }

    private headersPost(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }

        return new HttpHeaders(headerDict);
    }

    private headersPostFile(): any {
        const headerDict = {
            "enctype": "multipart/form-data",
            'token': this.servicioSesiones.obtenerSesion().token
        }

        return new HttpHeaders(headerDict);
    }

    public getTodos(): Observable<any> {
        var headers = this.headers();
        return this.http.get(this.urlApi + `FolioApi/Todos`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getEnUso(): Observable<any> {
        var headers = this.headers();
        return this.http.get(this.urlApi + `FolioApi/EnUso`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getDetalle(id: number): Observable<any> {
        var headers = this.headers();
        return this.http.get(this.urlApi + `FolioApi/Get?id=${id}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getDocumentos(id: number, pagina: number, largoPagina: number): Observable<any> {
        var headers = this.headers();
        return this.http.get(this.urlApi + `FolioApi/DocumentosDelFolio?id=${id}&pagina=${pagina}&largoPagina=${largoPagina}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public agregar(data: any) : any {
        var headers = this.headersPostFile();
        return this.http.post(`${this.urlApi}FolioApi/Agregar`, data, { headers: headers }).pipe(map((res: Response) => res));
    }

    public utilizar(id: number): Observable<any> {
        var headers = this.headersPost();
        return this.http.post(`${this.urlApi}FolioApi/Utilizar?id=${id}`, { id: id }, { headers: headers }).pipe(map((res: Response) => res));
    }
}