import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../../servicios/servicio.logging';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private servicioLogging: LoggingService
  ) {}

  handleError(error) {
      console.error(error);
      const mensaje = 'Url origen:' + window.location.href + '\n' +
                    error.stack;
      this.servicioLogging.registrarError(mensaje);
  }
}
