import { Component, OnInit } from '@angular/core';
import { InstanciaService } from '../../servicios/servicio.instancia';

@Component({
  selector: 'app-instancia-propiedadesglobales',
  templateUrl: './instancia-propiedadesglobales.component.html',
  styleUrls: ['./instancia-propiedadesglobales.component.css']
})
export class InstanciaPropiedadesglobalesComponent implements OnInit {
  public propiedadesGlobales: any[];

  constructor(private servicioInstancia: InstanciaService) { }

  ngOnInit() {
    this.servicioInstancia.propiedadesGlobales().subscribe(res => {
      this.propiedadesGlobales = Object.entries(res.datos);
      console.log(this.propiedadesGlobales);
    });
  }

}
