import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificacionesService } from '../../servicios/servicio.notificaciones';
import { environment } from '../../../environments/environment';
import { Notificacion } from '../../core/models/notificacion';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogoComponent } from './dialogos/error-dialogo/error-dialogo.component';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../servicios/servicio.api';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit, OnDestroy {

  notificaciones: Notificacion[] = [];

  static instance: NotificacionesComponent;

  constructor(private apiService: ApiService,
    private servicioNotificaciones: NotificacionesService,
    private router: Router,
    public dialog: MatDialog) {
    if (NotificacionesComponent.instance) {
      return NotificacionesComponent.instance;
    }

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.notificaciones = [];
      }
    });

    this.servicioNotificaciones.notificacionAgregada$.subscribe(
      notificacion => {
        if (notificacion.Nivel != "Error") {
          this.notificaciones.push(notificacion);
          setTimeout(() => {
            this.cerrarNotificacion(notificacion);
          }, (notificacion.Nivel != "Advertencia" ? 5000 : 20000));
        }
        else {
          const dialogRef = this.dialog.open(ErrorDialogoComponent, {
            width: '80%',
            data: notificacion
          });
          dialogRef.afterClosed().subscribe(result => {
            this.cerrarNotificacion(notificacion);
          });
        }
      })
  }

  ngOnInit() {
    if (NotificacionesComponent.instance) {
      return;
    }

    this.apiService.getAlertas(environment.nombreInstancia).subscribe(response => {
      for (let i = 0; i < response.datos.length; i++) {
        let notificacion: Notificacion;
        let titulo = response.datos[i].titulo;
        let mensaje = response.datos[i].mensaje;
        if (response.datos[i].tipo == 0) { //Importante
          notificacion = new Notificacion(titulo, mensaje, "Advertencia");
        }
        if (response.datos[i].tipo == 1) { //Recordatorio
          notificacion = new Notificacion(titulo, mensaje, "Info");
        }
        if (response.datos[i].tipo == 2) { //Ayuda
          notificacion = new Notificacion(titulo, mensaje, "Info");
        }
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
    });
  }

  ngOnDestroy(): void {
    NotificacionesComponent.instance = this;
  }

  cerrarNotificacion(notificacion: any): void {
    this.notificaciones = this.notificaciones.filter(n => n != notificacion);
  }
}
