<ng-container *ngIf="producto">
  <fieldset>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4>
          {{ this.accion == 'crear' ? "Crear nuevo Producto" : "Modificar" }}
        </h4>
      </div>
      <div class="panel-body">
        
        <div class="row">
          <div class="col-md-12 form-group">
            <label>
              Código Interno
            </label>
            <input placeholder="Ingrese el código interno del producto" class="form-control" required="true" [(ngModel)]="producto.codigoInterno" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label>
              Nombre
            </label>
            <input placeholder="Ingrese el nombre del producto" class="form-control" required="true" [(ngModel)]="producto.nombre" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label>
              Descripcion
            </label>
            <textarea placeholder="Ingrese una descripción para el producto" class="form-control" required="true" [(ngModel)]="producto.descripcion" ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label>
              Precio Unitario
            </label>
            <input type="number" placeholder="Ingrese el precio unitario del producto" class="form-control" required="true" [(ngModel)]="producto.precio" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label>
              Unidad de medida
            </label>
            <select class="form-control" required="true" [(ngModel)]="producto.unidadMedida.id">
              <option *ngFor="let unidad of unidades" value="{{unidad.id}}">{{unidad.nombre}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <fieldset>
              <legend>Afecto a impuestos:</legend>

              <ng-container *ngFor="let impuesto of this.impuestos">
                <input type="checkbox" [ngModel]="impuestoEstaSeleccionado(impuesto)" (change)="checkboxImpuestoCliqueado(impuesto.id, $event)" />
                <label>
                  {{impuesto.nombre}}
                  <strong>
                    (
                      {{impuesto.tasa}}
                      <ng-container *ngIf="impuesto.tipoTasa === 0">
                        %
                      </ng-container>
                      <ng-container *ngIf="impuesto.tipoTasa === 1">
                        Por Unidad
                      </ng-container>
                    )
                  </strong>
                </label>
                <br />
              </ng-container>
            </fieldset>
          </div>

        </div>
      </div>
      <div class="panel-footer">
        <div class="row">
          <div class="col-xs-6 col-md-6">
            <button (click)="guardar()" class="btn btn-block btn-success" type="submit" [disabled]="!esValido()">
              <i class="fa fa-save"></i> Guardar</button>
          </div>
          <div class="col-xs-6 col-md-6" style="text-align: right">
            <a *ngIf="this.accion != 'crear'" class="btn btn-warning" [routerLink]="['/erp/inventario/producto-detalle/', producto.id]">
              <i class="fa fa-arrow-left"></i> Regresar</a>
            <a *ngIf="this.accion == 'crear'" class="btn btn-warning" [routerLink]="['/erp/inventario/inventario-detalle/']">
              <i class="fa fa-arrow-left"></i> Regresar</a>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</ng-container>