<div class="row">
  <div class="col-md-12 form-group">
    <label>
      {{this.label}}
    </label>
    <ng-container *ngIf="this.type != 'textarea'">
      <input  class="form-control {{this.errores.length > 0 ? 'error' : ''}}" placeholder="{{this.placeholder}}" type="{{this.type}}" (keyup)="inputChanged()" [(ngModel)]="value">
    </ng-container>
    <ng-container *ngIf="this.type == 'textarea'">
      <textarea  class="form-control {{this.errores.length > 0 ? 'error' : ''}}" [attr.cols]="this.cols" [attr.rows]="this.rows" placeholder="{{this.placeholder}}" (keyup)="inputChanged()" [(ngModel)]="value"></textarea>
    </ng-container>
    <span class="error-list">
      <ng-container *ngIf="this.errores.length == 1">
        {{this.errores[0]}}
      </ng-container>
      <ng-container *ngIf="this.errores.length > 1">
        <ul>
          <li *ngFor="let error of this.errores">
            {{error}}
          </li>
        </ul>
      </ng-container>
    </span>
  </div>
</div>