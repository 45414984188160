import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapperless',
  templateUrl: './wrapperless.component.html',
  styleUrls: ['./wrapperless.component.css']
})
export class WrapperlessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
