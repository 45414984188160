import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../app/servicios/servicio.api';

@Component({
  selector: 'app-ayuda-index',
  templateUrl: './ayuda-index.component.html',
  styleUrls: ['./ayuda-index.component.css']
})
export class AyudaIndexComponent implements OnInit {

  public articulos: any[];

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getArticulosAyuda().subscribe(res => {
      this.articulos = res;
    });
  }

}
