<ng-container *ngIf="producto">
    <div class="row">
        <div class="col-xs-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4>
                        <i class="fa fa-info"></i> Información del producto</h4>
                </div>
                <div class="panel-body">
                    <fieldset>
                        <legend>Detalles del producto</legend>

                        <label>Nombre</label>
                        <p>{{producto.nombre}}</p>

                        <label>Código interno</label>
                        <p>{{producto.codigoInterno}}</p>

                        <label>Precio Unitario</label>
                        <p>{{producto.precio | number:'':'es-CL'}}</p>

                        <label>Unidad de Medida</label>
                        <p>{{producto.unidadMedida.nombre}}</p>

                        <label>Descripción</label>
                        <p>{{producto.descripcion}}</p>
                    </fieldset>

                    <br>
                    <fieldset>
                        <legend>Impuestos del producto</legend>
                        <ng-container *ngFor="let impuesto of producto.impuestos">
                            <label>
                                <strong>{{impuesto.codigoInterno}}</strong> ({{impuesto.tasa}} %)
                            </label>
                            <br />
                        </ng-container>
                    </fieldset>

                </div>
                <div class="panel-footer">
                    <button type="button" class="btn btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                        Acciones <span class="caret"></span>
                    </button>
                    <mat-menu #menuAcciones="matMenu">
                        <button mat-menu-item [routerLink]="['/erp/inventario/producto-modificar/', 'modificar', producto.id]"><i class="fa fa-edit"></i> Modificar</button>
                        <button mat-menu-item [routerLink]="['/erp/inventario/producto-eliminar/', producto.id]"><i class="fa fa-times"></i> Eliminar</button>
                    </mat-menu>
                    <a [routerLink]="['/erp/inventario/inventario-detalle']" class="btn btn-warning pull-right"><i class="fa fa-arrow-left"></i> Productos</a>
                </div>
            </div>
        </div>

        <div class="col-xs-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4>
                        <i class="fa fa-files-o"></i> Documentos</h4>
                </div>
                <div class="panel-body">
                    <div class="panel-group" id="accordion">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a><i class="fa fa-arrow-right"></i> Emitidos</a></h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <ng-container *ngIf="documentosEmitidos && documentosEmitidos.length > 0">
                                        <p>Últimos 10 documentos emitidos con este producto incluido.</p>
                                        <table id="tablaEmitidos" class="table table-bordered table-hover table-striped tablaFiltrada">
                                            <thead>
                                                <tr>
                                                    <th class="numero">
                                                        Folio
                                                    </th>
                                                    <th class="hidden-xs hidden-sm">
                                                        Destinatario
                                                    </th>
                                                    <th class="numero">
                                                        Cantidad
                                                    </th>
                                                    <th class="numero">
                                                        Total Producto
                                                    </th>
                                                    <th class="hidden-xs hidden-sm numero">
                                                        Total Documento
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="documentosEmitidosItems">
                                                <ng-container *ngIf="documentosEmitidos">
                                                    <tr *ngFor="let documento of documentosEmitidos">
                                                        <td class="numero">{{documento.numero | number:'':'es-CL'}}</td>
                                                        <td class="hidden-xs hidden-sm">{{documento.destinatario.razonSocial}}</td>
                                                        <td class="numero">{{contarCantidadProductosEnDocumento(documento) | number:'':'es-CL'}}</td>
                                                        <td class="numero">{{sumarCostoProductoEnDocumento(documento) | number:'':'es-CL'}}</td>
                                                        <td class="hidden-xs hidden-sm numero">{{documento.total | number:'':'es-CL'}}</td>
                                                        <td>
                                                            <a class="btn btn-sm btn-default" [routerLink]="['/erp/documentos/documento-detalle/', documento.id]">
                                                                <i class="fa fa-arrow-right"></i> Ir</a>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                        <a [routerLink]="['/erp/inventario/producto-documentos', this.producto.id]" class="btn btn-default btn-block">Ver todos</a>
                                    </ng-container>
                                    <ng-container *ngIf="documentosEmitidos && documentosEmitidos.length <= 0">
                                        No hay documentos emitidos asociados a este producto 
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a><i class="fa fa-arrow-left"></i> Recibidos</a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <ng-container *ngIf="documentosRecibidos && documentosRecibidos.length > 0">
                                        <p>Últimos 10 documentos recibidos con este producto incluido.</p>
                                        <table id="tablaRecibidos" class="table table-bordered table-hover table-striped tablaFiltrada">
                                            <thead>
                                                <tr>
                                                    <th class="numero">
                                                        Folio
                                                    </th>
                                                    <th class="hidden-xs hidden-sm">
                                                        Emisor
                                                    </th>
                                                    <th class="numero">
                                                        Cantidad
                                                    </th>
                                                    <th class="numero">
                                                        Total Producto
                                                    </th>
                                                    <th class="hidden-xs hidden-sm numero">
                                                        Total Documento
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="documentosRecibidosItems">
                                                <ng-container *ngIf="documentosRecibidos">
                                                    <tr *ngFor="let documento of documentosRecibidos">
                                                        <td class="numero">{{documento.numero | number:'':'es-CL'}}</td>
                                                        <td class="hidden-xs hidden-sm">{{documento.destinatario.razonSocial}}</td>
                                                        <td class="numero">{{contarCantidadProductosEnDocumento(documento) | number:'':'es-CL'}}</td>
                                                        <td class="numero">{{sumarCostoProductoEnDocumento(documento) | number:'':'es-CL'}}</td>
                                                        <td class="hidden-xs hidden-sm numero">{{documento.total | number:'':'es-CL'}}</td>
                                                        <td>
                                                            <a class="btn btn-sm btn-default" [routerLink]="['/erp/documentos/documento-detalle/', documento.id]">
                                                                <i class="fa fa-arrow-right"></i> Ir</a>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                        <a [routerLink]="['/erp/inventario/producto-documentos', this.producto.id]" class="btn btn-default btn-block">Ver todos</a>
                                    </ng-container>
                                    <ng-container *ngIf="documentosRecibidos && documentosRecibidos.length <= 0">
                                        No hay documentos recibidos asociados a este producto 
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="this.panelNoEncontradoVisible">
    <div class="row">
        <div class="col-xs-12">
            <div class="panel panel-danger">
                <div class="panel-heading">
                    <h4>
                        <i class="fa fa-ban"></i> No existe el producto</h4>
                </div>
                <div class="panel-body">
                    El producto con id "{{ this.id }}" que anda buscando no existe o pudo haber sido eliminado.
                </div>
            </div>
        </div>
    </div>
</ng-container>