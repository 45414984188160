import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FileHandlerWeb } from './FileHandlerWeb';
import { AbstractFileHandler } from './AbstractFileHandler';

@Injectable({
  providedIn: 'root',
})
export class FileHelper {
  private handler: AbstractFileHandler;

  constructor() {
    this.handler = new FileHandlerWeb();
   }

  public Descargar(base64: string, nombreArchivo: string): void {
    this.handler.Descargar(base64, nombreArchivo);
  }
}
