import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DocumentosService } from '../servicios/servicio.documentos';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EnviosService } from '../servicios/servicio.envios';
import { EnvioCrearComponent } from './envio-crear/envio-crear.component';
import { EnvioDetallesComponent } from './envio-detalles/envio-detalles.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    FormsModule
  ],
  declarations: [EnvioCrearComponent, EnvioDetallesComponent],
  providers: [DocumentosService, EnviosService]
})
export class EnviosModule { }
