<div class="row">
    <div class="col-xs-12">
        <ng-container *ngIf="!estaConfirmado">
            <h1 mat-dialog-title><i class="fa fa-spin fa-spinner"></i> Estamos validando...</h1>
            <div mat-dialog-content>
                <p>
                    Por favor espere mientras verificamos que las guias no hayan sido 
                    referenciados en otro documento...
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="estaConfirmado">
            <h1 mat-dialog-title>¿Está seguro?</h1>
            <div mat-dialog-content>
                <p>
                    ¿Esta seguro que quiere crear una nueva factura con estas {{ this.guias.length }} guías de despacho?
                </p>
                <ng-container *ngIf="this.documentosConObservaciones && this.documentosConObservaciones.length > 0">
                    <h4>Observaciones</h4>    
                    <p>Encontramos las siguientes guias de despacho que ya fueron incluidas en otros documentos, revisa bien antes de continuar:</p>
                    <table class="table table-bordered table-hover table-striped tablaFiltrada">
                        <thead>
                            <tr>
                                <th>Guía Despacho</th>
                                <th>Encontrada en Documento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of this.documentosConObservaciones">
                                <td>{{ item.numero  | number:'':'es-CL'}}</td>
                                <td>
                                    <a [routerLink]="['/erp/documentos/documento-detalle', item.documentoQueReferencia.id]">
                                        {{ item.documentoQueReferencia.numero | number:'':'es-CL'}}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
            <div mat-dialog-actions>
                <button mat-button class="btn btn-success" [mat-dialog-close]="{ result: 'ok', guias: this.guias }" cdkFocusInitial>Confirmar</button> &nbsp;
                <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
            </div>
        </ng-container>
    </div>
</div>