import { Routes } from '@angular/router';
import { HomeComponent } from './erp/home/home.component';
import { UserDesktopComponent } from './users/user-desktop/user-desktop.component';
import { DocumentoDetallesComponent } from './documentos/documento-detalles/documento-detalles.component';
import { LibroDetallesComponent } from './libros/libro-detalles/libro-detalles.component';
import { LibroSinPeriodoComponent } from './libros/libro-sinperiodo/libro-sinperiodo.component';
import { UserLoginComponent } from './users/user-login/user-login.component';
import { UserOptionsComponent } from './users/user-options/user-options.component';
import { PublicComponent } from './erp/public/public.component';
import { DocumentoModificarComponent } from './documentos/documento-modificar/documento-modificar.component';
import { ClienteListaComponent } from './clientes/cliente-lista/cliente-lista.component';
import { RecepcionListaComponent } from './recepciones/recepcion-lista/recepcion-lista.component';
import { InventarioDetallesComponent } from './inventario/inventario-detalles/inventario-detalles.component';
import { ProductoDetallesComponent } from './inventario/producto-detalles/producto-detalles.component';
import { ProductoModificarComponent } from './inventario/producto-modificar/producto-modificar.component';
import { ProductoEliminarComponent } from './inventario/producto-eliminar/producto-eliminar.component';
import { ProductoDocumentosComponent } from './inventario/producto-documentos/producto-documentos.component';
import { ClienteDetalleComponent } from './clientes/cliente-detalle/cliente-detalle.component';
import { ClienteModificarComponent } from './clientes/cliente-modificar/cliente-modificar.component';
import { FolioListaComponent } from './instancia/folio-lista/folio-lista.component';
import { FolioDetalleComponent } from './instancia/folio-detalle/folio-detalle.component';
import { PeriodoListaComponent } from './instancia/periodo-lista/periodo-lista.component';
import { PeriodoDetalleComponent } from './instancia/periodo-detalle/periodo-detalle.component';
import { InstanciaDetalleComponent } from './instancia/instancia-detalle/instancia-detalle.component';
import { EnvioCrearComponent } from './envios/envio-crear/envio-crear.component';
import { EnvioDetallesComponent } from './envios/envio-detalles/envio-detalles.component';
import { BoletasModificarComponent } from './documentos/boletas-modificar/boletas-modificar.component';
import { BoletaPosComponent } from './documentos/boleta-pos/boleta-pos.component';
import { LibroGuiasComponent } from './libros/libro-guias/libro-guias.component';
import { GuiasPorClienteComponent } from './libros/libro-guias/guias-por-cliente/guias-por-cliente.component';
import { AdministracionDashboardComponent } from './administracion/administracion-dashboard/administracion-dashboard.component';
import { AdministracionCertificacionBoletasComponent } from './administracion/administracion-certificacionboletas/administracion-certificacionboletas.component';
import { AyudaIndexComponent } from './ayuda/ayuda-index/ayuda-index.component';
import { ArticuloDetalleComponent } from './ayuda/articulo-detalle/articulo-detalle.component';
import { LibroImprimirComponent } from './libros/libro-imprimir/libro-imprimir.component';
import { WrapperlessComponent } from './erp/wrapperless/wrapperless.component';
import { InstanciaPropiedadesglobalesComponent } from './instancia/instancia-propiedadesglobales/instancia-propiedadesglobales.component';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'public/users/user-login',
        pathMatch: 'full'
    },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: '',
                redirectTo: 'users/user-login',
                pathMatch: 'full'
            },
            {
                path: 'users/user-login',
                component: UserLoginComponent
            }
        ]
    },
    {
        path: 'erp',
        component: HomeComponent,
        children: [
            {
                path: '',
                redirectTo: 'users/user-desktop',
                pathMatch: 'full'
            },
            {
                path: 'users/user-desktop',
                component: UserDesktopComponent
            },
            {
                path: 'users/user-options',
                component: UserOptionsComponent
            },
            {
                path: 'documentos/documento-detalle/:id',
                component: DocumentoDetallesComponent
            },
            {
                path: 'documentos/documento-modificar/:accion/:id',
                component: DocumentoModificarComponent
            },
            {
                path: 'documentos/boleta-modificar/:accion/:id',
                component: BoletasModificarComponent
            },
            {
                path: 'documentos/boleta-pos/:accion/:id',
                component: BoletaPosComponent
            },
            {
                path: 'libros/libro-detalle/:anio/:mes/:tipo',
                component: LibroDetallesComponent
            },
            {
                path: 'libros/libro-detalle/:tipo',
                component: LibroDetallesComponent
            },
            {
                path: 'libros/libro-guias',
                component: LibroGuiasComponent
            },
            {
                path: 'libros/libro-guias/:anio/:mes',
                component: LibroGuiasComponent
            },
            {
                path: 'libros/libro-guias/:anio/:mes/:rut',
                component: GuiasPorClienteComponent
            },
            {
                path: 'libros/libro-imprimir/:anio/:mes/:tipo',
                component: LibroImprimirComponent
            },
            {
                path: 'libros/libro-imprimir/:tipo',
                component: LibroImprimirComponent
            },
            {
                path: 'libros/libro-sinperiodo',
                component: LibroSinPeriodoComponent
            },
            {
                path: 'clientes',
                component: ClienteListaComponent
            },
            {
                path: 'clientes/cliente-lista/:pagina/:largoPagina',
                component: ClienteListaComponent
            },
            {
                path: 'clientes/cliente-detalle/:rut',
                component: ClienteDetalleComponent
            },
            {
                path: 'clientes/cliente-modificar/:rut/:accion',
                component: ClienteModificarComponent
            },
            {
                path: 'recepciones/recepcion-lista/:pagina/:largoPagina',
                component: RecepcionListaComponent
            },
            {
                path: 'inventario/inventario-detalle',
                component: InventarioDetallesComponent
            },
            {
                path: 'inventario/producto-detalle/:id',
                component: ProductoDetallesComponent
            },
            {
                path: 'inventario/producto-modificar/:accion/:id',
                component: ProductoModificarComponent
            },
            {
                path: 'inventario/producto-crear',
                component: ProductoModificarComponent
            },
            {
                path: 'inventario/producto-eliminar/:id',
                component: ProductoEliminarComponent
            },
            {
                path: 'inventario/producto-documentos/:id',
                component: ProductoDocumentosComponent
            },
            {
                path: 'instancia/instancia-detalle/:id',
                component: InstanciaDetalleComponent
            },
            {
                path: 'instancia/folio-lista',
                component: FolioListaComponent
            },
            {
                path: 'instancia/folio-detalle/:id',
                component: FolioDetalleComponent
            },
            {
                path: 'instancia/periodo-lista',
                component: PeriodoListaComponent
            },
            {
                path: 'instancia/periodo-detalle/:anio/:mes',
                component: PeriodoDetalleComponent
            },
            {
                path: 'envios/envio-crear',
                component: EnvioCrearComponent
            },
            {
                path: 'envios/envio-detalle/:id',
                component: EnvioDetallesComponent
            },
            {
                path: 'administracion',
                component: AdministracionDashboardComponent
            },
            {
                path: 'administracion/certificacion-boletas',
                component: AdministracionCertificacionBoletasComponent
            },
            {
                path: 'administracion/instancia/propiedades-globales',
                component: InstanciaPropiedadesglobalesComponent
            },
            {
                path: 'ayuda',
                component: AyudaIndexComponent
            },
            {
                path: 'ayuda/articulo-detalle/:id',
                component: ArticuloDetalleComponent
            },
        ]
    },
    {
    path: 'wrapperless',
        component: WrapperlessComponent,
        children: [
            {
                path: 'libros/libro-imprimir/:anio/:mes/:tipo',
                component: LibroImprimirComponent
            },
            {
                path: 'libros/libro-imprimir/:tipo',
                component: LibroImprimirComponent
            },
        ]
    }
]
