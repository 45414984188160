export enum TipoDocumento
{
    SET = -1,
    FacturaVenta = 30,
    FacturaVentaExenta = 32,
    FacturaElectronica = 33,
    FacturaExentaElectronica = 34,
    Boleta = 35,
    BoletaExenta = 38,
    BoletaElectronica = 39,
    LiquidacionFactura = 40,
    BoletaExentaElectronica = 41,
    LiquidacionFacturaElectronica = 43,
    FacturaCompra = 45,
    FacturaCompraElectronica = 46,
    GuiaDespacho = 50,
    GuiaDespachoElectronica = 52,
    NotaDebito= 55,
    NotaDebitoElectronica = 56,
    NotaCredito = 60,
    NotaCreditoElectronica = 61,
    Liquidacion = 103,
    OrdenCompra = 801,
    NotaDePedido = 802
}