<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>Duplicar Documento</h1>
    <div mat-dialog-content>
      <p>Para duplicar un documento ud debe crear otro documento con el mismo contenido que este. ¿Desea crear este
        documento?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="{ result: 'ok', documento: this.documento }" [routerLink]="['/erp/documentos/documento-modificar', 'duplicar', this.documento.ID]" class="btn btn-success" cdkFocusInitial>Duplicar</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>