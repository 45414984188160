<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>Remover del periodo</h1>
    <div mat-dialog-content>
      <p>¿Estas seguro que quieres remover de este periodo el documento?. Esto significa que este documento ya no
        figurará en ningun periodo.</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="{ result: 'ok', documento: documento }" class="btn btn-danger" cdkFocusInitial>Remover</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>