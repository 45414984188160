import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RecepcionesService } from '../../servicios/servicio.recepciones';

@Component({
  selector: 'app-recepcion-lista',
  templateUrl: './recepcion-lista.component.html',
  styleUrls: ['./recepcion-lista.component.css']
})
export class RecepcionListaComponent implements OnInit {
  public recepciones: any;

  constructor(
    private route: ActivatedRoute,
    private servicioRecepciones: RecepcionesService
  ) { }

  ngOnInit() {
    let pagina = 0;
    let largoPagina = 0;
    this.route.params.subscribe(params => {
      pagina = +params['pagina']; // (+) converts string 'id' to a number
      largoPagina = +params['largoPagina']; // (+) converts string 'id' to a number
      console.log("consultando lista pagina " + pagina + "  con " + largoPagina + " elementos");
      this.servicioRecepciones.getRecepciones(pagina, largoPagina).subscribe(response => {
        this.recepciones = response.Datos;
        console.log(this.recepciones);
      });
    });
  }

}
