<div class="row">
  <div class="col-xs-12">
    <h1 mat-dialog-title>¿Quieres crear a este cliente?</h1>
    <div mat-dialog-content>
      <p>El cliente no existe entre nuestros registros, ¿deseas que lo agreguemos para tu proxima venta?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="{ result: 'ok', documento: documento }" class="btn btn-success" cdkFocusInitial>Crear Cliente</button>
      <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
</div>