import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class DocumentosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private sesionesService: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.sesionesService.obtenerSesion().token
          }

          return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.sesionesService.obtenerSesion().token
          }

          return new HttpHeaders(headerDict);
    }

    public getDocumento(idDocumento: string) : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(this.urlApi + `DocumentoApi/Get?id=${idDocumento}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getDocumentoPorReferencia(idReferencia: string) : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(this.urlApi + `DocumentoApi/SeleccionarPorReferencia?id=${idReferencia}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getRespuestaSii(idDocumento: string) : Observable<any> {
        var headers = this.headersGet();
        return this.http.get(this.urlApi + `DocumentoApi/RespuestaSii?id=${idDocumento}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public emision(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/Emision`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public emisionBoletaElectronica(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/EmisionBoletaElectronica`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public ingreso(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/Ingreso`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public ingresoBoletas(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/IngresoBoletas`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public modificarBoletas(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/ModificarBoletas`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public modificar(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/Modificar`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public eliminar(documento: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/Eliminar`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public emitir(documento: any) : Observable<any> {
        var envioDTE = { Documentos: [documento]};
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/Emitir`, envioDTE, { headers: headers }).pipe(map((res: Response) => res));
    }

    public reenviarDTE(documento: any) : Observable<any> {
        var envioDTE = { Documentos: [documento]};
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/ReenviarDTE`, envioDTE, { headers: headers }).pipe(map((res: Response) => res));
    }

    public emitirBoleta(documento: any) : Observable<any> {
        var envioDTE = { Documentos: [documento]};
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/EmitirBoletaElectronica`, envioDTE, { headers: headers }).pipe(map((res: Response) => res));
    }

    public reenviarBoleta(documento: any) : Observable<any> {
        var envioDTE = { Documentos: [documento]};
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/ReenviarBoleta`, envioDTE, { headers: headers }).pipe(map((res: Response) => res));
    }

    public emitirBoletasParaCertificacion(documentos: any[]) : Observable<any> {
        var envioDTE = { Documentos: documentos};
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/CertificarEmisionBoletasElectronicas`, envioDTE, { headers: headers }).pipe(map((res: Response) => res));
    }

    public emitirVarios(documentos: Array<any>) : Observable<any> {
        var envioDTE = { Documentos: documentos};
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/Emitir`, envioDTE, { headers: headers }).pipe(map((res: Response) => res));
    }

    public enviarPorCorreo(documento, email) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/EnviarPorCorreo?email=${email}`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public cambiarPeriodo(documento, anio, mes) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/CambiarPeriodo/?anio=${anio}&mes=${mes}`, documento, { headers: headers }).pipe(map((res: Response) => res));
    }

    public documentoDesdeGuias(guias: Array<any>) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `DocumentoApi/DocumentoDesdeGuias`, guias, { headers: headers }).pipe(map((res: Response) => res));
    }

    public descargarPDF(documento: any, cedible: boolean) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.get(this.urlApi + `DocumentoApi/PDF/?id=${documento.id}&cedible=${cedible}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public descargarPDFMobile(documento: any) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.get(this.urlApi + `DocumentoApi/PDFMobile?id=${documento.id}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public descargarPDFBorrador(documento: any) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.get(this.urlApi + `DocumentoApi/PDFBorrador/?id=${documento.id}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public descargarXML(documento: any) : Observable<any>  {
        var headers = this.headersPost();
        return this.http.get(this.urlApi + `DocumentoApi/XML/?id=${documento.id}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public obtenerEmailIntercambio(rut: number): Observable<any> {
        var headers = this.headersGet();
        return this.http.get(this.urlApi + `DocumentoApi/ObtenerEmailIntercambio/?rut=${rut}`, { headers: headers }).pipe(map((res: Response) => res));
    }

    public obtenerTokenBoleta() : Observable<any>  {
      var headers = this.headersPost();
      return this.http.get(this.urlApi + `DocumentoApi/ObtenerTokenBoleta`, { headers: headers }).pipe(map((res: Response) => res));
    }
}
