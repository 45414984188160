import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentosService } from '../../../../servicios/servicio.documentos';

@Component({
  selector: 'app-libros-dialogo-infoenvio',
  templateUrl: './libros-dialogo-confirmarguias.component.html',
  styleUrls: ['./libros-dialogo-confirmarguias.component.css']
})
export class LibrosDialogoConfirmarGuiasComponent implements OnInit {

  public documentosConObservaciones: Array<any>;
  public estaConfirmado: Boolean;

  constructor(
    private servicioDocumentos: DocumentosService,
    public dialogRef: MatDialogRef<LibrosDialogoConfirmarGuiasComponent>,
    @Inject(MAT_DIALOG_DATA) public guias: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.documentosConObservaciones = [];
    this.guias.forEach(guia => {
      this.servicioDocumentos.getDocumentoPorReferencia(guia.id).subscribe(resp => { 
        guia.documentoQueReferencia = resp.datos;
        if (this.checkearRespuestasPendientes()) {
          console.log(this.guias);
          this.documentosConObservaciones = this.calcularDocumentosConObservaciones();
          this.estaConfirmado = true;
        }
      });
    });
  }

  checkearRespuestasPendientes() : Boolean {
    for (let i=0; i<this.guias.length; i++) {
      if (!this.guias[i].documentoQueReferencia) 
        return false;
    }
    return true;
  }

  calcularDocumentosConObservaciones() : Array<any> {
    return this.guias.filter(m => m.documentoQueReferencia.numero > 0);
  }
}
