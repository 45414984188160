<mat-sidenav-container class="menu-container" (backdropClick)="sidenav.close()">
    <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" disableClose>
        <app-menu (onClose)="sidenav.close()"></app-menu>
        <p style="text-align: right; padding-right:20px">
            <button class="btn btn-sm btn-secondary" mat-button (click)="sidenav.close()">Cerrar</button>
        </p>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar>
            <mat-toolbar-row>
                <button style="border: 0px; margin: 0px; padding: 2px" (click)="sidenav.open()">
                    <mat-icon aria-hidden="false" aria-label="Menu principal" style="cursor: pointer;">menu</mat-icon>
                </button>
                <span><img height="35" style="height: 35px" src="assets/styles/images/logoThumb.png" /></span>
                <span class="menu-spacer"></span>
                <button style="border: 0px; margin: 0px; padding: 2px" mat-button [matMenuTriggerFor]="matMenuUsuario">
                    <mat-icon aria-hidden="false" aria-label="Mi cuenta" style="cursor: pointer;" >account_box</mat-icon>
                </button>
                <mat-menu #matMenuUsuario="matMenu">
                    <button mat-menu-item [routerLink]="['/erp/users/user-options']"><i class="fa fa-edit"></i> Opciones</button>
                    <button mat-menu-item (click)="salir()"><i class="fa fa-sign-out-alt"></i> Salir</button>
                </mat-menu>

            </mat-toolbar-row>
        </mat-toolbar>
        <div class="container-fluid">
            <br/>
            <div *ngIf="this.entorno != 'PRODUCCION'" style="text-align: center; background-color: crimson; color: white; padding: 5px; position: relative; z-index: 2;">Este es un ambiente de {{entorno}}. Las operaciones y datos disponibles son de prueba, no significan cambios en el ambiente de producción.</div>
            <app-notificaciones></app-notificaciones>
            <router-outlet></router-outlet>
            <ng-container *ngIf="this.boletaExpressHabilitada">
              <app-boleta-total></app-boleta-total>
            </ng-container>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
