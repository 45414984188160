<div *ngIf="this.propiedadesGlobales" class="panel panel-default">
  <div class="panel-heading">
    <h3>Propiedades de esta instancia</h3>
  </div>
  <div class="panel-body">
    <ng-container *ngFor="let prop of this.propiedadesGlobales">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          {{ prop[0] }}
        </div>
        <div class="col-xs-12 col-md-6">
          <strong>Value:</strong> {{ prop[1].value }}
          <br><br>
          <strong>Inner Value:</strong> {{ prop[1].innerValue }}
        </div>
      </div>
      <hr>
    </ng-container>
    <hr />
  </div>
</div>