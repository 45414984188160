<div class="panel panel-default">
  <div class="panel-heading">
    <i class="fa fa-send"></i> Emitir boletas casos de prueba
  </div>
  <div class="panel-body">
    <p>El plan es que acá cargue el archivo certificacion-boletas\boletas.json, complete datos como fecha, información
      del emisor/destinatario y luego de eso cree las 5 boletas que piden en el caso de pruebas con sólo un click.</p>
  </div>
</div>

<div class="panel panel-default">
  <div class="panel-heading">
    <i class="fa fa-send"></i> Enviar Boletas en Batch
  </div>
  <div class="panel-body">
    <div class="form-group">
      <label>
        Por favor, ingrese los IDs de las boletas que desea enviar separados por comas:
      </label>
      <div class="input-group" style="width: 100%">
        <input type="text" [(ngModel)]="boletasAEmitir" placeholder="Ingrese IDs" required="true" class="form-control">
      </div>
    </div>
    <button class="btn btn-success" value="Emitir" (click)="emitir()">Emitir</button>
  </div>
</div>
