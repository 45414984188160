import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { ErpModule } from './erp/erp.module';
import { UsersModule } from './users/users.module';
import { DocumentosModule } from './documentos/documentos.module';
import { LibrosModule } from './libros/libros.module';
import { ClientesModule } from './clientes/clientes.module';
import { RecepcionesModule } from './recepciones/recepciones.module';
import { InventarioModule } from './inventario/inventario.module';
import { InstanciaModule } from './instancia/instancia.module';
import { InterceptorAutentificacion } from './servicios/interceptores/interceptor.autentificacion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localeEsCL from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { EnviosModule } from './envios/envios.module';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';
//import { HttpErrorInterceptor } from './servicios/interceptores/interceptor.http-error';
import { AyudaModule } from './ayuda/ayuda.module';
import { AdministracionDashboardComponent } from './administracion/administracion-dashboard/administracion-dashboard.component';
import { AdministracionCertificacionBoletasComponent } from './administracion/administracion-certificacionboletas/administracion-certificacionboletas.component';
import { SesionesService } from './servicios/servicio.sesiones';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
registerLocaleData(localeEsCL);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AdministracionDashboardComponent,
    AdministracionCertificacionBoletasComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    NgxGoogleAnalyticsModule.forRoot('UA-60546228-1'),
    NgxGoogleAnalyticsRouterModule,
    ErpModule,
    UsersModule,
    DocumentosModule,
    LibrosModule,
    ClientesModule,
    RecepcionesModule,
    InventarioModule,
    InstanciaModule,
    EnviosModule,
    AyudaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule
  ],
  exports: [TranslateModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useFactory: authHttpServiceInterceptor,
    multi: true,
    deps: [Router],
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: HttpErrorInterceptor,
  //   multi: true
  // },
  { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
  { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function authHttpServiceInterceptor(router: Router) {
  return new InterceptorAutentificacion(router);
}
