<div class="container-fluid">
<div class="row">
    <div class="col-xs-12">
        <div class="col-md-4 col-md-offset-4">
            <div *ngIf="entorno != 'PRODUCCION'" style="text-align: center; background-color: crimson; color: white; padding: 5px; position: relative; z-index: 2;">Este es un ambiente de {{entorno}}. Las operaciones y datos disponibles son de prueba, no significan cambios en el ambiente de producción.</div>
            <app-notificaciones></app-notificaciones>
        </div>
    </div>
    <div class="col-xs-12">
        <br/>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
</div>