import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionesService } from './servicio.sesiones';

@Injectable()
export class InventarioService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionesService
    ) {
        this.urlApi = environment.apiURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    public getProductos(pagina: number, largoPagina: number, filtro: string) : Observable<any> {
        
        var options =  {
            headers: this.headersGet()
        };

        return this.http.get(`${this.urlApi}ProductoApi/Productos?pagina=${pagina}&largoPagina=${largoPagina}&filtro=${filtro}`, options);
    }

    public getProducto(id: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}ProductoApi/Get?id=${id}`, options);
    }

    public getImpuestos() : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}InstanciaApi/Impuestos`, options);
    }

    public modificar(producto: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `ProductoApi/Modificar`, producto, { headers: headers }).pipe(map((res: Response) => res));
    }

    public eliminar(producto: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `ProductoApi/Eliminar`,  producto, { headers: headers }).pipe(map((res: Response) => res));
    }

    public crear(producto: any) : Observable<any> {
        var headers = this.headersPost();
        return this.http.post(this.urlApi + `ProductoApi/Crear`, producto, { headers: headers }).pipe(map((res: Response) => res));
    }

    public getDocumentos(idProducto: number, operacion: number, pagina: number, cantidadResultados: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}ProductoApi/Documentos?idProducto=${idProducto}&operacion=${operacion}&pagina=${pagina}&cantidadResultados=${cantidadResultados}`, options);
    }
}