<div class="row">
    <div class="col-xs-12">
        <h1 mat-dialog-title>Enviar Copia</h1>
        <div mat-dialog-content>
            <p>Ingrese la dirección de correo a la cual quiere enviar este documento (si viene preestablecida, es la que
                el destinatario publicó en el SII para estos fines)</p>
            <fieldset>
                <div class="form-group">
                    <label>
                        Email del destinatario
                    </label>
                    <input type="text" [(ngModel)]="this.correoElectronico"
                        placeholder="Ingrese el email al que desea enviar el documento" required="true"
                        class="form-control" />
                </div>
            </fieldset>
        </div>
        <div mat-dialog-actions>
            <button mat-button [mat-dialog-close]="this.correoElectronico" class="btn btn-danger" cdkFocusInitial>Enviar</button>
            <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
        </div>
    </div>
</div>