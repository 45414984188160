<div class="panel panel-default">
  <div class="panel-heading">
    <h4>Recepciones</h4>
  </div>
  <div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="paginacion">
          <ul class="pager" style="text-align: right">
            <span class="small">Página 1 &nbsp;&nbsp;</span>
            <li class="disabled">
              <a href="/RecepcionDTE?pagina=0&amp;cantResultados=10">
                <i class="fa fa-arrow-left"></i> Anterior</a>
            </li>
            <li class="">
              <a href="/RecepcionDTE?pagina=2&amp;cantResultados=10">Siguiente
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
          </ul>
        </nav>
        
          <ng-container *ngFor="let recepcion of recepciones">
            <div class="panel panel-default">

              <ng-container *ngIf="recepcion.Recepciones.length === 0">
                <div class="panel-heading">
                  <h5>
                    No se recibieron DTE's
                  </h5>
                </div>
              </ng-container>
              <ng-container *ngIf="!(recepcion.Recepciones.length === 0)">
                <div class="panel-heading">
                  <h4 title="{{recepcion.Recepciones[0].Documento.Emisor.RazonSocial}}">
                    <a class="btn btn-default" data-toggle="collapse" data-parent="#accordion" href="#collapse-{{recepcion.ID}}">
                      <i class="fa fa-arrow-down"></i>
                    </a>
                    {{recepcion.Recepciones[0].Documento.Emisor.RazonSocial}}
                    <span class="hidden-xs hidden-sm small">
                      {{recepcion.RUTEmisor | number:'':'es-CL'}}
                    </span>
                  </h4>
                </div>
                <div id="collapse-{{recepcion.ID}}" class="panel-collapse collapse">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-md-6">
                        <h4>Documento</h4>
                        <h5>
                          <strong>{{recepcion.NombreFichero}}</strong>
                          <a href="/RecepcionDTE/DTEXML/2242" target="_blank">
                            <i class="fa fa-download"></i> Descargar</a>
                        </h5>
                        <p>
                          Recibido a las:
                          <br>
                          <strong>{{ recepcion.FechaCreacion | date : "yyyy-MM-dd H:mm" }}</strong>
                        </p>
                        <p>
                          Estado:
                          <br>
                          <strong>{{ 'DOCUMENTOS.EstadoEnvio_' + recepcion.Estado | translate }}</strong>
                        </p>
                      </div>
                      
                      <div class="col-md-6">
                        <h4>Acuse de Recibo</h4>
                        <ng-container *ngIf="recepcion.XmlAcuseRecibo != null">
                          <p>Enviado a:
                            <strong>{{recepcion.EmailAcuseRecibo}}</strong> el
                            <strong>{{ recepcion.FechaAcuseRecibo | date : "yyyy-MM-dd H:mm" }}</strong>
                          </p>
                          <p>
                            <a href="/RecepcionDTE/AcuseReciboXML/2242" target="_blank">
                              <i class="fa fa-download"></i> Descargar</a> &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="/RecepcionDTE/EnviarAcuseRecibo/2242">
                              <i class="fa fa-envelope"></i> Reenviar acuse</a>
                            <br>
                          </p>
                        </ng-container>
                        <ng-container *ngIf="recepcion.XmlAcuseRecibo == null">
                          <strong>No se pudo generar un acuse de recibo para esta recepción</strong>
                        </ng-container>
                      </div>
                    </div>
                    <h4>Documentos dentro del envío</h4>
                    <br>
                      <ng-container *ngFor="let documento of recepcion.Recepciones">
                        <ng-container *ngIf="documento.Documento.ID > 0">
                            <div class="panel panel-{{documento.XmlResultadoDTE? 'success' : 'warning' }}">
                                <div class="panel-heading">
                                  <div class="btn-group pull-right">
                                      <ng-container *ngIf="!(documento.XmlResultadoDTE)">
                                          <a data-toggle="modal" class="btn btn-danger" data-target="#EnviarRespuestaDoc-{{recepcion.ID}}-{{documento.Documento.ID}}"><i class="fa fa-arrow-right"></i> Ingresar</a>
                                      </ng-container>
                                      <ng-container *ngIf="documento.XmlResultadoDTE">
                                          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                              Acciones <span class="caret"></span>
                                          </button>
                                          <ul class="dropdown-menu" role="menu">
                                              <li><a href=""><i class="fa fa-arrow-right"></i> Ir al Documento</a></li>
                                              <li><a href="" target="_blank"><i class="fa fa-file"></i> Xml Recepcion DTE</a></li>
                                              <li><a href="" target="_blank"><i class="fa fa-file"></i> Xml Recepcion Mercaderias</a></li>
                                          </ul>
                                      </ng-container>
                                  </div>
                                  <h4>
                                    <strong>{{!documento.XmlResultadoDTE ? 'Documento pendiente de ingreso' : 'DOCUMENTOS.EstadoRecepcionDocumento_' + documento.EstadoRecepcionDocumento | translate }}</strong>
                                  </h4>
                                </div>
                                <div class="panel-body">
                                  <app-documento-detalles [documento]="documento.Documento"></app-documento-detalles>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!(documento.Documento.ID > 0)">
                            <div class="panel panel-danger">
                              <div class="panel-heading">
                                <h4>DESCONOCIDO</h4>
                              </div>
                              <div class="panel-body">
                                <p>El documento no puede ser recuperado porque fue eliminado o hay un problema con el mismo.  Si tiene dudas con este mensaje contactenos a traves de la seccion de ayuda</p>
                              </div>
                            </div>
                        </ng-container>
                      </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        
        <nav aria-label="paginacion">
          <ul class="pager" style="text-align: right">
            <span class="small">Página 1 &nbsp;&nbsp;</span>
            <li class="disabled">
              <a href="/RecepcionDTE?pagina=0&amp;cantResultados=10">
                <i class="fa fa-arrow-left"></i> Anterior</a>
            </li>
            <li class="">
              <a href="/RecepcionDTE?pagina=2&amp;cantResultados=10">Siguiente
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="panel-footer">
    <a data-toggle="modal" data-target="#CargarDocumento" href="#" class="btn btn-success">
      <i class="fa fa-plus"></i> Nueva recepción</a>
  </div>
</div>